import { Utils } from "../Utils";
import { MemoryLocation } from "../code/MemoryLocation";
import { DataRange, Range } from "../code/Range";
import { DataCommand } from "./DataCommand";

const hex = (x: number) => Utils.toHexAuto(x);

export const newDataCommand = (address: number, countBytes: number, name: string): DataCommand => {
    return {
        type: 'data',
        address,
        countBytes,
        name
    }
}

export const markUpMemory = (command: DataCommand, memory: MemoryLocation[], ranges: Range[]) => {
    const { address, countBytes, name } = command;
    for (let index = address; index < address + countBytes; index++) {
        if (index > 0xffff) { continue; }
        memory[index].IsData = true;

        if (index > address) {
            memory[index].CountWithinBlock = -(index - address);
        }
    }

    memory[address].SectionHeading = `${name}`;
    memory[address].CountWithinBlock = countBytes;
    ranges.push(new DataRange(name, address, address + countBytes - 1));
}

export const equals = (command: DataCommand, otherCommand: DataCommand) => {

    let res = true;
    res &&= (command.address === otherCommand.address);
    res &&= (command.countBytes === otherCommand.countBytes);
    res &&= (command.name === otherCommand.name);

    return res;
}

export const version = "1";

export const serialise = (command: DataCommand): string => {
    const escapedName = encodeURIComponent(command.name.replaceAll('|', '-'));
    return `dr|${hex(command.address)}|${hex(command.countBytes)}|${escapedName}`;
}

const deserialise_v01 = (components: string[]): DataCommand | undefined => {

    if (components.length !== 3) { return; }

    const address = Number(`0x${components[0]}`);
    const countBytes = Number(`0x${components[1]}`);
    const name = components[2];
    if (isNaN(address) || isNaN(countBytes) || name === '') { return; }

    return newDataCommand(address, countBytes, decodeURIComponent(name));
}

export const deserialisers: { [version: string]: (c: string[]) => DataCommand | undefined } = {
    '1': deserialise_v01
};
