import React, { CSSProperties, useEffect, useMemo, useRef, MouseEvent } from 'react';
import styles from './Graphic.module.css';
import { GraphicData } from '../../classes/graphics/GraphicData';
import { ViewportLocation } from '../../store/rendererSlice';
import { useOnDraw } from '../../classes/effects/useOnDraw';
import { cx, Utils } from '../../classes/Utils';
import { GraphicCommand } from '../../classes/commands/GraphicCommand';
import { logMinorComponentRender } from '../../classes/Logger';
import { equals } from "../../classes/commands/GraphicCommandHelpers";
import { MemoryAddress } from '../../classes/code/MemoryLocation';


type GraphicProps = {
    settings: GraphicCommand,
    location: ViewportLocation,
    focusable?: boolean,
    fitToSize?: boolean,
}

export const Graphic: React.FunctionComponent<GraphicProps> = (props) => {

    logMinorComponentRender(Graphic.name);

    const divRefSource = useRef<HTMLDivElement>(null);
    const dataRef = useRef<GraphicData>({
        location: props.location,
        left: 0,
        top: 0,
        width: 0,
        height: 0
    });


    const { settings, focusable, fitToSize } = props;
    const unfocusable = !(focusable ?? true);

    useEffect(() => {

        const navigate = (e: CustomEvent<{ sources?: MemoryAddress[], target: MemoryAddress, saveCurrentAddress?: boolean }>) => {

            if (unfocusable) { return; }
            if (settings == null) { return; }

            const { target } = e.detail;

            const address = target.address;
            const gfxStartAddress = settings.address;
            const gfxEndAddress = settings.address + settings.countBytes - 1;
            const shouldBeSelected = (address >= gfxStartAddress) && (address <= gfxEndAddress);

            if (shouldBeSelected) {
                divRefSource.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }

        document.addEventListener<any>('navigate-to-address', navigate);

        return () => {
            document.removeEventListener<any>('navigate-to-address', navigate);
        }
    }, [divRefSource, settings, unfocusable]);

    const onDraw = useMemo(() => () => {

        if (divRefSource.current == null || dataRef.current == null) {
            return undefined;
        }

        if (settings == null) { return undefined; }

        const data = dataRef.current;
        const rect = divRefSource.current.getBoundingClientRect();
        data.left = rect.left;
        data.top = rect.top;
        data.width = rect.width;
        data.height = rect.height;
        data.settings = settings ?? undefined;

        return data;

    }, [divRefSource, dataRef, settings]);
    useOnDraw(onDraw);

    const scale = settings?.scale ?? 1;
    const widthPx = settings?.widthPx ?? 1;
    const heightPx = settings?.heightPx ?? 1;

    const cssSizes: CSSProperties = { ['--graphic-width' as any]: `${widthPx * scale}px`, ['--graphic-height' as any]: `${heightPx * scale}px` };
    const cssClass = fitToSize ? styles.fitToSize : styles.graphic;

    return (
        <div className={cssClass} style={cssSizes} ref={divRefSource} />
    );
}


type WrappedGraphicProps = {
    selected?: boolean,
    onClick?: (s: GraphicCommand) => void,
    onDoubleClick?: () => void
}

const WrappedGraphicBase: React.FC<GraphicProps & WrappedGraphicProps> = (props) => {

    logMinorComponentRender(WrappedGraphicBase.name);

    const { focusable, location, settings, fitToSize, onClick, onDoubleClick } = props;

    const address = settings?.address ?? 0;
    const countBytes = settings?.countBytes ?? 0;
    const label = `${Utils.to4DigitHexString(address)}+${Utils.toHexAuto(countBytes)}`;

    let timer: NodeJS.Timeout;

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (onClick == null) { return; }

        if (onDoubleClick == null) { onClick(settings); return; }

        if (e.detail !== 1) { return; }
        timer = setTimeout(() => onClick(settings), 200);
    }

    const handleDoubleClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        clearTimeout(timer);

        if (onDoubleClick != null) { onDoubleClick(); }
    }

    return (
        <div className={cx(styles.wrapper, props.selected ? styles.selected : undefined)} onClick={handleClick} onDoubleClick={handleDoubleClick}>
            <div>{label}</div>
            <Graphic focusable={focusable} location={location} settings={settings} fitToSize={fitToSize} />
        </div>
    );
}

export const WrappedGraphic = React.memo(WrappedGraphicBase, (prev, next) => (prev.selected === next.selected) && equals(prev.settings, next.settings) && (prev.onClick === next.onClick));