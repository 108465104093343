import React, { createContext, useState } from 'react';
import { fromSnapshot as epFromSnapshot } from '../../classes/commands/EntryPointHelpers';
import { deserialise as deserialiseProjectDescription, newProjectDescription } from '../../classes/ProjectDescriptionHelpers';
import { SnapshotLoader } from '../../classes/SnapshotLoader';

import styles from './ProjectPicker.module.css';
import { deleteAsync, getAsync, listAsync as listLocalAsync, saveAsync } from '../../classes/LocalStorageHelper';
import { deleteAsync as deleteCloudAsync, getAsync as getCloudAsync, listAsync as listCloudAsync, listPublicAsync, saveAsync as saveCloudAsync } from '../../classes/CloudStorageHelper';
import { newGraphicCommandsFromSnapshot } from '../../classes/commands/GraphicCommandHelpers';
import { makeCopy, makeEmpty, makeGuid } from '../../classes/ProjectDescription';
import { logError, logMajorComponentRender } from '../../classes/Logger';
import { useLocation, useNavigate } from 'react-router-dom';
import { logProjectActionAnalytic } from '../../classes/code/Firebase';
import { Stack } from '../Stack';
import { ProjectLocationTabs, TabTypes } from './ProjectLocationTabs';
import { ProjectPickerControls } from './ProjectPickerControls';
import { PaginatedProjectList, ProjectList } from './ProjectList';
import { useAppSelector } from '../../store/hooks';
import { selectUser } from '../../store/uiSlice';
import { setRoute } from '../../App';

export const ProjectActionsContext = createContext<{
    cancel: () => void,
    newProject: (isCloud: boolean) => Promise<void>,
    newProjectFromFile: (isCloud: boolean, files: FileList | null) => Promise<void>,
    openProject: (guid: string, name: string) => Promise<void>,
    duplicateProject: (guid: string, name: string, isCloud: boolean, toOtherTarget?: boolean) => Promise<void>,
    deleteProject: (guid: string, name: string, isCloud: boolean) => Promise<void>,
    activeTab: TabTypes
} | undefined>(undefined);

export const ProjectPicker: React.FC<{ onClose: () => void }> = ({ onClose }) => {

    logMajorComponentRender(ProjectPicker.name);

    const navigate = useNavigate();
    const location = useLocation();

    const { uid, userName, isSignedIn } = useAppSelector(selectUser);

    const [isReady, setIsReady] = useState<boolean>(true);
    const [activeTab, SetActiveTab] = useState<TabTypes>(isSignedIn ? 'Cloud' : 'Local');

    const refresh = async () => {
        document.dispatchEvent(new CustomEvent('refresh-project-lists'));
    }

    const cancel = () => {
        onClose();
    }

    const newProject = async (isCloud: boolean) => {

        try {
            setIsReady(false);

            const desc = makeEmpty();

            logProjectActionAnalytic('new_project', desc.guid, desc.name);
            const saveMethod = isCloud ? saveCloudAsync : saveAsync;
            if (isCloud) {
                desc.uid = uid;
                desc.userName = userName;
            }
            await saveMethod(desc, Date.now());
            setRoute(navigate, location, { guid: desc.guid, tool: 'graphics' });
        }
        catch (error) {
            logError((error instanceof Error) ? `${error.name} : ${error.message}` : String(error));
        }
        finally {
            setIsReady(true);
            onClose();
        }
    }

    const newProjectFromFile = async (isCloud: boolean, files: FileList | null) => {

        if (files == null) { return; }
        if (files.length === 0) { return; }

        try {
            setIsReady(false);

            const saveMethod = isCloud ? saveCloudAsync : saveAsync;

            const file = files[0];

            const isProject = file.name.endsWith(".46c");
            const isSnapshot = file.name.endsWith(".vsf");
            if (isProject) {
                logProjectActionAnalytic('new_project_from_project_file', '00000000', file.name);

                const contents = await file.text();
                const desc = deserialiseProjectDescription(contents);
                if (desc == null) { throw new Error('Cannot load project file'); }

                desc.guid = makeGuid();

                desc.uid = isCloud ? uid : undefined;
                desc.userName = isCloud ? userName : undefined;
                desc.visibility = 'private';

                await saveMethod(desc, Date.now());

                setRoute(navigate, location, { guid: desc.guid, tool: 'graphics' });

            } else if (isSnapshot) {
                logProjectActionAnalytic('new_project_from_snapshot_file', '00000000', file.name);

                const contentsArray = await file.arrayBuffer();
                const contents = new Uint8Array(contentsArray);

                const snapshot = new SnapshotLoader().LoadFromBytes(contents);
                if (snapshot == null) { throw new Error('Cannot load snapshot file'); }

                const { RAM, COLRAM, graphics, cpu } = snapshot;
                const graphicCommands = newGraphicCommandsFromSnapshot(graphics);
                const entryPointCommand = epFromSnapshot(cpu);
                const desc = newProjectDescription(file.name, makeGuid(), 'private', Array.from(RAM), Array.from(COLRAM), [...graphicCommands, entryPointCommand]);

                desc.uid = isCloud ? uid : undefined;
                desc.userName = isCloud ? userName : undefined;

                await saveMethod(desc, Date.now());

                setRoute(navigate, location, { guid: desc.guid, tool: 'graphics' });
            } else {
                throw new Error('Not a valid format');
            }
        }
        catch (error) {
            logError((error instanceof Error) ? `${error.name} : ${error.message}` : String(error));
        }
        finally {
            setIsReady(true);
            onClose();
        }
    }

    const openProject = async (guid: string, name: string) => {

        logProjectActionAnalytic('open_project', guid, name);

        navigate(`/project/${guid}`);

        onClose();
    }

    const duplicateProject = async (guid: string, name: string, isCloud: boolean, toOtherTarget: boolean = false) => {

        const action = !toOtherTarget ? 'duplicate_project' : (isCloud ? 'duplicate_cloud_project_to_local' : 'duplicate_local_project_to_cloud');
        logProjectActionAnalytic(action, guid, name);

        setIsReady(false);

        try {
            const getMethod = isCloud ? getCloudAsync : getAsync;
            const file = await getMethod(guid);
            if (file != null) {
                const copy = makeCopy(file);

                const copyFromLocalToCloud = (isCloud !== toOtherTarget);
                if (copyFromLocalToCloud && isSignedIn) {
                    copy.userName = userName;
                    copy.uid = uid;
                }
                const saveMethod = copyFromLocalToCloud ? saveCloudAsync : saveAsync;
                await saveMethod(copy, Date.now());

                await refresh();
            }
        }
        catch (error) {
            logError((error instanceof Error) ? `${error.name} : ${error.message}` : String(error));
        }

        setIsReady(true);
    }

    const deleteProject = async (guid: string, name: string, isCloud: boolean) => {

        logProjectActionAnalytic('delete_project', guid, name);

        setIsReady(false);

        try {
            const deleteMethod = isCloud ? deleteCloudAsync : deleteAsync;
            await deleteMethod(guid);
            await refresh();
        }
        catch (error) {
            logError((error instanceof Error) ? `${error.name} : ${error.message}` : String(error));
        }

        setIsReady(true);
    }

    const isMyProjects = (activeTab !== 'Public');

    return (
        <ProjectActionsContext.Provider value={{ cancel, newProject, newProjectFromFile, openProject, duplicateProject, deleteProject, activeTab }}>
            {!isReady && <div className={styles.overlay} />}

            <Stack layout='vertical' sizes={isMyProjects ? 'auto auto 1fr' : 'auto 1fr'} cssClassName={styles.filepickerContainer}>

                <ProjectLocationTabs activeTab={activeTab} setTab={t => { SetActiveTab(t) }} />

                {isMyProjects && <ProjectPickerControls activeTab={activeTab} />}

                <div className={styles.projectsContainer}>
                    {(activeTab === 'Local') &&
                        <ProjectList listFunction={listLocalAsync} />
                    }
                    {(activeTab === 'Cloud') &&
                        <ProjectList listFunction={listCloudAsync} />
                    }
                    {(activeTab === 'Public') &&
                        <PaginatedProjectList listFunction={listPublicAsync} maxPerPage={10} />
                    }
                </div>

            </Stack>

        </ProjectActionsContext.Provider>
    );
}