import styles from './Sequences.module.css';
import { cx, toHex } from '../../classes/Utils';
import { BitGroup, ByteSequence } from './ByteSequence';
// import { Graphic } from '../../components/graphics/Graphic';
// import { ViewportLocation } from '../../store/rendererSlice';
// import { newGraphicCommand } from '../../classes/commands/GraphicCommandHelpers';


export const ByteAsBitGroups: React.FC<{ byte: number, groups: BitGroup[] }> = ({ byte, groups }) => {

    return (
        <div className={styles.describedByteContainer}>
            <div>{toHex(byte)}</div>
            <div>
                {groups.map((g, i) => {
                    const colourStyle = (g.label == null) ? styles.bitgroupUnlabelled : styles[`bitgroup${g.order % 8}`];
                    return <span key={i} className={cx(styles.groupedBits, colourStyle)}>{g.valueBin}</span>
                })}
            </div>
        </div>
    );
}

export const SequenceAsBitGroups: React.FC<{ sequence: ByteSequence }> = ({ sequence }) => {

    return (
        <div>
            <div>
                {sequence.summary}
            </div>

            <div className={styles.describedBytesAsBitsContainer}>
                {sequence.map((value, groups, i) => <ByteAsBitGroups key={i} byte={value} groups={groups} />)}
            </div>

            <div className={styles.describedBytesLabelsContainer}>
                {sequence.elements.map((d, i) =>
                    <div key={i}>
                        <span className={cx(styles.bitgroupLabel, styles[`bitgroup${d.order}`])}>{d.label}</span>
                        =
                        <span className={cx(styles.bitgroupLabel, styles[`bitgroup${d.order}`])}>{d.valueBin}</span>
                        → {d.displayed}
                    </div>)}
            </div>
        </div>
    );
}


export const SequencesAsSummaries: React.FC<{ sequences: ByteSequence[], selectedIndex: number, onClick: (i: number) => void }> = ({ sequences, selectedIndex, onClick }) => {

    return (
        <div className={styles.container}>
            {sequences.map((s, i) =>
                <button key={i} onClick={() => onClick(i)}>
                    <span className={cx(styles.detail, (i > selectedIndex) ? styles.unselected : undefined, i === selectedIndex ? styles.selected : undefined)}>
                        {s.summary}
                    </span>
                </button>
            )}
        </div>
    )
}


export const SequencesAsByteGroups: React.FC<{ sequences: ByteSequence[], selectedIndex: number, onClick: (i: number) => void }> = ({ sequences, selectedIndex, onClick }) => {

    return (
        <div className={styles.container}>
            {sequences.map((s, i) =>
                <button key={i} onClick={() => onClick(i)}>
                    <span className={cx((i > selectedIndex) ? styles.unselected : undefined, i === selectedIndex ? styles.selected : undefined)}>
                        {s.map((b, _, k) => <span key={k} className={styles.byte}>{toHex(b)}</span>)}
                    </span>
                </button>
            )}
        </div>
    )
}


// const PlottedSequencesAsGraphic: React.FC<{ sequences: PlottedByteSequence[] }> = ({ sequences }) => {

//     const s = newGraphicCommand({ countBytes: 0x3e8, heightPx: 200, widthPx: 320, scale: 1, graphicsType: 'CharacterMappedMultiColourRAM', address: 0x0000, referenceAddress1: 0x0400 });

//     return (
//         <div>
//             <Graphic location={ViewportLocation.Main} settings={s} />
//         </div>
//     )
// }
