import React from 'react';
import styles from './App.module.css';
import { LogoPanel } from './components/LogoPanel';
import { GraphicsCanvas } from './components/graphics/GraphicsCanvas';
import { GraphicsRenderer } from './components/graphics/GraphicsRenderer';
import { UserPanel } from './components/UserPanel';
import { useAppSelector } from './store/hooks';
import { logInfo, logMajorComponentRender } from './classes/Logger';
import { Stack } from './components/Stack';
import { Footer } from './components/Footer';
import { MenuPanel } from './components/MenuPanel';
import { ProjectPageRoot, ProjectPage, projectLoader } from './components/ProjectPage';
import { HomePage } from './components/HomePage';
import { createBrowserRouter, Navigate, NavigateFunction, Outlet, RouterProvider, useNavigation, Location } from 'react-router-dom';
import { NotFound } from './components/NotFound';
import { Loading } from './components/Loading';
import { ErrorPage } from './components/Error';
import { ToolTypes } from './store/toolSlice';
import { MemoryViewType } from './store/codeSlice';
import { Utils } from './classes/Utils';
import { MemoryAddress } from './classes/code/MemoryLocation';
import { MontezumasRevenge } from './articles/MontezumasRevenge';
import { SacredArmourOfAntiriad } from './articles/SacredArmourOfAntiriad';
import { ArticlePageRoot } from './components/ArticlePage';


const Layout: React.FunctionComponent = () => {

    logMajorComponentRender(Layout.name)

    const glAvailable = useAppSelector(state => state.renderer.glAvailable);

    const nav = useNavigation();
    const isLoading = nav.state === 'loading';

    return (
        <>

            <GraphicsCanvas />

            {glAvailable &&
                <>
                    <GraphicsRenderer />

                    <Stack layout='vertical' sizes={'auto 1fr auto'} cssClassName={styles.page}>

                        {/* Header */}
                        <Stack layout='horizontal' alignItems='center' sizes={'auto 1fr auto'}>
                            <LogoPanel />
                            <MenuPanel />
                            <UserPanel />
                        </Stack>

                        {/* Page */}
                        <div className={styles.container}>
                            {isLoading ? <Loading /> : <Outlet />}
                        </div>

                        {/* Footer */}
                        <Footer />

                    </Stack>

                </>
            }

        </>
    );
}

const makeRoute = (props: { guid?: string, tool?: ToolTypes, memoryView?: MemoryViewType, address?: number }) => {
    const { guid, memoryView, tool, address } = props;
    if (guid == null) {
        return '/';
    }

    const baseFragment = `/project/${guid}`;
    if (tool === 'graphics') { return `${baseFragment}/graphics`; }
    else if (tool === 'library') { return `${baseFragment}/library`; }
    else if (tool === 'code') {
        const viewFragment = (memoryView === 'ram' || memoryView === 'rom') ? `/${memoryView}` : '';
        const addressFragment = (address != null && 0 <= address && address <= 0x10000) ? `/${Utils.to4DigitHexString(address)}` : '';
        return `${baseFragment}/code${viewFragment}${addressFragment}`;
    }
    else { return baseFragment; }
}

export const setRouteWithAddress = (navigate: NavigateFunction, location: Location, props: { guid?: string, tool: ToolTypes, target: MemoryAddress }) => {
    setRoute(navigate, location, { guid: props.guid, tool: props.tool, memoryView: props.target.type, address: props.target.address });
}

export const setRoute = (navigate: NavigateFunction, location: Location, props: { guid?: string, tool: ToolTypes, memoryView?: MemoryViewType, address?: number }, force: boolean = true) => {
    const { guid, tool, memoryView, address } = props;
    if (guid == null) { return; }

    const targetRoute = makeRoute({ guid, tool, memoryView, address });
    logInfo(`Target route is : ${targetRoute}, current location is : ${location.pathname}`);
    if (location.pathname === targetRoute) { return; }

    logInfo(`Setting route to : ${targetRoute}`);
    navigate(targetRoute, { flushSync: force });
}

export const setRouteHome = (navigate: NavigateFunction) => {
    navigate('/');
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <HomePage />
            },
            {
                path: 'articles',
                element: <ArticlePageRoot />,
                children: [
                    {
                        path: 'montezuma',
                        element: <MontezumasRevenge />
                    },
                    {
                        path: 'antiriad',
                        element: <SacredArmourOfAntiriad />
                    },
                ]
            },
            {
                path: 'project/:guid',
                element: <Navigate to='./graphics' relative='path' replace={true} />
            },
            {
                path: 'project/:guid',
                element: <ProjectPageRoot />,
                loader: projectLoader,
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: 'graphics',
                        element: <ProjectPage toolParam='graphics' />,
                    },
                    {
                        path: 'library/:index?',
                        element: <ProjectPage toolParam='library' />,
                    },
                    {
                        path: 'code',
                        element: <Navigate to='./ram' relative='path' replace={true} />,
                    },
                    {
                        path: 'code',
                        children: [
                            {
                                path: 'ram/:address?',
                                element: <ProjectPage toolParam='code' memoryViewParam='ram' />,
                            },
                            {
                                path: 'rom/:address?',
                                element: <ProjectPage toolParam='code' memoryViewParam='rom' />,
                            },
                        ]
                    },
                ]
            },
            {
                path: '*',
                element: <NotFound />
            }
        ]
    },
]);

const App: React.FC = () => <RouterProvider router={router} fallbackElement={<Loading />} />;

export default App;