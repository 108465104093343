import React, { createRef, CSSProperties, useEffect, useState } from 'react';
import styles from './PreviewSettingsSection.module.css';
import { Graphic } from '../graphics/Graphic';
import { commandAdded, commandRemovedById, selectCurrentCommandIndex, selectGuidAndName } from '../../store/projectSlice';
import { ViewportLocation } from '../../store/rendererSlice';
import { previewCommandSelector } from '../../store/graphicsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';
import { Utils } from '../../classes/Utils';
import { selectActiveTool } from '../../store/toolSlice';
import { logCommandAnalytic } from '../../classes/code/Firebase';
import { selectIsProjectReadOnly } from '../../store/extraSelectors';
import { commandIcon } from './MarkupIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { useOnKeyboardShortcut } from '../../classes/effects/useOnKeyboardShortcut';
import { selectPageHasFocus } from '../../store/uiSlice';


export const PreviewSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(PreviewSettingsSection.name);

    const { guid, name } = useAppSelector(selectGuidAndName);
    const isReadOnly = useAppSelector(selectIsProjectReadOnly);

    const pageHasFocus = useAppSelector(selectPageHasFocus);

    const containerRef = createRef<HTMLDivElement>();
    const [containerHeight, SetContainerHeight] = useState(0);
    const [containerWidth, SetContainerWidth] = useState(0);
    const controlsRef = createRef<HTMLDivElement>();
    const [controlsHeight, SetControlsHeight] = useState(52);
    const headingRef = createRef<HTMLDivElement>();
    const [headingHeight, SetHeadingHeight] = useState(25);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (containerRef.current == null) { return; }
        if (containerRef.current.parentElement == null) { return; }
        if (controlsRef.current == null) { return; }
        if (headingRef.current == null) { return; }

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === containerRef.current?.parentElement) {
                    SetContainerWidth(entry.contentRect.width);
                    SetContainerHeight(entry.contentRect.height);
                } else if (entry.target === controlsRef.current) {
                    SetControlsHeight(entry.borderBoxSize[0].blockSize);
                } else if (entry.target === headingRef.current) {
                    SetHeadingHeight(entry.borderBoxSize[0].blockSize);
                }
            }
        });

        resizeObserver.observe(containerRef.current.parentElement);
        resizeObserver.observe(controlsRef.current);
        resizeObserver.observe(headingRef.current);

        return () => resizeObserver.disconnect();

    }, [containerRef, SetContainerWidth, SetContainerHeight, controlsRef, SetControlsHeight, headingRef, SetHeadingHeight]);

    const activeTool = useAppSelector(selectActiveTool);

    const currentCommandIndex = useAppSelector(selectCurrentCommandIndex);
    const previewCommand = useAppSelector(previewCommandSelector) ?? undefined;

    const handleAdd = () => {

        if (previewCommand == null) { return; }
        if ((isReadOnly || activeTool !== 'graphics')) { return; }

        logCommandAnalytic('add_command', 'graphic', previewCommand.address, guid, name);

        dispatch(commandAdded(previewCommand));
    }

    const handleDelete = () => {

        if (currentCommandIndex == null) { return; }
        if ((isReadOnly || activeTool !== 'library')) { return; }

        logCommandAnalytic('delete_command', 'graphic', previewCommand.address, guid, name);

        dispatch(commandRemovedById(currentCommandIndex));
    };

    useOnKeyboardShortcut('r', pageHasFocus, handleAdd);
    useOnKeyboardShortcut('Delete', pageHasFocus, handleDelete);

    if (previewCommand == null) { return null; }

    const sizesCss: CSSProperties = {
        ['--aspect' as any]: previewCommand.widthPx / previewCommand.heightPx,
        ['--parent-width' as any]: `${containerWidth}px`,
        ['--parent-height' as any]: `${containerHeight}px`,
        ['--controls-height' as any]: `${controlsHeight}px`,
        ['--heading-height' as any]: `${headingHeight}px`
    };

    const label = `${Utils.to4DigitHexString(previewCommand.address)}+${Utils.toHexAuto(previewCommand.countBytes)}`;

    return (
        <div className={styles.container} style={sizesCss} ref={containerRef}>
            <div className={styles.previewPanel}>

                <div className={styles.heading} ref={headingRef}>{label}</div>

                <div className={styles.graphicWrapper}>
                    <Graphic settings={previewCommand} location={ViewportLocation.Preview} fitToSize={true} focusable={false} />
                </div>

                <div className={styles.controls} ref={controlsRef}>
                    {(!isReadOnly && activeTool === 'graphics') && <button className={styles.button} onClick={handleAdd}>{commandIcon('graphic')}Rip</button>}
                    {(!isReadOnly && activeTool === 'library') && <button className={styles.button} onClick={handleDelete} disabled={currentCommandIndex == null}><FontAwesomeIcon icon={faTrashCan} className={styles.icon} />Delete</button>}
                </div>

            </div>
        </div>
    );
}