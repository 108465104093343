import React from "react";
import styles from './Footer.module.css';
import { logMajorComponentRender } from "../classes/Logger";

export const Footer: React.FC = () => {
    logMajorComponentRender(Footer.name);

    const sha1 = process.env.REACT_APP_SHA1;
    const flag = process.env.NODE_ENV.substring(0, 1);

    const divider = <span className={styles.divider}>|</span>

    return (
        <div className={styles.footer}>
            &copy; 2024
            {divider}
            <a href='https://x.com/46cio' target='_blank' rel="noreferrer">@46cio</a>
            {divider}
            #{sha1}-{flag}
        </div>
    )
}