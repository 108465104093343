import { createRef, useContext } from "react";
import { ProjectActionsContext } from "./ProjectPicker";
import styles from './ProjectPickerControls.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { TabTypes } from "./ProjectLocationTabs";

export const ProjectPickerControls: React.FC<{ activeTab: TabTypes }> = ({ activeTab }) => {

    const context = useContext(ProjectActionsContext);
    if (context == null) { return undefined; }
    const { newProject, newProjectFromFile } = context;

    const fileInputRef = createRef<HTMLInputElement>();

    const forwardClickToInputElement = () => {
        fileInputRef.current!.click();
    };

    const targetIsCloud = (activeTab === 'Cloud');

    return (
        <div className={styles.container}>
            <button onClick={forwardClickToInputElement}><FontAwesomeIcon icon={faPlus} className={styles.icon} />Add from file</button>
            <input className={styles.hidden} ref={fileInputRef} type="file" onChange={e => newProjectFromFile(targetIsCloud, e.target.files)}></input>
            <button onClick={() => newProject(targetIsCloud)}><FontAwesomeIcon icon={faFile} className={styles.icon} />New</button>
        </div>
    );
}