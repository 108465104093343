import { useEffect } from 'react';
import { OnDraw } from '../graphics/GraphicsRendererModel';
import { deregisterOnDraw, registerOnDraw } from '../graphics/RendererStore';

export function useOnDraw(onDraw: OnDraw) {

    useEffect(() => {
        registerOnDraw(onDraw);

        return () => {
            deregisterOnDraw(onDraw);
        }
    }, [onDraw]);
}





