import styles from './Setting.module.css';
import { MouseEvent, useContext } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newDataCommand } from "../../classes/commands/DataCommandHelpers";
import { selectActiveMemoryView, updateRouteToCurrentLine } from "../../store/codeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { commandAdded, commandRemoved, commandUpdated, selectGuidAndName, selectIndexedDataCommands } from "../../store/projectSlice";
import { selectionSet, selectSelection } from "../../store/toolSlice";
import { EnumSetting } from "./EnumSetting";
import { Modal } from "./Modal";
import { SettingsRow } from "./SettingsRow";
import { TextInput } from "./TextInput";
import { faObjectGroup, faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";
import { logCommandAnalytic } from '../../classes/code/Firebase';
import { commandIcon } from './MarkupIcons';
import { selectPageHasFocus } from '../../store/uiSlice';
import { useOnKeyboardShortcut } from '../../classes/effects/useOnKeyboardShortcut';
import { RibbonMenuContext } from '../RibbonMenu';

export const DataRangeMarkupSettings: React.FC = () => {

    const { guid, name: projectName } = useAppSelector(selectGuidAndName);

    const [modalIsOpen, SetModalIsOpen] = useState<boolean>(false);

    const pageHasFocus = useAppSelector(selectPageHasFocus);
    const { isHiddenCopy } = useContext(RibbonMenuContext);

    const { selectionAddress, selectionCount } = useAppSelector(selectSelection);
    const selectionEndAddress = selectionAddress + selectionCount - 1;

    const activeMemoryView = useAppSelector(selectActiveMemoryView);
    const isRAM = activeMemoryView === 'ram';

    const dispatch = useAppDispatch();

    const dataCommands = useAppSelector(selectIndexedDataCommands).filter(c =>
        (selectionAddress < c.command.address && selectionEndAddress >= c.command.address) ||
        (selectionAddress >= c.command.address && selectionAddress <= (c.command.address + c.command.countBytes - 1))
    );

    const touchedDataCommand = dataCommands.length === 1 ? dataCommands[0] : undefined;
    const selectionIsWithinASingleCommand =
        touchedDataCommand != null &&
        selectionAddress >= touchedDataCommand.command.address &&
        selectionEndAddress <= (touchedDataCommand.command.address + touchedDataCommand.command.countBytes - 1);

    const dataText = touchedDataCommand?.command.name ?? 'new data range';

    const dataLabel = dataText.substring(0, 14).concat(dataText.length > 14 ? '…' : '');
    const dataValue = dataText;
    const noneLabel = 'none';
    const noneValue = 'none';
    const mixedLabel = 'mixed';
    const mixedValue = 'mixed';

    let selectedValue = noneValue;
    if (!isRAM || dataCommands.length === 0) {
        selectedValue = noneValue;
    } else if (selectionIsWithinASingleCommand) {
        selectedValue = dataValue;
    } else {
        selectedValue = mixedValue;
    }

    const showDataLabel = dataCommands.length < 2;
    const extraDataLabel = isRAM && (showDataLabel) ? [dataLabel] : [];
    const extraMixedLabel = isRAM && (dataCommands.length >= 1 && !selectionIsWithinASingleCommand) ? [mixedLabel] : [];

    const dataOption = (showDataLabel) ? { [dataLabel]: dataValue } : undefined;
    const mixedOption = (selectedValue === mixedValue) ? { [mixedLabel]: mixedValue } : undefined;

    const canEdit = isRAM && touchedDataCommand != null;

    const handleDataRangeChanged = (newDataValue: string) => {
        const { name, address, countBytes } = touchedDataCommand?.command ?? { name: '', address: 0, countBytes: 0 };
        const endAddress = address + countBytes - 1;
        if (newDataValue === noneValue) {
            if (touchedDataCommand != null && selectionAddress > address && selectionEndAddress < endAddress) {
                // Splitting a command in two
                // Trim end off existing command
                logCommandAnalytic('edit_command', 'data', address, guid, projectName);
                const cmd = newDataCommand(address, selectionAddress - address, name);
                dispatch(commandUpdated({ commandIndexToUpdate: touchedDataCommand.index, command: cmd }));

                // Create new command for remaining bytes at the end
                logCommandAnalytic('add_command', 'data', selectionEndAddress + 1, guid, projectName);
                dispatch(commandAdded(newDataCommand(selectionEndAddress + 1, endAddress - selectionEndAddress, name)));
            } else {
                // Trim the end off the first command, if there is one
                const first = dataCommands.filter(c => c.command.address < selectionAddress && (c.command.address + c.command.countBytes - 1) <= selectionEndAddress);
                if (first.length > 0) {
                    const firstCommand = first[0];
                    logCommandAnalytic('edit_command', 'data', firstCommand.command.address, guid, projectName);
                    const cmd = newDataCommand(firstCommand.command.address, selectionAddress - firstCommand.command.address, firstCommand.command.name);
                    dispatch(commandUpdated({ commandIndexToUpdate: firstCommand.index, command: cmd }));
                }

                // Trim the start off the last command, if there is one
                const last = dataCommands.filter(c => c.command.address <= selectionEndAddress && (c.command.address + c.command.countBytes - 1) > selectionEndAddress)
                if (last.length > 0) {
                    const lastCommand = last[0];
                    logCommandAnalytic('edit_command', 'data', lastCommand.command.address, guid, projectName);
                    const cmd = newDataCommand(selectionEndAddress + 1, lastCommand.command.address + lastCommand.command.countBytes - 1 - selectionEndAddress, lastCommand.command.name);
                    dispatch(commandUpdated({ commandIndexToUpdate: lastCommand.index, command: cmd }));
                }

                // Remove any commands which are completely encompassed by the selection
                dataCommands
                    .filter(c => c.command.address >= selectionAddress && (c.command.address + c.command.countBytes - 1) <= selectionEndAddress)
                    .sort((a, b) => a.index - b.index)
                    .reverse()
                    .forEach(c => {
                        logCommandAnalytic('delete_command', 'data', c.command.address, guid, projectName);
                        dispatch(commandRemoved(c.index))
                    });
            }
        } else {
            if (touchedDataCommand == null) {
                // Create an entirely new data range
                logCommandAnalytic('add_command', 'data', selectionEndAddress, guid, projectName);
                dispatch(commandAdded(newDataCommand(selectionAddress, selectionCount, dataText)));
                SetModalIsOpen(true);
            } else {
                // Extend an existing data range in either/both directions
                const newStartAddress = Math.min(touchedDataCommand.command.address, selectionAddress);
                const newEndAddress = Math.max(touchedDataCommand.command.address + touchedDataCommand.command.countBytes - 1, selectionEndAddress);

                logCommandAnalytic('edit_command', 'data', touchedDataCommand.command.address, guid, projectName);
                const cmd = newDataCommand(newStartAddress, newEndAddress - newStartAddress + 1, touchedDataCommand.command.name);
                dispatch(commandUpdated({ commandIndexToUpdate: touchedDataCommand.index, command: cmd }));
            }
        }

        updateRouteToCurrentLine();
    }

    const handleDataRangeNameChanged = (newName: string) => {
        SetModalIsOpen(false);
        if (touchedDataCommand == null) { return; }

        const { address, countBytes } = touchedDataCommand.command;
        const cmd = newDataCommand(address, countBytes, newName);
        logCommandAnalytic('edit_command', 'data', address, guid, projectName);
        dispatch(commandUpdated({ commandIndexToUpdate: touchedDataCommand.index, command: cmd }));

        updateRouteToCurrentLine();
    }

    const handleCloseModal = () => {
        SetModalIsOpen(false);
    }

    const handleOpenModal = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        SetModalIsOpen(true);
    }

    const handleSelectRange = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (touchedDataCommand == null) { return; }

        dispatch(selectionSet({ startAddress: touchedDataCommand.command.address, count: touchedDataCommand.command.countBytes, type: 'ram' }));
    }

    useOnKeyboardShortcut('d', pageHasFocus && !isHiddenCopy, () => {
        if (dataCommands.length > 1) { return; }
        handleDataRangeChanged('');
    });

    useOnKeyboardShortcut('D', pageHasFocus && !isHiddenCopy, () => {
        handleDataRangeChanged(noneValue);
    });

    return (
        <SettingsRow label={<>{commandIcon('data')}Data Range</>}>
            <EnumSetting
                label="label"
                selectedOption={selectedValue}
                options={{ [noneLabel]: noneValue, ...mixedOption, ...dataOption }}
                order={[noneLabel, ...extraDataLabel, ...extraMixedLabel]}
                onChange={handleDataRangeChanged}
            />
            <div style={{ display: 'flex' }}>
                <button onClick={handleOpenModal} disabled={!canEdit}><FontAwesomeIcon icon={faPenToSquare} className={styles.iconButton} /></button>
                {modalIsOpen &&
                    <Modal onClose={handleCloseModal} align="bottom-right">
                        <div className={styles.modalTextEditorField}>
                            <TextInput
                                enabled={true}
                                label='label'
                                text={dataText}
                                onChange={handleDataRangeNameChanged}
                                size={30}
                            />
                        </div>
                    </Modal>
                }
                <button onClick={handleSelectRange} disabled={!canEdit}><FontAwesomeIcon icon={faObjectGroup} className={styles.iconButton} /></button>
            </div>
        </SettingsRow>
    );
}