import { CPUSnapshot } from "../SnapshotLoader";
import { Utils } from "../Utils";
import { EntryPointCommand } from "./EntryPointCommand";

const hex = Utils.to4DigitHexString;

export const newEntryPointCommand = (address: number): EntryPointCommand => {
    return {
        type: 'entry-point',
        address
    }
}

export const markUpMemory = () => { }

export const equals = (command: EntryPointCommand, otherCommand: EntryPointCommand) => {

    let res = true;
    res &&= (command.address === otherCommand.address);
    return res;
}

export const version = "1";

export const serialise = (command: EntryPointCommand): string => {
    return `ep|${hex(command.address)}`;
}

export const fromSnapshot = (snapshot: CPUSnapshot) => {
    return newEntryPointCommand(snapshot.programCounter);
}

const deserialise_v01 = (components: string[]): EntryPointCommand | undefined => {
    const address = Number(`0x${components[0]}`);
    if (isNaN(address)) { return; }

    return newEntryPointCommand(address);
}

export const deserialisers: { [version: string]: (c: string[]) => EntryPointCommand | undefined } = {
    '1': deserialise_v01
};