export type BitGroup = { label?: string, mask: number, bitCount: number, value: number, valueBin: string, transformed: number, displayed: string, order: number };

export class ByteSequence {
    bytesCount: number;
    summary: string = '';
    groups: (BitGroup[])[] = [];
    elements: BitGroup[] = [];

    constructor(readonly bytes: number[]) {
        this.bytes.forEach(_ => this.groups.push(new Array<BitGroup>()));
        this.bytesCount = this.bytes.length;
    }

    setBytesCount(count: number) {
        this.bytesCount = count;
    }

    setSummary(summary: string) {
        this.summary = summary;
    }

    setElements(elements: BitGroup[]) {
        this.elements = elements;
    }

    map(callback: (value: number, groups: BitGroup[], index: number) => JSX.Element) {
        const res: JSX.Element[] = [];
        for (let index = 0; index < this.bytesCount; index++) {
            res.push(callback(this.bytes[index], this.groups[index], index));
        }
        return res;
    }

    describeBits(byteIndex: number, label: string, mask: number, transform?: (value: number) => number, display?: (transformed: number, value: number) => string) {

        // Find least significant bit of mask
        let bitStart = 0;
        for (let index = 0; index < 8; index++) {
            if ((mask & (2 ** index)) > 0) { break; }
            bitStart++;
        }

        // Calculate number of bits in mask (assuming mask is continuous bits...)
        let bitCount = 1;
        for (let index = bitStart + 1; index < 8; index++) {
            if ((mask & (2 ** index)) === 0) { break; }
            bitCount++;
        }

        // Shift the bit range all the way to the right, to normalise it
        const value = (this.bytes[byteIndex] & mask) / (2 ** bitStart);

        // Apply a numerical transformation if needed
        const transformed = transform?.(value) ?? value;

        // Transform for display if needed
        const displayed = display?.(transformed, value) ?? transformed.toString();

        // Prepare a binary string version of the value
        const binStr = value.toString(2);
        const valueBin = (binStr.length >= bitCount) ? binStr : `00000000${binStr}`.substring(8 + binStr.length - bitCount)

        // Store this description, keeping track of the new object
        const groups = this.groups[byteIndex];
        const result = groups[groups.push({ label, mask, bitCount, value, valueBin, transformed, displayed, order: 0 }) - 1];

        // Sort this byte's updated bitGroups by highest (msb) mask value, so we can easily draw them in the correct order
        groups.sort((a, b) => b.mask - a.mask);

        // Update the bitgroup order across all bytes, so we can eg colour consistently
        this.groups.flat().forEach((g, i) => { g.order = i });

        return result;
    }
}
