import { Link, useLocation } from 'react-router-dom';
import { Banner } from './HomePage';
import styles from './HomePage.module.css';

export const NotFound: React.FC = () => {

    const loc = useLocation();

    return (
        <div className={styles.container}>
            <div className={styles.column}>

                <h2><Banner message={'** PAGE NOT FOUND :( '} colours={['04', '0D', '0F', '07']} background={'0A'} updateMS={250} /></h2>

                <p>
                    No page found '{loc.pathname}'. <Link to={'/'}>Go home</Link>
                </p>
            </div>
        </div>
    );
}