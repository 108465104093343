import { Command } from "./commands/types";
import { newProjectDescription } from "./ProjectDescriptionHelpers";
import { Utils } from "./Utils";

export const makeGuid = () => Utils.makeId(8);
export const checkGuid = (guidRaw?: string) => Utils.checkIdIsValid(8, guidRaw);

export const makeEmpty = () => newProjectDescription('untitled', makeGuid(), 'private', new Array(0x10000).fill(0x00), new Array(0x03e8).fill(0x00), new Array<Command>());

export const clone = (description: ProjectDescription) => {
    return newProjectDescription(description.name, description.guid, description.visibility, description.RAM, description.COLRAM, description.commands, description.uid, description.userName);
}

export const makeCopy = (description: ProjectDescription) => {

    const copy = clone(description);
    copy.guid = makeGuid();
    copy.name += '-copy';

    return copy;
}

export type ProjectVisibility = 'private' | 'public';

export type ProjectDescription = {
    magic: string,
    name: string,
    guid: string,
    uid?: string,
    userName?: string,
    visibility: ProjectVisibility,
    RAM: number[],
    COLRAM: number[],
    commands: Command[]
}

