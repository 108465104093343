import { useEffect } from "react";
import { OnTick } from "../../components/graphics/GraphicsCanvas";
import { deregisterOnTick, registerOnTick } from "../graphics/RendererStore";
import { logInfo } from "../Logger";

export function useOnTick(onTick: OnTick, name: string) {

    useEffect(() => {

        logInfo(`Registering onTick '${name}'`);
        registerOnTick(onTick);

        return (() => {
            logInfo(`Deregistering onTick '${name}'`);
            deregisterOnTick(onTick);
        });

    }, [onTick, name]);

}