import * as React from "react";
import styles from './HexNumberInput.module.css';

import { ISettingProps } from "./ISettingProps";
import { logMinorComponentRender } from "../../classes/Logger";
import { cx } from "../../classes/Utils";


export interface IMultiLineTextInputProps extends ISettingProps {
    onChange: (newText: string) => void;
    text: string;
    enabled: boolean;
    cssClassName?: string;
    cols?: number;
    rows?: number;
}

export const MultiLineTextInput: React.FunctionComponent<IMultiLineTextInputProps> = (props) => {

    logMinorComponentRender(MultiLineTextInput.name);

    const [textString, setTextString] = React.useState<string>(props.text);
    const [textStringIsValid, setTextStringIsValid] = React.useState<boolean>(true);
    const [isFocused, setIsFocused] = React.useState<boolean>(true);

    const inputRef = React.useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        setTextString(props.text);
    }, [props.text])

    React.useEffect(() => {
        if (isFocused) {
            inputRef.current?.select();
        }
    }, [inputRef, isFocused]);


    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

        const str = e.target.value;
        setTextString(str);

        const valid = (str.length > 0);
        setTextStringIsValid(valid);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {

        if (e.key === "Escape") {
            e.preventDefault();
            setTextString(props.text);
            setTextStringIsValid(true);
        }
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {

        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            changeValueIfValid();
        }
    }

    const handleBlur = () => {

        setIsFocused(false);
        changeValueIfValid();
    }

    const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {

        setIsFocused(true);
    }

    const changeValueIfValid = () => {
        if (!textStringIsValid) { return; }
        if (textString === props.text) { return; }
        props.onChange(textString);
    }

    return (
        <textarea
            ref={inputRef}
            disabled={!props.enabled}
            value={textString}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
            onFocus={handleFocus}
            className={cx(styles.textInput, styles.textArea, props.cssClassName, textStringIsValid ? '' : styles.bad)}
            cols={props.cols}
            rows={props.rows}
        />
    );
}