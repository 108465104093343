import { logFileActionResultAnalytic } from "./code/Firebase";
import { logError } from "./Logger";
import { ProjectDescription, makeCopy } from "./ProjectDescription";
import { serialise as serialiseProjectDescription, deserialise as deserialiseProjectDescription } from './ProjectDescriptionHelpers';
import { ProjectInfo, serialise as serialiseProjectInfo, deserialise as deserialiseProjectInfo } from "./ProjectInfo";

const supported = () => (typeof window['localStorage'] != "undefined") && (window['localStorage'] != null);

export const listAsync = async () => {

    if (!supported()) { return []; }

    const list = Object.keys(localStorage)
        .filter(key => key.endsWith(".meta"))
        .map(key => deserialiseProjectInfo(localStorage.getItem(key)))
        .filter((p): p is ProjectInfo => p != null)
        .sort((a, b) => b.timestamp - a.timestamp)
        .map(i => { return { ...i, location: 'local' } as ProjectInfo; });

    return list;
};

export const exists = (guid: string) => Object.keys(localStorage).filter(f => f.includes(guid)).length > 0;

export const getAsync = async (guid: string) => {

    if (!supported()) { return null; }

    try {
        const item = localStorage.getItem(`${guid}.46c`);
        if (item == null) { throw new Error(`Can't load local project with guid ${guid}`); }

        const desc = deserialiseProjectDescription(item);
        if (desc == null) { throw new Error(`Can't load local project with guid ${guid}`); }

        logFileActionResultAnalytic('open_local_file', 'success', guid, desc.name);

        return desc;

    } catch (error) {
        logFileActionResultAnalytic('open_local_file', 'failure', guid);

        if (!(error instanceof Error)) {
            throw error
        }

        logError(`${error.name} : ${error.message}`);
    }
}

export const duplicateAsync = async (guid: string) => {

    if (!supported()) { return; }

    try {
        const file = await getAsync(guid);
        if (file == null) { throw new Error(`Can't load local project with guid ${guid}`); }

        const copy = makeCopy(file);
        await saveAsync(copy, Date.now());

        logFileActionResultAnalytic('duplicate_local_file', 'success', copy.guid, copy.name);

    } catch (error) {
        logFileActionResultAnalytic('duplicate_local_file', 'failure', guid);

        if (!(error instanceof Error)) {
            throw error
        }

        logError(`${error.name} : ${error.message}`);
    }
}

export const saveAsync = async (p: ProjectDescription, time: number) => {

    if (!supported()) { return; }

    try {
        localStorage.setItem(`${p.guid}.meta`, serialiseProjectInfo(p, time));
        localStorage.setItem(`${p.guid}.46c`, serialiseProjectDescription(p, time));

        logFileActionResultAnalytic('save_local_file', 'success', p.guid, p.name);

    } catch (error) {
        logFileActionResultAnalytic('save_local_file', 'failure', p.guid, p.name);

        if (!(error instanceof Error)) {
            throw error
        }

        logError(`${error.name} : ${error.message}`);
    }
}

export const deleteAsync = async (guid: string) => {

    if (!supported()) { return; }

    try {
        localStorage.removeItem(`${guid}.meta`);
        localStorage.removeItem(`${guid}.46c`);

        logFileActionResultAnalytic('delete_local_file', 'success', guid);

    } catch (error) {
        logFileActionResultAnalytic('delete_local_file', 'failure', guid);

        if (!(error instanceof Error)) {
            throw error
        }

        logError(`${error.name} : ${error.message}`);
    }
}