import { createSelector } from "@reduxjs/toolkit";
import { selectionExtended, selectionSet } from "./toolSlice";
import { selectMarkedUpMemory, selectProjectStatus } from "./projectSlice";
import { LineDataManager } from "../classes/code/LineDataManager";
import { store } from ".";
import { navigate, selectActiveMemoryView } from "./codeSlice";
import { LineRenderer } from "../components/MainPanel/LineRenderer";
import { logSelector } from "../classes/Logger";
import { MemoryAddress } from "../classes/code/MemoryLocation";
import { selectUser } from "./uiSlice";

export const selectLineDatas = createSelector(
    selectActiveMemoryView,
    selectMarkedUpMemory,
    (view, { ram, rom, ramRanges, romRanges }) => {
        const viewIsRAM = (view === 'ram');
        const memory = viewIsRAM ? ram : rom;
        const ranges = viewIsRAM ? ramRanges : romRanges;

        logSelector('extraSelectors', 'lineDatas');
        const bytesPerLine = 8;
        const lineDatas = LineDataManager.makeLineDatas(memory, ranges, bytesPerLine);
        const itemSizes = lineDatas.map((l, i) => {
            return (i < lineDatas.length - 1) ? lineDatas[i + 1].offsetPx - l.offsetPx : l.heightPx;
        });
        const totalItemSize = itemSizes.length > 0 ? itemSizes.reduce((a, b) => a + b) : 0;

        const handleArgumentClick = (sourceAddresses: MemoryAddress[], argumentAddress: MemoryAddress) => {
            navigate(argumentAddress, sourceAddresses);
        }

        const handleClick = (e: React.MouseEvent<HTMLElement>, address: number) => {
            if (e.shiftKey) {
                store.dispatch(selectionExtended({ address, type: view }));
            } else {
                store.dispatch(selectionSet({ startAddress: address, count: 1, type: view }));
            }
        }

        const lr = new LineRenderer(viewIsRAM, ram, rom, bytesPerLine, handleClick, handleArgumentClick);
        const renderLine = (i: number) => lr.renderLine(lineDatas[i]).lineDOM;


        return { lineDatas, itemSizes, totalItemSize, renderLine };
    }
)

export const selectIsProjectReadOnly = createSelector(
    selectUser,
    selectProjectStatus,
    (currentUser, projectStatus) => {
        const isLocal = (projectStatus.uid == null);
        const currentUserIsAuthor = (currentUser?.uid === projectStatus.uid);
        return !isLocal && !currentUserIsAuthor;
    }
)