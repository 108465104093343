import React from 'react';
import { newGraphicCommand } from '../../classes/commands/GraphicCommandHelpers';
import { allSettingsUpdatedFromCommand, interleavedMainColourAddressRAMSet, interleavedMainColourSourceSet, selectInterleavedScale } from '../../store/graphicsSlice';
import { ViewportLocation } from '../../store/rendererSlice';
import { WrappedGraphic } from '../graphics/Graphic';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMinorComponentRender } from '../../classes/Logger';

export const BitmapScreen: React.FunctionComponent<{ startAddress: number }> = (props) => {

    logMinorComponentRender(BitmapScreen.name);

    const dispatch = useAppDispatch();

    const scale = 0.5 * useAppSelector(selectInterleavedScale);

    const { startAddress } = props;

    const settings = newGraphicCommand({ address: startAddress, countBytes: 0x03e8, widthPx: 320, heightPx: 200, scale, graphicsType: 'ColoursDoubleMemory' });

    const handleOnClick = () => {
        dispatch(interleavedMainColourAddressRAMSet(props.startAddress));
        dispatch(interleavedMainColourSourceSet('MainRAM'));
    }

    const handleOnDoubleClick = () => {
        dispatch(allSettingsUpdatedFromCommand(settings!));
    }

    return <WrappedGraphic settings={settings} location={ViewportLocation.Main} onClick={handleOnClick} onDoubleClick={handleOnDoubleClick} focusable={false} />

}