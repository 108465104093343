import * as React from "react";

import styles from './LineRenderer.module.css';

import { LineData, ByteLineData, CodeLineData, LineType, ElipsisLineData, GraphicsLineData, LabelLineData, CommentLineData, GraphicsPreviewLineData } from '../../classes/code/LineDataManager';
import { cx, Utils } from '../../classes/Utils';
import { MemoryAddress, MemoryLocation } from '../../classes/code/MemoryLocation';
import { OpCodes, OpCodePrologues, OpCodeEpilogues } from '../../classes/code/OpCodes';
import { Graphic } from "../graphics/Graphic";
import { ViewportLocation } from "../../store/rendererSlice";
import { Byte } from "./Byte";
import { References } from "./References";

const getLineTypeClass = (type: LineType) => {
    if (type === LineType.Code) { return styles.typeCode; }
    else if (type === LineType.Data) { return styles.typeData; }
    else if (type === LineType.Graphics) { return styles.typeGraphics; }
    else { return styles.typeUnknown; }
}

export const getTargetLabel = (targetLocation: MemoryLocation, ram: MemoryLocation[], rom: MemoryLocation[]) => {
    const labelText = targetLocation.Label;

    const countWithinBlock = targetLocation.CountWithinBlock < 0 ? -targetLocation.CountWithinBlock : 0;
    const targetBlockStartAddress = targetLocation.Address.address - countWithinBlock;
    const targetBlockStartLocation = (targetLocation.Address.type === 'rom') ? rom[targetBlockStartAddress] : ram[targetBlockStartAddress];
    const sectionHeadingText = targetBlockStartLocation.SectionHeading;

    const parts: string[] = [];
    if (sectionHeadingText != null) { parts.push(`[${sectionHeadingText} + $${Utils.toHexAuto(countWithinBlock)}]`); }
    if (labelText != null) { parts.push(`${labelText}`); }

    let label: string = '';
    if (sectionHeadingText != null && countWithinBlock === 0 && labelText == null) {
        label = sectionHeadingText;
    } else if (sectionHeadingText != null && labelText == null) {
        label = `${sectionHeadingText} + $${Utils.toHexAuto(countWithinBlock)}`;
    } else if (sectionHeadingText != null && labelText != null) {
        label = `[${sectionHeadingText} + $${Utils.toHexAuto(countWithinBlock)}] -> ${labelText}`;
    } else if (sectionHeadingText == null && labelText != null) {
        label = labelText;
    } else {
        label = '';
    }

    return label;
}



export class LineRenderer {

    memory: MemoryLocation[];

    constructor(
        private readonly viewIsRAM: boolean,
        private readonly ram: MemoryLocation[],
        private readonly rom: MemoryLocation[],
        private readonly bytesPerLine: number,
        private readonly handleClick: (e: React.MouseEvent<HTMLElement>, address: number) => void,
        private readonly handleArgumentClick: (sourceAddresses: MemoryAddress[], targetAddress: MemoryAddress) => void
    ) {
        this.memory = this.viewIsRAM ? ram : rom;
    }


    renderLine(lineData: LineData) {

        let lineDOM: JSX.Element = <></>;
        let lineStyle: string = '';

        if (lineData instanceof LabelLineData) {
            lineDOM = this.renderLabelLine(lineData as LabelLineData);
            lineStyle = styles.bigLabelLine;
        }

        if (lineData instanceof CommentLineData) {
            lineDOM = this.renderCommentLine(lineData as CommentLineData);
            lineStyle = styles.bigLabelLine;
        }

        if (lineData instanceof GraphicsPreviewLineData) {
            lineDOM = this.renderGraphicsPreviewLine(lineData as GraphicsPreviewLineData);
            lineStyle = styles.bigLabelLine;
        }

        if (lineData instanceof ByteLineData) {
            lineDOM = this.renderByteLine(lineData as ByteLineData);
            lineStyle = styles.byteLine;
        }

        if (lineData instanceof GraphicsLineData) {
            lineDOM = this.renderGraphicsLine(lineData as GraphicsLineData);
            lineStyle = styles.byteLine;
        }

        if (lineData instanceof ElipsisLineData) {
            lineDOM = this.renderElipsisLine(lineData as ElipsisLineData);
            lineStyle = styles.byteLine;
        }

        if (lineData instanceof CodeLineData) {
            lineDOM = this.renderCodeLine(lineData as CodeLineData);
            lineStyle = styles.byteLine;
        }

        return { lineDOM, lineStyle };
    }

    private renderGraphicsLine(lineData: GraphicsLineData) {

        const baseAddress = lineData.baseAddress;
        const count = lineData.count;

        const address = this.renderAddress(baseAddress, false);
        const { bytes } = this.renderBytesAndChars(count, baseAddress, this.bytesPerLine, false);

        return (
            <span className={cx(styles.line, styles.byteLine)}>{address}{bytes}</span>
        );
    }

    private renderCodeLine(lineData: CodeLineData) {

        const baseAddress = lineData.baseAddress;
        const count = lineData.count;

        const address = this.renderAddress(baseAddress, false);
        const { bytes } = this.renderBytesAndChars(count, baseAddress, 3, false);
        const code = this.renderCode(baseAddress);

        const lineTypeStyle = getLineTypeClass(lineData.type);
        const endStyle = lineData.isSectionEnd ? styles.endStyle : undefined;
        const startStyle = lineData.isSectionStart ? styles.startStyle : undefined;
        const startEndStyle = lineData.isSectionStart && lineData.isSectionEnd ? styles.startEndStyle : undefined;

        return (
            <span className={cx(lineTypeStyle, styles.line, styles.byteLine, startStyle, endStyle, startEndStyle)}>{address}{bytes}{code}</span>
        );
    }


    private renderByteLine(lineData: ByteLineData) {

        const baseAddress = lineData.baseAddress;
        const count = lineData.count;

        const address = this.renderAddress(baseAddress, lineData.isUndefined);
        const { bytes, chars } = this.renderBytesAndChars(count, baseAddress, this.bytesPerLine, true);
        const refs = this.renderAllRefs(baseAddress, [], this.memory[baseAddress].IncomingJMPs, this.memory[baseAddress].IncomingJSRs, this.memory[baseAddress].IncomingReads, this.memory[baseAddress].IncomingWrites, this.memory[baseAddress].IncomingPointers, 1);

        const lineTypeStyle = getLineTypeClass(lineData.type);

        const lastByteFormatting = this.memory[baseAddress + count - 1].Formatting;
        const hasGap = lastByteFormatting === 'GapAfter' || lastByteFormatting === 'ManualGapAfter';
        const gapStyle = hasGap ? styles.extraGapSpace : undefined;
        const endStyle = lineData.isSectionEnd ? styles.endStyle : undefined;
        const startStyle = lineData.isSectionStart ? styles.startStyle : undefined;
        const startEndStyle = lineData.isSectionStart && lineData.isSectionEnd ? styles.startEndStyle : undefined;

        const byteHasLabel = (count === 1) && this.memory[baseAddress].Label != null;

        return (
            <span className={cx(lineTypeStyle, styles.line, styles.byteLine, gapStyle, startStyle, endStyle, startEndStyle)}>{address}{bytes}{chars}{(lineData.showRefs && !byteHasLabel) && refs}</span>
        );
    }


    private renderElipsisLine(lineData: ElipsisLineData) {

        const elipsis = <span className={styles.segment}>...</span>;

        return (
            <span className={cx(styles.line, styles.byteLine)}>{elipsis}</span>
        );
    }

    private renderLabelLine(lineData: LabelLineData) {

        const lineTypeStyle = getLineTypeClass(lineData.type);
        const loc = this.memory[lineData.baseAddress];
        const endStyle = lineData.isSectionEnd ? styles.endStyle : undefined;
        const startStyle = lineData.isSectionStart ? styles.startStyle : undefined;
        const startEndStyle = lineData.isSectionStart && lineData.isSectionEnd ? styles.startEndStyle : undefined;

        if (lineData.isBig) {

            return (
                <div className={cx(lineTypeStyle, styles.line, styles.bigLabelLine, startStyle, endStyle, startEndStyle)}>
                    <span className={styles.labelAddress}>{Utils.to4DigitHexString(lineData.baseAddress)}</span>
                    {(lineData.type !== LineType.Code) && <span className={styles.labelLength}>+{Utils.to4DigitHexString(lineData.endAddress + 1 - lineData.baseAddress)}</span>}
                    <span className={styles.labelTitle}>{lineData.text}</span>
                    {lineData.showRefs && this.renderAllRefs(lineData.baseAddress, loc.IncomingBranches, loc.IncomingJMPs, loc.IncomingJSRs, loc.IncomingReads, loc.IncomingWrites, loc.IncomingPointers, 5)}
                </div >
            );
        }
        else {
            const style: React.CSSProperties = {
                height: lineData.heightPx,
            };

            return (
                <div style={style} className={cx(lineTypeStyle, styles.line, styles.smallLabelLine, startStyle, endStyle, startEndStyle)}>
                    <span>{loc.Label}</span>
                    {lineData.showRefs && this.renderAllRefs(lineData.baseAddress, loc.IncomingBranches, loc.IncomingJMPs, loc.IncomingJSRs, loc.IncomingReads, loc.IncomingWrites, loc.IncomingPointers, 3)}
                </div >
            );

        }
    }

    private renderCommentLine(lineData: CommentLineData) {

        const lineTypeStyle = getLineTypeClass(lineData.type);

        return (
            <div className={cx(lineTypeStyle, styles.line, styles.commentLine)}>
                <span className={styles.commentText}>{lineData.text}</span>
            </div >
        );
    }

    private renderGraphicsPreviewLine(lineData: GraphicsPreviewLineData) {

        const lineTypeStyle = getLineTypeClass(lineData.type);

        return (
            <div className={cx(lineTypeStyle, styles.line, styles.graphicsPreviewLine)}>
                <Graphic settings={lineData.command} location={ViewportLocation.Main} focusable={false} />
            </div >
        );
    }


    /** ----------------------------- */
    /** ----------------------------- */


    private renderAllRefs(sourceAddress: number, branches: MemoryAddress[], JMPs: MemoryAddress[], JSRs: MemoryAddress[], reads: MemoryAddress[], writes: MemoryAddress[], pointers: MemoryAddress[], maxRefs: number) {

        const refs = [
            { prefix: 'branch', addresses: branches },
            { prefix: 'jmp', addresses: JMPs },
            { prefix: 'jsr', addresses: JSRs },
            { prefix: 'read', addresses: reads },
            { prefix: 'write', addresses: writes },
            { prefix: 'pointer', addresses: pointers },
        ];

        return <References maxRefs={maxRefs} sourceAddress={sourceAddress} refs={refs} />;
    }

    private renderAddress(address: number, isUndefined: boolean) {

        const addressStr = Utils.to4DigitHexString(address);

        const addressClass = styles.segment + (isUndefined ? ' ' + styles.isUndefined : '');

        return (
            <span className={addressClass}>{addressStr}</span>
        );
    }

    private preparePointerDecoration(pointerAddress: number) {
        const pointer = this.memory[pointerAddress].OutgoingPointer;

        const pointerIsValid = this.memory[pointerAddress].OutgoingPointerIsValid;
        const pointerTargetAddress = pointer?.target.address ?? 0;
        const pointerTargetTypeIsROM = pointer?.target.type === 'rom';
        const prologue = pointer?.part === '16bit' ? '' : (pointer?.part === 'hi' ? '>' : '<');

        const targetMemory = pointerTargetTypeIsROM ? this.rom : this.ram;

        const targetLocation = targetMemory[pointerTargetAddress];
        const label = `${prologue} ${getTargetLabel(targetLocation, this.ram, this.rom)}`;

        return { label, outgoingPointerIsValid: pointerIsValid, outgoingPointerTarget: pointerTargetAddress };
    }

    private decorate(value: JSX.Element | string, label: string, labelIsValid: boolean, address: number, labelTarget: number, targetIsROM: boolean, argumentIsClickable: boolean) {

        const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
            const addressClickedOn: MemoryAddress = { address, type: this.viewIsRAM ? 'ram' : 'rom' };
            const targetAddress: MemoryAddress = { address: labelTarget, type: targetIsROM ? 'rom' : 'ram' };
            this.handleArgumentClick([addressClickedOn], targetAddress);
        }

        const romStyle = targetIsROM ? styles.rom : undefined;
        const validStyle = labelIsValid ? styles.labelValid : styles.labelInvalid;
        const outerStyle = cx(styles.labelOuter, romStyle, validStyle);
        const innerStyle = cx(styles.label, romStyle, validStyle);
        const onClickOuter = argumentIsClickable ? undefined : onClick;
        const onClickInner = argumentIsClickable ? onClick : undefined;
        return <span className={outerStyle} onClick={onClickOuter}>{value}{label && <span className={innerStyle} onClick={onClickInner}>{label}{!labelIsValid && '?'}</span>}</span>
    }



    private renderBytesAndChars(count: number, baseAddress: number, bytesPerLine: number, highlightValidChars: boolean) {

        const bytesArr: JSX.Element[] = [];
        const charsArr: JSX.Element[] = [];

        for (let i = 0; i < count; i++) {

            const address = baseAddress + i;
            if (address >= 0x10000)
                break;

            const location = this.memory[address];

            bytesArr.push(
                <Byte
                    key={i}
                    address={address}
                    location={location}
                    highlightValidChars={highlightValidChars}
                    onClick={e => this.handleClick(e, address)}
                />
            );

            const byte = location.Value;
            const { char, valid } = Utils.toChar(byte);
            const isValid = valid && highlightValidChars;

            let extraStyle: string;
            const selected = false; // This prevents chars from being highlighted during selection...
            if (selected)
                extraStyle = isValid ? ' ' + styles.selected : ' ' + styles.selectedinvalid;
            else
                extraStyle = isValid ? '' : ' ' + styles.invalid;

            const charStyle = styles.char + extraStyle;
            charsArr.push(
                <span key={i} className={charStyle} onClick={e => this.handleClick(e, address)}>{char}</span>
            );
        };

        const outgoingPointer = this.memory[baseAddress].OutgoingPointer;
        const hasOutgoingPointer = outgoingPointer != null;

        const extraCount = hasOutgoingPointer ? 0 : bytesPerLine - count;
        if (extraCount > 0) {
            for (let i = 0; i < extraCount; i++) {
                bytesArr.push(<span key={`x${i}`} className={styles.byte}>&nbsp;&nbsp;</span>);
                charsArr.push(<span key={`x${i}`} className={styles.char}>&nbsp;</span>);
            }
        }

        const getBytes = () => {
            if (hasOutgoingPointer) {
                const { label, outgoingPointerIsValid, outgoingPointerTarget } = this.preparePointerDecoration(baseAddress);
                return this.decorate(<>{bytesArr}</>, label, outgoingPointerIsValid, baseAddress, outgoingPointerTarget, outgoingPointer.target.type === 'rom', true);
            }
            else {
                return <span className={styles.segment}>{bytesArr}</span>;
            }
        };

        const getChars = () => {
            if (hasOutgoingPointer) { return undefined; }
            return <span className={styles.segment}>{charsArr}</span>;
        };


        return { bytes: getBytes(), chars: getChars() };
    }

    private renderCode(baseAddress: number) {
        const location = this.memory[baseAddress];
        const opcode = OpCodes[location.Value];
        const operator = opcode.OpName;
        const prologue = OpCodePrologues[opcode.Mode];
        const epilogue = OpCodeEpilogues[opcode.Mode];

        const getArgument = () => {
            if (location.Argument == null) { return undefined; }

            const argument = '$' + ((location.ArgumentType === '8bitRAM' || location.ArgumentType === '8bitImmediate') ? Utils.to2DigitHexString(location.Argument) : Utils.to4DigitHexString(location.Argument));
            const argIsAddress = opcode.OperandReference !== "None";
            const pointerAddress = baseAddress < 0xffff ? baseAddress + 1 : baseAddress;
            const outgoingPointer = this.memory[pointerAddress].OutgoingPointer;
            const argIsPointer = opcode.OperandReference === "None" && opcode.Mode === 'Immediate' && outgoingPointer != null;

            if (argIsAddress) {
                const targetAddress = location.Argument;
                const targetIsROM = (location.ArgumentType === '16bitROM');
                const targetLocation = targetIsROM ? this.rom[targetAddress] : this.ram[targetAddress];

                const label = getTargetLabel(targetLocation, this.ram, this.rom);

                return this.decorate(argument, label, true, baseAddress, targetAddress, targetIsROM, false);

            }
            else if (argIsPointer) {
                const { label, outgoingPointerIsValid, outgoingPointerTarget } = this.preparePointerDecoration(pointerAddress);
                return this.decorate(argument, label, outgoingPointerIsValid, baseAddress, outgoingPointerTarget, outgoingPointer.target.type === 'rom', false);

            }
            else {
                return argument;
            }
        }

        return (
            <span>{operator} {prologue}{getArgument()}{epilogue}</span>
        );
    }
}