export let OpCodePrologues = {
    Immediate: '#',         // #$00
    ZPage: '',              // $00
    ZPageX: '',             // $00,X
    ZPageY: '',             // $00,Y
    Indirect: '(',          // ($0000)
    IndexedIndirectX: '(',   // ($00,X)
    IndirectIndexedY: '(',   // ($00),Y
    Implicit: '',           // CLC, RTS etc
    Accumulator: '',        // ASL A, ROR A etc
    Absolute: '',           // $0000
    AbsoluteIndexedX: '',   // $0000,X
    AbsoluteIndexedY: '',   // $0000,Y
    Relative: ''            // $0000 (PC-relative)
};


export let OpCodeEpilogues = {
    Immediate: '',          // #$00
    ZPage: '',              // $00
    ZPageX: ',X',           // $00,X
    ZPageY: ',Y',           // $00,Y
    Indirect: ')',          // ($0000)
    IndexedIndirectX: ',X)', // ($00,X)
    IndirectIndexedY: '),Y', // ($00),Y
    Implicit: '',           // CLC, RTS etc
    Accumulator: '',        // ASL A, ROR A etc
    Absolute: '',           // $0000
    AbsoluteIndexedX: ',X', // $0000,X
    AbsoluteIndexedY: ',Y', // $0000,Y
    Relative: ''            // $0000 (PC-relative)
};


export type OpCodeModes =
    'Immediate'             // #$00
    | 'ZPage'               // $00
    | 'ZPageX'              // $00,X
    | 'ZPageY'              // $00,Y
    | 'Indirect'            // ($0000)
    | 'IndexedIndirectX'    // ($00,X)
    | 'IndirectIndexedY'    // ($00),Y
    | 'Implicit'            // CLC, RTS etc
    | 'Accumulator'         // ASL, ROR
    | 'Absolute'            // $0000
    | 'AbsoluteIndexedX'    // $0000,X
    | 'AbsoluteIndexedY'    // $0000,Y
    | 'Relative'            // *+$00
    ;

export type OpCodeNames =
    '???' | 'BRK' | 'RTI' | 'RTS' | 'CLV' | 'NOP' | 'PHP' | 'PLP' | 'PHA' | 'PLA' | 'SEC' | 'CLC' | 'SEI' | 'CLI' | 'SED' | 'CLD' | 'INY'
    | 'DEY' | 'INX' | 'DEX' | 'TXS' | 'TSX' | 'TXA' | 'TAX' | 'TYA' | 'TAY' | 'ORA' | 'AND' | 'EOR' | 'BIT' | 'ASL' | 'LSR' | 'ROL'
    | 'ROR' | 'BPL' | 'BMI' | 'BVC' | 'BVS' | 'BCC' | 'BCS' | 'BNE' | 'BEQ' | 'JSR' | 'JMP' | 'ADC' | 'SBC' | 'DEC' | 'INC' | 'LDA'
    | 'LDX' | 'LDY' | 'STA' | 'STX' | 'STY' | 'CMP' | 'CPX' | 'CPY' | 'JAM' | 'NOP' | 'SLO' | 'ANC' | 'ASR' | 'ARR' | 'RLA' | 'SRE'
    | 'RRA' | 'SAX' | 'LAX' | 'LXA' | 'DCP' | 'ISB' | 'ANE' | 'SBX' | 'SBN' | 'SHA' | 'SHY' | 'SHX' | 'SHS' | 'LAE';

type OperandReference = 'None' | 'Read' | 'Write' | 'ReadWrite' | 'Flow';
type ProgramFlow = 'Stop' | 'Continue' | 'Branch' | 'GoSub' | 'GoTo';

export interface OpCode {
    Standard: boolean;
    OpName: OpCodeNames;
    Description: string;
    Mode: OpCodeModes;
    ByteLength: number;
    CycleCount: number;
    ProgramFlow: ProgramFlow;
    OperandReference: OperandReference;
    Note?: number;
}


// Check against https://en.wikibooks.org/wiki/6502_Assembly

// ---------------------------------------------
// Standard
// http://www.6502.org/tutorials/6502opcodes.html

// Implicit
const x00 = { Standard: true, OpName: 'BRK', ProgramFlow: 'Stop', OperandReference: 'None', Description: 'Stack <- PC, PC <- ($fffe)', Mode: 'Implicit', ByteLength: 1, CycleCount: 7 } as OpCode;
const x40 = { Standard: true, OpName: 'RTI', ProgramFlow: 'Stop', OperandReference: 'None', Description: 'P <- (Stack), PC <-(Stack)', Mode: 'Implicit', ByteLength: 1, CycleCount: 6 } as OpCode;
const x60 = { Standard: true, OpName: 'RTS', ProgramFlow: 'Stop', OperandReference: 'None', Description: 'PC <- (Stack)', Mode: 'Implicit', ByteLength: 1, CycleCount: 6 } as OpCode;
const xEA = { Standard: true, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Description: '[no operation]', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;

const x18 = { Standard: true, OpName: 'CLC', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'C <- 0', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const x38 = { Standard: true, OpName: 'SEC', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'C <- 1', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const x58 = { Standard: true, OpName: 'CLI', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'I <- 0', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const x78 = { Standard: true, OpName: 'SEI', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'I <- 1', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const xB8 = { Standard: true, OpName: 'CLV', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'V <- 0', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const xD8 = { Standard: true, OpName: 'CLD', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'D <- 0', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const xF8 = { Standard: true, OpName: 'SED', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'D <- 1', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;

const xAA = { Standard: true, OpName: 'TAX', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'X <- (A)', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const x8A = { Standard: true, OpName: 'TXA', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'A <- (X)', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const xCA = { Standard: true, OpName: 'DEX', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'X <- (X) - 1', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const xE8 = { Standard: true, OpName: 'INX', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'X <- (X) + 1', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const xA8 = { Standard: true, OpName: 'TAY', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'Y <- (A)', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const x98 = { Standard: true, OpName: 'TYA', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'A <- (Y)', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const x88 = { Standard: true, OpName: 'DEY', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'Y <- (Y) - 1', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const xC8 = { Standard: true, OpName: 'INY', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'Y <- (Y) + 1', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;

const x9A = { Standard: true, OpName: 'TXS', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'S <- (X)', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const xBA = { Standard: true, OpName: 'TSX', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'X <- (S)', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
const x48 = { Standard: true, OpName: 'PHA', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'Stack <- (A)', Mode: 'Implicit', ByteLength: 1, CycleCount: 3 } as OpCode;
const x68 = { Standard: true, OpName: 'PLA', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'A <- (Stack)', Mode: 'Implicit', ByteLength: 1, CycleCount: 4 } as OpCode;
const x08 = { Standard: true, OpName: 'PHP', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'Stack <- (P)', Mode: 'Implicit', ByteLength: 1, CycleCount: 3 } as OpCode;
const x28 = { Standard: true, OpName: 'PLP', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'A <- (Stack)', Mode: 'Implicit', ByteLength: 1, CycleCount: 4 } as OpCode;

// Bitwise logic
const x09 = { Standard: true, OpName: 'ORA', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'A <- (A) V M', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
const x05 = { Standard: true, OpName: 'ORA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) V M', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const x15 = { Standard: true, OpName: 'ORA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) V M', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
const x0D = { Standard: true, OpName: 'ORA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) V M', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
const x1D = { Standard: true, OpName: 'ORA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) V M', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const x19 = { Standard: true, OpName: 'ORA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) V M', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const x01 = { Standard: true, OpName: 'ORA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) V M', Mode: 'IndexedIndirectX', ByteLength: 2, CycleCount: 6 } as OpCode;
const x11 = { Standard: true, OpName: 'ORA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) V M', Mode: 'IndirectIndexedY', ByteLength: 2, CycleCount: 5, Note: 1 } as OpCode;

const x29 = { Standard: true, OpName: 'AND', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'A <- A & M', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
const x25 = { Standard: true, OpName: 'AND', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A & M', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const x35 = { Standard: true, OpName: 'AND', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A & M', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
const x2D = { Standard: true, OpName: 'AND', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A & M', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
const x3D = { Standard: true, OpName: 'AND', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A & M', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const x39 = { Standard: true, OpName: 'AND', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A & M', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const x21 = { Standard: true, OpName: 'AND', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A & M', Mode: 'IndexedIndirectX', ByteLength: 2, CycleCount: 6 } as OpCode;
const x31 = { Standard: true, OpName: 'AND', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A & M', Mode: 'IndirectIndexedY', ByteLength: 2, CycleCount: 5, Note: 1 } as OpCode;

const x49 = { Standard: true, OpName: 'EOR', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'A <- (A) XOR M', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
const x45 = { Standard: true, OpName: 'EOR', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) XOR M', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const x55 = { Standard: true, OpName: 'EOR', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) XOR M', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
const x4D = { Standard: true, OpName: 'EOR', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) XOR M', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
const x5D = { Standard: true, OpName: 'EOR', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) XOR M', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const x59 = { Standard: true, OpName: 'EOR', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) XOR M', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const x41 = { Standard: true, OpName: 'EOR', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) XOR M', Mode: 'IndexedIndirectX', ByteLength: 2, CycleCount: 6 } as OpCode;
const x51 = { Standard: true, OpName: 'EOR', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) XOR M', Mode: 'IndirectIndexedY', ByteLength: 2, CycleCount: 5, Note: 1 } as OpCode;

const x24 = { Standard: true, OpName: 'BIT', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'Z <- ~(A & M) N<-M7 V<-M6', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const x2C = { Standard: true, OpName: 'BIT', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'Z <- ~(A & M) N<-M7 V<-M6', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;

// Bitwise shifts
const x0A = { Standard: true, OpName: 'ASL', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'C <- A7, A <- A << 1', Mode: 'Accumulator', ByteLength: 1, CycleCount: 2 } as OpCode;
const x06 = { Standard: true, OpName: 'ASL', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A7, A <- A << 1', Mode: 'ZPage', ByteLength: 2, CycleCount: 5 } as OpCode;
const x16 = { Standard: true, OpName: 'ASL', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A7, A <- A << 1', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6 } as OpCode;
const x0E = { Standard: true, OpName: 'ASL', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A7, A <- A << 1', Mode: 'Absolute', ByteLength: 3, CycleCount: 6 } as OpCode;
const x1E = { Standard: true, OpName: 'ASL', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A7, A <- A << 1', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7 } as OpCode;

const x4A = { Standard: true, OpName: 'LSR', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'C <- A0, A <- (A) >> 1', Mode: 'Accumulator', ByteLength: 1, CycleCount: 2 } as OpCode;
const x46 = { Standard: true, OpName: 'LSR', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A0, A <- (A) >> 1', Mode: 'ZPage', ByteLength: 2, CycleCount: 5 } as OpCode;
const x56 = { Standard: true, OpName: 'LSR', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A0, A <- (A) >> 1', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6 } as OpCode;
const x4E = { Standard: true, OpName: 'LSR', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A0, A <- (A) >> 1', Mode: 'Absolute', ByteLength: 3, CycleCount: 6 } as OpCode;
const x5E = { Standard: true, OpName: 'LSR', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A0, A <- (A) >> 1', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7 } as OpCode;

const x2A = { Standard: true, OpName: 'ROL', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'C <- A7 & A <- A << 1 + C', Mode: 'Accumulator', ByteLength: 1, CycleCount: 2 } as OpCode;
const x26 = { Standard: true, OpName: 'ROL', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A7 & A <- A << 1 + C', Mode: 'ZPage', ByteLength: 2, CycleCount: 5 } as OpCode;
const x36 = { Standard: true, OpName: 'ROL', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A7 & A <- A << 1 + C', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6 } as OpCode;
const x2E = { Standard: true, OpName: 'ROL', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A7 & A <- A << 1 + C', Mode: 'Absolute', ByteLength: 3, CycleCount: 6 } as OpCode;
const x3E = { Standard: true, OpName: 'ROL', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C <- A7 & A <- A << 1 + C', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7 } as OpCode;

const x6A = { Standard: true, OpName: 'ROR', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'C<-A0 & A<- (A7=C + A>>1)', Mode: 'Accumulator', ByteLength: 1, CycleCount: 2 } as OpCode;
const x66 = { Standard: true, OpName: 'ROR', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C<-A0 & A<- (A7=C + A>>1)', Mode: 'ZPage', ByteLength: 2, CycleCount: 5 } as OpCode;
const x76 = { Standard: true, OpName: 'ROR', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C<-A0 & A<- (A7=C + A>>1)', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6 } as OpCode;
const x6E = { Standard: true, OpName: 'ROR', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C<-A0 & A<- (A7=C + A>>1)', Mode: 'Absolute', ByteLength: 3, CycleCount: 6 } as OpCode;
const x7E = { Standard: true, OpName: 'ROR', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'C<-A0 & A<- (A7=C + A>>1)', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7 } as OpCode;

// Branching
// Branch not taken = 2 cycles
// Branch taken = +1
// Branch crosses page boundary = +1
const x10 = { Standard: true, OpName: 'BPL', ProgramFlow: 'Branch', OperandReference: 'Flow', Description: 'if N=0, PC : PC + offset', Mode: 'Relative', ByteLength: 2, CycleCount: 2, Note: 2 } as OpCode;
const x30 = { Standard: true, OpName: 'BMI', ProgramFlow: 'Branch', OperandReference: 'Flow', Description: 'if N=1, PC : PC + offset', Mode: 'Relative', ByteLength: 2, CycleCount: 2, Note: 2 } as OpCode;
const x50 = { Standard: true, OpName: 'BVC', ProgramFlow: 'Branch', OperandReference: 'Flow', Description: 'if V=0, PC : PC + offset', Mode: 'Relative', ByteLength: 2, CycleCount: 2, Note: 2 } as OpCode;
const x70 = { Standard: true, OpName: 'BVS', ProgramFlow: 'Branch', OperandReference: 'Flow', Description: 'if V=1, PC : PC + offset', Mode: 'Relative', ByteLength: 2, CycleCount: 2, Note: 2 } as OpCode;
const x90 = { Standard: true, OpName: 'BCC', ProgramFlow: 'Branch', OperandReference: 'Flow', Description: 'if C=0, PC : PC + offset', Mode: 'Relative', ByteLength: 2, CycleCount: 2, Note: 2 } as OpCode;
const xB0 = { Standard: true, OpName: 'BCS', ProgramFlow: 'Branch', OperandReference: 'Flow', Description: 'if C=1, PC : PC + offset', Mode: 'Relative', ByteLength: 2, CycleCount: 2, Note: 2 } as OpCode;
const xD0 = { Standard: true, OpName: 'BNE', ProgramFlow: 'Branch', OperandReference: 'Flow', Description: 'if Z=0, PC : PC + offset', Mode: 'Relative', ByteLength: 2, CycleCount: 2, Note: 2 } as OpCode;
const xF0 = { Standard: true, OpName: 'BEQ', ProgramFlow: 'Branch', OperandReference: 'Flow', Description: 'if Z=1, PC : PC + offset', Mode: 'Relative', ByteLength: 2, CycleCount: 2, Note: 2 } as OpCode;

const x4C = { Standard: true, OpName: 'JMP', ProgramFlow: 'GoTo', OperandReference: 'Flow', Description: 'PC <- Address', Mode: 'Absolute', ByteLength: 3, CycleCount: 3 } as OpCode;
const x6C = { Standard: true, OpName: 'JMP', ProgramFlow: 'GoTo', OperandReference: 'Flow', Description: 'PC <- Address', Mode: 'Indirect', ByteLength: 3, CycleCount: 5 } as OpCode;
const x20 = { Standard: true, OpName: 'JSR', ProgramFlow: 'GoSub', OperandReference: 'Flow', Description: 'Stack <- PC, PC <- Address', Mode: 'Absolute', ByteLength: 3, CycleCount: 6 } as OpCode;

// Add + subtract, increment + decrement
const x69 = { Standard: true, OpName: 'ADC', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'A <- A + Imm + C', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
const x65 = { Standard: true, OpName: 'ADC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A + M + C', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const x75 = { Standard: true, OpName: 'ADC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A + M + C', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
const x6D = { Standard: true, OpName: 'ADC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A + M + C', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
const x7D = { Standard: true, OpName: 'ADC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A + M + C', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const x79 = { Standard: true, OpName: 'ADC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A + M + C', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const x61 = { Standard: true, OpName: 'ADC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A + M + C', Mode: 'IndexedIndirectX', ByteLength: 2, CycleCount: 6 } as OpCode;
const x71 = { Standard: true, OpName: 'ADC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- A + M + C', Mode: 'IndirectIndexedY', ByteLength: 2, CycleCount: 5, Note: 1 } as OpCode;

const xE9 = { Standard: true, OpName: 'SBC', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'A <- (A) - M - ~C', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
const xE5 = { Standard: true, OpName: 'SBC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) - M - ~C', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const xF5 = { Standard: true, OpName: 'SBC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) - M - ~C', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
const xED = { Standard: true, OpName: 'SBC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) - M - ~C', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
const xFD = { Standard: true, OpName: 'SBC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) - M - ~C', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const xF9 = { Standard: true, OpName: 'SBC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) - M - ~C', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const xE1 = { Standard: true, OpName: 'SBC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) - M - ~C', Mode: 'IndexedIndirectX', ByteLength: 2, CycleCount: 6 } as OpCode;
const xF1 = { Standard: true, OpName: 'SBC', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- (A) - M - ~C', Mode: 'IndirectIndexedY', ByteLength: 2, CycleCount: 5, Note: 1 } as OpCode;

const xC6 = { Standard: true, OpName: 'DEC', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'M <- M - 1', Mode: 'ZPage', ByteLength: 2, CycleCount: 5 } as OpCode;
const xD6 = { Standard: true, OpName: 'DEC', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'M <- M - 1', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6 } as OpCode;
const xCE = { Standard: true, OpName: 'DEC', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'M <- M - 1', Mode: 'Absolute', ByteLength: 3, CycleCount: 6 } as OpCode;
const xDE = { Standard: true, OpName: 'DEC', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'M <- M - 1', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7 } as OpCode;

const xE6 = { Standard: true, OpName: 'INC', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'M <- (M) + 1', Mode: 'ZPage', ByteLength: 2, CycleCount: 5 } as OpCode;
const xF6 = { Standard: true, OpName: 'INC', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'M <- (M) + 1', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6 } as OpCode;
const xEE = { Standard: true, OpName: 'INC', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'M <- (M) + 1', Mode: 'Absolute', ByteLength: 3, CycleCount: 6 } as OpCode;
const xFE = { Standard: true, OpName: 'INC', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Description: 'M <- (M) + 1', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7 } as OpCode;

// Load and store
const xA9 = { Standard: true, OpName: 'LDA', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'A <- Imm', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
const xA5 = { Standard: true, OpName: 'LDA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- M', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const xB5 = { Standard: true, OpName: 'LDA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- M', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
const xAD = { Standard: true, OpName: 'LDA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- M', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
const xBD = { Standard: true, OpName: 'LDA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- M', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const xB9 = { Standard: true, OpName: 'LDA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- M', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const xA1 = { Standard: true, OpName: 'LDA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- M', Mode: 'IndexedIndirectX', ByteLength: 2, CycleCount: 6 } as OpCode;
const xB1 = { Standard: true, OpName: 'LDA', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'A <- M', Mode: 'IndirectIndexedY', ByteLength: 2, CycleCount: 5, Note: 1 } as OpCode;

const xA2 = { Standard: true, OpName: 'LDX', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'X <- Imm', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
const xA6 = { Standard: true, OpName: 'LDX', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'X <- M', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const xB6 = { Standard: true, OpName: 'LDX', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'X <- M', Mode: 'ZPageY', ByteLength: 2, CycleCount: 4 } as OpCode;
const xAE = { Standard: true, OpName: 'LDX', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'X <- M', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
const xBE = { Standard: true, OpName: 'LDX', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'X <- M', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;

const xA0 = { Standard: true, OpName: 'LDY', ProgramFlow: 'Continue', OperandReference: 'None', Description: 'Y <- M', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
const xA4 = { Standard: true, OpName: 'LDY', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'Y <- M', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const xB4 = { Standard: true, OpName: 'LDY', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'Y <- M', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
const xAC = { Standard: true, OpName: 'LDY', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'Y <- M', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
const xBC = { Standard: true, OpName: 'LDY', ProgramFlow: 'Continue', OperandReference: 'Read', Description: 'Y <- M', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;

const x85 = { Standard: true, OpName: 'STA', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (A)', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const x95 = { Standard: true, OpName: 'STA', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (A)', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
const x8D = { Standard: true, OpName: 'STA', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (A)', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
const x9D = { Standard: true, OpName: 'STA', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (A)', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 5 } as OpCode;
const x99 = { Standard: true, OpName: 'STA', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (A)', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 5 } as OpCode;
const x81 = { Standard: true, OpName: 'STA', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (A)', Mode: 'IndexedIndirectX', ByteLength: 2, CycleCount: 6 } as OpCode;
const x91 = { Standard: true, OpName: 'STA', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (A)', Mode: 'IndirectIndexedY', ByteLength: 2, CycleCount: 6 } as OpCode;

const x86 = { Standard: true, OpName: 'STX', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (X)', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const x96 = { Standard: true, OpName: 'STX', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (X)', Mode: 'ZPageY', ByteLength: 2, CycleCount: 4 } as OpCode;
const x8E = { Standard: true, OpName: 'STX', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (X)', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;

const x84 = { Standard: true, OpName: 'STY', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (Y)', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const x94 = { Standard: true, OpName: 'STY', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (Y)', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
const x8C = { Standard: true, OpName: 'STY', ProgramFlow: 'Continue', OperandReference: 'Write', Description: 'M <- (Y)', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;

// Compare
const xC9 = { Standard: true, OpName: 'CMP', ProgramFlow: 'Continue', OperandReference: 'None', Description: '(A - Imm) -> NZC', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
const xC5 = { Standard: true, OpName: 'CMP', ProgramFlow: 'Continue', OperandReference: 'Read', Description: '(A - M) -> NZC', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const xD5 = { Standard: true, OpName: 'CMP', ProgramFlow: 'Continue', OperandReference: 'Read', Description: '(A - M) -> NZC', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
const xCD = { Standard: true, OpName: 'CMP', ProgramFlow: 'Continue', OperandReference: 'Read', Description: '(A - M) -> NZC', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
const xDD = { Standard: true, OpName: 'CMP', ProgramFlow: 'Continue', OperandReference: 'Read', Description: '(A - M) -> NZC', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const xD9 = { Standard: true, OpName: 'CMP', ProgramFlow: 'Continue', OperandReference: 'Read', Description: '(A - M) -> NZC', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
const xC1 = { Standard: true, OpName: 'CMP', ProgramFlow: 'Continue', OperandReference: 'Read', Description: '(A - M) -> NZC', Mode: 'IndexedIndirectX', ByteLength: 2, CycleCount: 6 } as OpCode;
const xD1 = { Standard: true, OpName: 'CMP', ProgramFlow: 'Continue', OperandReference: 'Read', Description: '(A - M) -> NZC', Mode: 'IndirectIndexedY', ByteLength: 2, CycleCount: 5, Note: 1 } as OpCode;

const xE0 = { Standard: true, OpName: 'CPX', ProgramFlow: 'Continue', OperandReference: 'None', Description: '(X - Imm) -> NZC', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
const xE4 = { Standard: true, OpName: 'CPX', ProgramFlow: 'Continue', OperandReference: 'Read', Description: '(X - M) -> NZC', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const xEC = { Standard: true, OpName: 'CPX', ProgramFlow: 'Continue', OperandReference: 'Read', Description: '(X - M) -> NZC', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;

const xC0 = { Standard: true, OpName: 'CPY', ProgramFlow: 'Continue', OperandReference: 'None', Description: '(Y - Imm) -> NZC', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
const xC4 = { Standard: true, OpName: 'CPY', ProgramFlow: 'Continue', OperandReference: 'Read', Description: '(Y - M) -> NZC', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
const xCC = { Standard: true, OpName: 'CPY', ProgramFlow: 'Continue', OperandReference: 'Read', Description: '(Y - M) -> NZC', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;

const x__ = { Standard: true, OpName: '???', ProgramFlow: 'Stop', OperandReference: 'None', Description: 'Unknown opcode', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;

// ---------------------------------------------
// Non-standard
// Extra info from here : http://www.oxyron.de/html/opcodes02.html
// And here : http://unusedino.de/ec64/technical/aay/c64/bmain.htm

// JAM
//const x02 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0x02, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;
//const x12 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0x12, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;
//const x22 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0x22, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;
//const x32 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0x32, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;
//const x42 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0x42, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;
//const x52 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0x52, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;
//const x62 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0x62, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;
//const x72 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0x72, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;
//const x92 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0x92, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;
//const xB2 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0xB2, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;
//const xD2 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0xD2, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;
//const xF2 = { Standard: false, OpName: 'JAM', ProgramFlow: 'Stop', OperandReference: 'None', Code: 0xF2, Description: '[locks up machine]', Mode: 'Implicit', ByteLength: 1, CycleCount: 0 } as OpCode;

// Multi-byte, multi-cycle NOP
//const x1A = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x1A, Description: '[no operation]', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
//const x3A = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x3A, Description: '[no operation]', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
//const x5A = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x5A, Description: '[no operation]', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
//const x7A = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x7A, Description: '[no operation]', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
//const xDA = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0xDA, Description: '[no operation]', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;
//const xFA = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0xFA, Description: '[no operation]', Mode: 'Implicit', ByteLength: 1, CycleCount: 2 } as OpCode;

//const xE2 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0xE2, Description: '[no operation]', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
//const x80 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x80, Description: '[no operation]', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
//const x82 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x82, Description: '[no operation]', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
//const x89 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x89, Description: '[no operation]', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
//const xC2 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0xC2, Description: '[no operation]', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;
//const x04 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x04, Description: '[no operation]', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
//const x44 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x44, Description: '[no operation]', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
//const x64 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x64, Description: '[no operation]', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
//const x14 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x14, Description: '[no operation]', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
//const x1C = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x1C, Description: '[no operation]', Mode: 'AbsoluteIndexedX', ByteLength: 2, CycleCount: 4, Note: 1 } as OpCode;
//const x34 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x34, Description: '[no operation]', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
//const x54 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x54, Description: '[no operation]', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
//const x74 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x74, Description: '[no operation]', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
//const xD4 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0xD4, Description: '[no operation]', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;
//const xF4 = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0xF4, Description: '[no operation]', Mode: 'ZPageX', ByteLength: 2, CycleCount: 4 } as OpCode;

//const x0C = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x0C, Description: '[no operation]', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
//const x3C = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x3C, Description: '[no operation]', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
//const x5C = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x5C, Description: '[no operation]', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
//const x7C = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x7C, Description: '[no operation]', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
//const xDC = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0xDC, Description: '[no operation]', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;
//const xFC = { Standard: false, OpName: 'NOP', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0xFC, Description: '[no operation]', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;

// SLO : SLO {adr} = ASL {adr} + ORA {adr}
//const x03 = { Standard: false, OpName: 'SLO', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x03, Description: 'M <- (M >> 1) + A + C', Mode: 'IndexedIndirect', ByteLength: 2, CycleCount: 8 } as OpCode;
//const x07 = { Standard: false, OpName: 'SLO', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x07, Description: 'M <- (M >> 1) + A + C', Mode: 'ZPage', ByteLength: 2, CycleCount: 5 } as OpCode;
//const x0F = { Standard: false, OpName: 'SLO', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x0F, Description: 'M <- (M >> 1) + A + C', Mode: 'Absolute', ByteLength: 3, CycleCount: 6 } as OpCode;
//const x13 = { Standard: false, OpName: 'SLO', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x13, Description: 'M <- (M >. 1) + A + C', Mode: 'IndirectIndexed', ByteLength: 2, CycleCount: 8, Note: 5 } as OpCode;
//const x17 = { Standard: false, OpName: 'SLO', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x17, Description: 'M <- (M >> 1) + A + C', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6 } as OpCode;
//const x1B = { Standard: false, OpName: 'SLO', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x1B, Description: 'M <- (M >> 1) + A + C', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 7 } as OpCode;
//const x1F = { Standard: false, OpName: 'SLO', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x1F, Description: 'M <- (M >> 1) + A + C', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7 } as OpCode;

// ANC : ANC #{imm} = AND #{imm} + (ASL) (this command performs an AND operation only, but bit 7 is put into the carry, as if the ASL/ROL would have been executed)
//const x0B = { Standard: false, OpName: 'ANC', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x0B, Description: 'A <- A /\ M, C <- ~A7', Mode: 'Immediate', ByteLength: 1, CycleCount: 2 } as OpCode;
//const x2B = { Standard: false, OpName: 'ANC', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x2B, Description: 'A <- A /\ M, C <- ~A7', Mode: 'Immediate', ByteLength: 1, CycleCount: 2 } as OpCode;

// ASR : ASR #{imm} = AND #{imm} + LSR
//const x4B = { Standard: false, OpName: 'ASR', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x4B, Description: 'A <- [(A /\ M) >> 1]', Mode: 'Immediate', ByteLength: 1, CycleCount: 2 } as OpCode;

// ARR : ARR #{imm} = AND #{imm} + ROR
// (parts of this command are some ADC mechanisms. following effects appear after AND but before ROR: the V-Flag is set
// according to (A and #{imm})+#{imm} as OpCode; bit 0 does NOT go into carry, but bit 7 is exchanged with the carry)
//const x6B = { Standard: false, OpName: 'ARR', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0x6B, Description: 'A <- [(A /\ M) >> 1]', Mode: 'Immediate', ByteLength: 1, CycleCount: 2, Note: 5 } as OpCode;

// RLA : RLA {adr} = ROL {adr} + AND {adr}
//const x23 = { Standard: false, OpName: 'RLA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x23, Description: 'M <- (M << 1) /\ (A)', Mode: 'IndexedIndirect', ByteLength: 2, CycleCount: 8 } as OpCode;
//const x27 = { Standard: false, OpName: 'RLA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x27, Description: 'M <- (M << 1) /\ (A)', Mode: 'ZPage', ByteLength: 2, CycleCount: 5, Note: 5 } as OpCode;
//const x2F = { Standard: false, OpName: 'RLA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x2F, Description: 'M <- (M << 1) /\ (A)', Mode: 'Absolute', ByteLength: 3, CycleCount: 6, Note: 5 } as OpCode;
//const x33 = { Standard: false, OpName: 'RLA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x33, Description: 'M <- (M << 1) /\ (A)', Mode: 'IndirectIndexed', ByteLength: 2, CycleCount: 8, Note: 5 } as OpCode;
//const x37 = { Standard: false, OpName: 'RLA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x37, Description: 'M <- (M << 1) /\ (A)', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6, Note: 5 } as OpCode;
//const x3B = { Standard: false, OpName: 'RLA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x3B, Description: 'M <- (M << 1) /\ (A)', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 7, Note: 5 } as OpCode;
//const x3F = { Standard: false, OpName: 'RLA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x3F, Description: 'M <- (M << 1) /\ (A)', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7, Note: 5 } as OpCode;

// SRE : SRE {adr} = LSR {adr} + EOR {adr}
//const x43 = { Standard: false, OpName: 'SRE', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x43, Description: 'M <- (M >> 1) XOR A', Mode: 'IndexedIndirect', ByteLength: 2, CycleCount: 8 } as OpCode;
//const x47 = { Standard: false, OpName: 'SRE', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x47, Description: 'M <- (M >> 1) XOR A', Mode: 'ZPage', ByteLength: 2, CycleCount: 5 } as OpCode;
//const x4F = { Standard: false, OpName: 'SRE', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x4F, Description: 'M <- (M >> 1) XOR A', Mode: 'Absolute', ByteLength: 3, CycleCount: 6 } as OpCode;
//const x53 = { Standard: false, OpName: 'SRE', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x53, Description: 'M <- (M >> 1) XOR A', Mode: 'IndirectIndexed', ByteLength: 2, CycleCount: 8 } as OpCode;
//const x57 = { Standard: false, OpName: 'SRE', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x57, Description: 'M <- (M >> 1) XOR A', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6 } as OpCode;
//const x5B = { Standard: false, OpName: 'SRE', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x5B, Description: 'M <- (M >> 1) XOR A', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 7 } as OpCode;
//const x5F = { Standard: false, OpName: 'SRE', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x5F, Description: 'M <- (M >> 1) XOR A', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7 } as OpCode;

// RRA - RRA {adr} = ROR {adr} + ADC {adr}
//const x63 = { Standard: false, OpName: 'RRA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x63, Description: 'M <- (M >> 1) + (A) + C', Mode: 'IndexedIndirect', ByteLength: 2, CycleCount: 8, Note: 5 } as OpCode;
//const x67 = { Standard: false, OpName: 'RRA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x67, Description: 'M <- (M >> 1) + (A) + C', Mode: 'ZPage', ByteLength: 2, CycleCount: 5, Note: 5 } as OpCode;
//const x6F = { Standard: false, OpName: 'RRA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x6F, Description: 'M <- (M >> 1) + (A) + C', Mode: 'Absolute', ByteLength: 3, CycleCount: 6, Note: 5 } as OpCode;
//const x73 = { Standard: false, OpName: 'RRA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x73, Description: 'M <- (M >> 1) + (A) + C', Mode: 'IndirectIndexed', ByteLength: 2, CycleCount: 8, Note: 5 } as OpCode;
//const x77 = { Standard: false, OpName: 'RRA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x77, Description: 'M <- (M >> 1) + (A) + C', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6, Note: 5 } as OpCode;
//const x7B = { Standard: false, OpName: 'RRA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x7B, Description: 'M <- (M >> 1) + (A) + C', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 7, Note: 5 } as OpCode;
//const x7F = { Standard: false, OpName: 'RRA', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x7F, Description: 'M <- (M >> 1) + (A) + C', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7, Note: 5 } as OpCode;

// SAX : SAX {adr} = store A&X into {adr} (the A&X operation is a result of A and X put onto the bus at the same time)
//const x83 = { Standard: false, OpName: 'SAX', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x83, Description: 'M <- (A) /\ (X)', Mode: 'IndexedIndirect', ByteLength: 2, CycleCount: 6 } as OpCode;
//const x87 = { Standard: false, OpName: 'SAX', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x87, Description: 'M <- (A) /\ (X)', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
//const x8F = { Standard: false, OpName: 'SAX', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x8F, Description: 'M <- (A) /\ (X)', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
//const x97 = { Standard: false, OpName: 'SAX', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x97, Description: 'M <- (A) /\ (X)', Mode: 'ZPageY', ByteLength: 2, CycleCount: 4 } as OpCode;

// LAX : LAX {adr} = LDA {adr} + LDX {adr}
//const xA3 = { Standard: false, OpName: 'LAX', ProgramFlow: 'Continue', OperandReference: 'Read', Code: 0xA3, Description: 'A <- M, X <- M', Mode: 'IndexedIndirect', ByteLength: 2, CycleCount: 6 } as OpCode;
//const xA7 = { Standard: false, OpName: 'LAX', ProgramFlow: 'Continue', OperandReference: 'Read', Code: 0xA7, Description: 'A <- M, X <- M', Mode: 'ZPage', ByteLength: 2, CycleCount: 3 } as OpCode;
//const xAF = { Standard: false, OpName: 'LAX', ProgramFlow: 'Continue', OperandReference: 'Read', Code: 0xAF, Description: 'A <- M, X <- M', Mode: 'Absolute', ByteLength: 3, CycleCount: 4 } as OpCode;
//const xB3 = { Standard: false, OpName: 'LAX', ProgramFlow: 'Continue', OperandReference: 'Read', Code: 0xB3, Description: 'A <- M, X <- M', Mode: 'IndirectIndexed', ByteLength: 2, CycleCount: 5, Note: 1 } as OpCode;
//const xB7 = { Standard: false, OpName: 'LAX', ProgramFlow: 'Continue', OperandReference: 'Read', Code: 0xB7, Description: 'A <- M, X <- M', Mode: 'ZPageY', ByteLength: 2, CycleCount: 4 } as OpCode;
//const xBF = { Standard: false, OpName: 'LAX', ProgramFlow: 'Continue', OperandReference: 'Read', Code: 0xBF, Description: 'A <- M, X <- M', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;

// LXA : LXA #{imm} = LDA #{imm} + TAX (DO NOT USE!!! On my C128, this opcode is stable, but on my C64-II it loses bits so that the operation looks like this: ORA #? AND #{imm} TAX)
//const xAB = { Standard: false, OpName: 'LXA', ProgramFlow: 'Continue', OperandReference: 'Read', Code: 0xAB, Description: 'X04 <- (X04) /\ M04, A04 <- (A04) /\ M04', Mode: 'Immediate', ByteLength: 1, CycleCount: 2 } as OpCode;

// DCP : DCP {adr} = DEC {adr} + CMP {adr}
//const xC3 = { Standard: false, OpName: 'DCP', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xC3, Description: 'M <- (M)-1, (A-M) -> NZC', Mode: 'IndexedIndirect', ByteLength: 2, CycleCount: 8 } as OpCode;
//const xC7 = { Standard: false, OpName: 'DCP', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xC7, Description: 'M <- (M)-1, (A-M) -> NZC', Mode: 'ZPage', ByteLength: 2, CycleCount: 5 } as OpCode;
//const xCF = { Standard: false, OpName: 'DCP', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xCF, Description: 'M <- (M)-1, (A-M) -> NZC', Mode: 'Absolute', ByteLength: 3, CycleCount: 6 } as OpCode;
//const xD3 = { Standard: false, OpName: 'DCP', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xD3, Description: 'M <- (M)-1, (A-M) -> NZC', Mode: 'IndirectIndexed', ByteLength: 2, CycleCount: 8 } as OpCode;
//const xD7 = { Standard: false, OpName: 'DCP', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xD7, Description: 'M <- (M)-1, (A-M) -> NZC', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6 } as OpCode;
//const xDB = { Standard: false, OpName: 'DCP', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xDB, Description: 'M <- (M)-1, (A-M) -> NZC', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 7 } as OpCode;
//const xDF = { Standard: false, OpName: 'DCP', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xDF, Description: 'M <- (M)-1, (A-M) -> NZC', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7 } as OpCode;

// ISB : ISB {adr} = INC {adr} + SBC {adr}
//const xE3 = { Standard: false, OpName: 'ISB', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xE3, Description: 'M <- (M) - 1, A <- (A)-M-~C', Mode: 'IndexedIndirect', ByteLength: 3, CycleCount: 8, Note: 1 } as OpCode;
//const xE7 = { Standard: false, OpName: 'ISB', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xE7, Description: 'M <- (M) - 1, A <- (A)-M-~C', Mode: 'ZPage', ByteLength: 2, CycleCount: 5 } as OpCode;
//const xEF = { Standard: false, OpName: 'ISB', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xEF, Description: 'M <- (M) - 1, A <- (A)-M-~C', Mode: 'Absolute', ByteLength: 3, CycleCount: 6 } as OpCode;
//const xF3 = { Standard: false, OpName: 'ISB', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xF3, Description: 'M <- (M) - 1, A <- (A)-M-~C', Mode: 'IndirectIndexed', ByteLength: 2, CycleCount: 8 } as OpCode;
//const xF7 = { Standard: false, OpName: 'ISB', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xF7, Description: 'M <- (M) - 1, A <- (A)-M-~C', Mode: 'ZPageX', ByteLength: 2, CycleCount: 6 } as OpCode;
//const xFB = { Standard: false, OpName: 'ISB', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xFB, Description: 'M <- (M) - 1, A <- (A)-M-~C', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 7 } as OpCode;
//const xFF = { Standard: false, OpName: 'ISB', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0xFF, Description: 'M <- (M) - 1, A <- (A)-M-~C', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 7 } as OpCode;

// ANE : ANE #{imm} = TXA + AND #{imm} (DO NOT USE!!! Highly unstable!!!)
//const x8B = { Standard: false, OpName: 'ANE', ProgramFlow: 'Continue', OperandReference: 'ReadWrite', Code: 0x8B, Description: 'M <-[(A)\/$EE] /\ (X)/\(M)', Mode: 'Immediate', ByteLength: 2, CycleCount: 2, Note: 4 } as OpCode;

// SBX : SBX #{imm} = A&X minus #{imm} into X (performs CMP and DEX at the same time, so that the MINUS sets the flag like CMP, not SBC)
//const xCB = { Standard: false, OpName: 'SBX', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0xCB, Description: 'X <- (X) /\ (A) - M', Mode: 'Immediate', ByteLength: 2, CycleCount: 2 } as OpCode;

// SBN : SBN #{imm} = SBN #{imm} + NOP
//const xEB = { Standard: false, OpName: 'SBN', ProgramFlow: 'Continue', OperandReference: 'None', Code: 0xEB, Description: 'A <- (A) - M - ~C', Mode: 'Immediate', ByteLength: 1, CycleCount: 2 } as OpCode;

// SHA : SHA {adr} = stores A&X&H into {adr}
// (sometimes the &H drops off. Also page boundary crossing will not work as expected (the bank where the value is stored may be equal to the value stored))
//const x93 = { Standard: false, OpName: 'SHA', ProgramFlow: 'Continue', OperandReference: 'Write', Code: 0x93, Description: 'M <- (A) /\ (X) /\ (PCH+1)', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 6, Note: 3 } as OpCode;
//const x9F = { Standard: false, OpName: 'SHA', ProgramFlow: 'Continue', OperandReference: 'Write', Code: 0x9F, Description: 'M <- (A) /\ (X) /\ (PCH+1)', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 5, Note: 3 } as OpCode;

// SHX : SHX {adr} = stores X&H into {adr}
// (sometimes the &H drops off. Also page boundary crossing will not work as expected (the bank where the value is stored may be equal to the value stored))
//const x9C = { Standard: false, OpName: 'SHY', ProgramFlow: 'Continue', OperandReference: 'Write', Code: 0x9C, Description: 'M <- (Y) /\ (PCH+1)', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 5, Note: 3 } as OpCode;

// SHY : SHY {adr} : stores Y&H into {adr}
// (sometimes the &H drops off. Also page boundary crossing will not work as expected (the bank where the value is stored may be equal to the value stored))
//const x9E = { Standard: false, OpName: 'SHX', ProgramFlow: 'Continue', OperandReference: 'Write', Code: 0x9E, Description: 'M <- (X) /\ (PCH+1)', Mode: 'AbsoluteIndexedX', ByteLength: 3, CycleCount: 5, Note: 3 } as OpCode;

// SHS : SHS {adr} = stores A&X into S and A&X&H into {adr}
//const x9B = { Standard: false, OpName: 'SHS', ProgramFlow: 'Continue', OperandReference: 'Write', Code: 0x9B, Description: 'X <- (A) /\ (X), S <- (X), M <- (X) /\ (PCH+1)', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 5 } as OpCode;

// LAE : LAE {adr} = stores {adr}&S into A, X and S (called "propably unreliable" in one source)
//const xBB = { Standard: false, OpName: 'LAE', ProgramFlow: 'Continue', OperandReference: 'Read', Code: 0xBB, Description: 'X,S,A <- (S /\ M)', Mode: 'AbsoluteIndexedY', ByteLength: 3, CycleCount: 4, Note: 1 } as OpCode;


// export const OpCodesAll: OpCode[] = [
//     x00, x01, x02, x03, x04, x05, x06, x07, x08, x09, x0A, x0B, x0C, x0D, x0E, x0F,
//     x10, x11, x12, x13, x14, x15, x16, x17, x18, x19, x1A, x1B, x1C, x1D, x1E, x1F,
//     x20, x21, x22, x23, x24, x25, x26, x27, x28, x29, x2A, x2B, x2C, x2D, x2E, x2F,
//     x30, x31, x32, x33, x34, x35, x36, x37, x38, x39, x3A, x3B, x3C, x3D, x3E, x3F,
//     x40, x41, x42, x43, x44, x45, x46, x47, x48, x49, x4A, x4B, x4C, x4D, x4E, x4F,
//     x50, x51, x52, x53, x54, x55, x56, x57, x58, x59, x5A, x5B, x5C, x5D, x5E, x5F,
//     x60, x61, x62, x63, x64, x65, x66, x67, x68, x69, x6A, x6B, x6C, x6D, x6E, x6F,
//     x70, x71, x72, x73, x74, x75, x76, x77, x78, x79, x7A, x7B, x7C, x7D, x7E, x7F,
//     x80, x81, x82, x83, x84, x85, x86, x87, x88, x89, x8A, x8B, x8C, x8D, x8E, x8F,
//     x90, x91, x92, x93, x94, x95, x96, x97, x98, x99, x9A, x9B, x9C, x9D, x9E, x9F,
//     xA0, xA1, xA2, xA3, xA4, xA5, xA6, xA7, xA8, xA9, xAA, xAB, xAC, xAD, xAE, xAF,
//     xB0, xB1, xB2, xB3, xB4, xB5, xB6, xB7, xB8, xB9, xBA, xBB, xBC, xBD, xBE, xBF,
//     xC0, xC1, xC2, xC3, xC4, xC5, xC6, xC7, xC8, xC9, xCA, xCB, xCC, xCD, xCE, xCF,
//     xD0, xD1, xD2, xD3, xD4, xD5, xD6, xD7, xD8, xD9, xDA, xDB, xDC, xDD, xDE, xDF,
//     xE0, xE1, xE2, xE3, xE4, xE5, xE6, xE7, xE8, xE9, xEA, xEB, xEC, xED, xEE, xEF,
//     xF0, xF1, xF2, xF3, xF4, xF5, xF6, xF7, xF8, xF9, xFA, xFB, xFC, xFD, xFE, xFF
// ];

export const OpCodes: OpCode[] = [
    x00, x01, x__, x__, x__, x05, x06, x__, x08, x09, x0A, x__, x__, x0D, x0E, x__,
    x10, x11, x__, x__, x__, x15, x16, x__, x18, x19, x__, x__, x__, x1D, x1E, x__,
    x20, x21, x__, x__, x24, x25, x26, x__, x28, x29, x2A, x__, x2C, x2D, x2E, x__,
    x30, x31, x__, x__, x__, x35, x36, x__, x38, x39, x__, x__, x__, x3D, x3E, x__,
    x40, x41, x__, x__, x__, x45, x46, x__, x48, x49, x4A, x__, x4C, x4D, x4E, x__,
    x50, x51, x__, x__, x__, x55, x56, x__, x58, x59, x__, x__, x__, x5D, x5E, x__,
    x60, x61, x__, x__, x__, x65, x66, x__, x68, x69, x6A, x__, x6C, x6D, x6E, x__,
    x70, x71, x__, x__, x__, x75, x76, x__, x78, x79, x__, x__, x__, x7D, x7E, x__,
    x__, x81, x__, x__, x84, x85, x86, x__, x88, x__, x8A, x__, x8C, x8D, x8E, x__,
    x90, x91, x__, x__, x94, x95, x96, x__, x98, x99, x9A, x__, x__, x9D, x__, x__,
    xA0, xA1, xA2, x__, xA4, xA5, xA6, x__, xA8, xA9, xAA, x__, xAC, xAD, xAE, x__,
    xB0, xB1, x__, x__, xB4, xB5, xB6, x__, xB8, xB9, xBA, x__, xBC, xBD, xBE, x__,
    xC0, xC1, x__, x__, xC4, xC5, xC6, x__, xC8, xC9, xCA, x__, xCC, xCD, xCE, x__,
    xD0, xD1, x__, x__, x__, xD5, xD6, x__, xD8, xD9, x__, x__, x__, xDD, xDE, x__,
    xE0, xE1, x__, x__, xE4, xE5, xE6, x__, xE8, xE9, xEA, x__, xEC, xED, xEE, x__,
    xF0, xF1, x__, x__, x__, xF5, xF6, x__, xF8, xF9, x__, x__, x__, xFD, xFE, x__
];
