import React, { ReactNode, useMemo } from 'react';
import { viewportSet, ViewportLocation, } from '../../store/rendererSlice';
import { useOnTick } from '../../classes/effects/useOnTick';
import { useAppDispatch } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';

export type GraphicsViewportType = {
    location: ViewportLocation;
    render: (r: React.RefObject<HTMLDivElement>) => ReactNode;
    ignoreScrollbars: { h: boolean, v: boolean };
    show: { marked: boolean, selected: boolean, grid: boolean };
}

type Rect = { x: number, y: number, w: number, h: number }

export const GraphicsViewport: React.FunctionComponent<GraphicsViewportType> = (props) => {

    logMajorComponentRender(GraphicsViewport.name, `location '${ViewportLocation[props.location]}'`);

    const divRef = React.createRef<HTMLDivElement>();
    const prevRectRef = React.useRef<Rect>();
    const prevShow = React.useRef<{ marked: boolean, selected: boolean }>();
    const { location, ignoreScrollbars, show } = props;
    const dispatch = useAppDispatch();

    const onTick = useMemo(() => (timestampMS: number) => {

        const div = divRef.current;
        if (div == null) { return; }

        const rectIncludingScrollbars = div.getBoundingClientRect();
        const scrollbarWidth = div.offsetWidth - div.clientWidth;
        const rect: Rect = {
            x: rectIncludingScrollbars.x,
            y: rectIncludingScrollbars.y,
            w: rectIncludingScrollbars.width - (ignoreScrollbars.v ? 0 : scrollbarWidth),
            h: rectIncludingScrollbars.height
        };

        const pr = prevRectRef.current;
        const rectsChanged = !pr || pr.x !== rect.x || pr.y !== rect.y || pr.w !== rect.w || pr.h !== rect.h;
        const ps = prevShow.current;
        const propsChanged = !ps || ps.marked !== show.marked || ps.selected !== show.selected;

        if (rectsChanged || propsChanged) {
            dispatch(viewportSet({ location, show, left: rect.x, top: rect.y, width: rect.w, height: rect.h }));
        }

        prevRectRef.current = rect;
        prevShow.current = show;
    }, [location, show, dispatch, divRef, ignoreScrollbars]);

    useOnTick(onTick, `Viewport at location '${ViewportLocation[props.location]}'`);

    return props.render(divRef);
}