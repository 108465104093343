import { OverrideType } from "../commands/OverrideCommand";

export type ReferencePart = 'hi' | 'lo' | '16bit';

export type MemoryAddress = {
    type: 'ram' | 'rom';
    address: number;
}

export const memoryAddressEquals = (a?: MemoryAddress, b?: MemoryAddress) => (a != null) && (b != null) && (a.type === b.type) && (a.address === b.address);

export class MemoryLocation {

    Address: MemoryAddress;

    Value: number; // The byte value at this location
    CountWithinBlock: number; // +ve = it's the first location in a block of this length, -ve = jump back this many locations to reach the block's start
    SectionHeading?: string;
    Label?: string;
    Comment?: string[];
    Formatting: 'None' | 'LineBreakAfter' | 'GapAfter' | 'ManualLineBreakAfter' | 'ManualGapAfter';
    Override: 'None' | OverrideType;

    IsCode: boolean;
    IsArgument: boolean;
    IsData: boolean;
    IsGfx: boolean;

    Argument?: number; // The argument part of code at this location
    ArgumentType: 'None' | '8bitImmediate' | '8bitRAM' | '16bitRAM' | '16bitROM'; // The argument type of code at this location
    EntryPoint?: MemoryAddress; // The entry point which discovered this address

    IncomingJMPs: MemoryAddress[]; // Addresses which JMP to this location
    IncomingJSRs: MemoryAddress[]; // Addresses which JSR to this location
    IncomingBranches: MemoryAddress[]; // Addresses which Bxx to this location
    IncomingFlow?: MemoryAddress; // Address which flowed here
    IncomingReads: MemoryAddress[]; // Addresses which read from this location
    IncomingWrites: MemoryAddress[]; // Addresses which write to this location
    IncomingPointers: MemoryAddress[]; // Addresses which point to this location

    OutgoingRead?: MemoryAddress; // Address which code at this location reads from
    OutgoingWrite?: MemoryAddress; // Address which code at this location writes to
    OutgoingPointer?: { part: ReferencePart, target: MemoryAddress }; // Address which a pointer at this location targets
    HasOutgoingRefs: boolean; // Does this location read or write to another address?
    OutgoingPointerIsValid: boolean; // Does this location's value match the pointer's target?


    constructor(address: MemoryAddress, value: number) {
        this.Address = address;
        this.Value = value;
        this.CountWithinBlock = 1;
        this.SectionHeading = undefined;
        this.Label = undefined;
        this.Comment = undefined;
        this.Formatting = "None";
        this.Override = 'None';

        this.IsCode = false;
        this.IsArgument = false;
        this.IsData = false;
        this.IsGfx = false;

        this.Argument = undefined;
        this.ArgumentType = 'None';
        this.EntryPoint = undefined;

        this.IncomingJMPs = [];
        this.IncomingJSRs = [];
        this.IncomingBranches = [];
        this.IncomingFlow = undefined;
        this.IncomingReads = [];
        this.IncomingWrites = [];
        this.IncomingPointers = [];

        this.OutgoingRead = undefined;
        this.OutgoingWrite = undefined;
        this.OutgoingPointer = undefined;
        this.HasOutgoingRefs = false;
        this.OutgoingPointerIsValid = false;
    }

    clone() {
        const res = new MemoryLocation(this.Address, this.Value);

        res.CountWithinBlock = this.CountWithinBlock;
        res.SectionHeading = this.SectionHeading;
        res.Label = this.Label;
        res.Comment = this.Comment;
        res.Formatting = this.Formatting;
        res.Override = this.Override;

        res.IsCode = this.IsCode;
        res.IsArgument = this.IsArgument;
        res.IsData = this.IsData;
        res.IsGfx = this.IsGfx;

        res.Argument = this.Argument;
        res.ArgumentType = this.ArgumentType;
        res.EntryPoint = this.EntryPoint;

        res.IncomingJMPs = [...this.IncomingJMPs];
        res.IncomingJSRs = [...this.IncomingJSRs];
        res.IncomingBranches = [...this.IncomingBranches];
        res.IncomingFlow = this.IncomingFlow;
        res.IncomingReads = [...this.IncomingReads];
        res.IncomingWrites = [...this.IncomingWrites];
        res.IncomingPointers = [...this.IncomingPointers];

        res.OutgoingRead = this.OutgoingRead;
        res.OutgoingWrite = this.OutgoingWrite;
        res.OutgoingPointer = this.OutgoingPointer;
        res.HasOutgoingRefs = this.HasOutgoingRefs;
        res.OutgoingPointerIsValid = this.OutgoingPointerIsValid;

        return res;
    }
}