import React from 'react';
import { newGraphicCommand } from '../../classes/commands/GraphicCommandHelpers';
import { selectCharacterMappedScale, selectionWidthSet } from '../../store/graphicsSlice';
import { ViewportLocation } from '../../store/rendererSlice';
import { selectionSet } from '../../store/toolSlice';
import { WrappedGraphic } from '../graphics/Graphic';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMinorComponentRender } from '../../classes/Logger';

export const Screen: React.FunctionComponent<{ startAddress: number }> = (props) => {

    logMinorComponentRender(Screen.name);

    const dispatch = useAppDispatch();

    const scale = useAppSelector(selectCharacterMappedScale);

    const { startAddress } = props;

    const settings = newGraphicCommand({ address: startAddress, countBytes: 0x03e8, widthPx: 320, heightPx: 200, scale });

    const handleOnClick = () => {
        dispatch(selectionSet({ startAddress, count: 0x3e8, type: 'ram' }));
        dispatch(selectionWidthSet({ width: 40, layout: 'CharacterMapped' }));
    }

    return (
        <WrappedGraphic settings={settings} location={ViewportLocation.Main} onClick={handleOnClick} />
    )
}