import { useEffect, useState } from 'react';
import styles from './Ranges.module.css';
import { logMajorComponentRender } from '../../classes/Logger';
import { Stack } from '../Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from '../../store/hooks';
import { focusCountDecremented, focusCountIncremented } from '../../store/uiSlice';

export type Filter = { visual: JSX.Element, isActive: boolean, toggle: (selectOnly: boolean) => void };

export interface ISearchableListProps<T> {
    items: T[];
    renderItem: (c: T) => JSX.Element;
    onSearchTermChanged: (searchTerm: string) => void;
    filters: Filter[];
}

export function SearchableList<T>(props: ISearchableListProps<T>) {

    logMajorComponentRender(SearchableList.name);

    const [searchTerm, setSearchTerm] = useState('');

    const { onSearchTermChanged, items, renderItem } = props;

    useEffect(() => {
        onSearchTermChanged(searchTerm.toLowerCase());
    }, [searchTerm, onSearchTermChanged]);

    const results = items.map((item, index) =>
        <li className={styles.li} key={index}>
            {renderItem(item)}
        </li>
    )

    const filterJsx = props.filters.map((filter, i) =>
        <label key={i} style={{ alignItems: 'center', display: 'inline-flex', marginRight: '4px' }}>
            {filter.visual}
            <input value='true' type='checkbox' checked={filter.isActive} onChange={e => {
                // @ts-ignore
                filter.toggle(e.nativeEvent.shiftKey)
            }} style={{ margin: 0 }} />
        </label>
    );

    const dispatch = useAppDispatch();

    const handleBlur = () => {
        dispatch(focusCountDecremented());
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        dispatch(focusCountIncremented());
    }

    return (
        <div className={styles.searchPanel}>
            <Stack layout={'vertical'} sizes={'auto auto auto 1fr'}>

                <div className={styles.heading}>Markup</div>

                <div className={styles.search}>
                    <Stack layout='horizontal' sizes={'auto 1fr auto'} alignItems='center'>
                        <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
                        <input type="text" value={searchTerm} onFocus={handleFocus} onBlur={handleBlur} onChange={e => setSearchTerm(e.currentTarget.value)} />
                        <button onClick={e => setSearchTerm('')} className={styles.iconButton}><FontAwesomeIcon icon={faXmark} /></button>
                    </Stack>
                </div>

                <div className={styles.filters}>
                    {filterJsx}
                </div>

                <div className={styles.list}>
                    <ul className={styles.ul}>
                        {results}
                    </ul>
                </div>

            </Stack>
        </div>
    );
}