import React, { Dispatch } from 'react';
import { SettingsRow } from './SettingsRow';
import { RootState } from '../../store';
import {
    GraphicsState,
    characterMappedMainColourSourceSet,
    characterMappedMainColourAddressCOLRAMSet,
    characterMappedMainColourAddressRAMSet,
    interleavedMainColourSourceSet,
    interleavedMainColourAddressRAMSet,
    interleavedOtherColourSourceSet,
    interleavedOtherColourAddressCOLRAMSet,
    interleavedOtherColourAddressRAMSet

} from '../../store/graphicsSlice';
import { EnumSetting } from './EnumSetting';
import { HexNumberInput } from './HexNumberInput';
import { ColourSource } from '../../classes/graphics/GraphicsEnums';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';
import { Stack } from '../Stack';

const getSourceSettings = (settings: GraphicsState, dispatch: Dispatch<any>) => {

    let mainLabel: string;
    let mainOrder: string[] = ['fixed', 'col ram', 'main ram'];
    let mainOptions: { [label: string]: ColourSource } = {};
    let mainSelectedOption: ColourSource;
    let onMainChange: (v: ColourSource) => void;
    let otherLabel: string;
    let otherOrder: string[] = ['fixed', 'col ram', 'main ram'];
    let otherOptions: { [label: string]: ColourSource } = {};
    let otherSelectedOption: ColourSource;
    let onOtherChange: (v: ColourSource) => void;

    switch (settings.ViewLayout) {
        case 'CharacterMapped':

            if (settings.CharacterMappedMode === 'HiRes') {
                mainLabel = '1 source';
            }
            else if (settings.CharacterMappedMode === 'MultiColour') {
                mainLabel = '11 source';
            }
            else {
                mainLabel = '1 source';
            }

            mainOptions['fixed'] = 'Fixed';
            mainOptions['col ram'] = 'ColourRAM';
            mainOptions['main ram'] = 'MainRAM';
            mainSelectedOption = settings.CharacterMappedMainColourSource;
            onMainChange = v => dispatch(characterMappedMainColourSourceSet(v));

            otherLabel = 'unused';
            otherSelectedOption = 'Fixed';
            onOtherChange = v => { };
            break;

        case 'Interleaved':

            if (settings.InterleavedMode === 'HiRes') {
                mainLabel = '0 / 1 source';
                mainOptions['fixed'] = 'Fixed';
                mainOptions['main ram'] = 'MainRAM';
                mainSelectedOption = settings.InterleavedMainColourSource;

                otherLabel = 'unused';
                otherSelectedOption = 'None';
            }
            else {
                mainLabel = '01 / 10 source';
                mainOptions['fixed'] = 'Fixed';
                mainOptions['main ram'] = 'MainRAM';
                mainSelectedOption = settings.InterleavedMainColourSource;

                otherLabel = '11 source';
                otherOptions['fixed'] = 'Fixed';
                otherOptions['col ram'] = 'ColourRAM';
                otherOptions['main ram'] = 'MainRAM';
                otherSelectedOption = settings.InterleavedOtherColourSource;
            }

            onMainChange = v => dispatch(interleavedMainColourSourceSet(v));
            onOtherChange = v => dispatch(interleavedOtherColourSourceSet(v));
            break;

        case 'Continuous':
        default:

            mainLabel = '1 source';

            mainOptions['fixed'] = 'Fixed';
            mainSelectedOption = 'Fixed';
            onMainChange = v => { };

            otherLabel = 'unused';
            otherSelectedOption = 'Fixed';
            onOtherChange = v => { };
            break;
    }

    return {
        mainLabel, mainOrder, mainOptions, mainSelectedOption, onMainChange,
        otherLabel, otherOrder, otherOptions, otherSelectedOption, onOtherChange
    };
}


const getMemorySettings = (settings: GraphicsState, dispatch: Dispatch<any>) => {

    let mainColourAddressRAMEnabled: boolean = false;
    let mainColourAddressRAM: number = 0x0000;
    let mainColourAddressRAMOnChange: (v: number) => void = v => { };
    let mainColourAddressCOLRAMEnabled: boolean = false;
    let mainColourAddressCOLRAM: number = 0x0000;
    let mainColourAddressCOLRAMOnChange: (v: number) => void = v => { };
    let otherColourAddressRAMEnabled: boolean = false;
    let otherColourAddressRAM: number = 0x0000;
    let otherColourAddressRAMOnChange: (v: number) => void = v => { };
    let otherColourAddressCOLRAMEnabled: boolean = false;
    let otherColourAddressCOLRAM: number = 0x0000;
    let otherColourAddressCOLRAMOnChange: (v: number) => void = v => { };

    switch (settings.ViewLayout) {
        case 'CharacterMapped':

            switch (settings.CharacterMappedMainColourSource) {
                case 'ColourRAM':
                    mainColourAddressCOLRAMEnabled = true;
                    break;
                case 'MainRAM':
                    mainColourAddressRAMEnabled = true;
                    break;
                case 'Fixed':
                default:
                    break;
            }

            if (mainColourAddressCOLRAMEnabled) {
                mainColourAddressCOLRAM = settings.CharacterMappedMainColourAddressCOLRAM;
                mainColourAddressCOLRAMOnChange = v => dispatch(characterMappedMainColourAddressCOLRAMSet(v));
            }

            if (mainColourAddressRAMEnabled) {
                mainColourAddressRAM = settings.CharacterMappedMainColourAddressRAM;
                mainColourAddressRAMOnChange = v => dispatch(characterMappedMainColourAddressRAMSet(v));
            }

            break;

        case 'Interleaved':

            switch (settings.InterleavedMainColourSource) {
                case 'MainRAM':
                    mainColourAddressRAMEnabled = true;
                    break;
                case 'Fixed':
                default:
                    break;
            }

            if (mainColourAddressRAMEnabled) {
                mainColourAddressRAM = settings.InterleavedMainColourAddressRAM;
                mainColourAddressRAMOnChange = v => dispatch(interleavedMainColourAddressRAMSet(v));
            }

            let showOtherSource = (settings.InterleavedMode === 'MultiColour');
            if (showOtherSource) {
                switch (settings.InterleavedOtherColourSource) {
                    case 'ColourRAM':
                        otherColourAddressCOLRAMEnabled = true;
                        break;
                    case 'MainRAM':
                        otherColourAddressRAMEnabled = true;
                        break;
                    case 'Fixed':
                    default:
                        break;
                }

                if (otherColourAddressCOLRAMEnabled) {
                    otherColourAddressCOLRAM = settings.InterleavedOtherColourAddressCOLRAM;
                    otherColourAddressCOLRAMOnChange = v => dispatch(interleavedOtherColourAddressCOLRAMSet(v));
                }

                if (otherColourAddressRAMEnabled) {
                    otherColourAddressRAM = settings.InterleavedOtherColourAddressRAM;
                    otherColourAddressRAMOnChange = v => dispatch(interleavedOtherColourAddressRAMSet(v));
                }
            }

            break;

        case 'Continuous':
        default:
            break;
    }

    return {
        mainColourAddressRAMEnabled, mainColourAddressRAM, mainColourAddressRAMOnChange,
        mainColourAddressCOLRAMEnabled, mainColourAddressCOLRAM, mainColourAddressCOLRAMOnChange,
        otherColourAddressRAMEnabled, otherColourAddressRAM, otherColourAddressRAMOnChange,
        otherColourAddressCOLRAMEnabled, otherColourAddressCOLRAM, otherColourAddressCOLRAMOnChange
    };
}


export const ColoursSourcesSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(ColoursSourcesSettingsSection.name);

    const settings = useAppSelector((state: RootState) => state.graphics);
    const dispatch = useAppDispatch();

    const source = getSourceSettings(settings, dispatch);
    const mem = getMemorySettings(settings, dispatch);

    return (
        <Stack layout='vertical'>
            <SettingsRow label='Main Source'>
                <EnumSetting
                    label={source.mainLabel}
                    selectedOption={source.mainSelectedOption}
                    options={source.mainOptions}
                    order={source.mainOrder}
                    onChange={source.onMainChange}
                />
                {mem.mainColourAddressCOLRAMEnabled &&
                    <HexNumberInput
                        label="COLRAM address"
                        number={mem.mainColourAddressCOLRAM}
                        forceFourDigits={true}
                        enabled={mem.mainColourAddressCOLRAMEnabled}
                        onChange={mem.mainColourAddressCOLRAMOnChange}
                        notifyFocus={true}
                    />
                }
                {!mem.mainColourAddressCOLRAMEnabled &&
                    <HexNumberInput
                        label="RAM address"
                        number={mem.mainColourAddressRAM}
                        forceFourDigits={true}
                        enabled={mem.mainColourAddressRAMEnabled}
                        onChange={mem.mainColourAddressRAMOnChange}
                        notifyFocus={true}
                    />}
            </SettingsRow>

            <SettingsRow label='Other Source'>
                <EnumSetting
                    label={source.otherLabel}
                    selectedOption={source.otherSelectedOption}
                    options={source.otherOptions}
                    order={source.otherOrder}
                    onChange={source.onOtherChange}
                />
                {mem.otherColourAddressCOLRAMEnabled &&
                    <HexNumberInput
                        label="COLRAM address"
                        number={mem.otherColourAddressCOLRAM}
                        forceFourDigits={true}
                        enabled={mem.otherColourAddressCOLRAMEnabled}
                        onChange={mem.otherColourAddressCOLRAMOnChange}
                        notifyFocus={true}
                    />}
                {!mem.otherColourAddressCOLRAMEnabled &&
                    <HexNumberInput
                        label="RAM address"
                        number={mem.otherColourAddressRAM}
                        forceFourDigits={true}
                        enabled={mem.otherColourAddressRAMEnabled}
                        onChange={mem.otherColourAddressRAMOnChange}
                        notifyFocus={true}
                    />}
            </SettingsRow>
        </Stack>
    );
}