import { ProjectDescription, ProjectVisibility } from "./ProjectDescription";
import { buildHeader } from "./ProjectDescriptionHelpers";
import { splitToKV } from "./Utils";

export type ProjectInfo = {
    location?: 'local' | 'cloud',
    guid: string,
    name: string,
    uid?: string,
    userName?: string,
    visibility?: ProjectVisibility,
    timestamp: number,
    commandCount: number
}

export const serialise = (description: ProjectDescription, time: number) => {
    return [
        ...buildHeader(description, time)
    ].join("\n");
}

export const deserialise = (contents: string | null): ProjectInfo | undefined => {

    if (contents == null) { return; }

    const lines = contents.split(/[\r\n]+/);
    if (lines.length < 1) { return; }

    const firstline = splitToKV(lines.shift());
    if (firstline == null) { return; }

    const [magic, magicArgs] = firstline;
    if (magic !== '46c') { return; }
    if (magicArgs.length !== 1) { return; }
    const version = magicArgs[0];

    if (version === '1') { return deserialise_v01(lines); }
}

const deserialise_v01 = (lines: string[]): ProjectInfo | undefined => {

    let guid = '';
    let name = '';
    let timestamp = NaN;
    let commandCount = NaN;
    let uid: string | undefined;
    let userName: string | undefined;
    let visibilityRaw: string | undefined;

    lines
        .map(l => splitToKV(l))
        .filter((kv): kv is [string, string[]] => kv != null)
        .forEach(kv => {
            const [key, values] = kv;
            if (key === "guid") { guid = values[0]; }
            if (key === "name") { name = values[0]; }
            if (key === "t") { timestamp = Number(values[0]); }
            if (key === 'commandCount') { commandCount = Number(values[0]); }
            if (key === 'c') { commandCount = Number(values[0]); }
            if (key === 'uid') { uid = values[0]; }
            if (key === 'userName') { userName = values[0]; }
            if (key === 'visibility') { visibilityRaw = values[0]; }
        });

    if (guid === '' || name === '' || isNaN(timestamp) || isNaN(commandCount)) { return; }

    const visibilities: ProjectVisibility[] = ['private', 'public'];
    const visibilityIndex = visibilities.indexOf(visibilityRaw as ProjectVisibility);
    const visibility: ProjectVisibility = visibilityIndex < 0 ? 'private' : visibilities[visibilityIndex];

    return { guid, name: decodeURIComponent(name), uid, userName, visibility, timestamp, commandCount };
}
