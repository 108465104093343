import * as React from "react";
import { MemoryAddress, MemoryLocation } from "../../classes/code/MemoryLocation";
import { Utils } from "../../classes/Utils";
import styles from './Byte.module.css';
import { useEffect, useRef, useState } from "react";
import { formattingToggled, selectGuidAndName } from "../../store/projectSlice";
import { selectActiveMemoryView, updateRouteToCurrentLine } from "../../store/codeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectSelection } from "../../store/toolSlice";
import { logMinorComponentRender } from "../../classes/Logger";
import { logCommandAnalytic } from "../../classes/code/Firebase";
import { selectIsProjectReadOnly } from "../../store/extraSelectors";


export const Byte: React.FunctionComponent<{
    address: number,
    location: MemoryLocation,
    highlightValidChars: boolean,
    onClick: (e: React.MouseEvent<HTMLElement>) => void
}> = (props) => {

    logMinorComponentRender(Byte.name);

    const { guid, name } = useAppSelector(selectGuidAndName);

    const { address, location, highlightValidChars, onClick } = props;
    const { selectionAddress: selectionAddressRAM, selectionCount: selectionCountRAM, selectionAddressROM, selectionCountROM } = useAppSelector(selectSelection);
    const activeMemoryView = useAppSelector(selectActiveMemoryView);

    const isReadOnly = useAppSelector(selectIsProjectReadOnly);

    const isRAM = activeMemoryView === 'ram';
    const selectionAddress = isRAM ? selectionAddressRAM : selectionAddressROM;
    const selectionCount = isRAM ? selectionCountRAM : selectionCountROM;

    const isROM = location.Address.type === 'rom';
    const isBasic = 0xa000 <= address && address <= 0xbfff;
    const isIO = 0xd000 <= address && address <= 0xd7ff;
    const isCOLRAM = 0xd800 <= address && address <= 0xdbe7;
    const isKernal = 0xe000 <= address && address <= 0xffff;
    const isSelectable = !isROM || (isROM && (isBasic || isIO || isCOLRAM || isKernal));

    const selected = (address >= selectionAddress) && (address <= selectionAddress + selectionCount - 1) && isSelectable;

    const dispatch = useAppDispatch();

    const ref = useRef<HTMLSpanElement>(null);
    const [hoverSide, setHoverSide] = useState<'none' | 'left' | 'right'>('none');
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        if (!isSelectable || isROM || isReadOnly) { return; }
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        if (!isSelectable || isROM || isReadOnly) { return; }
        setIsHovering(false);
    };

    useEffect(() => {
        if (isReadOnly) { return; }
        if (!isSelectable || isROM) { return; }
        if (!isHovering) { return; }
        if (ref.current == null) { return; }

        const r = ref.current;

        const onMouseMove = (e: MouseEvent) => {
            if (e.currentTarget !== r) { return; }
            const hotspot = 0.4;
            const rect = r.getBoundingClientRect();
            const mouseOffsetFromLeftEdge = e.clientX - rect.left;
            const isHoveringOverLeftDigit = mouseOffsetFromLeftEdge <= (rect.width * hotspot);
            const isHoveringOverRightDigit = mouseOffsetFromLeftEdge >= (rect.width * (1 - hotspot));
            const side = isHoveringOverLeftDigit ? 'left' : (isHoveringOverRightDigit ? 'right' : 'none');
            setHoverSide(side);
        }

        r.addEventListener('mousemove', onMouseMove);

        return () => {
            r.removeEventListener('mousemove', onMouseMove);
        }
    }, [isHovering, ref, isSelectable, isROM, isReadOnly])

    const byte = location.Value;
    const byteAsHex = Utils.to2DigitHexString(byte);
    const { valid } = Utils.toChar(byte);
    const isValid = valid && highlightValidChars;

    const formatMemoryAddress = (a: MemoryAddress) => `$${Utils.to4DigitHexString(a.address)}${a.type === 'rom' ? ' (ROM)' : ''}`

    const reads = location.IncomingReads;
    const writes = location.IncomingWrites;
    const pointers = location.IncomingPointers;
    const pointerOut = location.OutgoingPointer;
    const jmpsIn = location.IncomingJMPs;
    const jsrsIn = location.IncomingJSRs;
    const isReadFrom = reads.length > 0;
    const isWrittenTo = writes.length > 0;
    const isPointedAt = pointers.length > 0;
    const pointsAt = pointerOut != null;
    const isJsrTo = jsrsIn.length > 0;
    const isJmpTo = jmpsIn.length > 0;
    const hasRefs = isReadFrom || isWrittenTo || isPointedAt || pointsAt || isJsrTo || isJmpTo;
    const showRefs = (location.IsCode || location.IsArgument || location.IsGfx || location.IsData);
    const hasRefsStyle = (hasRefs && showRefs) ? ' ' + styles.accessed : '';
    const readsStr = isReadFrom ? 'Read by : ' + reads.map(a => formatMemoryAddress(a)).join(', ') + '\n' : '';
    const writesStr = isWrittenTo ? 'Written by : ' + writes.map(a => formatMemoryAddress(a)).join(', ') + '\n' : '';
    const pointersStr = isPointedAt ? 'Pointed at by : ' + pointers.map(a => formatMemoryAddress(a)).join(', ') + '\n' : '';
    const pointsAtStr = pointsAt ? 'Points at : ' + formatMemoryAddress(pointerOut.target) : '';
    const jsrsInStr = isJsrTo ? "JSR'd to by : " + jsrsIn.map(a => formatMemoryAddress(a)).join(', ') + '\n' : '';
    const jmpsInStr = isJmpTo ? "JMP'd to by : " + jmpsIn.map(a => formatMemoryAddress(a)).join(', ') + '\n' : '';
    const accessesStr = (readsStr + writesStr + pointersStr + pointsAtStr + jsrsInStr + jmpsInStr).trim();
    const hasManualFormatting = ['ManualLineBreakAfter', 'ManualGapAfter'].indexOf(location.Formatting) !== -1;

    const handleFormattingChange = (e: React.MouseEvent, addr: number) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (!isSelectable || isROM) { return; }

        const toggleType = e.shiftKey;
        logCommandAnalytic(!hasManualFormatting ? 'add_command' : (toggleType ? 'edit_command' : 'delete_command'), 'formatting', addr, guid, name);
        dispatch(formattingToggled({ address: addr, toggleType }));

        updateRouteToCurrentLine();
    }

    let extraStyle: string;
    if (selected)
        extraStyle = isValid ? ' ' + styles.selected : ' ' + styles.selectedinvalid;
    else
        extraStyle = isValid ? '' : ' ' + styles.invalid;

    const breakStyle = hasManualFormatting ? ' ' + styles.break : '';

    const byteStyle = styles.byte + extraStyle + hasRefsStyle + breakStyle;

    return (
        <span
            ref={ref}
            className={byteStyle}
            onClick={isSelectable ? onClick : undefined}
            title={accessesStr}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            {byteAsHex}
            {isHovering && hoverSide === 'left' && (
                <div className={styles.before + ' ' + styles.divider} onClick={e => handleFormattingChange(e, address - 1)} />
            )}
            {isHovering && hoverSide === 'right' && (
                <div className={styles.after + ' ' + styles.divider} onClick={e => handleFormattingChange(e, address)} />
            )}
        </span>
    )
}