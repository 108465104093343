import React from 'react';
import { EnumSetting } from './EnumSetting';
import { MarkedRegionsAppearance, selectMarkedRegionsAppearance, selectShowGrid, showGridSet } from '../../store/graphicsSlice';
import { markedRegionsAppearanceSet } from '../../store/graphicsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';
import { Stack } from '../Stack';
import { SettingsRow } from './SettingsRow';

export const ViewSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(ViewSettingsSection.name);

    const showGrid = useAppSelector(selectShowGrid);
    const markedRegionsAppearance = useAppSelector(selectMarkedRegionsAppearance);
    const dispatch = useAppDispatch();

    return (
        <Stack layout='vertical'>
            <SettingsRow label='8x8 Grid'>
                <EnumSetting
                    label="grid"
                    selectedOption={showGrid}
                    options={{ 'Off': false, 'On': true }}
                    order={['Off', 'On']}
                    onChange={v => dispatch(showGridSet(v))}
                />
            </SettingsRow>

            <SettingsRow label='Dim Marked'>
                <EnumSetting
                    label="marked regions"
                    selectedOption={markedRegionsAppearance}
                    options={{ 'Off': MarkedRegionsAppearance.Normal, 'On': MarkedRegionsAppearance.Dimmed }}
                    order={['Off', 'On']}
                    onChange={v => dispatch(markedRegionsAppearanceSet(v))}
                />
            </SettingsRow>
        </Stack>
    );
}