import React, { MouseEvent } from 'react';
import { ISettingProps } from './ISettingProps';
import styles from './Setting.module.css';
import { logMinorComponentRender } from '../../classes/Logger';
import { cx } from '../../classes/Utils';


export interface IEnumSettingProps extends ISettingProps {
    options: { [label: string]: any };
    selectedOption: any;
    order: string[];
    onChange: (v: any) => void;
}


export const EnumSetting: React.FunctionComponent<IEnumSettingProps> = (props) => {

    logMinorComponentRender(EnumSetting.name);

    const handleChange = (e: MouseEvent, key: string) => {
        e.preventDefault();
        e.stopPropagation();

        props.onChange(props.options[key]);
    }

    const items = props.order.map((key, index) => {

        const enabled = key in props.options;
        const selected = (props.options[key] === props.selectedOption);
        let value = <>{key}</>;

        const selectedStyle = selected ? styles.selected : '';
        const disabledStyle = !enabled ? styles.disabled : '';
        const enumStyle = cx(styles.enum, disabledStyle, selectedStyle);
        const unselectedStyle = cx(styles.enum, styles.unselected);

        if (!selected && enabled)
            value = <button className={unselectedStyle} onClick={(e) => handleChange(e, key)}>{value}</button>;
        else
            value = <span className={enumStyle}>{value}</span>;

        value = <li key={index} className={styles.li}>{value}</li>;

        return value;
    });

    return (
        <ul className={styles.ul}>{items}</ul>
    );
}
