import { GraphicsState } from "../../store/graphicsSlice";
import { Palette } from "../Palette";
import { GraphicsSnapshot, SpriteSnapshot } from "../SnapshotLoader";
import { Utils, componentsToGraphicsType, graphicsTypeToComponents } from "../Utils";
import { MemoryLocation } from "../code/MemoryLocation";
import { GraphicsRange, Range } from "../code/Range";
import { ColourMode, ColourSource, GraphicsTypes } from "../graphics/GraphicsEnums";
import { GraphicCommand } from "./GraphicCommand";

export type GraphicCommandProps = {
    name?: string,
    address: number,
    countBytes: number,
    widthPx: number,
    heightPx: number,
    scale: number,
    graphicsType?: GraphicsTypes,
    referenceAddress1?: number,
    referenceAddress2?: number,
    colour00?: number,
    colour01?: number,
    colour10?: number,
    colour11?: number,
    extraColour?: number
}

let nextId = 10000;

export const newGraphicCommand = (p: GraphicCommandProps): GraphicCommand => {

    return {
        type: 'graphic',
        id: nextId++,
        name: p.name,
        address: p.address, countBytes: p.countBytes,
        widthPx: p.widthPx, heightPx: p.heightPx, scale: p.scale,
        graphicsType: p.graphicsType, referenceAddress1: p.referenceAddress1, referenceAddress2: p.referenceAddress2,
        colour00: p.colour00, colour01: p.colour01, colour10: p.colour10, colour11: p.colour11, extraColour: p.extraColour
    }
};

export const cloneGraphicCommand = (p: GraphicCommand): GraphicCommand => {

    return newGraphicCommand({
        name: p.name,
        address: p.address, countBytes: p.countBytes,
        widthPx: p.widthPx, heightPx: p.heightPx, scale: p.scale,
        graphicsType: p.graphicsType, referenceAddress1: p.referenceAddress1, referenceAddress2: p.referenceAddress2,
        colour00: p.colour00, colour01: p.colour01, colour10: p.colour10, colour11: p.colour11, extraColour: p.extraColour
    });
};



export const markUpMemory = (command: GraphicCommand, memory: MemoryLocation[], ranges: Range[]) => {
    const { address, countBytes } = command;
    for (let index = address; index < address + countBytes; index++) {
        if (index > 0xffff) { continue; }
        memory[index].IsGfx = true;

        if (index > address) {
            memory[index].CountWithinBlock = -(index - address);
        }
    }

    memory[address].SectionHeading = command.name ?? `gfx @ $${Utils.to4DigitHexString(address)}`;
    memory[address].CountWithinBlock = countBytes;
    ranges.push(new GraphicsRange('Graphic', command));
}

export const equals = (command: GraphicCommand, otherCommand: GraphicCommand) => {

    let res = true;
    // res &&= (command.id === otherCommand.id);
    res &&= (command.address === otherCommand.address);
    res &&= (command.countBytes === otherCommand.countBytes);
    res &&= (command.widthPx === otherCommand.widthPx);
    res &&= (command.heightPx === otherCommand.heightPx);
    res &&= (command.scale === otherCommand.scale);
    res &&= (command.graphicsType === otherCommand.graphicsType);
    res &&= (command.referenceAddress1 === otherCommand.referenceAddress1);
    res &&= (command.referenceAddress2 === otherCommand.referenceAddress2);
    res &&= (command.colour00 === otherCommand.colour00);
    res &&= (command.colour01 === otherCommand.colour01);
    res &&= (command.colour10 === otherCommand.colour10);
    res &&= (command.colour11 === otherCommand.colour11);
    res &&= (command.extraColour === otherCommand.extraColour);
    return res;
}

export const newGraphicCommandFromState = (graphicsSettings: GraphicsState, selectionAddress: number, selectionCount: number) => {

    const defaultColour = Palette.Black.index;

    let colourMode: ColourMode;
    let mainColourSource: ColourSource;
    let mainColourAddressRAM: number | undefined;
    let mainColourAddressCOLRAM: number | undefined;
    let otherColourSource: ColourSource | undefined;
    let otherColourAddressRAM: number | undefined;
    let otherColourAddressCOLRAM: number | undefined;
    let characterSetAddress: number | undefined;
    let colour00 = defaultColour;
    let colour01 = defaultColour;
    let colour10 = defaultColour;
    let colour11 = defaultColour;
    let extraColour = defaultColour;
    let scale: number = 1;
    let widthChars: number = 1;

    switch (graphicsSettings.ViewLayout) {
        case 'CharacterMapped':
            colourMode = graphicsSettings.CharacterMappedMode;
            mainColourSource = graphicsSettings.CharacterMappedMainColourSource;
            mainColourAddressRAM = graphicsSettings.CharacterMappedMainColourAddressRAM;
            mainColourAddressCOLRAM = graphicsSettings.CharacterMappedMainColourAddressCOLRAM;
            characterSetAddress = graphicsSettings.CharacterMappedCharacterSetAddress;
            scale = graphicsSettings.CharacterMappedScale;
            widthChars = graphicsSettings.CharacterMappedWidthChars;

            if (colourMode === 'HiRes') {
                colour00 = graphicsSettings.CharacterMappedHiresBackground00;
                if (mainColourSource === 'Fixed') {
                    colour01 = graphicsSettings.CharacterMappedHiresForeground01;
                }
            } else if (colourMode === 'MultiColour') {
                colour00 = graphicsSettings.CharacterMappedMultiColourBackground00;
                colour01 = graphicsSettings.CharacterMappedMultiColourForeground01;
                colour10 = graphicsSettings.CharacterMappedMultiColourForeground10;
                if (mainColourSource === 'Fixed') {
                    colour11 = graphicsSettings.CharacterMappedMultiColourForeground11;
                }
            } else if (colourMode === 'Extended') {
                colour00 = graphicsSettings.CharacterMappedExtendedColourBackground00;
                colour01 = graphicsSettings.CharacterMappedExtendedColourBackground01;
                colour10 = graphicsSettings.CharacterMappedExtendedColourBackground10;
                colour11 = graphicsSettings.CharacterMappedExtendedColourBackground11;
                if (mainColourSource === 'Fixed') {
                    extraColour = graphicsSettings.CharacterMappedExtendedColourForeground01;
                }
            }
            break;

        case 'Interleaved':
            colourMode = graphicsSettings.InterleavedMode;
            mainColourSource = graphicsSettings.InterleavedMainColourSource;
            mainColourAddressRAM = graphicsSettings.InterleavedMainColourAddressRAM;
            otherColourSource = graphicsSettings.InterleavedOtherColourSource;
            otherColourAddressRAM = graphicsSettings.InterleavedOtherColourAddressRAM;
            otherColourAddressCOLRAM = graphicsSettings.InterleavedOtherColourAddressCOLRAM;
            scale = graphicsSettings.InterleavedScale;
            widthChars = graphicsSettings.InterleavedWidthChars;

            if (colourMode === 'HiRes') {
                if (mainColourSource === 'Fixed') {
                    colour00 = graphicsSettings.InterleavedHiresBackground00;
                    colour01 = graphicsSettings.InterleavedHiresForeground01;
                }
            }
            else if (colourMode === 'MultiColour') {
                colour00 = graphicsSettings.InterleavedMultiColourBackground00;

                if (mainColourSource === 'Fixed') {
                    colour01 = graphicsSettings.InterleavedMultiColourForeground01
                    colour10 = graphicsSettings.InterleavedMultiColourForeground10
                }

                if (otherColourSource === 'Fixed') {
                    colour11 = graphicsSettings.InterleavedMultiColourForeground11;
                }
            }
            break;

        case 'Continuous':
        default:
            colourMode = graphicsSettings.ContinuousMode;
            mainColourSource = 'Fixed';
            scale = graphicsSettings.ContinuousScale;
            widthChars = graphicsSettings.ContinuousWidthChars;

            if (colourMode === 'HiRes') {
                colour00 = graphicsSettings.ContinuousHiresBackground00;
                colour01 = graphicsSettings.ContinuousHiresForeground01;
            }
            else {
                colour00 = graphicsSettings.ContinuousMultiColourBackground00;
                colour01 = graphicsSettings.ContinuousMultiColourForeground01;
                colour10 = graphicsSettings.ContinuousMultiColourForeground10;
                colour11 = graphicsSettings.ContinuousMultiColourForeground11;
            }
            break;
    }

    let mainColourSourceOffset: number | undefined;
    if (mainColourSource === 'MainRAM') {
        mainColourSourceOffset = mainColourAddressRAM;
    } else if (mainColourSource === 'ColourRAM') {
        mainColourSourceOffset = mainColourAddressCOLRAM;
    }

    let otherColourSourceOffset: number | undefined;
    if (otherColourSource === 'MainRAM') {
        otherColourSourceOffset = otherColourAddressRAM;
    } else if (otherColourSource === 'ColourRAM') {
        otherColourSourceOffset = otherColourAddressCOLRAM;
    }

    const viewLayout = graphicsSettings.ViewLayout;
    const graphicsType = componentsToGraphicsType(viewLayout, colourMode, mainColourSource, otherColourSource ?? 'Fixed');

    const isCharMapped = viewLayout === 'CharacterMapped';

    return newGraphicCommand({
        address: selectionAddress,
        countBytes: selectionCount,
        widthPx: widthChars * 8,
        heightPx: getHeight(graphicsType, selectionCount, widthChars),
        scale,
        graphicsType,
        referenceAddress1: isCharMapped ? characterSetAddress : mainColourSourceOffset,
        referenceAddress2: isCharMapped ? mainColourSourceOffset : otherColourSourceOffset,
        colour00,
        colour01,
        colour10,
        colour11,
        extraColour
    });
}

const getHeight = (graphicsType: GraphicsTypes, countBytes: number, widthChars: number) => {
    let heightPx;
    const { layout } = graphicsTypeToComponents(graphicsType);
    if (layout === 'CharacterMapped') {
        heightPx = Math.ceil(countBytes / widthChars) * 8;
    } else if (layout === 'Interleaved') {
        const charCount = Math.ceil(countBytes / 8);
        heightPx = Math.ceil(charCount / widthChars) * 8;
    } else if (layout === 'Continuous') {
        heightPx = Math.ceil(countBytes / widthChars);
    } else if (layout === 'DoubleMemory') {
        heightPx = Math.ceil(countBytes / widthChars) * 8;
    }
    else {
        heightPx = heightPx = Math.ceil(countBytes / widthChars) * 8;
    }

    return heightPx;
}

export const newGraphicCommandsFromSnapshot = (graphicsSnapshot: GraphicsSnapshot) => {

    const commands: GraphicCommand[] = [];

    const mainGraphicCommand = fromGraphicsSnapshot(graphicsSnapshot);
    if (mainGraphicCommand != null) {
        commands.push(mainGraphicCommand);
    }

    for (let index = 0; index <= 7; index++) {
        const command = fromSpriteSnapshot(graphicsSnapshot.sprites[index]);
        if (command != null) {
            commands.push(command);
        }
    }

    return commands;
}


const fromGraphicsSnapshot = (graphicsSnapshot: GraphicsSnapshot) => {

    let command: GraphicCommand | undefined;

    if (graphicsSnapshot.graphicsType === 'Bitmapped' || graphicsSnapshot.graphicsType === 'BitmappedMultiColour') {
        const isHires = graphicsSnapshot.graphicsType === 'Bitmapped';
        command = newGraphicCommand({
            address: graphicsSnapshot.bitmapAddress,
            countBytes: 0x1f40,
            widthPx: 320,
            heightPx: 200,
            scale: 1,
            graphicsType: isHires ? 'InterleavedHiresRAM' : 'InterleavedMultiColourRAMCOLRAM',
            referenceAddress1: graphicsSnapshot.screenAddress,
            referenceAddress2: 0x0000,
            colour00: graphicsSnapshot.background00ColourIndex
        });
    } else if (graphicsSnapshot.graphicsType === 'CharacterMapped' || graphicsSnapshot.graphicsType === 'CharacterMappedMultiColour' || graphicsSnapshot.graphicsType === 'ExtendedColour') {
        const graphicsType = graphicsSnapshot.graphicsType === 'CharacterMapped' ? 'CharacterMappedHiresCOLRAM' :
            (graphicsSnapshot.graphicsType === 'CharacterMappedMultiColour' ? 'CharacterMappedMultiColourCOLRAM' :
                'CharacterMappedExtendedCOLRAM');
        command = newGraphicCommand({
            address: graphicsSnapshot.screenAddress,
            countBytes: 0x03e8,
            widthPx: 320,
            heightPx: 200,
            scale: 1,
            graphicsType,
            referenceAddress1: graphicsSnapshot.charsetAddress,
            referenceAddress2: 0x0000,
            colour01: graphicsSnapshot.foreground01ColourIndex,
            colour10: graphicsSnapshot.foreground10ColourIndex,
            colour00: graphicsSnapshot.background00ColourIndex
        });
    }

    return command;
}

const fromSpriteSnapshot = (sprite: SpriteSnapshot) => {

    if (!sprite.enabled) { return undefined; }

    const graphicsType: GraphicsTypes = sprite.multiColour ? 'ContinuousMultiColour' : 'ContinuousHires';

    return newGraphicCommand({
        address: sprite.address,
        countBytes: 0x3f,
        widthPx: 24,
        heightPx: 21,
        scale: 1,
        graphicsType,
        colour01: sprite.multiColour ? sprite.foreground01Index : sprite.foreground1Or10Index,
        colour10: sprite.foreground1Or10Index,
        colour11: sprite.foreground11Index,
        colour00: sprite.background00Index
    });
}



const hex2 = Utils.to2DigitHexString;
const hex4 = Utils.to4DigitHexString;
const hex = (h: number) => (h > 0xff) ? hex4(h) : hex2(h);

const hex4Safe = (num?: number) => {
    return (num == null) ? '' : hex4(num);
}

const colourSafe = (col?: number) => {
    return (col == null) ? '' : col.toString();
}

export const version = "1";

export const serialise = (command: GraphicCommand): string => {

    const type = command.graphicsType;
    let shortType = '00';
    if (type === 'CharacterMappedHiresFixed') { shortType = '00' };
    if (type === 'CharacterMappedHiresCOLRAM') { shortType = '01' };
    if (type === 'CharacterMappedHiresRAM') { shortType = '02' };
    if (type === 'CharacterMappedMultiColourFixed') { shortType = '03' };
    if (type === 'CharacterMappedMultiColourCOLRAM') { shortType = '04' };
    if (type === 'CharacterMappedMultiColourRAM') { shortType = '05' };
    if (type === 'CharacterMappedExtendedFixed') { shortType = '06' };
    if (type === 'CharacterMappedExtendedCOLRAM') { shortType = '07' };
    if (type === 'CharacterMappedExtendedRAM') { shortType = '08' };
    if (type === 'InterleavedHiresFixed') { shortType = '10' };
    if (type === 'InterleavedHiresRAM') { shortType = '11' };
    if (type === 'InterleavedMultiColourFixedFixed') { shortType = '12' };
    if (type === 'InterleavedMultiColourFixedCOLRAM') { shortType = '13' };
    if (type === 'InterleavedMultiColourFixedRAM') { shortType = '14' };
    if (type === 'InterleavedMultiColourRAMFixed') { shortType = '15' };
    if (type === 'InterleavedMultiColourRAMCOLRAM') { shortType = '16' };
    if (type === 'InterleavedMultiColourRAMRAM') { shortType = '17' };
    if (type === 'ContinuousHires') { shortType = '20' };
    if (type === 'ContinuousMultiColour') { shortType = '21' };
    if (type === 'ColoursDoubleMemory') { shortType = '30' };

    return [
        'gr',
        hex4(command.address),
        hex(command.countBytes),
        hex(command.widthPx),
        hex(command.heightPx),
        command.scale.toString(),
        shortType,
        hex4Safe(command.referenceAddress1),
        hex4Safe(command.referenceAddress2),
        colourSafe(command.colour00),
        colourSafe(command.colour01),
        colourSafe(command.colour10),
        colourSafe(command.colour11),
        colourSafe(command.extraColour)
    ].join('|');
}

const deserialise_v01 = (components: string[]): GraphicCommand | undefined => {

    if (components.length !== 13) { return; }

    const address = Number(`0x${components[0]}`);
    const countBytes = Number(`0x${components[1]}`);
    const widthPx = Number(`0x${components[2]}`);
    const heightPx = Number(`0x${components[3]}`);
    const scale = Number(components[4]);

    if (isNaN(address) || isNaN(countBytes) || isNaN(widthPx) || isNaN(heightPx) || isNaN(scale)) { return; }

    const shortType = components[5];
    const referenceAddress1 = (components[6] === '') ? undefined : Number(`0x${components[6]}`);
    const referenceAddress2 = (components[7] === '') ? undefined : Number(`0x${components[7]}`);
    const colour00 = (components[8] === '') ? undefined : Number(components[8]);
    const colour01 = (components[9] === '') ? undefined : Number(components[9]);
    const colour10 = (components[10] === '') ? undefined : Number(components[10]);
    const colour11 = (components[11] === '') ? undefined : Number(components[11]);
    const extraColour = (components[12] === '') ? undefined : Number(components[12]);

    let graphicsType: GraphicsTypes = 'CharacterMappedHiresCOLRAM';
    if (shortType === '00') { graphicsType = 'CharacterMappedHiresFixed' };
    if (shortType === '01') { graphicsType = 'CharacterMappedHiresCOLRAM' };
    if (shortType === '02') { graphicsType = 'CharacterMappedHiresRAM' };
    if (shortType === '03') { graphicsType = 'CharacterMappedMultiColourFixed' };
    if (shortType === '04') { graphicsType = 'CharacterMappedMultiColourCOLRAM' };
    if (shortType === '05') { graphicsType = 'CharacterMappedMultiColourRAM' };
    if (shortType === '06') { graphicsType = 'CharacterMappedExtendedFixed' };
    if (shortType === '07') { graphicsType = 'CharacterMappedExtendedCOLRAM' };
    if (shortType === '08') { graphicsType = 'CharacterMappedExtendedRAM' };
    if (shortType === '10') { graphicsType = 'InterleavedHiresFixed' };
    if (shortType === '11') { graphicsType = 'InterleavedHiresRAM' };
    if (shortType === '12') { graphicsType = 'InterleavedMultiColourFixedFixed' };
    if (shortType === '13') { graphicsType = 'InterleavedMultiColourFixedCOLRAM' };
    if (shortType === '14') { graphicsType = 'InterleavedMultiColourFixedRAM' };
    if (shortType === '15') { graphicsType = 'InterleavedMultiColourRAMFixed' };
    if (shortType === '16') { graphicsType = 'InterleavedMultiColourRAMCOLRAM' };
    if (shortType === '17') { graphicsType = 'InterleavedMultiColourRAMRAM' };
    if (shortType === '20') { graphicsType = 'ContinuousHires' };
    if (shortType === '21') { graphicsType = 'ContinuousMultiColour' };
    if (shortType === '30') { graphicsType = 'ColoursDoubleMemory' };

    return newGraphicCommand({
        address, countBytes, widthPx, heightPx, scale, graphicsType,
        referenceAddress1, referenceAddress2,
        colour00, colour01, colour10, colour11, extraColour
    });
}

export const deserialisers: { [version: string]: (c: string[]) => GraphicCommand | undefined } = {
    '1': deserialise_v01
};
