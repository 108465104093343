import * as React from "react";
import styles from './HexNumberInput.module.css';

import { ISettingProps } from "./ISettingProps";
import { logMinorComponentRender } from "../../classes/Logger";
import { cx } from "../../classes/Utils";
import { useAppDispatch } from "../../store/hooks";
import { focusCountDecremented, focusCountIncremented } from "../../store/uiSlice";


export interface ITextInputProps extends ISettingProps {
    onChange: (newText: string) => void;
    text: string;
    enabled: boolean;
    cssClassName?: string;
    size?: number;
    notifyFocus?: boolean;
}

export const TextInput: React.FunctionComponent<ITextInputProps> = (props) => {

    logMinorComponentRender(TextInput.name);

    const [textString, setTextString] = React.useState<string>(props.text);
    const [textStringIsValid, setTextStringIsValid] = React.useState<boolean>(true);
    const [isFocused, setIsFocused] = React.useState<boolean>(true);

    const notifyFocus = (props.notifyFocus != null) && props.notifyFocus;

    const inputRef = React.useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        setTextString(props.text);
    }, [props.text])

    React.useEffect(() => {
        if (isFocused) {
            inputRef.current?.select();
        }
    }, [inputRef, isFocused]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const str = e.target.value;
        setTextString(str);

        const valid = (str.length > 0);
        setTextStringIsValid(valid);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key === "Escape") {
            e.preventDefault();
            setTextString(props.text);
            setTextStringIsValid(true);
        }
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key === "Enter") {
            e.preventDefault();
            changeValueIfValid();
        }
    }

    const handleBlur = () => {

        setIsFocused(false);
        if (notifyFocus) { dispatch(focusCountDecremented()); }
        changeValueIfValid();
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {

        setIsFocused(true);
        if (notifyFocus) { dispatch(focusCountIncremented()); }
    }

    const changeValueIfValid = () => {
        if (!textStringIsValid) { return; }
        if (textString === props.text) { return; }
        props.onChange(textString);
    }

    return (
        <input
            ref={inputRef}
            type="text"
            disabled={!props.enabled}
            value={textString}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
            onFocus={handleFocus}
            className={cx(styles.textInput, props.cssClassName, textStringIsValid ? '' : styles.bad)}
            size={props.size}
        />
    );
}