import styles from './ProjectCard.module.css';
import { useContext } from "react";
import { ProjectInfo } from "../../classes/ProjectInfo";
import { useAppSelector } from "../../store/hooks";
import { selectUser } from "../../store/uiSlice";
import { ProjectActionsContext } from "./ProjectPicker";
import { faArrowRight, faCloud, faTrash, faCopy, faFloppyDisk, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { Stack } from "../Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-regular-svg-icons';

export const ProjectCard: React.FC<{ projectInfo: ProjectInfo }> = ({ projectInfo }) => {

    const { isSignedIn, uid } = useAppSelector(selectUser);

    const context = useContext(ProjectActionsContext);
    if (context == null) { return undefined; }
    const { openProject, duplicateProject, deleteProject, activeTab } = context;

    const { timestamp, commandCount, location, name, guid, uid: uidAuthor, userName: userNameAuthor, visibility } = projectInfo;
    const maxLength = 40;
    const cleanedName = name.length > maxLength ? `${name.slice(0, maxLength)}…` : name;

    const time = new Date(timestamp).toLocaleString();
    const isCloud = (location === 'cloud');
    const symbol = isCloud ? faCloud : faFloppyDisk;
    const otherSymbol = isCloud ? faFloppyDisk : faCloud;

    const isLocal = (uidAuthor == null);
    const currentUserIsAuthor = (uid === uidAuthor);
    const isReadOnly = !isLocal && !currentUserIsAuthor

    return (
        <Stack layout='horizontal' sizes={'1fr auto'} alignItems='center' cssClassName={styles.card}>

            <Stack layout='vertical'>
                <span className={styles.name}>
                    <FontAwesomeIcon icon={symbol} className={styles.icon} />
                    {(visibility === 'public' && activeTab !== 'Public' && location === 'cloud') && <FontAwesomeIcon icon={faEye} className={styles.publicIcon} />}
                    &quot;{cleanedName}&quot;
                </span>
                <span className={styles.details}>{commandCount} cmds, saved {time}</span>
                {isCloud && <span className={styles.details}>By : <span className={styles.author}>{userNameAuthor != null ? userNameAuthor : uidAuthor}</span></span>}
            </Stack>

            <div className={styles.operations}>
                <button onClick={() => openProject(guid, name)}><FontAwesomeIcon icon={faFolderOpen} /></button>
                <button disabled={isReadOnly} onClick={(e) => { e.stopPropagation(); duplicateProject(guid, name, isCloud) }}><FontAwesomeIcon icon={faCopy} /></button>
                {isSignedIn && <>
                    <button disabled={isReadOnly} onClick={(e) => { e.stopPropagation(); duplicateProject(guid, name, isCloud, true) }}><FontAwesomeIcon icon={faCopy} /> <FontAwesomeIcon icon={faArrowRight} /> <FontAwesomeIcon icon={otherSymbol} /></button>
                </>}
                <button disabled={isReadOnly} onClick={(e) => { e.stopPropagation(); deleteProject(guid, name, isCloud) }}><FontAwesomeIcon icon={faTrash} /></button>
            </div>


        </Stack>
    );
}