import React from 'react';
import { newGraphicCommand } from '../../classes/commands/GraphicCommandHelpers';
import { ViewportLocation } from '../../store/rendererSlice';
import { selectionSet } from '../../store/toolSlice';
import { WrappedGraphic } from '../graphics/Graphic';
import { selectContinuousScale } from '../../store/graphicsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMinorComponentRender } from '../../classes/Logger';

export type SpriteProps = {
    startAddress: number;
    widthChars: number;
    countBytes: number;
}

export const Sprite: React.FunctionComponent<SpriteProps> = (props) => {

    logMinorComponentRender(Sprite.name);

    const dispatch = useAppDispatch();

    const scale = useAppSelector(selectContinuousScale);

    const { startAddress, countBytes, widthChars } = props;

    const settings = newGraphicCommand({ address: startAddress, countBytes, widthPx: widthChars * 8, heightPx: Math.ceil(countBytes / widthChars), scale });

    const handleOnClick = () => {
        dispatch(selectionSet({ startAddress, count: countBytes, type: 'ram' }));
    }

    return <WrappedGraphic settings={settings} location={ViewportLocation.Main} onClick={handleOnClick} />

}