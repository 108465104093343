import React, { useEffect } from "react";
import styles from "./GraphicsCanvas.module.css";
// import { RendererDebugInfo } from "./RendererDebugInfo";
import { clearOnTickHandlers, setOnTickHandlers } from "../../classes/graphics/RendererStore";
import { useAppDispatch } from "../../store/hooks";
import { glAvailableSet } from "../../store/rendererSlice";
import { logInfo, logMajorComponentRender, logWarning } from "../../classes/Logger";

export type OnTick = (timestampMS: number) => void;

const resizeCanvasIfClientRectHasChanged = (canvas: HTMLCanvasElement) => {

    const dpr = window.devicePixelRatio ?? 1;
    const { width: cssWidth, height: cssHeight } = canvas.getBoundingClientRect();
    const devicePixelsWidth = cssWidth * dpr;
    const devicePixelsHeight = cssHeight * dpr;
    const needResize = (canvas.width !== devicePixelsWidth) || (canvas.height !== devicePixelsHeight);

    if (needResize) {
        canvas.width = devicePixelsWidth;
        canvas.height = devicePixelsHeight;
    }
}

let gl: WebGL2RenderingContext | undefined;

export const getGl = () => gl;


export const GraphicsCanvas: React.FunctionComponent = () => {

    logMajorComponentRender(GraphicsCanvas.name);

    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const onTicksRef = React.useRef<Array<OnTick>>([]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const canvas = canvasRef.current as HTMLCanvasElement;
        if (canvas == null) {
            logInfo(`${GraphicsCanvas.name}.1 : No canvas yet`);
            return;
        }

        if (gl != null) {
            logInfo(`${GraphicsCanvas.name}.1 : Already got gl context ${gl}`);
            return;
        }

        const attribs = {
            alpha: true,
            antialias: false,
            depth: false,
            desyncronized: false,
            failIfMajorPerformanceCaveat: false,
            powerPreference: 'default',
            premultipliedAlpha: true,
            stencil: false,
            preserveDrawingBuffer: false
        };
        const glContext = canvas.getContext("webgl2", attribs);

        if (!glContext || !(glContext instanceof WebGL2RenderingContext)) {
            logWarning(`${GraphicsCanvas.name}.1 : Failed to get WebGL context`);
            return;
        }

        logInfo(`${GraphicsCanvas.name}.1 : Setting WebGL context ${glContext}`);

        gl = glContext;
        dispatch(glAvailableSet(true));
    }, [canvasRef, dispatch])

    useEffect(() => {

        const canvas = canvasRef.current as HTMLCanvasElement;
        if (canvas == null) {
            logInfo(`${GraphicsCanvas.name}.2 : No canvas yet`);
            return;
        }

        if (gl == null) {
            logInfo(`${GraphicsCanvas.name}.2 : No gl yet`);
            return;
        }

        if (onTicksRef.current == null) {
            logInfo(`${GraphicsCanvas.name}.2 : No onTicksRef yet`);
            return;
        }

        const registerOnTick = (onTickToRegister: OnTick) => {
            logInfo(`${GraphicsCanvas.name}.3 : Registering onTick`);
            onTicksRef.current.push(onTickToRegister);
        };

        const deregisterOnTick = (onTickToDeregister: OnTick) => {
            logInfo(`${GraphicsCanvas.name}.3 : Deregistering onTick`);
            onTicksRef.current = onTicksRef.current.filter(onTick => onTick !== onTickToDeregister);
        }

        logInfo(`${GraphicsCanvas.name}.2 : Setting onTick handlers`);
        setOnTickHandlers(registerOnTick, deregisterOnTick);

        let animationFrameCallbackID: number;
        const onAnimationFrame = (timestampMS: number = 0) => {
            resizeCanvasIfClientRectHasChanged(canvas);
            onTicksRef.current.map(onTick => onTick(timestampMS));
            animationFrameCallbackID = window.requestAnimationFrame(onAnimationFrame);
        };

        logInfo(`${GraphicsCanvas.name}.2 : Requesting first animation frame`);
        onAnimationFrame();

        return () => {
            logInfo(`${GraphicsCanvas.name}.2 : Cancelling animation frame`);
            window.cancelAnimationFrame(animationFrameCallbackID)
            clearOnTickHandlers();
        };
    }, [canvasRef, onTicksRef]);


    return (
        <div>
            <canvas ref={canvasRef} className={styles.canvas} />
        </div>
    );
}