import { getAuth } from "firebase/auth";
import { firebaseApp, logFileActionResultAnalytic } from '../classes/code/Firebase';
import { ProjectDescription } from "./ProjectDescription";
import { serialise as serialiseProjectDescription, deserialise as deserialiseProjectDescription } from './ProjectDescriptionHelpers';
import { UploadMetadata, getStorage, ref, uploadBytes, getBlob, deleteObject, getMetadata } from "firebase/storage";
import { ProjectInfo } from "./ProjectInfo";
import { logError } from "./Logger";
import { collection, deleteDoc, doc, DocumentData, getDocs, getFirestore, limit, orderBy, query, QueryConstraint, QueryDocumentSnapshot, setDoc, startAfter, endBefore, where, limitToLast } from "firebase/firestore";

type Snapshot = QueryDocumentSnapshot<DocumentData, DocumentData>;
export type Cursor = { first: Snapshot, last: Snapshot };
export type PageAction = 'prev' | 'next';

const projectInfoFromSnapshot = (d: Snapshot): ProjectInfo | undefined => {
    const guid = d.get('guid');
    const commandCount = Number(d.get('commandCount'));
    const name = d.get('name');
    const uid = d.get('uid');
    const userName = d.get('userName');
    const timestamp = Number(d.get('time'));
    const visibility = d.get('visibility');

    if (guid == null || isNaN(commandCount) || name == null || uid == null || isNaN(timestamp)) { return undefined; }

    const info: ProjectInfo = { guid, commandCount, name, uid, userName, visibility, timestamp, location: 'cloud' };

    return info;
}

export const listPublicAsync = async (countPerPage: number, cursor?: Cursor, pageAction?: PageAction) => {

    // Try to read an additional result in whichever direction we're looking - success means there's
    // another page of results in that direction
    const extendedCount = countPerPage + 1;
    const constraints: QueryConstraint[] = [where('visibility', '==', 'public'), orderBy('time', 'desc')];
    if (cursor != null) {
        if (pageAction === 'prev') {
            constraints.push(endBefore(cursor.first));
            constraints.push(limitToLast(extendedCount));
        } else {
            constraints.push(startAfter(cursor.last));
            constraints.push(limit(extendedCount));
        }
    } else {
        constraints.push(limit(extendedCount));
    }

    // Fetch results
    const { projects: extendedProjects, snapshots: extendedSnapshots } = await listInnerAsync(constraints);

    // Cut off the extra result if we successfully got it
    const reachedEnd = extendedProjects.length < extendedCount;
    const startIndex = (pageAction === 'prev' && !reachedEnd) ? 1 : 0;
    const projects = extendedProjects.splice(startIndex, countPerPage);
    const snapshots = extendedSnapshots.splice(startIndex, countPerPage);

    // Save the first and last snapshots so we can use one of them as a starting point when later fetching the prev/next page
    const updatedCursor: Cursor = { first: snapshots[0], last: snapshots[snapshots.length - 1] }

    let moreBefore = false;
    let moreAfter = false;
    if (pageAction == null) {
        moreBefore = false; // This is when called for the first time - we're definitely on the first page
        moreAfter = !reachedEnd;
    } else if (pageAction === 'next') {
        moreBefore = true;
        moreAfter = !reachedEnd;
    } else {
        moreBefore = !reachedEnd;
        moreAfter = true;
    }

    return { projects, updatedCursor, moreBefore, moreAfter };
}

export const listAsync = async () => {

    const auth = getAuth(firebaseApp);
    await auth.authStateReady();
    const { currentUser } = auth;

    if (currentUser == null) { return; }

    const constraints: QueryConstraint[] = [where('uid', '==', auth.currentUser?.uid), orderBy('time', 'desc')];
    const { projects } = await listInnerAsync(constraints);
    return projects;
}

const listInnerAsync = async (constraints: QueryConstraint[]) => {

    //await new Promise(r => setTimeout(r, 1000));

    const projects: ProjectInfo[] = [];

    const firestore = getFirestore(firebaseApp);
    const projectsMetaRef = collection(firestore, 'projects-meta');
    const projectsQuery = query(projectsMetaRef, ...constraints);
    const projectsSnapshots = await getDocs(projectsQuery);

    const snapshots: QueryDocumentSnapshot[] = [];
    projectsSnapshots.forEach(d => {
        const info = projectInfoFromSnapshot(d);
        if (info != null) {
            snapshots.push(d);
            projects.push(info);
        }
    });

    return { projects, snapshots };
}

export const getAsync = async (guid: string) => {

    const auth = getAuth(firebaseApp);
    await auth.authStateReady();
    const storage = getStorage(firebaseApp);

    try {
        // Get file from Storage
        const fileRef = ref(storage, `projects/${guid}.46c`);
        const fileRes = await getBlob(fileRef);
        const file = await fileRes.text();

        const metadata = (await getMetadata(fileRef)).customMetadata;
        const uid = (metadata != null) ? metadata['uid'] : '0';

        if (file == null) { throw new Error(`Can't load cloud project with guid ${guid}`); }

        const desc = deserialiseProjectDescription(file);
        if (desc == null) { throw new Error(`Can't load cloud project with guid ${guid}`); }

        desc.uid = uid;

        logFileActionResultAnalytic('open_cloud_file', 'success', guid, desc?.name)

        return desc;

    } catch (error) {
        logFileActionResultAnalytic('open_cloud_file', 'failure', guid)
        logError((error instanceof Error) ? `${error.name} : ${error.message}` : String(error));

        throw error;
    }
}


export const saveAsync = async (p: ProjectDescription, time: number) => {

    const auth = getAuth(firebaseApp);
    await auth.authStateReady();
    const { currentUser } = auth;
    const storage = getStorage(firebaseApp);
    const firestore = getFirestore(firebaseApp);

    try {
        if (currentUser == null) { throw new Error(`Can't save to cloud while not logged in`); }

        const metadata = {
            "name": p.name.replaceAll('|', '-'),
            "guid": p.guid,
            "commandCount": p.commands.length.toString(),
            "time": time.toString(),
            'visibility': p.visibility,
            ...(p.uid != null ? { 'uid': p.uid } : undefined),
            ...(p.userName != null ? { 'userName': p.userName } : undefined)
        };

        // Save file to Storage
        const fileRef = ref(storage, `projects/${p.guid}.46c`);
        const fileBytes = new Blob([serialiseProjectDescription(p, time)]);
        const fileMetadata: UploadMetadata = { customMetadata: metadata };
        await uploadBytes(fileRef, fileBytes, fileMetadata);

        // Save metadata to Firestore
        await setDoc(doc(firestore, 'projects-meta', p.guid), metadata);

        logFileActionResultAnalytic('save_cloud_file', 'success', p.guid, p.name)

    } catch (error) {
        logFileActionResultAnalytic('save_cloud_file', 'failure', p.guid, p.name)
        logError((error instanceof Error) ? `${error.name} : ${error.message}` : String(error));

        throw error;
    }
}

export const deleteAsync = async (guid: string) => {

    const auth = getAuth(firebaseApp);
    await auth.authStateReady();
    const { currentUser } = auth;
    const storage = getStorage(firebaseApp);
    const firestore = getFirestore(firebaseApp);

    try {
        if (currentUser == null) { throw new Error(`Can't delete from cloud while not logged in`); }

        // Delete file from Storage
        const fileRef = ref(storage, `projects/${guid}.46c`);
        await deleteObject(fileRef);

        // Delete metadata from Firestore
        await deleteDoc(doc(firestore, 'projects-meta', guid));

        logFileActionResultAnalytic('delete_cloud_file', 'success', guid)

    } catch (error) {
        logFileActionResultAnalytic('delete_cloud_file', 'failure', guid)
        logError((error instanceof Error) ? `${error.name} : ${error.message}` : String(error));

        throw error;
    }
}