import React from 'react';
import { selectActiveTool, ToolTypes } from '../store/toolSlice';

import styles from './TabsPanel.module.css';
import { logMajorComponentRender } from '../classes/Logger';
import { Stack } from './Stack';
import { cx } from '../classes/Utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import { setRoute, setRouteWithAddress } from '../App';
import { selectActiveMemoryView, selectCurrentLineStartAddress } from '../store/codeSlice';
import { selectIsProjectReadOnly } from '../store/extraSelectors';


export const TabsPanel: React.FunctionComponent = (props) => {

    logMajorComponentRender(TabsPanel.name);

    const isReadOnly = useAppSelector(selectIsProjectReadOnly);

    return (
        <Stack layout='horizontal' alignItems='center' justifyContent='start'>
            {!isReadOnly && <Tab name='Graphics' tool='graphics' />}
            <Tab name='Code' tool='code' />
            <Tab name='Library' tool='library' />
        </Stack>
    );
}

const Tab: React.FC<{ name: string, tool: ToolTypes }> = (props) => {

    const activeTool = useAppSelector(selectActiveTool);
    const memoryView = useAppSelector(selectActiveMemoryView);
    const { address, type } = useAppSelector(selectCurrentLineStartAddress);
    const { guid } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const { name, tool } = props;

    logMajorComponentRender(TabsPanel.name, `with tool ${tool}`);

    const handleNavigate = () => {
        if (activeTool === 'code') {
            setRouteWithAddress(navigate, location, { guid, tool: activeTool, target: { address, type } });
        }
        if (tool === 'code') {
            setRouteWithAddress(navigate, location, { guid, tool, target: { address, type } });
        } else {
            setRoute(navigate, location, { guid, tool, memoryView });
        }
    }

    const selectedLink = <div className={cx(styles.tab, styles.selected)}>{name}</div>;
    const unselectedLink = <button onClick={handleNavigate} className={cx(styles.unselected, styles.tab)}>{name}</button>

    return (activeTool === tool) ? selectedLink : unselectedLink;

}