import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { logReducer } from "../classes/Logger";

type Page = 'home' | 'project';
export type UIState = {
    currentPage: Page;
    recentlyClickedAddress: number;
    memoryMapHeight: number;
    currentUid?: string;
    currentUserName?: string;
    currentUserIsAdmin: boolean;
    focusCount: number;
}

const initialState: UIState = {
    currentPage: 'home',
    recentlyClickedAddress: -1,
    memoryMapHeight: -1,
    focusCount: 0,
    currentUserIsAdmin: false
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        currentPageSet(state, action: PayloadAction<Page>) {
            logReducer('uiSlice', 'currentPageSet');
            state.currentPage = action.payload;
        },

        recentlyClickedAddressAdded(state, action: PayloadAction<number>) {
            logReducer('uiSlice', 'recentlyClickedAddressAdded');
            state.recentlyClickedAddress = action.payload;
        },

        memoryMapHeightSet(state, action: PayloadAction<number>) {
            logReducer('uiSlice', 'memoryMapHeightSet');
            state.memoryMapHeight = action.payload;
        },

        userSet(state, action: PayloadAction<{ uid?: string, userName?: string, isAdmin: boolean }>) {
            logReducer('uiSlice', 'userSet');
            const { uid, userName, isAdmin } = action.payload;
            state.currentUid = uid;
            state.currentUserName = userName;
            state.currentUserIsAdmin = isAdmin;
        },

        focusCountIncremented(state) {
            logReducer('uiSlice', 'modalCountIncremented');
            state.focusCount += 1;
        },

        focusCountDecremented(state) {
            logReducer('uiSlice', 'modalCountDecremented');
            state.focusCount -= 1;
        }
    }
});

export const { currentPageSet, recentlyClickedAddressAdded, memoryMapHeightSet, userSet, focusCountIncremented, focusCountDecremented } = uiSlice.actions;
export default uiSlice.reducer;

export const selectRecentlyClickedAddresses = (state: RootState) => state.ui.recentlyClickedAddress;

export const selectCurrentPage = (state: RootState) => state.ui.currentPage;

export const selectPageHasFocus = createSelector(
    (state: RootState) => state.ui.focusCount,
    (focusCount) => {
        return focusCount === 0;
    }
);

export const selectUser = createSelector(
    (state: RootState) => state.ui.currentUid,
    (state: RootState) => state.ui.currentUserName,
    (state: RootState) => state.ui.currentUserIsAdmin,
    (uid, userName, isAdmin) => {
        return { isSignedIn: (uid != null), uid, userName, isAdmin };
    }
);