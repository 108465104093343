import React, { MouseEvent, useState } from 'react';
import styles from './ColourSwatch.module.css';
import { cx, Utils } from '../../classes/Utils';
import { logMinorComponentRender } from '../../classes/Logger';
import { ColourProperties, colourSet, unpackColourProperty } from '../../store/graphicsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Modal } from './Modal';
import { RootState } from '../../store';
import { Stack } from '../Stack';


const colourCssName = (paletteIndex: number) => `col${Utils.to2DigitHexString(paletteIndex)}`;


export const ColourSwatch: React.FC<{ property: ColourProperties, enabled: boolean }> = (props) => {

    logMinorComponentRender(ColourSwatch.name);

    const dispatch = useAppDispatch();
    const settings = useAppSelector((state: RootState) => state.graphics);

    const [modalIsOpen, SetModalIsOpen] = useState<boolean>(false);

    const handleOpenModal = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        SetModalIsOpen(true);
    }

    const handleCloseModal = () => {
        SetModalIsOpen(false);
    }

    const handleChange = (e: MouseEvent, colourIndex: number) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(colourSet({ colourIndex, property }));
        SetModalIsOpen(false);
    }

    const { property, enabled } = props;
    const { colourIndex, label } = unpackColourProperty(property, settings);

    const bigSwatch = (c: number) => <button key={c} className={cx(styles.bigSwatch, styles[colourCssName(c)])} onClick={(e) => handleChange(e, c)} />;
    const bigSwatchesA = Array(8).fill(0).map((_, i) => bigSwatch(i));
    const bigSwatchesB = Array(8).fill(0).map((_, i) => bigSwatch(i + 8));

    return (
        <div className={styles.swatches}>

            <button className={modalIsOpen ? styles.beingPicked : undefined} disabled={!enabled} onClick={handleOpenModal} >
                <div className={cx(styles.swatch, enabled ? styles[colourCssName(colourIndex)] : undefined)} />{label}
            </button>

            {modalIsOpen &&
                <Modal onClose={handleCloseModal} align='bottom-right' debugName={ColourProperties[property]}>

                    <Stack layout='vertical' cssClassName={styles.picker}>
                        <Stack layout='horizontal'>
                            {bigSwatchesA}
                        </Stack>
                        <Stack layout='horizontal'>
                            {bigSwatchesB}
                        </Stack>
                    </Stack>

                </Modal>
            }

        </div>
    );
}