import * as React from "react";
import { logMajorComponentRender } from "../../classes/Logger";

export const CentralHighlight: React.FunctionComponent<{ height: number }> = (props) => {

    logMajorComponentRender(CentralHighlight.name, `with height ${props.height}px`);

    const style: React.CSSProperties = {
        position: 'absolute',
        width: '100%',
        height: props.height,
        transition: 'height 0.2s',
        margin: '0',
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: '#00000020'
    }

    return (
        <div style={style} />
    );
}