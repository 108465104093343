export class MeshUtils {

    static CreateQuadMeshData() {

        return {

            positions: new Float32Array([
                0, 0,
                1, 0,
                1, 1,
                0, 1
            ]),
            uvs: new Float32Array([
                0, 0,
                1, 0,
                1, 1,
                0, 1
            ]),
            indices: new Uint16Array([
                0, 1, 3,
                1, 2, 3
            ])
        }

    }
}