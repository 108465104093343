
export class Colour {

    name: string;

    index: number;

    r: number;
    g: number;
    b: number;

    constructor(name: string, index: number, r: number, g: number, b: number) {
        this.name = name;
        this.index = index;
        this.r = r / 255.0;
        this.g = g / 255.0;
        this.b = b / 255.0;
    }
}


export class Palette {
    static Black = new Colour('black', 0x00, 0, 0, 0);
    static White = new Colour('white', 0x01, 255, 255, 255);
    static Red = new Colour('red', 0x02, 136, 0, 0);
    static Cyan = new Colour('cyan', 0x03, 170, 255, 238);
    static Magenta = new Colour('magenta', 0x04, 204, 68, 204);
    static Green = new Colour('green', 0x05, 0, 204, 85);
    static Blue = new Colour('blue', 0x06, 0, 0, 170);
    static Yellow = new Colour('yellow', 0x07, 238, 238, 119);
    static Orange = new Colour('orange', 0x08, 221, 136, 85);
    static Brown = new Colour('brown', 0x09, 102, 68, 0);
    static LightRed = new Colour('lightred', 0x0a, 255, 119, 119);
    static DarkGrey = new Colour('darkgrey', 0x0b, 51, 51, 51);
    static Grey = new Colour('grey', 0x0c, 119, 119, 119);
    static LightGreen = new Colour('lightgreen', 0x0d, 170, 255, 102);
    static LightBlue = new Colour('lightblue', 0x0e, 0, 136, 255);
    static LightGrey = new Colour('lightgrey', 0x0f, 187, 187, 187);
    static None = new Colour('', 0x10, 0, 0, 0);

    static index = [
        Palette.Black,
        Palette.White,
        Palette.Red,
        Palette.Cyan,
        Palette.Magenta,
        Palette.Green,
        Palette.Blue,
        Palette.Yellow,
        Palette.Orange,
        Palette.Brown,
        Palette.LightRed,
        Palette.DarkGrey,
        Palette.Grey,
        Palette.LightGreen,
        Palette.LightBlue,
        Palette.LightGrey,
        Palette.Black
    ];

    static GetOpposite(colour: Colour) {
        let opposites = [
            0x1, 0x0, 0x1, 0x0,
            0x1, 0x1, 0x1, 0x0,
            0x1, 0x1, 0x0, 0x1,
            0x1, 0x0, 0x0, 0x0
        ];
        return this.index[opposites[colour.index]];
    }
};



