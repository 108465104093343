import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { logReducer } from "../classes/Logger";
import { MemoryAddress, memoryAddressEquals } from "../classes/code/MemoryLocation";

export type MemoryViewType = 'ram' | 'rom';

export type CodeState = {
    index: number;
    currentLineStartAddress: MemoryAddress;
    currentLineEndAddress: MemoryAddress;
    activeMemoryView: MemoryViewType;
}

const initialState: CodeState = {
    index: 0,
    currentLineStartAddress: { address: 0x0000, type: 'ram' },
    currentLineEndAddress: { address: 0x0000, type: 'ram' },
    activeMemoryView: 'ram'
}

const codeSlice = createSlice({
    name: 'code',
    initialState,
    reducers: {
        activeMemoryViewSet(state, action: PayloadAction<MemoryViewType>) {
            logReducer('toolSlice', 'activeMemoryViewSet');
            state.activeMemoryView = action.payload;
        },

        currentLineAddressBoundsSet(state, action: PayloadAction<{ startAddress: MemoryAddress, endAddress: MemoryAddress }>) {
            //logReducer('codeSlice', 'currentLineAddressBoundsSet');
            if (memoryAddressEquals(state.currentLineStartAddress, action.payload.startAddress) && memoryAddressEquals(state.currentLineEndAddress, action.payload.endAddress)) { return; }
            state.currentLineStartAddress = action.payload.startAddress;
            state.currentLineEndAddress = action.payload.endAddress;
        },
    }
})

export const { activeMemoryViewSet, currentLineAddressBoundsSet, } = codeSlice.actions;
export default codeSlice.reducer;

export const selectActiveMemoryView = (state: RootState) => state.code.activeMemoryView;
export const selectCurrentLineStartAddress = (state: RootState) => state.code.currentLineStartAddress;

export const updateRouteToCurrentLine = ()=>{
    const saveCurrentAddress = true;
    document.dispatchEvent(new CustomEvent<{ sources?: MemoryAddress[], target?: MemoryAddress, saveCurrentAddress?: boolean }>('navigate-to-address', { detail: { saveCurrentAddress } }))
}

export const navigate = (target: MemoryAddress, sources?: MemoryAddress[]) => {

    const saveCurrentAddress = true;
    document.dispatchEvent(new CustomEvent<{ sources?: MemoryAddress[], target: MemoryAddress, saveCurrentAddress?: boolean }>('navigate-to-address', { detail: { sources, target, saveCurrentAddress } }))
};