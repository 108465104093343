import * as React from "react";

import styles from './References.module.css';
import { cx, Utils } from "../../classes/Utils";
import { navigate, selectActiveMemoryView } from "../../store/codeSlice";
import { useState, useEffect, useRef } from "react";
import { logMinorComponentRender } from "../../classes/Logger";
import { MemoryAddress } from "../../classes/code/MemoryLocation";
import { useAppSelector } from "../../store/hooks";

export const References: React.FunctionComponent<{ maxRefs: number, sourceAddress: number, refs: { prefix: string, addresses: MemoryAddress[] }[] }> = (props) => {

    logMinorComponentRender(References.name);

    const { maxRefs, sourceAddress, refs } = props;

    const allRefs = refs.map((r, i) => <Reference key={i} prefix={r.prefix} refs={r.addresses} maxRefs={maxRefs} sourceAddress={sourceAddress} />);

    return (
        <span className={styles.refs}>{allRefs}</span>
    )
}


const Reference: React.FC<{ prefix: string, refs: MemoryAddress[], maxRefs: number, sourceAddress: number }> = (props) => {

    logMinorComponentRender(Reference.name);

    const [expanded, SetExpanded] = useState<boolean>(false);
    const r = useRef<HTMLSpanElement>(null);
    const activeMemoryView = useAppSelector(selectActiveMemoryView);

    const { prefix, refs, maxRefs, sourceAddress } = props;

    useEffect(() => {

        const handleOutsideClick = (e: MouseEvent) => {

            if (r.current == null) return;
            if (r.current.contains(e.target as Element)) return;

            SetExpanded(false);
        }

        if (r.current == null) return;

        if (expanded) {
            document.addEventListener('click', handleOutsideClick, false);
        }
        else {
            document.removeEventListener('click', handleOutsideClick, false);
        }

        return () => document.removeEventListener('click', handleOutsideClick, false);
    }, [r, expanded, SetExpanded]);

    if (refs == null || refs.length === 0) { return null; }

    const handleArgumentClick = (e: React.MouseEvent<HTMLElement>, sourceAddress: number, argumentAddress: MemoryAddress) => {

        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        SetExpanded(false);

        const source: MemoryAddress = { address: sourceAddress, type: activeMemoryView };
        navigate(argumentAddress, [source]);
    }

    const prefixJsx = <span className={styles.text}>{prefix}:</span>;

    const refsNodes = refs
        .sort((a, b) => a.address - b.address)
        .map(r => <span key={r.address} className={cx(styles.ref, r.type === 'rom' ? styles.rom : undefined)} onClick={e => handleArgumentClick(e, sourceAddress, r)}>${Utils.to4DigitHexString(r.address)}</span>)
        ;

    if (refs.length <= maxRefs) {
        return <span className={styles.refGroup}>{prefixJsx}{refsNodes}</span>;
    }

    const expandedRefs = (
        <span className={styles.refsPopup}>
            <span style={{ display: 'inline-block' }}>◀</span>
            <ul style={{ display: 'inline-block' }}>
                {refsNodes.map((r, i) => <li key={i}>{r}</li>)}
            </ul>
        </span>);

    return (
        <span ref={r} className={styles.refGroup}>
            {prefixJsx}
            <span className={styles.ref} onClick={() => SetExpanded(!expanded)}>
                …{refs.length}
            </span>
            <span className={styles.popupHolder}>
                {expanded && expandedRefs}
            </span>
        </span>
    );

}