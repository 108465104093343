import { useContext } from "react";
import { logCommandAnalytic } from "../../classes/code/Firebase";
import { newEntryPointCommand } from "../../classes/commands/EntryPointHelpers";
import { useOnKeyboardShortcut } from "../../classes/effects/useOnKeyboardShortcut";
import { Utils } from "../../classes/Utils";
import { selectActiveMemoryView, updateRouteToCurrentLine } from "../../store/codeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { commandAdded, commandRemoved, selectGuidAndName, selectIndexedEntryPointCommands } from "../../store/projectSlice";
import { selectSelection } from "../../store/toolSlice";
import { selectPageHasFocus } from "../../store/uiSlice";
import { EnumSetting } from "./EnumSetting";
import { commandIcon } from "./MarkupIcons";
import { SettingsRow } from "./SettingsRow";
import { RibbonMenuContext } from "../RibbonMenu";

export const EntryPointMarkupSettings: React.FC = () => {

    const { selectionAddress, selectionCount, selectionAddressROM } = useAppSelector(selectSelection);
    const selectionEndAddress = selectionAddress + selectionCount - 1;
    const activeMemoryView = useAppSelector(selectActiveMemoryView);
    const isRAM = activeMemoryView === 'ram';

    const pageHasFocus = useAppSelector(selectPageHasFocus);
    const { isHiddenCopy } = useContext(RibbonMenuContext);

    const dispatch = useAppDispatch();
    const { guid, name } = useAppSelector(selectGuidAndName);

    const entrypointCommands = useAppSelector(selectIndexedEntryPointCommands).filter(
        c => c.command.address >= selectionAddress && c.command.address <= selectionEndAddress
    );

    const addressValue = `${selectionAddress}`;
    const addressLabel = `$${Utils.to4DigitHexString(isRAM ? selectionAddress : selectionAddressROM)}`;
    const noneLabel = 'none';
    const noneValue = 'none';
    const mixedLabel = 'mixed';
    const mixedValue = 'mixed';

    let selectedValue = noneValue;
    if (!isRAM || entrypointCommands.length === 0) {
        selectedValue = noneValue;
    } else if (selectionCount > 1) {
        selectedValue = mixedValue;
    } else {
        selectedValue = addressValue;
    }

    const extraPropertyLabel = (selectionCount < 2) ? addressLabel : mixedLabel;

    const addressOption = isRAM && (selectedValue !== mixedValue) ? { [addressLabel]: addressValue } : undefined;
    const mixedOption = isRAM && (selectedValue === mixedValue) ? { [mixedLabel]: mixedValue } : undefined;

    const handleEntryPointChanged = (v: string) => {
        if (v === noneValue) {
            entrypointCommands.sort((a, b) => a.index - b.index).reverse().forEach(c => {
                logCommandAnalytic('delete_command', 'entry-point', c.command.address, guid, name);
                dispatch(commandRemoved(c.index))
            });
        } else {
            const address = Number(v);
            logCommandAnalytic('add_command', 'entry-point', address, guid, name);
            dispatch(commandAdded(newEntryPointCommand(address)));
        }

        updateRouteToCurrentLine();
    }

    useOnKeyboardShortcut('e', pageHasFocus && !isHiddenCopy, () => {
        const canAdd = isRAM && (selectionCount === 1) && (entrypointCommands.length === 0);
        if (!canAdd) { return; }
        handleEntryPointChanged(String(selectionAddress));
    });

    useOnKeyboardShortcut('E', pageHasFocus && !isHiddenCopy, () => {
        if (entrypointCommands.length === 0) { return; }
        handleEntryPointChanged(noneValue);
    })

    return (
        <SettingsRow label={<>{commandIcon('entry-point')}Entry Point</>}>
            <EnumSetting
                label="entrypoint"
                selectedOption={selectedValue}
                options={{ [noneLabel]: noneValue, ...mixedOption, ...addressOption }}
                order={[noneLabel, extraPropertyLabel]}
                onChange={handleEntryPointChanged}
            />
        </SettingsRow>
    );
}