export type Channel = 'Error' | 'Warning' | 'Info' | 'MajorComponent' | 'MinorComponent' | 'Reducer' | 'Selector';

const enabledChannels: Channel[] = ['Error'];
//const enabledChannels: Channel[] = ['Error', 'Warning', 'Info'];
//const enabledChannels: Channel[] = ['Error', 'Warning', 'Info', 'MajorComponent', 'Reducer', 'Selector'];
//const enabledChannels: Channel[] = ['Error', 'Warning', 'Info', 'MajorComponent', 'MinorComponent', 'Reducer', 'Selector'];

const log = (channel: Channel, message: string) => {
    if (enabledChannels.indexOf(channel) < 0) { return; }

    const str = `[${Date.now()}] ${channel} : ${message}`;
    if (channel === 'Error') {
        console.error(str);
    } else if (channel === 'Warning') {
        console.warn(str);
    } else {
        console.info(str);
    }
}

export const logMajorComponentRender = (componentName: string, message?: string) => {
    const extra = message == null ? '' : ` : ${message}`;
    log('MajorComponent', `${componentName}${extra}`);
}

export const logMinorComponentRender = (componentName: string, message?: string) => {
    const extra = message == null ? '' : ` : ${message}`;
    log('MinorComponent', `${componentName}${extra}`);
}

export const logReducer = (slice: string, reducer: string, message?: string) => {
    const extra = message == null ? '' : ` : ${message}`;
    log('Reducer', `${slice}.${reducer}${extra}`);
}

export const logSelector = (slice: string, selector: string, message?: string) => {
    const extra = message == null ? '' : ` : ${message}`;
    log('Selector', `${slice}.${selector}${extra}`);
}


export const logError = (message: string) => log('Error', message);
export const logInfo = (message: string) => log('Info', message);
export const logWarning = (message: string) => log('Warning', message);
