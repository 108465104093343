import React from 'react';
import { SettingsRow } from './SettingsRow';
import { ColourProperties, characterMappedCharacterSetModeSet, characterMappedCharacterSetAddressSet, selectCharacterSetInfo } from '../../store/graphicsSlice';
import { EnumSetting } from './EnumSetting';
import { HexNumberInput } from './HexNumberInput';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';
import { Stack } from '../Stack';
import { ColourSwatch } from './ColourSwatch';


export const CharacterSetSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(CharacterSetSettingsSection.name);

    const dispatch = useAppDispatch();
    const { viewLayout, address, mode } = useAppSelector(selectCharacterSetInfo);

    const enabled = (viewLayout === 'CharacterMapped');

    const swatches: JSX.Element[] = [];

    if (mode === 'HiRes') {
        swatches.push(<ColourSwatch key='0' property={ColourProperties.CharacterMappedCharacterSetHiresBackground00} enabled={enabled} />)
        swatches.push(<ColourSwatch key='1' property={ColourProperties.CharacterMappedCharacterSetHiresForeground01} enabled={enabled} />);
    }

    if (mode === 'MultiColour') {
        swatches.push(<ColourSwatch key='00' property={ColourProperties.CharacterMappedCharacterSetMultiColourBackground00} enabled={enabled} />)
        swatches.push(<ColourSwatch key='01' property={ColourProperties.CharacterMappedCharacterSetMultiColourForeground01} enabled={enabled} />);
        swatches.push(<ColourSwatch key='10' property={ColourProperties.CharacterMappedCharacterSetMultiColourForeground10} enabled={enabled} />);
        swatches.push(<ColourSwatch key='11' property={ColourProperties.CharacterMappedCharacterSetMultiColourForeground11} enabled={enabled} />);
    }

    return (
        <Stack layout='vertical'>

            <SettingsRow label='Charset'>
                <HexNumberInput
                    label={"address"}
                    enabled={viewLayout === 'CharacterMapped'}
                    number={address}
                    onChange={n => dispatch(characterMappedCharacterSetAddressSet(n))}
                    forceFourDigits={true}
                    notifyFocus={true}
                />
                <EnumSetting
                    label="mode"
                    selectedOption={mode}
                    options={viewLayout === 'CharacterMapped' ? { 'hires': 'HiRes', 'multicol': 'MultiColour' } : {}}
                    order={['hires', 'multicol']}
                    onChange={v => dispatch(characterMappedCharacterSetModeSet(v))}
                />
            </SettingsRow>

            <SettingsRow label='Colours'>
                {swatches}
            </SettingsRow>

        </Stack>
    );
}