import { useContext } from "react";
import { logCommandAnalytic } from "../../classes/code/Firebase";
import { OverrideType } from "../../classes/commands/OverrideCommand";
import { newOverrideCommand } from "../../classes/commands/OverrideCommandHelpers";
import { useOnKeyboardShortcut } from "../../classes/effects/useOnKeyboardShortcut";
import { selectActiveMemoryView, updateRouteToCurrentLine } from "../../store/codeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { commandAdded, commandRemoved, commandUpdated, selectGuidAndName, selectIndexedOverrideCommands } from "../../store/projectSlice";
import { selectSelection } from "../../store/toolSlice";
import { selectPageHasFocus } from "../../store/uiSlice";
import { EnumSetting } from "./EnumSetting";
import { commandIcon } from "./MarkupIcons";
import { SettingsRow } from "./SettingsRow";
import { RibbonMenuContext } from "../RibbonMenu";

export const OverrideMarkupSettings: React.FC = () => {

    const { guid, name } = useAppSelector(selectGuidAndName);

    const { selectionAddress, selectionCount } = useAppSelector(selectSelection);
    const selectionEndAddress = selectionAddress + selectionCount - 1;

    const pageHasFocus = useAppSelector(selectPageHasFocus);
    const { isHiddenCopy } = useContext(RibbonMenuContext);

    const activeMemoryView = useAppSelector(selectActiveMemoryView);
    const isRAM = activeMemoryView === 'ram';

    const dispatch = useAppDispatch();

    const overrideCommands = useAppSelector(selectIndexedOverrideCommands)
        .filter(c => c.command.address >= selectionAddress && c.command.address <= selectionEndAddress);
    const { index: overrideCommandIndex, command: overrideCommand } = overrideCommands.length === 1 ? overrideCommands[0] : { index: -1, command: undefined };

    const handleOverrideChanged = (v: OverrideOptions) => {
        if (overrideCommand == null && overrideCommands.length === 0) {

            logCommandAnalytic('add_command', 'override', selectionAddress, guid, name);
            const newCommand = newOverrideCommand(selectionAddress, v as OverrideType);
            dispatch(commandAdded(newCommand));
        } else if (v === 'none') {
            overrideCommands.sort((a, b) => a.index - b.index).reverse().forEach(c => {
                logCommandAnalytic('delete_command', 'override', c.command.address, guid, name);
                dispatch(commandRemoved(c.index))
            });
        } else if (overrideCommand != null) {
            logCommandAnalytic('edit_command', 'override', overrideCommand.address, guid, name);
            const newCommand = newOverrideCommand(overrideCommand.address, v as OverrideType);
            dispatch(commandUpdated({ commandIndexToUpdate: overrideCommandIndex, command: newCommand }));
        }

        updateRouteToCurrentLine();
    }

    type OverrideOptions = OverrideType | 'none' | 'mixed';
    let current: OverrideOptions = 'none';
    const uniqueTypes = [...new Set(overrideCommands.map(c => c.command.override))];
    if (!isRAM || uniqueTypes.length === 0) {
        current = 'none';
    } else if (uniqueTypes.length === 1 && selectionCount === overrideCommands.length) {
        current = uniqueTypes[0];
    } else {
        current = 'mixed';
    }

    const showMixedOnly = current === 'mixed';

    const simple = selectionCount === 1;
    const romProperty = isRAM && (simple || (current === 'ArgIsROM')) ? 'ArgIsROM' : '';
    // const skipProperty = isRAM && (simple || (current === 'Skip')) ? 'Skip' : '';
    // const stopProperty = isRAM && (simple || (current === 'Stop')) ? 'Stop' : '';
    const mixedProperty = isRAM && showMixedOnly ? 'mixed' : '';

    const options = showMixedOnly ? ['mixed'] : ['ArgIsROM'/*, 'Skip', 'Stop'*/];

    useOnKeyboardShortcut('o', pageHasFocus && !isHiddenCopy, () => {
        const canAdd = isRAM && (selectionCount === 1) && (overrideCommands.length === 0);
        if (!canAdd) { return; }
        handleOverrideChanged('ArgIsROM');
    });

    useOnKeyboardShortcut('O', pageHasFocus && !isHiddenCopy, () => {
        if (overrideCommands.length === 0) { return; }
        handleOverrideChanged('none');
    })

    return (
        <SettingsRow label={<>{commandIcon('override')}Override</>}>
            <EnumSetting
                label="override"
                selectedOption={current}
                options={{ 'none': 'none', [romProperty]: 'ArgIsROM', /*[skipProperty]: 'Skip', [stopProperty]: 'Stop',*/[mixedProperty]: 'mixed' }}
                order={['none', ...options]}
                onChange={handleOverrideChanged}
            />
        </SettingsRow>
    );
}