import { Utils } from '../Utils';
import { MemoryLocation } from '../code/MemoryLocation';
import { Range } from "../code/Range";
import { FormattingCommand, FormattingType } from './FormattingCommand';

const hex = Utils.to4DigitHexString;

export const newFormattingCommand = (address: number, format: FormattingType): FormattingCommand => {
    return {
        type: 'formatting',
        address,
        format
    }
}

export const markUpMemory = (command: FormattingCommand, memory: MemoryLocation[], ranges: Range[]) => {
    memory[command.address].Formatting = (command.format === 'line-break') ? 'ManualLineBreakAfter' :
        (command.format === 'gap' ? 'ManualGapAfter' : memory[command.address].Formatting);
}

export const equals = (command: FormattingCommand, otherCommand: FormattingCommand) => {

    let res = true;
    res &&= (command.address === otherCommand.address);
    res &&= (command.format === otherCommand.format);

    return res;
}

export const version = "1";

export const serialise = (command: FormattingCommand): string => {
    const formatShort = (command.format === 'line-break') ? 'l' : 'g';
    return `ft|${hex(command.address)}|${formatShort}`;
}

const deserialise_v01 = (components: string[]): FormattingCommand | undefined => {
    if (components.length !== 2) { return; }
    const address = Number(`0x${components[0]}`);
    const format: FormattingType | undefined =
        components[1] === 'l' ? 'line-break' : (
            components[1] === 'g' ? 'gap' :
                undefined
        );

    if (isNaN(address) || format == null) { return; }

    return newFormattingCommand(address, format);
}

export const deserialisers: { [version: string]: (c: string[]) => FormattingCommand | undefined } = {
    '1': deserialise_v01
};

