import styles from './ArticlePage.module.css';
import { GraphicsViewport } from "./graphics/GraphicsViewport";
import { ViewportLocation } from "../store/rendererSlice";
import { Outlet } from "react-router-dom";

export const ArticlePageRoot: React.FC = () =>
    <GraphicsViewport location={ViewportLocation.Main} ignoreScrollbars={{ h: true, v: true }} show={{ marked: false, selected: false, grid: true }} render={r =>
        <div className={styles.pageContainer} ref={r}>
            <Outlet />
        </div>
    } />


