import { logWarning } from "../Logger";
import { UniformInfo } from "./primitives/ShaderProgramTypes";

export class GLUtils {

    static createShader(gl: WebGL2RenderingContext, type: number, source: string, defines?: string[]) {

        if (defines) {
            let definesString = defines.map(x => { return "#define " + x }).join("\n") + "\n";
            source = definesString + source;
        }

        const shader = gl.createShader(type);
        if (shader == null) { return undefined; }

        gl.shaderSource(shader, source);
        gl.compileShader(shader);
        const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
        if (success) {
            return shader;
        }
        logWarning("++++++++++++++++++++");
        logWarning(source.split("\n").map((l, i) => `${i + 1}:${l}`).join("\n"));
        logWarning(gl.getShaderInfoLog(shader) ?? '');
        logWarning("++++++++++++++++++++");
        gl.deleteShader(shader);
        return undefined;
    }


    static createProgram(gl: WebGL2RenderingContext, vertexShader: WebGLShader, fragmentShader: WebGLShader) {

        // Compile and link program
        const program = gl.createProgram();
        if (program == null) { return undefined; }

        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        gl.linkProgram(program);

        // Die if it didn't work
        let success = gl.getProgramParameter(program, gl.LINK_STATUS);
        if (success) {
            return program;
        }

        logWarning(gl.getProgramInfoLog(program) ?? '');
        gl.deleteProgram(program);
        return undefined;
    }


    static findUniforms(gl: WebGL2RenderingContext, program: WebGLProgram) {

        let uniforms = new Map<string, UniformInfo>();

        let uniformsCount = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);
        for (let i = 0; i < uniformsCount; i++) {

            let uniform = gl.getActiveUniform(program, i);
            if (uniform == null) { continue; }

            let location = gl.getUniformLocation(program, uniform.name);
            if (location == null) { continue; }

            uniforms.set(uniform.name, new UniformInfo(location, uniform.type));
        }

        return uniforms;
    }


    static findAttributes(gl: WebGL2RenderingContext, program: WebGLProgram) {

        const attributes = new Map<string, number>();

        const attribsCount = gl.getProgramParameter(program, gl.ACTIVE_ATTRIBUTES);
        for (let i = 0; i < attribsCount; i++) {

            const attrib = gl.getActiveAttrib(program, i);
            if (attrib == null) { continue; }

            const location = gl.getAttribLocation(program, attrib.name);

            attributes.set(attrib.name, location);
        }

        return attributes;
    }


    static clearToColour(gl: WebGL2RenderingContext, r: number, g: number, b: number, a: number, debug: boolean = false) {

        gl.clearColor(r, g, b, a);
        gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
        gl.clear(gl.COLOR_BUFFER_BIT);

        // Debug - show a border within the drawing buffer to see where it fits with
        // surrounding elements
        if (debug) {
            gl.enable(gl.SCISSOR_TEST);
            gl.scissor(30, 30, gl.drawingBufferWidth - 60, gl.drawingBufferHeight - 60);
            gl.clearColor(1, 0, 1, 1);
            gl.clear(gl.COLOR_BUFFER_BIT);
            gl.disable(gl.SCISSOR_TEST);
        }
    }


    static buildArrayBuffer(gl: WebGL2RenderingContext, data: ArrayBufferView | ArrayBuffer, usage: number, target: number = gl.ARRAY_BUFFER) {

        let buffer = gl.createBuffer();
        gl.bindBuffer(target, buffer);
        gl.bufferData(target, data as ArrayBuffer, usage);
        gl.bindBuffer(target, null);

        return buffer ?? undefined;
    }

    static UpdateBuffer(gl: WebGL2RenderingContext, data: ArrayBuffer, buffer: WebGLBuffer, target: number = gl.ARRAY_BUFFER) {
        gl.bindBuffer(target, buffer);
        gl.bufferData(target, data, gl.DYNAMIC_DRAW);
        gl.bindBuffer(target, null);
    }


    static buildElementArrayBuffer(gl: WebGL2RenderingContext, data: ArrayBufferView | ArrayBuffer, usage: number) {

        return this.buildArrayBuffer(gl, data, usage, gl.ELEMENT_ARRAY_BUFFER);
    }
}