import React, { CSSProperties, PropsWithChildren } from 'react';
import styles from './Stack.module.css';
import { cx } from '../classes/Utils';
import { logMajorComponentRender } from '../classes/Logger';


export const Stack: React.FC<PropsWithChildren<{ sizes?: string, layout: 'horizontal' | 'vertical', justifyContent?: 'start' | 'end', alignItems?: 'center', style?: CSSProperties, cssClassName?: string, divider?: boolean }>> = (props) => {

    logMajorComponentRender(Stack.name);

    const isHorizontal = (props.layout === 'horizontal');

    const sizes = props.sizes ?? React.Children.map(props.children, () => 'auto')?.join(' ');

    const className = (props.divider ?? false) ? (isHorizontal ? styles.horizontal : styles.vertical) : undefined;

    const fullStyle: CSSProperties = {
        ...props.style,
        display: 'grid',
        gridTemplateRows: isHorizontal ? 'auto' : sizes,
        gridTemplateColumns: isHorizontal ? sizes : 'auto',
        justifyContent: props.justifyContent ?? 'stretch',
        alignItems: props.alignItems ?? 'normal',
        minHeight: isHorizontal ? 'auto' : '100%',
        minWidth: isHorizontal ? '100%' : 'auto'
    };

    return <div className={cx(props.cssClassName, className)} style={fullStyle}>{props.children}</div>
}