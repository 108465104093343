import { MagFilterTypes, MinFilterTypes, WrapTypes } from "./TextureTypes";

export class TextureSettings {
    MagFilter: MagFilterTypes;
    MinFilter: MinFilterTypes;
    WrapS: WrapTypes;
    WrapT: WrapTypes;


    constructor(MagFilter: MagFilterTypes, MinFilter: MinFilterTypes, WrapS: WrapTypes, WrapT: WrapTypes) {
        this.MagFilter = MagFilter;
        this.MinFilter = MinFilter;
        this.WrapS = WrapS;
        this.WrapT = WrapT;
    }
}