import React, { useMemo, useState } from 'react';
import { Filter, SearchableList } from './SearchableList';
import { Command } from '../../classes/commands/types';
import { Utils, graphicsTypeToComponents } from '../../classes/Utils';
import { commandRemoved, selectMarkedUpMemory, selectIndexedCommands, selectGuidAndName } from '../../store/projectSlice';
import styles from './Ranges.module.css';
import { navigate, updateRouteToCurrentLine } from '../../store/codeSlice';
import { DataCommand } from '../../classes/commands/DataCommand';
import { LabelCommand } from '../../classes/commands/LabelCommand';
import { PointerCommand } from '../../classes/commands/PointerCommand';
import { GraphicCommand } from '../../classes/commands/GraphicCommand';
import { FormattingCommand } from '../../classes/commands/FormattingCommand';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';
import { CommentCommand } from '../../classes/commands/CommentCommand';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { OverrideCommand } from '../../classes/commands/OverrideCommand';
import { getTargetLabel } from '../MainPanel/LineRenderer';
import { logCommandAnalytic, toCommandType } from '../../classes/code/Firebase';
import { selectIsProjectReadOnly } from '../../store/extraSelectors';
import { commandIcon } from './MarkupIcons';

type Result = { commandType?: string, commandIndex?: number, address?: number, label: JSX.Element };

export const RangesSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(RangesSettingsSection.name);

    const { guid, name } = useAppSelector(selectGuidAndName);

    const isReadOnly = useAppSelector(selectIsProjectReadOnly);

    const dispatch = useAppDispatch();
    const [results, SetResults] = useState<Result[]>([]);
    const commands = useAppSelector(selectIndexedCommands);
    const { ram, rom, entryPointsData } = useAppSelector(selectMarkedUpMemory);

    const allFiltersDisabled = {
        'data': false,
        'entry-point': false,
        'formatting': false,
        'graphic': false,
        'label': false,
        'comment': false,
        'pointer': false,
        'override': false
    };
    const initialFilters = {
        'data': true,
        'entry-point': true,
        'formatting': false,
        'graphic': false,
        'label': true,
        'comment': true,
        'pointer': true,
        'override': false
    };
    const [filtersStatus, SetFiltersStatus] = useState<({ [name: string]: boolean })>(initialFilters);

    const toggleFilterStatus = (name: string, selectOnly: boolean) => {
        let newFilters = selectOnly ? { ...allFiltersDisabled, [name]: true } : { ...filtersStatus, [name]: !filtersStatus[name] };

        const allDisabled = Object.entries(newFilters).filter(x => x[1]).length === 0;
        if (allDisabled) {
            SetFiltersStatus(initialFilters);
        }
        else {
            SetFiltersStatus(newFilters);
        }
    };

    const handleAddressClick = (e: React.MouseEvent<HTMLElement>, address: number) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        navigate({ address, type: 'ram' });
    }

    const handleDeleteCommand = (commandIndex: number, commandType: string, address: number) => {
        logCommandAnalytic('delete_command', toCommandType(commandType), address, guid, name);
        dispatch(commandRemoved(commandIndex));
        updateRouteToCurrentLine();
    }

    const makeFilter = (name: string): Filter => {
        return {
            visual: commandIcon(name), isActive: filtersStatus[name], toggle: (selectOnly: boolean) => toggleFilterStatus(name, selectOnly)
        }
    };

    const filters: Filter[] = [
        makeFilter('entry-point'),
        makeFilter('override'),
        makeFilter('comment'),
        makeFilter('label'),
        makeFilter('data'),
        makeFilter('pointer'),
        makeFilter('formatting'),
        makeFilter('graphic'),
    ];

    const getLabel = useMemo(() => (command: Command) => {

        let label: JSX.Element = <></>;

        switch (command.type) {
            case 'data':
                const dataCommand = command as DataCommand;
                label = <><span className={styles.count}>+{Utils.toHexAuto(dataCommand.countBytes)}</span>"{dataCommand.name}"</>;
                break;
            case 'entry-point':
                const entryPointData = entryPointsData.filter(e => e.address.type === 'ram' && e.address.address === command.address);
                if (entryPointData.length > 0) {
                    const { addressesDiscovered, discoveredBy } = entryPointData[0];
                    if (discoveredBy != null) {
                        label = <>(found by ${Utils.to4DigitHexString(discoveredBy.address)})</>
                    }
                    else {
                        label = <>found {addressesDiscovered} addresses</>
                    }
                }
                break;
            case 'formatting':
                label = <>"{(command as FormattingCommand).format}"</>;
                break;
            case 'graphic':
                const graphicCommand = command as GraphicCommand;
                const size = `${graphicCommand.widthPx}x${graphicCommand.heightPx}`;
                const { layout } = graphicsTypeToComponents(graphicCommand.graphicsType!);
                label = <><span className={styles.count}>+{Utils.toHexAuto(graphicCommand.countBytes)}</span>{layout} ({size})</>;
                break;
            case 'label':
                label = <>"{(command as LabelCommand).text}"</>;
                break;
            case 'comment':
                label = <>"{(command as CommentCommand).text}"</>;
                break;
            case 'override':
                label = <>"{(command as OverrideCommand).override}"</>;
                break;
            case 'pointer':
                const pointer = command as PointerCommand;
                const prologue = pointer?.part === '16bit' ? '' : (pointer?.part === 'hi' ? '>' : '<');

                const pointerTargetAddress = pointer.target.address;
                const targetTypeIsROM = pointer.target.type === 'rom'
                const targetMemory = targetTypeIsROM ? rom : ram;

                const targetLocation = targetMemory[pointerTargetAddress];
                label = <>{prologue} {getTargetLabel(targetLocation, ram, rom)}</>;

                break;
        }

        return label;
    }, [entryPointsData, ram, rom]);

    const renderItem = (result: Result) => {
        const { commandType, commandIndex, address, label } = result;

        return (
            <div className={styles.result}>
                {(!isReadOnly && commandIndex != null && commandType != null && address != null) && <button onClick={() => handleDeleteCommand(commandIndex, commandType, address)} className={styles.resultDelete}><FontAwesomeIcon icon={faTrashCan} /></button>}
                {(commandType != null) && commandIcon(commandType)}
                {(address != null) && <span className={styles.address} onClick={e => handleAddressClick(e, address)}>${Utils.to4DigitHexString(address)}</span>}
                <span className={styles.resultLabel}>{label}</span>
            </div>
        );
    };

    const search = useMemo(() => (searchTerm: string) => {
        const words = searchTerm.split(' ');
        const wordsAsBytes = words.map(w => Utils.hexStringToNumber(w)).filter(w => w.valid && w.value < 0x100 && w.value >= 0).map(w => w.value);
        const isBytes = wordsAsBytes.length === words.length;

        if (isBytes) {

            const getDistToNextByte = (address: number, value: number) => {
                const maxDist = 0x20;
                let dist: number | undefined = undefined;
                for (let i = 1; i < maxDist; i++) {
                    const testAddress = address + i;
                    if (testAddress >= 0x10000) { break; }
                    if (ram[testAddress].Value === value) {
                        dist = i - 1;
                        break;
                    }
                }

                return dist;
            }

            // Find all addresses which match the first byte
            let matches = ram.map((m, i) => { return { address: i, value: m.Value } }).filter(m => m.value === wordsAsBytes[0]).map(m => { return { address: m.address, searchAddress: m.address, totalDist: 0 } });

            // For each subsequent byte, update the total distance to the next byte and filter out any bad results
            wordsAsBytes.slice(1).forEach(w => {
                matches = matches
                    .map(m => {
                        const dist = getDistToNextByte(m.searchAddress, w);
                        const totalDist = (dist != null) ? (m.totalDist + dist) : -1;
                        const searchAddress = (dist != null) ? m.searchAddress + dist + 1 : -1;
                        return { address: m.address, searchAddress, totalDist };
                    })
                    .filter(m => m.totalDist !== -1)
            });

            if (matches.length > 10000) {
                SetResults([{ label: <span>(more than 10000 results)</span> }])
            } else {
                SetResults(matches
                    .sort((a, b) => a.totalDist - b.totalDist)
                    .map(m => {
                        const r: Result = { address: m.address, label: <span>{searchTerm} ({m.totalDist} gap{m.totalDist === 1 ? '' : 's'})</span> };
                        return r;
                    }));
            }
        } else {
            const matches = commands
                .filter(c => filtersStatus[c.command.type]) // only look at filtered commands
                .filter(c => {
                    const { command } = c;
                    const matchesAddress = Utils.to4DigitHexString(command.address).toLowerCase().includes(searchTerm);
                    const matchesText =
                        ((command as LabelCommand)?.text?.toLowerCase().includes(searchTerm) ?? false)
                        || ((command as CommentCommand)?.text?.toLowerCase().includes(searchTerm) ?? false)
                        || ((command as DataCommand)?.name?.toLowerCase().includes(searchTerm) ?? false)
                        ;

                    return matchesAddress || matchesText;
                })
                .sort((a, b) => a.command.address - b.command.address);

            SetResults(matches.map(m => {
                const r: Result = { commandIndex: m.index, commandType: m.command.type, address: m.command.address, label: getLabel(m.command) };
                return r;
            }));
        }
    }, [SetResults, getLabel, filtersStatus, commands, ram]);

    return (
        <SearchableList<Result>
            items={results}
            renderItem={renderItem}
            onSearchTermChanged={search}
            filters={filters}
        />
    );
}