import { Banner } from './HomePage';
import styles from './HomePage.module.css';

export const Loading: React.FC = () => {

    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <h2><Banner message={'** LOADING '} colours={['05', '0D']} background={'0A'} updateMS={125} /></h2>
            </div>
        </div>
    );
}