import { Utils } from '../Utils';
import { MemoryLocation } from '../code/MemoryLocation';
import { Range } from "../code/Range";
import { OverrideCommand, OverrideType } from './OverrideCommand';

const hex = Utils.to4DigitHexString;

export const newOverrideCommand = (address: number, override: OverrideType): OverrideCommand => {
    return {
        type: 'override',
        address,
        override
    }
}

export const markUpMemory = (command: OverrideCommand, memory: MemoryLocation[], ranges: Range[]) => {
    memory[command.address].Override = command.override;
}

export const equals = (command: OverrideCommand, otherCommand: OverrideCommand) => {

    let res = true;
    res &&= (command.address === otherCommand.address);
    res &&= (command.override === otherCommand.override);

    return res;
}

export const version = "1";

export const serialise = (command: OverrideCommand): string => {
    const formatShort = (command.override === 'ArgIsROM') ? 'r' : (command.override === 'Skip' ? 's' : 't');
    return `ov|${hex(command.address)}|${formatShort}`;
}

const deserialise_v01 = (components: string[]): OverrideCommand | undefined => {
    if (components.length !== 2) { return; }
    const address = Number(`0x${components[0]}`);
    const override: OverrideType | undefined =
        components[1] === 'r' ? 'ArgIsROM' : (
            components[1] === 's' ? 'Skip' : (
                components[1] === 't' ? 'Stop' :
                    undefined
            ));

    if (isNaN(address) || override == null) { return; }

    return newOverrideCommand(address, override);
}

export const deserialisers: { [version: string]: (c: string[]) => OverrideCommand | undefined } = {
    '1': deserialise_v01
};

