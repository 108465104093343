import { configureStore } from "@reduxjs/toolkit";
import toolReducer from './toolSlice';
import rendererReducer from './rendererSlice';
import uiReducer from './uiSlice';
import codeReducer from './codeSlice';
import projectReducer from './projectSlice';
import graphicsReducer from './graphicsSlice';

export const store = configureStore({
    reducer: {
        tool: toolReducer,
        renderer: rendererReducer,
        ui: uiReducer,
        code: codeReducer,
        project: projectReducer,
        graphics: graphicsReducer
    }
});

export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;