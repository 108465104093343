import { MemoryAddress, memoryAddressEquals, MemoryLocation } from "./MemoryLocation";
import { Utils } from "../Utils";
import { OpCodes } from './OpCodes';
import { Range, CodeRange } from './Range';
import { logWarning } from "../Logger";

import basicBin from '../../data/basic.901226-01.bin?bin';
import kernalBin from '../../data/kernal.901227-03.bin?bin';
import { markUpMemory as markupFromLabelCommand, newLabelCommand } from "../commands/LabelCommandHelpers";
import { markUpMemory as markupFromDataCommand, newDataCommand } from "../commands/DataCommandHelpers";
import { markUpMemory as markupFromGraphicCommand, newGraphicCommand } from "../commands/GraphicCommandHelpers";
import { markUpMemory as markupFromCommentCommand, newCommentCommand } from "../commands/CommentCommandHelpers";

function dataURLtoArray(dataurl: string) {
    const arr = dataurl.split(",");
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const array = new Array<number>(n);
    while (n--) {
        array[n] = bstr.charCodeAt(n);
    }
    return array;
}

const basicBytes = dataURLtoArray(basicBin);
const kernalBytes = dataURLtoArray(kernalBin);

export const getROMBytes = (colramBytes: number[]) => {

    const rom = Array<number>(0x10000);

    for (let index = 0x0000; index < 0xa000; index++) {
        rom[index] = 0;
    }
    for (let index = 0; index < basicBytes.length; index++) {
        rom[0xa000 + index] = basicBytes[index];
    }
    for (let index = 0xc000; index < 0xd000; index++) {
        rom[index] = 0;
    }
    for (let index = 0xd000; index < 0xd800; index++) {
        rom[index] = 0;
    }
    for (let index = 0; index < 0x3e8; index++) {
        rom[0xd800 + index] = colramBytes[index];
    }
    for (let index = 0xdbe8; index < 0xe000; index++) {
        rom[index] = 0;
    }
    for (let index = 0; index < kernalBytes.length; index++) {
        rom[0xe000 + index] = kernalBytes[index];
    }

    return rom;
}

export const getROM = (colramBytes: number[]) => {

    const romBytes = getROMBytes(colramBytes);

    const rom = Array<MemoryLocation>(0x10000);
    for (let index = 0x0000; index < 0x10000; index++) {
        rom[index] = new MemoryLocation({ type: 'rom', address: index }, romBytes[index]);
    }

    const romRanges = new Array<Range>();

    const markupVICII = (base: number, mirror: number) => {
        const thisBase = base + mirror * 0x40;
        markupFromDataCommand(newDataCommand(thisBase, 0x40, (mirror === 0) ? 'VIC-II' : `VIC-II (mirror ${mirror})`), rom, romRanges);
        if (mirror > 0) { return; }

        markupFromLabelCommand(newLabelCommand(thisBase + 0x00, "VIC-II Sprite 0 X-pos lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x01, "VIC-II Sprite 0 Y-pos"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x02, "VIC-II Sprite 1 X-pos lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x03, "VIC-II Sprite 1 Y-pos"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x04, "VIC-II Sprite 2 X-pos lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x05, "VIC-II Sprite 2 Y-pos"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x06, "VIC-II Sprite 3 X-pos lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x07, "VIC-II Sprite 3 Y-pos"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x08, "VIC-II Sprite 4 X-pos lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x09, "VIC-II Sprite 4 Y-pos"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0a, "VIC-II Sprite 5 X-pos lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0b, "VIC-II Sprite 5 Y-pos"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0c, "VIC-II Sprite 6 X-pos lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0d, "VIC-II Sprite 6 Y-pos"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0e, "VIC-II Sprite 7 X-pos lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0f, "VIC-II Sprite 7 Y-pos"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x10, "VIC-II Sprites X-pos hi"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x11, "VIC-II Screen control 1"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x12, "VIC-II Current raster line"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x13, "VIC-II Light pen X-pos"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x14, "VIC-II Light pen Y-pos"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x15, "VIC-II Enable sprites"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x16, "VIC-II Screen control 2"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x17, "VIC-II Sprites expand Y"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x18, "VIC-II Memory control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x19, "VIC-II Interrupt flag"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x1a, "VIC-II Interrupt mask"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x1b, "VIC-II Sprite-bg priority"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x1c, "VIC-II Sprites multicolour"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x1d, "VIC-II Sprites expand X"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x1e, "VIC-II Sprite-sprite col"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x1f, "VIC-II Sprite-bg col"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x20, "VIC-II Border col"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x21, "VIC-II Background col 0"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x22, "VIC-II Background col 1"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x23, "VIC-II Background col 2"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x24, "VIC-II Background col 3"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x25, "VIC-II Sprite multicol 1"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x26, "VIC-II Sprite multicol 2"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x27, "VIC-II Sprite 0 colour"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x28, "VIC-II Sprite 1 colour"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x29, "VIC-II Sprite 2 colour"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x2a, "VIC-II Sprite 3 colour"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x2b, "VIC-II Sprite 4 colour"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x2c, "VIC-II Sprite 5 colour"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x2d, "VIC-II Sprite 6 colour"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x2e, "VIC-II Sprite 7 colour"), rom, romRanges);
        markupFromCommentCommand(newCommentCommand(thisBase + 0x2f, "Last 17 bytes are unused"), rom, romRanges);
    }

    const markupSID = (base: number, mirror: number) => {
        const thisBase = base + mirror * 0x20;
        markupFromDataCommand(newDataCommand(thisBase, 0x20, (mirror === 0) ? 'SID' : `SID (mirror ${mirror})`), rom, romRanges);
        if (mirror > 0) { return; }

        markupFromLabelCommand(newLabelCommand(thisBase + 0x00, "SID Voice 1 <freq control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x01, "SID Voice 1 >freq control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x02, "SID Voice 1 <pulse width"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x03, "SID Voice 1 >pulse width"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x04, "SID Voice 1 control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x05, "SID Voice 1 AD"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x06, "SID Voice 1 SR"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x07, "SID Voice 2 <freq control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x08, "SID Voice 2 >freq control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x09, "SID Voice 2 <pulse width"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0a, "SID Voice 2 >pulse width"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0b, "SID Voice 2 control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0c, "SID Voice 2 AD"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0d, "SID Voice 2 SR"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0e, "SID Voice 3 <freq control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0f, "SID Voice 3 >freq control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x10, "SID Voice 3 <pulse width"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x11, "SID Voice 3 >pulse width"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x12, "SID Voice 3 control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x13, "SID Voice 3 AD"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x14, "SID Voice 3 SR"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x15, "SID <Filter cutoff frequency"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x16, "SID >Filter cutoff frequency"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x17, "SID Filter/voices control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x18, "SID Filter mode + volume"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x19, "SID Paddle 1 adc"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x1a, "SID Paddle 2 adc"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x1b, "SID Oscillator 3 rng"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x1c, "SID Envelope 3 output"), rom, romRanges);
        markupFromCommentCommand(newCommentCommand(thisBase + 0x1d, "Last 3 bytes are unused"), rom, romRanges);
    }

    const markupCIA1 = (base: number, mirror: number) => {
        const thisBase = base + mirror * 0x10;
        markupFromDataCommand(newDataCommand(thisBase, 0x10, (mirror === 0) ? 'CIA1' : `CIA1 (mirror ${mirror})`), rom, romRanges);
        if (mirror > 0) { return; }

        markupFromLabelCommand(newLabelCommand(thisBase + 0x00, "CIA1 Data port A"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x01, "CIA1 Data port B"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x02, "CIA1 Data dir port A"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x03, "CIA1 Data dir port B"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x04, "CIA1 Timer A lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x05, "CIA1 Timer A hi"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x06, "CIA1 Timer B lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x07, "CIA1 Timer B hi"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x08, "CIA1 Realtime clock 1/10s"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x09, "CIA1 Realtime clock seconds"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0a, "CIA1 Realtime clock minutes"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0b, "CIA1 Realtime clock hours"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0c, "CIA1 Serial shift"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0d, "CIA1 IRQ Control + status"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0e, "CIA1 Timer A control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0f, "CIA1 Timer B control"), rom, romRanges);
    }

    const markupCIA2 = (base: number, mirror: number) => {
        const thisBase = base + mirror * 0x10;
        markupFromDataCommand(newDataCommand(thisBase, 0x10, (mirror === 0) ? 'CIA2' : `CIA2 (mirror ${mirror})`), rom, romRanges);
        if (mirror > 0) { return; }

        markupFromLabelCommand(newLabelCommand(thisBase + 0x00, "CIA2 Data port A"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x01, "CIA2 Data port B"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x02, "CIA2 Data dir port A"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x03, "CIA2 Data dir port B"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x04, "CIA2 Timer A lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x05, "CIA2 Timer A hi"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x06, "CIA2 Timer B lo"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x07, "CIA2 Timer B hi"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x08, "CIA2 Realtime clock 1/10s"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x09, "CIA2 Realtime clock seconds"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0a, "CIA2 Realtime clock minutes"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0b, "CIA2 Realtime clock hours"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0c, "CIA2 Serial shift"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0d, "CIA2 NMI Control + status"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0e, "CIA2 Timer A control"), rom, romRanges);
        markupFromLabelCommand(newLabelCommand(thisBase + 0x0f, "CIA2 Timer B control"), rom, romRanges);
    }

    // $d000-$d3ff VIC-II (and mirrors)
    Array(0x10).fill(0).forEach((_, i) => markupVICII(0xd000, i));

    // $d400-$d7ff SID (and mirrors)
    Array(0x20).fill(0).forEach((_, i) => markupSID(0xd400, i));

    // $d800-$dbe7 COLRAM
    markupFromGraphicCommand(newGraphicCommand({ name:'COLRAM', address: 0xd800, countBytes: 0x3e8, heightPx: 200, widthPx: 320, scale: 1, graphicsType: "ColoursSingle" }), rom, romRanges);
    markupFromDataCommand(newDataCommand(0xdbe8, 0x18, "Unused"), rom, romRanges);

    // $dc00-$dcff CIA1 (and mirrors)
    Array(0x10).fill(0).forEach((_, i) => markupCIA1(0xdc00, i));

    // $dd00-$ddff CIA1 (and mirrors)
    Array(0x10).fill(0).forEach((_, i) => markupCIA2(0xdd00, i));

    // $de00-$deff I/O area 1
    markupFromDataCommand(newDataCommand(0xde00, 0x100, "I/O Area 1"), rom, romRanges);

    // $df00-$dfff I/O area 2
    markupFromDataCommand(newDataCommand(0xdf00, 0x100, "I/O Area 2"), rom, romRanges);

    return { rom, romRanges };
}

export type EntryPointData = {
    address: MemoryAddress,
    addressesDiscovered: number,
    discoveredBy?: MemoryAddress
}

export type Note = {
    address: MemoryAddress,
    type: 'info' | 'warning' | 'error' | 'debug',
    note: string
}

export class MemoryManager {

    /** Disassemble from an entry point address */
    disassemble(ram: MemoryLocation[], rom: MemoryLocation[], ramRanges: Range[], romRanges: Range[], entryPoints: MemoryAddress[], entryPointsData: EntryPointData[], notes: Note[]) {

        const startTime = Date.now();

        const redundantEntryPoints: { address: MemoryAddress, discoveredBy: MemoryAddress }[] = [];
        const entryPointsWithSomeData: { address: MemoryAddress, addressesDiscovered: number }[] = [];

        // Disassemble from each entry point
        entryPoints.forEach(thisEntryPoint => {

            notes.push({ address: thisEntryPoint, type: 'info', note: `Disassembling from here` });

            // Seed the list of addresses to investigate with this initial entry point
            const addressesToInvestigate = [thisEntryPoint];

            // Keep track of what this entry point changed
            const addressesChangedByThisEntryPoint: MemoryAddress[] = [];

            // Disassemble (with an infinite loop check)
            let counter = 0x10000;
            while (addressesToInvestigate.length > 0) {

                // Get an address from the pool
                const addressToInvestigate = addressesToInvestigate.pop();
                if (addressToInvestigate === undefined) { continue; }

                // If this entrypoint has discovered another entrypoint, the latter is redundant
                const addressIsNotThisEntryPoint = !memoryAddressEquals(addressToInvestigate, thisEntryPoint)
                const addressIsAnEntryPoint = entryPoints.findIndex(a => memoryAddressEquals(a, addressToInvestigate)) > -1;
                if (addressIsNotThisEntryPoint && addressIsAnEntryPoint) {
                    redundantEntryPoints.push({ address: addressToInvestigate, discoveredBy: thisEntryPoint });
                }

                // Disassemble from this address
                this.disassembleAddress(ram, rom, thisEntryPoint, addressToInvestigate, addressesToInvestigate, addressesChangedByThisEntryPoint, notes);

                // Sanity check
                if (counter-- < 0) {
                    logWarning("Disassembler is caught in a loop");
                    break;
                }
            }

            entryPointsWithSomeData.push({ address: thisEntryPoint, addressesDiscovered: addressesChangedByThisEntryPoint.length });
        });

        // Match up redundant entrypoints with other data we gathered about each entrypoint
        entryPointsWithSomeData
            .map(entryPointWithSomeData => {
                const discovererOrUndefined = redundantEntryPoints.filter(redundantEntryPoint => memoryAddressEquals(redundantEntryPoint.address, entryPointWithSomeData.address))[0]?.discoveredBy;
                return { ...entryPointWithSomeData, discoveredBy: discovererOrUndefined };
            })
            .forEach(e => entryPointsData.push(e));

        // Post-process the memmory to find code ranges
        ramRanges.push(...this.findCodeRanges(ram));
        romRanges.push(...this.findCodeRanges(rom));

        const duration = Date.now() - startTime;
        return duration;
    }


    /** Disassemble from an entry point address, keeping track of what addresses changed */
    private disassembleAddress(ram: MemoryLocation[], rom: MemoryLocation[], entrypoint: MemoryAddress, memoryAddress: MemoryAddress, addressPool: MemoryAddress[], changedAddresses: MemoryAddress[], notes: Note[]) {

        const address = memoryAddress.address;
        const addressIsRAM = memoryAddress.type === 'ram';
        const memory = addressIsRAM ? ram : rom;

        // Info about this address
        const location = memory[address];

        const makeNotes = (location.EntryPoint === undefined);

        if (location.Override === 'Stop') {
            if (makeNotes) { notes.push({ address: memoryAddress, type: 'info', note: `Encountered a 'stop' override at ${Utils.to4DigitHexString(address)}` }) };
            return;
        }

        // Info about the following two addresses, if we need it later
        const location1 = memory[address + 1];
        const location2 = memory[address + 2];

        if (location1 === undefined || location2 === undefined) {
            if (makeNotes) { notes.push({ address: memoryAddress, type: 'error', note: `Undefined memory at ${Utils.to4DigitHexString(address + 1)} or ${Utils.to4DigitHexString(address + 2)}` }) };
            return;
        }

        // Following two addresses interpreted as 8 and 16-bit addresses, if we need them later 
        const arg8 = location1.Value;
        const locationArg8 = ram[arg8];

        const arg16Address = location1.Value + (location2.Value * 0x0100);
        const arg16CouldBeROM = (0xa000 <= arg16Address && arg16Address <= 0xbfff) || (0xd000 <= arg16Address && arg16Address <= 0xffff);
        const arg16IsRom = (location.ArgumentType === '16bitROM') || (!addressIsRAM && arg16CouldBeROM) || location.Override === 'ArgIsROM';
        const arg16: MemoryAddress = { type: arg16IsRom ? 'rom' : 'ram', address: arg16Address }
        const locationArg16 = arg16IsRom ? rom[arg16Address] : ram[arg16Address];

        // Have we already visited this address?
        const locationAlreadyFoundByThisEntryPoint = memoryAddressEquals(location.EntryPoint, entrypoint);
        if (location.IsCode && locationAlreadyFoundByThisEntryPoint) {
            notes.push({ address: memoryAddress, type: 'info', note: `Went in a circle after XXX - exiting` });
            return;
        }

        // Have we already visited this address when it was an argument?
        if (location.IsArgument && locationAlreadyFoundByThisEntryPoint) {
            notes.push({ address: memoryAddress, type: 'warning', note: `Code flows to an existing argument from XXX` });
            return;
        }

        // Get the opcode at this address, and its details
        const opcode = OpCodes[location.Value];
        const byteLength = opcode.ByteLength;

        // Check it wouldn't overlap with an existing instruction (a sign of incorrect disassembly)
        const argsAreCode = (byteLength === 2 && location1.IsCode) || (byteLength === 3 && (location1.IsCode || location2.IsCode));
        if (argsAreCode) {
            if (makeNotes) { notes.push({ address: memoryAddress, type: 'warning', note: `Argument(s) overlap existing code` }) };
        }

        // Check if any address argument would overlap with gfx or data
        const targetIsGfxOrData = (byteLength === 2 && (locationArg8.IsGfx || locationArg8.IsData)) || (byteLength === 3 && (locationArg16.IsGfx || locationArg16.IsData));

        // Mark this as code
        location.IsCode = true;
        location.EntryPoint = entrypoint;

        const mode = opcode.Mode;
        const flow = opcode.ProgramFlow;
        const opref = opcode.OperandReference;
        const nextAddress: MemoryAddress = { type: memoryAddress.type, address: address + byteLength };
        const locationNext = memory[nextAddress.address];

        if (location.Formatting !== 'None') {
            addressPool.push({ type: memoryAddress.type, address: (address + 1) });
        }


        // Deal with program flow
        let branchAddress = 0;
        switch (flow) {

            case 'Continue':

                // Add the next address to the pool
                addressPool.push(nextAddress);
                if (makeNotes) { notes.push({ address: memoryAddress, type: 'debug', note: `Adding ${Utils.to4DigitHexString(nextAddress.address)} to list (continue)` }) };
                locationNext.IncomingFlow = memoryAddress;
                break;


            case 'Branch':

                // Add the next address to the pool
                addressPool.push(nextAddress);
                if (makeNotes) { notes.push({ address: memoryAddress, type: 'debug', note: `Adding ${Utils.to4DigitHexString(nextAddress.address)} to list (branch fail)` }) };
                locationNext.IncomingFlow = memoryAddress;

                // Calculate the relative branch address and add it to the pool
                let branchAddressRelative = arg8;
                if (branchAddressRelative >= 128)
                    branchAddressRelative -= 256;
                branchAddress = address + branchAddressRelative + 2;
                addressPool.push({ type: memoryAddress.type, address: branchAddress });
                if (makeNotes) { notes.push({ address: memoryAddress, type: 'info', note: `Adding ${Utils.to4DigitHexString(branchAddress)} to list (branch take)` }) };

                // Make a reference in the branched address back to here
                let locationBranch = memory[branchAddress];
                if (locationBranch.IncomingBranches.findIndex(a => memoryAddressEquals(a, memoryAddress)) < 0) {
                    locationBranch.IncomingBranches.push(memoryAddress);
                }

                // Note that this address has outgoing refs
                location.HasOutgoingRefs = true;
                break;


            case 'GoSub':

                // Add the next address to the pool
                addressPool.push(nextAddress);
                if (makeNotes) { notes.push({ address: memoryAddress, type: 'debug', note: `Adding ${Utils.to4DigitHexString(nextAddress.address)} to list (gosub continue)` }) };
                locationNext.IncomingFlow = memoryAddress;

                // Check referenced address is valid
                if (!targetIsGfxOrData) {

                    // Add the referenced address to the pool
                    addressPool.push(arg16);
                    if (makeNotes) { notes.push({ address: memoryAddress, type: 'info', note: `Adding ${Utils.to4DigitHexString(arg16Address)} to list (gosub)` }) };

                    // Note that this address has outgoing refs
                    location.HasOutgoingRefs = true;
                }

                // Make a reference in the visited address back to here
                if (locationArg16.IncomingJSRs.findIndex(a => memoryAddressEquals(a, memoryAddress)) < 0) {
                    locationArg16.IncomingJSRs.push(memoryAddress);
                }

                break;


            case 'GoTo':

                if (mode === 'Absolute') {

                    if (!targetIsGfxOrData) {
                        // Add the referenced address to the pool
                        addressPool.push(arg16);
                        if (makeNotes) { notes.push({ address: memoryAddress, type: 'info', note: `Adding ${Utils.to4DigitHexString(arg16Address)} to list (jump)` }) };
                    }

                    // Make a reference in the visited address back to here
                    if (locationArg16.IncomingJMPs.findIndex(a => memoryAddressEquals(a, memoryAddress)) < 0) {
                        locationArg16.IncomingJMPs.push(memoryAddress);
                    }
                }
                else {
                    if (makeNotes) { notes.push({ address: memoryAddress, type: 'error', note: `Indirect jump at $${Utils.to4DigitHexString(address)}` }) };
                }

                // Note that this address has outgoing refs
                location.HasOutgoingRefs = true;
                break;


            case 'Stop':
            default:
                break;
        }


        // Store the calculated argument, to avoid recalculating it when rendering
        if (mode === 'Relative') {
            location.Argument = branchAddress;
            location.ArgumentType = addressIsRAM ? '16bitRAM' : '16bitROM';
        }
        else if (mode !== 'Implicit' && mode !== 'Accumulator') {
            if (byteLength === 2) {
                location.Argument = arg8;
                location.ArgumentType = (mode === 'Immediate') ? '8bitImmediate' : '8bitRAM';
            }
            if (byteLength === 3) {
                location.Argument = arg16Address;
                location.ArgumentType = arg16IsRom ? '16bitROM' : '16bitRAM';
            }
        }


        // Deal with references
        if (opref === 'Read' || opref === 'ReadWrite') {
            if (byteLength === 2) {
                if (locationArg8.IncomingReads.findIndex(a => memoryAddressEquals(a, memoryAddress)) < 0) {
                    locationArg8.IncomingReads.push(memoryAddress)
                }
                location.OutgoingRead = { type: 'ram', address: arg8 };
            }
            if (byteLength === 3) {
                if (locationArg16.IncomingReads.findIndex(a => memoryAddressEquals(a, memoryAddress)) < 0) {
                    locationArg16.IncomingReads.push(memoryAddress);
                }
                location.OutgoingRead = arg16;
            }
        }

        if (opref === 'Write' || opref === 'ReadWrite') {
            if (byteLength === 2) {
                if (locationArg8.IncomingWrites.findIndex(a => memoryAddressEquals(a, memoryAddress)) < 0) {
                    locationArg8.IncomingWrites.push(memoryAddress)
                }
                location.OutgoingWrite = { type: 'ram', address: arg8 };
            }
            if (byteLength === 3) {
                if (locationArg16.IncomingWrites.findIndex(a => memoryAddressEquals(a, memoryAddress)) < 0) {
                    locationArg16.IncomingWrites.push(memoryAddress);
                }
                location.OutgoingWrite = arg16;
            }
        }


        // Keep track of changed addresses 
        for (let i = 0; i < byteLength; i++)
            changedAddresses.push({ type: memoryAddress.type, address: (address + i) });

        // Update counts and types
        if (argsAreCode || byteLength === 1)
            location.CountWithinBlock = 1;
        else if (byteLength === 2) {
            location.CountWithinBlock = 2;
            location1.CountWithinBlock = -1;
            location1.IsArgument = true;
            location1.EntryPoint = entrypoint;
        }
        else if (byteLength === 3) {
            location.CountWithinBlock = 3;
            location1.CountWithinBlock = -1;
            location2.CountWithinBlock = -2;
            location1.IsArgument = true;
            location1.EntryPoint = entrypoint;
            location2.IsArgument = true;
            location2.EntryPoint = entrypoint;
        }

        return;
    }


    findCodeRanges(memory: MemoryLocation[]) {

        const ranges: Range[] = [];

        const memoryLength = memory.length;
        let address = 0;
        const visitedAddresses: boolean[] = [];
        visitedAddresses.fill(false, 0, memoryLength);
        while (address < memoryLength) {

            if (visitedAddresses[address]) {
                logWarning(`Assembling ranges and found address ${Utils.to4DigitHexString(address)} again.`)
                break;
            }
            visitedAddresses[address] = true;

            // First of all, take this opportunity to sort reads & writes
            const locationStart = memory[address];
            locationStart.IncomingReads.sort((a, b) => a.address - b.address);
            locationStart.IncomingWrites.sort((a, b) => a.address - b.address);

            // If this isn't code, skip the rest
            if (!locationStart.IsCode) {
                address += locationStart.CountWithinBlock;
                continue;
            }

            // We've got code, so start a range
            const rangeStartAddress = address;
            const breakAfterThis = false;
            let isFirst = true;

            const branches = locationStart.IncomingBranches.slice().sort((a, b) => a.address - b.address);
            const jmps = locationStart.IncomingJMPs.slice().sort((a, b) => a.address - b.address);
            const jsrs = locationStart.IncomingJSRs.slice().sort((a, b) => a.address - b.address);
            const flow = locationStart.IncomingFlow;
            const pointers = locationStart.IncomingPointers.slice().sort((a, b) => a.address - b.address);

            // Keep going till we hit an opcode which would end this range
            while (!breakAfterThis && address < memoryLength) {

                const location = memory[address];
                if (!location.IsCode) { break; }

                // Save the number of bytes in this instruction
                const endOfLineAddress = address + location.CountWithinBlock - 1;
                memory[endOfLineAddress].Formatting = 'LineBreakAfter';

                // Break range before a JMP/JSR/branch target 
                if (!isFirst && (location.IncomingJMPs.length > 0 || location.IncomingJSRs.length > 0 || location.IncomingBranches.length > 0))
                    break;

                isFirst = false;
                address += location.CountWithinBlock;

                // Break range if the flow stops/branches/gosubs here
                if (OpCodes[location.Value].ProgramFlow !== 'Continue')
                    break;
            }

            const isEntrypoint = rangeStartAddress === (locationStart.EntryPoint?.address ?? -1);
            const needsLabel = jmps.length > 0 || jsrs.length > 0 || branches.length > 0 || isEntrypoint;

            // Assemble the range
            if (memory[rangeStartAddress].Label == null && needsLabel) {
                memory[rangeStartAddress].Label = 'L' + Utils.to4DigitHexString(rangeStartAddress);
            }

            const rangeEndAddress = address - 1;
            const range = new CodeRange(rangeStartAddress, rangeEndAddress, branches, jmps, jsrs, pointers, flow);
            ranges.push(range);
        }

        return ranges;
    }
}