import { selectionWidthSet, selectSelectionWidth } from "../../store/graphicsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { HexNumberInput } from "./HexNumberInput";
import { SettingsRow } from "./SettingsRow";

export const GraphicsWidthSettingsSection: React.FC = () => {

    const { selectionWidth, layout } = useAppSelector(selectSelectionWidth);
    const dispatch = useAppDispatch();

    return (
        <SettingsRow label="Width">
            <HexNumberInput
                enabled={true}
                label="width"
                number={selectionWidth}
                unit="chars"
                onChange={v => dispatch(selectionWidthSet({ width: v, layout }))}
                forceFourDigits={false}
                notifyFocus={true}
            />
        </SettingsRow>
    );
}