import { GraphicCommand } from "../commands/GraphicCommand";
import { GraphicsTypes } from "../graphics/GraphicsEnums";
import { MemoryAddress } from "./MemoryLocation";

export class DataRange {

    constructor(
        readonly Name: string,
        readonly StartAddress: number,
        readonly EndAddress: number
    ) { }
}

export class GraphicsRange {

    constructor(
        readonly Name: string,
        readonly Command: GraphicCommand,
        readonly Type: GraphicsTypes = Command.graphicsType ?? 'Unknown',
        readonly StartAddress: number = Command.address,
        readonly EndAddress: number = Command.address + Command.countBytes - 1
    ) { }
}


export class CodeRange {

    constructor(
        readonly StartAddress: number,
        readonly EndAddress: number,
        readonly Branches: MemoryAddress[],
        readonly JMPs: MemoryAddress[],
        readonly JSRs: MemoryAddress[],
        readonly Pointers: MemoryAddress[],
        readonly Flow?: MemoryAddress
    ) { }
}


export type Range = DataRange | CodeRange | GraphicsRange;