import React from 'react';
import { SettingsRow } from './SettingsRow';
import { selectMarkedUpMemory } from '../../store/projectSlice';
import { useAppSelector } from '../../store/hooks';
import { logMinorComponentRender } from '../../classes/Logger';


export const DisassemblyTimingSettingsSection: React.FunctionComponent = () => {

    logMinorComponentRender(DisassemblyTimingSettingsSection.name);

    const { durationMS } = useAppSelector(selectMarkedUpMemory);

    return (
        <SettingsRow label='Debug'>
            <div>Disassembly took {durationMS}ms</div>
        </SettingsRow>
    );
}
