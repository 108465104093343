import React, { useCallback, useState } from 'react';
import styles from './MainPanel.module.css';
import { Sprites } from './MainPanel/Sprites';
import { Bitmaps } from './MainPanel/Bitmaps';
import { Screens } from './MainPanel/Screens';
import { selectViewLayout } from '../store/graphicsSlice';
import { Bytes } from './MainPanel/Bytes';
import { Map } from './MainPanel/Map';
import { GraphicsViewport } from './graphics/GraphicsViewport';
import { ViewportLocation } from '../store/rendererSlice';
import { useAppSelector } from '../store/hooks';
import { logMajorComponentRender } from '../classes/Logger';
import { Stack } from './Stack';
import { PreviewSettingsSection } from './SettingsPanel/PreviewSettingsSection';
import { RangesSettingsSection } from './SettingsPanel/RangesSettingsSection';
import { Resizer } from './Resizer';
import { EnumSetting } from './SettingsPanel/EnumSetting';
import { MemoryViewType, selectActiveMemoryView, selectCurrentLineStartAddress } from '../store/codeSlice';
import { selectActiveTool } from '../store/toolSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setRouteWithAddress, setRoute } from '../App';

const minPreviewWidthPercent = 20;
const startPreviewWidthPercent = 40;
const maxPreviewWidthPercent = 70;

const minCommandsWidthPercent = 20;
const startCommandsWidthPercent = 30;
const maxCommandsWidthPercent = 50;

export const MainPanel: React.FunctionComponent = (() => {

    logMajorComponentRender(MainPanel.name);

    const [previewWidthPercent, SetPreviewWidthPercent] = useState(startPreviewWidthPercent);
    const [commandsWidthPercent, SetCommandsWidthPercent] = useState(startCommandsWidthPercent);

    const tool = useAppSelector(selectActiveTool)


    const layout = useAppSelector(selectViewLayout);
    const memoryView = useAppSelector(selectActiveMemoryView);
    const currentLineStartAddress = useAppSelector(selectCurrentLineStartAddress);

    const navigate = useNavigate();
    const location = useLocation();
    const { guid } = useParams();

    const showScreens = (tool === 'graphics') && (layout === 'CharacterMapped');
    const showBitmaps = (tool === 'graphics') && (layout === 'Interleaved');
    const showSprites = (tool === 'graphics') && (layout === 'Continuous');
    const showCode = (tool === 'code');
    const showLibrary = (tool === 'library');

    const showPreview = showBitmaps || showScreens || showSprites || showLibrary;
    const showCommands = showCode;

    const getSizes = () => {
        if (showPreview) { return `${previewWidthPercent}% min-content auto`; }
        if (showCommands) { return `${commandsWidthPercent}% min-content auto` };
    }

    const handleOnResize = useCallback((widthPercent: number) => {
        if (showPreview) {
            SetPreviewWidthPercent(widthPercent);
        } else {
            SetCommandsWidthPercent(widthPercent);
        }
    }, [SetPreviewWidthPercent, SetCommandsWidthPercent, showPreview]);

    const resizerMinPercent = showPreview ? minPreviewWidthPercent : minCommandsWidthPercent;
    const resizerMaxPercent = showPreview ? maxPreviewWidthPercent : maxCommandsWidthPercent;

    const handleSetVisibleMemoryView = (newMemoryView: MemoryViewType) => {
        setRouteWithAddress(navigate, location, { guid, tool, target: currentLineStartAddress });
        setRoute(navigate, location, { guid, tool, memoryView: newMemoryView });
    }

    return (
        <Stack layout='horizontal' sizes={getSizes()}>

            {/* Preview */}
            {showPreview &&
                <GraphicsViewport location={ViewportLocation.Preview} ignoreScrollbars={{ h: true, v: true }} show={{ marked: false, selected: false, grid: true }} render={r =>
                    <div ref={r}>
                        <PreviewSettingsSection />
                    </div>
                } />
            }

            {/* Searchable commands */}
            {showCode &&
                <Stack layout='vertical' sizes={'auto 1fr'}>
                    <div className={styles.ramRomSwitch}>
                        <EnumSetting
                            label='test'
                            selectedOption={memoryView}
                            options={{ 'RAM': 'ram', 'ROM': 'rom' }}
                            order={['RAM', 'ROM']}
                            onChange={handleSetVisibleMemoryView}
                        />
                    </div>
                    <RangesSettingsSection />
                </Stack>
            }

            {/* Resizer */}
            <Resizer minSizePercent={resizerMinPercent} maxSizePercent={resizerMaxPercent} onResizedPercent={handleOnResize} />

            {/* View */}
            <GraphicsViewport location={ViewportLocation.Main} ignoreScrollbars={{ h: true, v: false }} show={{ marked: true, selected: true, grid: true }} render={r =>
                <div className={styles.panel} ref={r}>
                    <div className={styles.inner}>
                        {showSprites && <Sprites />}
                        {showBitmaps && <Bitmaps />}
                        {showScreens && <Screens />}
                        {showCode && <Bytes />}
                        {showLibrary && <Map />}
                    </div>
                </div>
            } />

        </Stack>
    );
})