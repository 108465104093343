import { logWarning } from "../../Logger";
import { Utils } from "../../Utils";
import { GLUtils } from "../GLUtils";
import { UniformInfo } from "./ShaderProgramTypes";

export class ShaderProgram {

    private gl: WebGL2RenderingContext;
    private program?: WebGLProgram;
    private uniforms?: Map<string, UniformInfo>;
    private vao?: WebGLVertexArrayObject;


    constructor(
        gl: WebGL2RenderingContext,
        buffers: {
            positions: WebGLBuffer,
            indices: WebGLBuffer,
            packedInstances: WebGLBuffer,
            packedInstancesStride: number
        },
        vertexProgramSource: string,
        fragmentProgramSource: string,
        defines?: string[],
    ) {

        this.gl = gl;

        const vertShader = GLUtils.createShader(gl, gl.VERTEX_SHADER, vertexProgramSource, defines);
        const fragShader = GLUtils.createShader(gl, gl.FRAGMENT_SHADER, fragmentProgramSource, defines);
        if (vertShader == null || fragShader == null) { return; }

        const program = GLUtils.createProgram(gl, vertShader, fragShader);
        if (program == null) { return; }

        this.program = program;
        this.uniforms = GLUtils.findUniforms(gl, program);

        gl.useProgram(this.program);

        // Build vao
        // https://webgl2fundamentals.org/webgl/lessons/webgl-instanced-drawing.html
        // https://webglfundamentals.org/webgl/lessons/webgl-how-it-works.html
        // http://learnwebgl.brown37.net/rendering/buffer_object_primer.html
        // https://webgl2fundamentals.org/webgl/lessons/webgl-fundamentals.html

        const vao = gl.createVertexArray();
        this.vao = vao ?? undefined;
        gl.bindVertexArray(vao);

        const linkBuffer = (attributeName: string, buffer: WebGLBuffer, size: number, offset: number, perInstance: boolean = false) => {
            const attributeLocation = gl.getAttribLocation(program, attributeName);
            if (attributeLocation < 0) { return; }

            const glFloatSize = 4;
            gl.enableVertexAttribArray(attributeLocation);
            gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
            gl.vertexAttribPointer(attributeLocation, size, gl.FLOAT, false, (perInstance ? buffers.packedInstancesStride : 0) * glFloatSize, offset * glFloatSize);
            gl.vertexAttribDivisor(attributeLocation, perInstance ? 1 : 0);
        }

        linkBuffer('a_position', buffers.positions, 2, 0);

        linkBuffer('a_packedPositionAndSize', buffers.packedInstances, 4, 0, true);
        linkBuffer('a_packedAddresses', buffers.packedInstances, 4, 4, true);
        linkBuffer('a_packedColours', buffers.packedInstances, 4, 8, true);
        linkBuffer('a_packedExtras', buffers.packedInstances, 4, 12, true);

        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indices);

        gl.bindVertexArray(null);

        gl.bindBuffer(gl.ARRAY_BUFFER, null);
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
    }


    SetUniform(uniformName: string, values: Array<any>) {

        let gl = this.gl;

        gl.useProgram(this.program ?? null);

        // Get the location
        let uniform = this.uniforms?.get(uniformName);
        if (uniform == null) {
            //Utils.warnIfDebug('Uniform \'' + uniformName + '\' doesn\'t exist');
            return;
        }

        let location = uniform.location;
        switch (uniform.type) {
            case WebGL2RenderingContext.FLOAT:
                if (values.length === 1)
                    gl.uniform1f(location, values[0]);
                break;
            case WebGL2RenderingContext.FLOAT_VEC2:
                if (values.length === 2)
                    gl.uniform2f(location, values[0], values[1]);
                break;
            case WebGL2RenderingContext.FLOAT_VEC3:
                if (values.length === 3)
                    gl.uniform3f(location, values[0], values[1], values[2]);
                break;
            case WebGL2RenderingContext.FLOAT_VEC4:
                if (values.length === 4)
                    gl.uniform4f(location, values[0], values[1], values[2], values[3]);
                break;
            case WebGL2RenderingContext.INT:
                if (values.length === 1)
                    gl.uniform1i(location, values[0]);
                break;
            case WebGL2RenderingContext.INT_VEC2:
                if (values.length === 2)
                    gl.uniform2i(location, values[0], values[1]);
                break;
            case WebGL2RenderingContext.INT_VEC3:
                if (values.length === 3)
                    gl.uniform3i(location, values[0], values[1], values[2]);
                break;
            case WebGL2RenderingContext.INT_VEC4:
                if (values.length === 4)
                    gl.uniform4i(location, values[0], values[1], values[2], values[3]);
                break;
            case WebGL2RenderingContext.UNSIGNED_INT:
                if (values.length === 1)
                    gl.uniform1ui(location, values[0]);
                break;
            case WebGL2RenderingContext.UNSIGNED_INT_VEC2:
                if (values.length === 2)
                    gl.uniform2ui(location, values[0], values[1]);
                break;
            case WebGL2RenderingContext.UNSIGNED_INT_VEC3:
                if (values.length === 3)
                    gl.uniform3ui(location, values[0], values[1], values[2]);
                break;
            case WebGL2RenderingContext.UNSIGNED_INT_VEC4:
                if (values.length === 4)
                    gl.uniform4ui(location, values[0], values[1], values[2], values[3]);
                break;
            case WebGL2RenderingContext.BOOL:
                if (values.length === 1)
                    gl.uniform1ui(location, values[0]);
                break;
            case WebGL2RenderingContext.SAMPLER_2D:
                if (values.length === 1)
                    gl.uniform1i(location, values[0]);
                break;
            case WebGL2RenderingContext.UNSIGNED_INT_SAMPLER_2D:
                if (values.length === 1)
                    gl.uniform1i(location, values[0]);
                break;
            default:
                logWarning('Can\'t understand uniform type ' + Utils.to4DigitHexString(uniform.type));
                break;
        }
    }

    Draw(instanceCount: number) {
        if (this.vao == null || this.program == null) { return; }

        const gl = this.gl;
        gl.useProgram(this.program);
        gl.bindVertexArray(this.vao);
        gl.drawElementsInstanced(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0, instanceCount);
        gl.bindVertexArray(null);
    }
}