import React from 'react';
import styles from './LogoPanel.module.css';
import { logMajorComponentRender } from '../classes/Logger';
import { Stack } from './Stack';


export const LogoPanel: React.FunctionComponent = () => {

    logMajorComponentRender(LogoPanel.name);

    return (
        <Stack layout='vertical'>
            <div className={styles.logo}>46C.IO</div>
            <div className={styles.subheading}>..reversing the c64..</div>
        </Stack>
    );
}