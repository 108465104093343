import * as React from "react";
import { PersistentScroller } from "./PersistentScroller";
import { logMajorComponentRender } from "../../classes/Logger";
import { Heading, SpritesSet } from "./Rippers";


export const Sprites: React.FunctionComponent = () => {

    logMajorComponentRender(Sprites.name);

    return (
        <PersistentScroller name="sprites">
            <Heading bank={3} />
            <SpritesSet bank={3} />
            <Heading bank={2} />
            <SpritesSet bank={2} />
            <Heading bank={1} />
            <SpritesSet bank={1} />
            <Heading bank={0} />
            <SpritesSet bank={0} />
        </PersistentScroller>
    );
}