import * as React from "react";
import { GraphicCommand } from "../../classes/commands/GraphicCommand";
import { allSettingsUpdatedFromCommand, previewCommandSelector } from "../../store/graphicsSlice";
import { ViewportLocation } from "../../store/rendererSlice";
import { currentCommandIndexSet, selectCurrentCommandIndex, commandUpdatedById, selectIndexedGraphicsCommands, selectGuidAndName } from "../../store/projectSlice";
import { selectionSet } from "../../store/toolSlice";
import { WrappedGraphic } from "../graphics/Graphic";
import { PersistentScroller } from "./PersistentScroller";
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { equals } from "../../classes/commands/GraphicCommandHelpers";
import { logMajorComponentRender } from "../../classes/Logger";
import { logCommandAnalytic } from "../../classes/code/Firebase";


export const Map: React.FunctionComponent = () => {

    logMajorComponentRender(Map.name);

    const { guid, name } = useAppSelector(selectGuidAndName);

    const dispatch = useAppDispatch();
    const indexedGraphicCommands = useAppSelector(selectIndexedGraphicsCommands);
    const currentCommandIndex = useAppSelector(selectCurrentCommandIndex);
    const previewCommand = useAppSelector(previewCommandSelector);


    // Clear current command index when switching away from this panel
    useEffect(() => {
        return (() => {
            dispatch(currentCommandIndexSet(undefined));
        });

    }, [dispatch]);


    // If the preview has changed (as a result of gfx settings being changed) and is now
    // different to the currently selected command, then update the command
    useEffect(() => {
        if (previewCommand == null) { return; }
        if (currentCommandIndex == null) { return; }

        const currentCommand = indexedGraphicCommands.find(c => c.command.id === currentCommandIndex);
        if (currentCommand == null) { return; }
        if (currentCommand && equals(currentCommand.command, previewCommand)) { return; }

        logCommandAnalytic('edit_command', 'graphic', previewCommand.address, guid, name);

        dispatch(commandUpdatedById({ commandIdToUpdate: currentCommandIndex, command: previewCommand }));

    }, [previewCommand, indexedGraphicCommands, currentCommandIndex, dispatch, guid, name])


    // A graphic has been clicked 
    const handleSelectCommand = useCallback((command: GraphicCommand) => {
        dispatch(currentCommandIndexSet(command.id));
        dispatch(allSettingsUpdatedFromCommand(command));
        dispatch(selectionSet({ startAddress: command.address, count: command.countBytes, type: 'ram' }));
    }, [dispatch]);

    const graphicsSettings = indexedGraphicCommands
        .sort((a, b) => a.command.address - b.command.address)
        .map(s => <WrappedGraphic location={ViewportLocation.Main} key={s.command.id} selected={s.command.id === currentCommandIndex} settings={s.command} onClick={handleSelectCommand} />);

    return (
        <PersistentScroller name="map">
            {graphicsSettings}
        </PersistentScroller>
    )
}
