import { setWindowTitle, Utils } from "../classes/Utils";
import styles from './SacredArmourOfAntiriad.module.css';

const mapData = [
    { 'east': 0xFF, 'west': 0xFF, 'south': 0x01, 'north': 0xFF }, //0x00
    { 'east': 0xFF, 'west': 0xFF, 'south': 0x02, 'north': 0x00 }, //0x01
    { 'east': 0x03, 'west': 0xFF, 'south': 0x04, 'north': 0x01 }, //0x02
    { 'east': 0xFF, 'west': 0x02, 'south': 0x05, 'north': 0xFF }, //0x03
    { 'east': 0x05, 'west': 0xFF, 'south': 0x07, 'north': 0x02 }, //0x04
    { 'east': 0xFF, 'west': 0x04, 'south': 0x08, 'north': 0x03 }, //0x05
    { 'east': 0x07, 'west': 0x09, 'south': 0x0A, 'north': 0xFF }, //0x06
    { 'east': 0x08, 'west': 0x06, 'south': 0x0B, 'north': 0x04 }, //0x07
    { 'east': 0x09, 'west': 0x07, 'south': 0xFF, 'north': 0x05 }, //0x08
    { 'east': 0x06, 'west': 0x08, 'south': 0x0C, 'north': 0xFF }, //0x09
    { 'east': 0x0B, 'west': 0xFF, 'south': 0x0E, 'north': 0x06 }, //0x0a
    { 'east': 0xFF, 'west': 0x0A, 'south': 0x0F, 'north': 0x07 }, //0x0b
    { 'east': 0xFF, 'west': 0xFF, 'south': 0x11, 'north': 0x09 }, //0x0c
    { 'east': 0x0E, 'west': 0x12, 'south': 0xFF, 'north': 0xFF }, //0x0d
    { 'east': 0x0F, 'west': 0x0D, 'south': 0x13, 'north': 0x0A }, //0x0e
    { 'east': 0x10, 'west': 0x0E, 'south': 0x14, 'north': 0x0B }, //0x0f

    { 'east': 0x11, 'west': 0x0F, 'south': 0x15, 'north': 0xFF }, //0x10
    { 'east': 0x12, 'west': 0x10, 'south': 0xFF, 'north': 0x0C }, //0x11
    { 'east': 0x0D, 'west': 0x11, 'south': 0xFF, 'north': 0xFF }, //0x12
    { 'east': 0x14, 'west': 0xFF, 'south': 0x16, 'north': 0x0E }, //0x13
    { 'east': 0x15, 'west': 0x13, 'south': 0x17, 'north': 0x0F }, //0x14
    { 'east': 0xFF, 'west': 0x14, 'south': 0x18, 'north': 0x10 }, //0x15
    { 'east': 0x17, 'west': 0xFF, 'south': 0xFF, 'north': 0x13 }, //0x16
    { 'east': 0x18, 'west': 0x16, 'south': 0x19, 'north': 0x14 }, //0x17
    { 'east': 0xFF, 'west': 0x17, 'south': 0xFF, 'north': 0x15 }, //0x18
    { 'east': 0xFF, 'west': 0xFF, 'south': 0x1E, 'north': 0x17 }, //0x19
    { 'east': 0x1B, 'west': 0x23, 'south': 0xFF, 'north': 0xFF }, //0x1a
    { 'east': 0x1C, 'west': 0x1A, 'south': 0xFF, 'north': 0xFF }, //0x1b
    { 'east': 0x1D, 'west': 0x1B, 'south': 0x24, 'north': 0xFF }, //0x1c
    { 'east': 0x1E, 'west': 0x1C, 'south': 0xFF, 'north': 0xFF }, //0x1d
    { 'east': 0x1F, 'west': 0x1D, 'south': 0x25, 'north': 0x19 }, //0x1e
    { 'east': 0x20, 'west': 0x1E, 'south': 0x26, 'north': 0xFF }, //0x1f

    { 'east': 0x21, 'west': 0x1F, 'south': 0xFF, 'north': 0xFF }, //0x20
    { 'east': 0x22, 'west': 0x20, 'south': 0xFF, 'north': 0xFF }, //0x21
    { 'east': 0x23, 'west': 0x21, 'south': 0xFF, 'north': 0xFF }, //0x22
    { 'east': 0x1A, 'west': 0x22, 'south': 0xFF, 'north': 0xFF }, //0x23
    { 'east': 0xFF, 'west': 0xFF, 'south': 0x2E, 'north': 0x1C }, //0x24
    { 'east': 0x26, 'west': 0xFF, 'south': 0x30, 'north': 0x1E }, //0x25
    { 'east': 0xFF, 'west': 0x25, 'south': 0x31, 'north': 0x1F }, //0x26
    { 'east': 0x28, 'west': 0xFF, 'south': 0x36, 'north': 0xFF }, //0x27
    { 'east': 0xFF, 'west': 0x27, 'south': 0x37, 'north': 0xFF }, //0x28
    { 'east': 0x2A, 'west': 0x37, 'south': 0xFF, 'north': 0xFF }, //0x29
    { 'east': 0x2B, 'west': 0x29, 'south': 0x38, 'north': 0xFF }, //0x2a
    { 'east': 0x2C, 'west': 0x2A, 'south': 0x39, 'north': 0xFF }, //0x2b
    { 'east': 0x2D, 'west': 0x2B, 'south': 0x3A, 'north': 0xFF }, //0x2c
    { 'east': 0x2E, 'west': 0x2C, 'south': 0x3B, 'north': 0xFF }, //0x2d
    { 'east': 0x2F, 'west': 0x2D, 'south': 0x3C, 'north': 0x24 }, //0x2e
    { 'east': 0x30, 'west': 0x2E, 'south': 0x3D, 'north': 0xFF }, //0x2f

    { 'east': 0x31, 'west': 0x2F, 'south': 0x3E, 'north': 0x25 }, //0x30
    { 'east': 0x32, 'west': 0x30, 'south': 0x3F, 'north': 0x26 }, //0x31
    { 'east': 0x33, 'west': 0x31, 'south': 0x45, 'north': 0xFF }, //0x32
    { 'east': 0x34, 'west': 0x32, 'south': 0x41, 'north': 0xFF }, //0x33
    { 'east': 0x35, 'west': 0x33, 'south': 0x42, 'north': 0xFF }, //0x34
    { 'east': 0x36, 'west': 0x34, 'south': 0x43, 'north': 0xFF }, //0x35
    { 'east': 0x37, 'west': 0x35, 'south': 0x44, 'north': 0x27 }, //0x36
    { 'east': 0x29, 'west': 0x36, 'south': 0xFF, 'north': 0x28 }, //0x37
    { 'east': 0x39, 'west': 0xFF, 'south': 0xFF, 'north': 0x2A }, //0x38
    { 'east': 0x3A, 'west': 0x38, 'south': 0xFF, 'north': 0x2B }, //0x39
    { 'east': 0x3B, 'west': 0x39, 'south': 0xFF, 'north': 0x2C }, //0x3a
    { 'east': 0x3C, 'west': 0x3A, 'south': 0xFF, 'north': 0x2D }, //0x3b
    { 'east': 0x3D, 'west': 0x3B, 'south': 0xFF, 'north': 0x2E }, //0x3c
    { 'east': 0x3E, 'west': 0x3C, 'south': 0xFF, 'north': 0x2F }, //0x3d
    { 'east': 0x3F, 'west': 0x3D, 'south': 0xFF, 'north': 0x30 }, //0x3e
    { 'east': 0x40, 'west': 0x3E, 'south': 0xFF, 'north': 0x31 }, //0x3f

    { 'east': 0x41, 'west': 0x3F, 'south': 0xFF, 'north': 0x32 }, //0x40
    { 'east': 0x42, 'west': 0x40, 'south': 0xFF, 'north': 0x33 }, //0x41
    { 'east': 0x43, 'west': 0x41, 'south': 0xFF, 'north': 0x34 }, //0x42
    { 'east': 0x44, 'west': 0x42, 'south': 0xFF, 'north': 0x35 }, //0x43
    { 'east': 0xFF, 'west': 0x43, 'south': 0xFF, 'north': 0x36 }, //0x44
    { 'east': 0xFF, 'west': 0xFF, 'south': 0xFF, 'north': 0xff } //0x45
];


export const SacredArmourOfAntiriad: React.FC = () => {

    setWindowTitle(`46c - Sacred Armour of Antiriad rooms`);

    const w = 100;
    const h = 100;
    const map = new Array<number>(w * h).fill(-1);

    const seenRooms: number[] = [];
    let xMin = 999;
    let xMax = -999;
    let yMin = 999;
    let yMax = -999;

    const getIndex = (x: number, y: number) => (y * w) + x;
    const plotRooms = (rooms: { index: number, x: number, y: number }[]) => {

        while (rooms.length > 0) {
            // Take first room off list
            const room = rooms.shift();
            if (room == null) { break; }

            // Skip the room if it's ben seen before, otherwise keep track of it
            const { x, y, index } = room;
            if (seenRooms.indexOf(index) !== -1) { continue; }
            seenRooms.push(index);

            // Set it in the map
            map[getIndex(x, y)] = index;

            // Keep track of map bounds
            xMin = Math.min(x, xMin);
            xMax = Math.max(x, xMax);
            yMin = Math.min(y, yMin);
            yMax = Math.max(y, yMax);

            // Look at adjoining rooms if the (a) haven't been seen already and (b) aren't already in the list to investigate
            const roomData = mapData[index];
            if (roomData.south !== 0xff && seenRooms.indexOf(roomData.south) === -1 && rooms.find(r => r.index === roomData.south) == null) { rooms.push({ index: roomData.south, x, y: y + 1 }); }
            if (roomData.north !== 0xff && seenRooms.indexOf(roomData.north) === -1 && rooms.find(r => r.index === roomData.north) == null) { rooms.push({ index: roomData.north, x, y: y - 1 }); }
            if (roomData.west !== 0xff && seenRooms.indexOf(roomData.west) === -1 && rooms.find(r => r.index === roomData.west) == null) { rooms.push({ index: roomData.west, x: x - 1, y }); }
            if (roomData.east !== 0xff && seenRooms.indexOf(roomData.east) === -1 && rooms.find(r => r.index === roomData.east) == null) { rooms.push({ index: roomData.east, x: x + 1, y }); }

            //console.log(`Plotted room ${Utils.to2DigitHexString(index)}, [${rooms.map(r => r.index).join(' ')}]`);
        }
    }

    plotRooms([{ index: 0x3f, x: 50, y: 50 }]);

    const rows = new Array(yMax - yMin + 1).fill(0).map((_, rowIndex) => {
        const row = map.slice((rowIndex + yMin) * w, (rowIndex + yMin + 1) * w);
        const cells = row.slice(xMin, xMax + 1).map((c, i) => <td key={i}>{(c === -1 || c === 0xff) ? <>&nbsp;&nbsp;</> : Utils.to2DigitHexString(c)}</td>)
        return <tr key={rowIndex}>{cells}</tr>
    });

    return (
        <div>
            <h1>Bit-packing notation</h1>
            <p>
                b0 : z<sub>0</sub> r<sub>1</sub>r<sub>0</sub> 00010<br />
                b1 : y<sub>2</sub>y<sub>1</sub>y<sub>0</sub> x<sub>2</sub>x<sub>1</sub>x<sub>0</sub> z<sub>2</sub>z<sub>1</sub><br />
            </p>

            <h1>Antiriad map</h1>
            <table className={styles.testTable}><tbody>{rows}</tbody></table>
        </div>
    );

}