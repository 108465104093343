import { GraphicCommand } from '../commands/GraphicCommand';
import { MemoryLocation } from './MemoryLocation';
import { Range, DataRange, CodeRange, GraphicsRange } from './Range';

export enum LineType {
    Unknown,
    Code,
    Data,
    Graphics
}

export class ByteLineData {

    heightPx = 20;

    constructor(
        readonly type: LineType,
        readonly baseAddress: number,
        readonly count: number,
        readonly isUndefined: boolean,
        readonly offsetPx: number,
        extraHeight: number,
        readonly isSectionStart: boolean,
        readonly isSectionEnd: boolean,
        readonly showRefs: boolean
    ) {
        this.heightPx += extraHeight;
    }
}

export class GraphicsLineData {

    heightPx = 20;

    constructor(
        readonly baseAddress: number,
        readonly count: number,
        readonly offsetPx: number,
        readonly isSectionStart: boolean,
        readonly isSectionEnd: boolean
    ) { }
}


export class CodeLineData {

    heightPx = 20;

    constructor(
        readonly type: LineType,
        readonly baseAddress: number,
        readonly count: number,
        readonly offsetPx: number,
        extraHeight: number,
        readonly isSectionStart: boolean,
        readonly isSectionEnd: boolean
    ) {
        this.heightPx += extraHeight;
    }
}

export class ElipsisLineData {
    heightPx = 20;
    constructor(
        readonly baseAddress: number,
        readonly count: number,
        readonly offsetPx: number
    ) { }
}

export class LabelLineData {

    readonly heightPx: number;


    constructor(
        readonly type: LineType,
        readonly baseAddress: number,
        readonly endAddress: number,
        readonly count: number,
        readonly text: string,
        readonly offsetPx: number,
        readonly isBig: boolean,
        readonly isSectionStart: boolean,
        readonly isSectionEnd: boolean,
        readonly showRefs: boolean
    ) {
        this.heightPx = isBig ? 51 : 30;
    }
}

export class CommentLineData {
    heightPx = 21;
    count = 0;

    constructor(
        readonly type: LineType,
        readonly baseAddress: number,
        readonly text: string,
        readonly offsetPx: number,
        readonly isSectionStart: boolean,
        readonly isSectionEnd: boolean
    ) { }
}

export class GraphicsPreviewLineData {

    type = LineType.Graphics;
    command: GraphicCommand;
    heightPx: number;
    baseAddress: number;
    isSectionStart = false;
    isSectionEnd = false;
    count = 0;

    constructor(
        command: GraphicCommand,
        readonly offsetPx: number
    ) {
        this.command = command;
        this.heightPx = command.heightPx * command.scale + 6;
        this.baseAddress = command.address;
    }
}


export type LineData = CodeLineData | ByteLineData | GraphicsLineData | ElipsisLineData | CommentLineData | GraphicsPreviewLineData;

export const borderWidthPx = 1;
export const marginPx = 3;



export class LineDataManager {

    /** Convert ranges to lines for rendering */
    static makeLineDatas(memory: MemoryLocation[], ranges: Range[], bytesPerLine: number) {

        const allRangesSorted: Range[] = ranges.slice().sort((a, b) => a.StartAddress - b.EndAddress);

        const lineDatas: LineData[] = [];

        let addressCounter = 0x0000;
        let offsetPx = 0;
        allRangesSorted.forEach(r => {

            // Is there any undefined space before this range?
            if (r.StartAddress > addressCounter) {
                offsetPx = this.makeLineDatasForData(LineType.Unknown, memory, new DataRange(`undefined-${r.StartAddress}`, addressCounter, r.StartAddress - 1), bytesPerLine, offsetPx, lineDatas);
            }

            // Make lines for the range itself
            if (r instanceof DataRange)
                offsetPx = this.makeLineDatasForData(LineType.Data, memory, r as DataRange, bytesPerLine, offsetPx, lineDatas);

            if (r instanceof GraphicsRange)
                offsetPx = this.makeLineDatasForData(LineType.Graphics, memory, r as GraphicsRange, bytesPerLine, offsetPx, lineDatas);

            if (r instanceof CodeRange)
                offsetPx = this.makeLineDatasForCodeRange(r as CodeRange, memory, offsetPx, lineDatas);

            addressCounter = r.EndAddress + 0x0001;
        });

        if (addressCounter < 0x10000) {
            offsetPx = this.makeLineDatasForData(LineType.Unknown, memory, new DataRange(`undefined-${addressCounter}`, addressCounter, 0xffff), bytesPerLine, offsetPx, lineDatas);
        }

        return lineDatas;
    }


    /** Convert a data range to lines for rendering */
    private static makeLineDatasForData(type: LineType, memory: MemoryLocation[], range: DataRange | GraphicsRange, bytesPerLine: number, offsetPx: number, lineDatas: LineData[]) {

        const startAddress = range.StartAddress;
        const endAddress = range.EndAddress;
        const isUndefined = (type === LineType.Unknown);

        const hasRefs = (address: number) => memory[address].IncomingPointers.length + memory[address].IncomingReads.length + memory[address].IncomingWrites.length > 0;
        let addressesInRangeWithRefs = 0;
        for (let address = range.StartAddress; address <= range.EndAddress; address++) {
            addressesInRangeWithRefs += hasRefs(address) ? 1 : 0;
        }
        const showRefsInHeading = addressesInRangeWithRefs <= 1 && hasRefs(range.StartAddress);
        // let firstByteRefsShown = false;

        const sectionHeadingText = memory[range.StartAddress].SectionHeading;
        const labelText = memory[range.StartAddress].Label;

        if (sectionHeadingText != null) {
            const sectionHeading = new LabelLineData(type, startAddress, endAddress, 0, sectionHeadingText, offsetPx, true, true, false, showRefsInHeading);
            // firstByteRefsShown = showRefsInHeading;
            lineDatas.push(sectionHeading);
            offsetPx += sectionHeading.heightPx;
        }

        if (type === LineType.Graphics) {
            const preview = new GraphicsPreviewLineData((range as GraphicsRange).Command, offsetPx);
            lineDatas.push(preview);
            offsetPx += preview.heightPx;
        }

        if (labelText != null) {
            const label = new LabelLineData(type, startAddress, endAddress, 0, labelText, offsetPx, false, false, false, !showRefsInHeading);
            lineDatas.push(label);
            offsetPx += label.heightPx;
        }

        const hasComment = memory[range.StartAddress].Comment != null;
        if (hasComment) {
            const commentAddress = range.StartAddress;
            const commentLines = memory[commentAddress].Comment ?? [];
            for (let index = 0; index < commentLines.length; index++) {
                const comment = new CommentLineData(type, commentAddress, commentLines[index], offsetPx, false, false);
                lineDatas.push(comment);
                offsetPx += comment.heightPx;
            }
        }

        // Range bytes
        let indexWithinLine = 0;
        for (let address = startAddress; address <= endAddress; address++) {

            const lineStartAddress = address - indexWithinLine;
            const shouldShowRefs = lineStartAddress > startAddress || !showRefsInHeading;

            const lineShouldWrapAfterThisByte = indexWithinLine === (bytesPerLine - 1);

            const thisByteHasFormatting = memory[address].Formatting !== 'None';
            const nextByteHasLabel = (address < endAddress) && memory[address + 1].Label != null;
            const nextByteHasComment = (address < endAddress) && memory[address + 1].Comment != null;
            const nextByteIsAPointer = (address < endAddress) && memory[address + 1].OutgoingPointer != null;
            const thisByteIsAShortPointer = (memory[address].OutgoingPointer != null) && (memory[address].OutgoingPointer?.part !== '16bit');
            const prevByteIsALongPointer = (address > 0x0000) && memory[address - 1].OutgoingPointer?.part === '16bit';
            const nextByteIsPointedAt = (address < endAddress) && memory[address + 1].IncomingPointers.length > 0;
            const thisByteIsPointedAt = shouldShowRefs && ((address < endAddress) && memory[address].IncomingPointers.length > 0);
            const nextByteHasReadOrWriteRefs = (address < endAddress) && ((memory[address + 1].IncomingReads.length > 0) || (memory[address + 1].IncomingWrites.length > 0));
            const thisByteHasReadOrWriteRefs = shouldShowRefs && ((address < endAddress) && ((memory[address].IncomingReads.length > 0) || (memory[address].IncomingWrites.length > 0)));
            const nextByteHasIncomingFlow = (address < endAddress) && (memory[address + 1].IncomingJSRs.length > 0 || memory[address + 1].IncomingJMPs.length > 0);
            const thisByteHasIncomingFlow = (address < endAddress) && (memory[address].IncomingJSRs.length > 0 || memory[address].IncomingJMPs.length > 0);
            const lineShouldBreak =
                thisByteHasFormatting
                || nextByteHasLabel
                || nextByteHasComment
                || nextByteIsAPointer
                || thisByteIsAShortPointer
                || prevByteIsALongPointer
                || nextByteIsPointedAt
                || thisByteIsPointedAt
                || nextByteHasReadOrWriteRefs
                || thisByteHasReadOrWriteRefs
                || nextByteHasIncomingFlow
                || thisByteHasIncomingFlow
                ;

            const lineShouldEnd = address === endAddress;

            if (lineShouldWrapAfterThisByte || lineShouldBreak || lineShouldEnd) {
                const gapInRange = ['GapAfter', 'ManualGapAfter'].indexOf(memory[address].Formatting) !== -1;
                const extraHeight = (gapInRange || lineShouldEnd) ? 8 : 0;
                const byteLineData = new ByteLineData(type, lineStartAddress, indexWithinLine + 1, isUndefined, offsetPx, extraHeight, false, lineShouldEnd, shouldShowRefs);
                lineDatas.push(byteLineData);
                offsetPx += byteLineData.heightPx;

                if (nextByteHasLabel) {
                    const labelAddress = address + 1;
                    const label = new LabelLineData(type, labelAddress, 0, 0, memory[labelAddress].Label ?? '', offsetPx, false, false, false, true);
                    lineDatas.push(label);
                    offsetPx += label.heightPx;
                }

                if (nextByteHasComment) {
                    const commentAddress = address + 1;
                    const commentLines = memory[commentAddress].Comment ?? [];
                    for (let index = 0; index < commentLines.length; index++) {
                        const comment = new CommentLineData(type, commentAddress, commentLines[index], offsetPx, false, false);
                        lineDatas.push(comment);
                        offsetPx += comment.heightPx;
                    }
                }

                indexWithinLine = 0;
            }
            else {
                indexWithinLine++;
            }
        }

        return offsetPx;
    }

    /** Convert a code range to lines for rendering */
    private static makeLineDatasForCodeRange(range: CodeRange, memory: MemoryLocation[], offsetPx: number, lineDatas: LineData[]) {

        const hasLabel = memory[range.StartAddress].Label != null;
        const hasComment = memory[range.StartAddress].Comment != null;
        const startAddress = range.StartAddress;
        const endAddress = range.EndAddress;

        const isBranchedTo = range.Branches.length > 0;
        const isJMPedTo = range.JMPs.length > 0;
        const isJSRedTo = range.JSRs.length > 0;
        const isFlowedTo = range.Flow != null;

        let needsBigLabel = false;
        let needsSmallLabel = false;

        // What components do we want at the start of the range
        if (!isFlowedTo) {
            if (!isBranchedTo)
                needsBigLabel = true;
            else
                needsSmallLabel = true;
        }
        else {
            if (isBranchedTo || isJMPedTo || isJSRedTo || hasLabel)
                needsSmallLabel = true;
        }

        let needsFirst = true;

        if (needsBigLabel) {
            const labelStr = memory[range.StartAddress].Label ?? '';
            const label = new LabelLineData(LineType.Code, startAddress, startAddress, 0, labelStr, offsetPx, true, needsFirst, false, true)
            needsFirst = false;
            lineDatas.push(label);
            offsetPx += label.heightPx;
        }

        if (needsSmallLabel) {
            const labelStr = memory[range.StartAddress].Label ?? '';
            const label = new LabelLineData(LineType.Code, startAddress, startAddress, 0, labelStr, offsetPx, false, needsFirst, false, needsFirst);
            needsFirst = false;
            lineDatas.push(label);
            offsetPx += label.heightPx;
        }

        if (hasComment) {
            const commentAddress = range.StartAddress;
            const commentLines = memory[commentAddress].Comment ?? [];
            for (let index = 0; index < commentLines.length; index++) {
                const comment = new CommentLineData(LineType.Code, commentAddress, commentLines[index], offsetPx, needsFirst, false);
                needsFirst = false;
                lineDatas.push(comment);
                offsetPx += comment.heightPx;
            }
        }

        // Range code
        let indexWithinLine = 0;
        for (let address = startAddress; address <= endAddress; address++) {

            const lineShouldBreak = memory[address].Formatting !== 'None';
            const nextByteHasLabel = (address < endAddress) && memory[address + 1].Label != null;
            const nextByteHasComment = (address < endAddress) && memory[address + 1].Comment != null;
            const lineShouldEnd = address === endAddress;
            if (lineShouldBreak || lineShouldEnd || nextByteHasLabel || nextByteHasComment) {

                const lineStartAddress = address - indexWithinLine;
                const lineByteLength = indexWithinLine + 1;
                const codeLineData = new CodeLineData(LineType.Code, lineStartAddress, lineByteLength, offsetPx, (needsFirst ? 8 : 0) + (lineShouldEnd ? 8 : 0), needsFirst, lineShouldEnd);

                lineDatas.push(codeLineData);
                offsetPx += codeLineData.heightPx;

                needsFirst = lineShouldEnd;
                indexWithinLine = 0;

                if (nextByteHasLabel) {
                    const labelAddress = address + 1;
                    const labelStr = memory[labelAddress].Label ?? '';
                    const label = new LabelLineData(LineType.Code, labelAddress, labelAddress, 0, labelStr, offsetPx, false, needsFirst, false, true);
                    needsFirst = false;
                    lineDatas.push(label);
                    offsetPx += label.heightPx;
                }

                if (nextByteHasComment) {
                    const commentAddress = address + 1;
                    const commentLines = memory[commentAddress].Comment ?? [];
                    for (let index = 0; index < commentLines.length; index++) {
                        const comment = new CommentLineData(LineType.Code, commentAddress, commentLines[index], offsetPx, needsFirst, false);
                        needsFirst = false;
                        lineDatas.push(comment);
                        offsetPx += comment.heightPx;
                    }
                }

            }
            else {
                indexWithinLine++;
            }
        }

        return offsetPx;
    }

}