import { scaleSet } from "../../store/graphicsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { EnumSetting } from "./EnumSetting";
import { SettingsRow } from "./SettingsRow";


export const ScaleSettings: React.FC = () => {

    const settings = useAppSelector(state => state.graphics);
    const dispatch = useAppDispatch();

    const scale = settings.ViewLayout === 'CharacterMapped' ? settings.CharacterMappedScale : (
        settings.ViewLayout === 'Interleaved' ? settings.InterleavedScale : (
            settings.ViewLayout === 'Continuous' ? settings.ContinuousScale : (
                settings.CharacterMappedScale
            )
        )
    );

    return (
        <SettingsRow label='Scale'>
            <EnumSetting
                label="scale"
                selectedOption={scale}
                options={{ '1x': 1, '2x': 2, '3x': 3, '4x': 4 }}
                order={['1x', '2x', '3x', '4x']}
                onChange={scale => dispatch(scaleSet({ layout: settings.ViewLayout, scale }))}
            />
        </SettingsRow>
    );
}