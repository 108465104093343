import { logInfo, logWarning } from "./Logger";
import { Utils } from "./Utils"

export type SpriteSnapshot = {
    address: number;
    enabled: boolean;
    expandedX: boolean;
    expandedY: boolean;
    multiColour: boolean;
    background00Index: number;
    foreground01Index: number;
    foreground1Or10Index: number;
    foreground11Index: number;
}

export type GraphicsSnapshot = {
    graphicsType: SnapshotGraphicMode;
    screenAddress: number;
    charsetAddress: number;
    bitmapAddress: number;
    background00ColourIndex: number;
    foreground01ColourIndex: number;
    foreground10ColourIndex: number;
    sprites: SpriteSnapshot[];
}

export type CPUSnapshot = {
    programCounter: number;
    stackPointer: number;
    negativeFlag: boolean;
    overflowFlag: boolean;
    breakFlag: boolean;
    decimalModeFlag: boolean;
    irqDisableFlag: boolean;
    zeroFlag: boolean;
    carryFlag: boolean;
    aRegister: number;
    xRegister: number;
    yRegister: number;
}

export type SnapshotGraphicMode =
    "Unknown"
    | "CharacterMapped" | "CharacterMappedMultiColour"
    | "Bitmapped" | "BitmappedMultiColour"
    | "ExtendedColour";

type Memory = {
    RAM: Uint8Array;
}

type VICII = {
    colourRAM: Uint8Array;
    registers: Uint8Array;
}

type CIA = {
    PRA: number;
}

export class SnapshotLoader {

    // Read from eg here : https://sourceforge.net/p/vice-emu/code/HEAD/tree/trunk/vice/src/vicii/
    // Version diffs : https://sourceforge.net/p/vice-emu/code/31087/tree//trunk/vice/src/c64/c64memsnapshot.c?diff=51af4689e88f3d01589f7c22:31086

    currentPos: number = 0;
    dataView: DataView = new DataView(new ArrayBuffer(0));


    LoadFromBytes(bytes: Uint8Array) {

        this.currentPos = 0;
        this.dataView = new DataView(bytes.buffer);

        // Read the snapshot file header
        const { machineName, snapshotVersionMajor, snapshotVersionMinor } = this.ReadSnapshotHeader();
        logInfo("Loading " + machineName + " snapshort, version " + snapshotVersionMajor + "." + snapshotVersionMinor);

        // Try to load the snapshot version info (only in newer snapshot files)
        let snapshotVersion = this.ReadSnapshotVersion();
        if (snapshotVersion != null) {
            logInfo(`Snapshot from Vice version ${snapshotVersion.version0}.${snapshotVersion.version1}.${snapshotVersion.version2}.${snapshotVersion.version3}, revision ${snapshotVersion.revision}`);
        }

        /*
            Modules in a typical snapshot :
            C64, MAINCPU, C64MEM, C64CART, CIA1, CIA2, SID, SIDEXTENDED, DRIVE, DRIVECPU0
            1541VIA1D0, VIA2D0, VIC-II, GLUE, TAPE, DATASETTE, KEYBOARD, JOYSTICK
        */
        let cpu: CPUSnapshot | undefined;
        let mem: Memory | undefined;
        let vicii: VICII | undefined;
        let cia2: CIA | undefined;

        // Step through the modules within the snapshot looking for the ones we want
        while (this.currentPos < bytes.byteLength) {

            // Read module header
            let moduleHeaderStartPos = this.currentPos;
            const { size, moduleName, vMajor, vMinor } = this.ReadModuleHeader();

            // Calculate expected section lengths for sanity checking
            let moduleHeaderLength = this.currentPos - moduleHeaderStartPos;
            let moduleLength = size - moduleHeaderLength;
            let nextModuleStartPos = moduleHeaderStartPos + size;

            // Read module
            let moduleRead = true;
            const versionedName = `${machineName}_${moduleName}_${vMajor}.${vMinor}`;
            switch (versionedName) {
                case 'C64_C64MEM_0.0':
                case 'C64SC_C64MEM_0.0':
                    mem = this.ReadC64MemModule_0_0();
                    break;

                case 'C64_C64MEM_0.1':
                case 'C64SC_C64MEM_0.1':
                    mem = this.ReadC64MemModule_0_1();
                    break;

                case 'C64_VIC-II_1.1':
                    vicii = this.ReadC64VicIIModule_1_1();
                    break;

                case 'C64SC_VIC-II_1.1':
                    vicii = this.ReadC64SCVicIIModule_1_1();
                    break;

                case 'C64SC_VIC-II_1.3':
                    vicii = this.ReadC64SCVicIIModule_1_3();
                    break;

                case 'C64_MAINCPU_1.1':
                    cpu = this.ReadC64MainCPUModule_1_1();
                    break;

                case 'C64SC_MAINCPU_1.1':
                    cpu = this.ReadC64SCMainCPUModule_1_1();
                    break;

                case 'C64SC_MAINCPU_1.2':
                    cpu = this.ReadC64SCMainCPUModule_1_2();
                    break;

                case 'C64_CIA2_2.2':
                case 'C64SC_CIA2_2.2':
                    cia2 = this.ReadCIA_2_2();
                    break;

                case 'C64SC_CIA2_2.5':
                    cia2 = this.ReadCIA_2_5();
                    break;

                default:
                    moduleRead = false;
                    break;
            }

            // Report back on success/failure and sanity check position in file
            if (moduleRead) {
                logInfo(`Loaded ${machineName} module '${moduleName}', version ${vMajor}.${vMinor} (${moduleLength} bytes)`);

                // Are we where we expect to be in the file?
                let offset = nextModuleStartPos - this.currentPos;
                if (offset < 0)
                    logWarning(`Module '${moduleName}' was ${-offset} bytes longer than expected (${moduleLength})`);
                if (offset > 0)
                    logWarning(`Module '${moduleName}' was ${offset} bytes shorter than expected (${moduleLength})`);
            }
            else
                logWarning(`Couldn't load ${machineName} module '${moduleName}', version ${vMajor}.${vMinor} (${moduleLength} bytes)`);

            // Put us in the correct place regardless of what we read from the stream
            this.currentPos = nextModuleStartPos;
        }

        if ((cpu == null) || (mem == null) || (vicii == null) || (cia2 == null)) {
            logWarning(`Couldn't load enough of the snapshot to proceed`);
            return undefined;
        }

        const { RAM } = mem;
        const COLRAM = vicii.colourRAM.slice(0, 0x03e8);

        const vicBankAddress = (3 - cia2.PRA & 0b00000011) * 0x4000;
        const graphics = this.getGraphicsSnapshot(RAM, vicii.registers, vicBankAddress);

        logInfo(`CPU : PC=$${Utils.to4DigitHexString(cpu.programCounter)}, SP=$${Utils.to4DigitHexString(cpu.stackPointer)}, A=$${Utils.to2DigitHexString(cpu.aRegister)}, X=$${Utils.to2DigitHexString(cpu.xRegister)}, Y=$${Utils.to2DigitHexString(cpu.yRegister)}`);

        return { RAM, COLRAM, graphics, cpu };
    }

    getGraphicsSnapshot(RAM: Uint8Array, REGISTERS: Uint8Array, baseAddress: number) {
        // https://www.c64-wiki.com/wiki/Graphics_Modes

        const d011 = REGISTERS[0x11];
        const d016 = REGISTERS[0x16];
        const ecm = (d011 & 0b01000000) !== 0;
        const bmm = (d011 & 0b00100000) !== 0;
        const mcm = (d016 & 0b00010000) !== 0;

        // https://codebase64.org/doku.php?id=base:vicii_memory_organizing
        const d018 = REGISTERS[0x18];
        const screenAddress = baseAddress + ((d018 >> 4) * 0x0400);
        const charsetAddress = baseAddress + (((d018 & 0b00001110) >> 1) * 0x0800);
        const bitmapAddress = baseAddress + (((d018 & 0b00001000) >> 3) * 0x2000);

        let graphicsType: SnapshotGraphicMode;
        if (!ecm && !bmm && !mcm) {
            graphicsType = "CharacterMapped";
        } else if (!ecm && !bmm && mcm) {
            graphicsType = "CharacterMappedMultiColour";
        } else if (!ecm && bmm && !mcm) {
            graphicsType = "Bitmapped";
        } else if (!ecm && bmm && mcm) {
            graphicsType = "BitmappedMultiColour"
        } else if (ecm && !bmm && !mcm) {
            graphicsType = "ExtendedColour"
        } else {
            graphicsType = "Unknown"
        }

        const sprites: SpriteSnapshot[] = [];
        for (let spriteIndex = 0; spriteIndex <= 7; spriteIndex++) {
            sprites.push({
                address: baseAddress + (RAM[screenAddress + 0x03f8 + spriteIndex] * 0x40),
                enabled: Utils.testBitZeroIndexed(REGISTERS[0x15], spriteIndex),
                expandedX: Utils.testBitZeroIndexed(REGISTERS[0x1d], spriteIndex),
                expandedY: Utils.testBitZeroIndexed(REGISTERS[0x17], spriteIndex),
                multiColour: Utils.testBitZeroIndexed(REGISTERS[0x1C], spriteIndex),
                background00Index: REGISTERS[0x21] & 0x0f,
                foreground01Index: REGISTERS[0x25] & 0x0f,
                foreground11Index: REGISTERS[0x26] & 0x0f,
                foreground1Or10Index: REGISTERS[0x27 + spriteIndex] & 0x0f
            });
        }

        const background00ColourIndex = REGISTERS[0x21] & 0x0f;
        const foreground01ColourIndex = REGISTERS[0x22] & 0x0f;
        const foreground10ColourIndex = REGISTERS[0x23] & 0x0f;

        const graphicSnapshot: GraphicsSnapshot = { graphicsType, screenAddress, charsetAddress, bitmapAddress, sprites, background00ColourIndex, foreground01ColourIndex, foreground10ColourIndex };

        return graphicSnapshot;
    }

    ReadString(length: number) {

        let finalString = '';

        for (let i = 0; i < length; i++) {
            const charCode = this.dataView.getUint8(this.currentPos);
            this.currentPos++;

            if (charCode !== 0 && charCode !== 0x1a)
                finalString += String.fromCharCode(charCode);
        }

        return finalString;
    }


    ReadByte() {
        const b = this.dataView.getUint8(this.currentPos);
        this.currentPos++;
        return b;
    }


    ReadBytes(count: number): Uint8Array {
        const b = new Uint8Array(this.dataView.buffer.slice(this.currentPos, this.currentPos + count));
        this.currentPos += count;
        return b;
    }


    ReadWord() {
        const bLow = this.ReadByte();
        const bHigh = this.ReadByte();

        const w = (bHigh * 0x100) + bLow;

        return w;
    }


    ReadDWord() {
        const wLow = this.ReadWord();
        const wHigh = this.ReadWord();

        const dw = (wHigh * 0x10000) + wLow;

        return dw;
    }


    ReadDouble() {
        // WRONG
        const dwLow = this.ReadDWord();
        const dwHigh = this.ReadDWord();

        const dw = (dwHigh * 0x100000000) + dwLow;

        return dw;
    }


    ReadClock() {
        const dwLow = this.ReadDWord();
        const dwHigh = this.ReadDWord();

        const dw = (dwHigh * 0x100000000) + dwLow;

        return dw;
    }


    ReadModuleHeader() {

        return {
            moduleName: this.ReadString(16),
            vMajor: this.ReadByte(),
            vMinor: this.ReadByte(),
            size: this.ReadDWord()
        };
    }


    ReadSnapshotHeader() {

        return {
            magicString: this.ReadString(19),
            snapshotVersionMajor: this.ReadByte(),
            snapshotVersionMinor: this.ReadByte(),
            machineName: this.ReadString(16)
        };
    }


    ReadSnapshotVersion() {

        const currentPos = this.currentPos;
        const magicString = this.ReadString(13);
        if (!magicString.startsWith('VICE Version')) {
            this.currentPos = currentPos;
            return null;
        }

        return {
            magicString: magicString,
            version0: this.ReadByte(),
            version1: this.ReadByte(),
            version2: this.ReadByte(),
            version3: this.ReadByte(),
            revision: this.ReadDWord()
        };
    }


    ReadC64MainCPUModule_1_1() {
        // vice/src/maincpu.c -> maincpu_snapshot_write_module()
        this.ReadDWord(); // CLK
        const aRegister = this.ReadByte(); // AC
        const xRegister = this.ReadByte(); // XR
        const yRegister = this.ReadByte(); // YR
        const stackPointer = this.ReadByte(); // SP
        const programCounter = this.ReadWord(); // PC
        const status = this.ReadByte(); // ST
        this.ReadDWord(); // LASTOPCODE
        this.ReadDWord(); // IRQCLK
        this.ReadDWord(); // NMICLK
        this.ReadDWord(); // IRQPENDINGCLK
        this.ReadDWord(); // NUMLASTSTOLENCYCLES
        this.ReadDWord(); // LASTSTOLENCYCLESCLK
        this.ReadDWord(); // NIRQ
        this.ReadDWord(); // NNMI
        this.ReadDWord(); // GLOBALPENDINGINT

        const cpu: CPUSnapshot = {
            programCounter,
            stackPointer,
            negativeFlag: Utils.testBitZeroIndexed(status, 7),
            overflowFlag: Utils.testBitZeroIndexed(status, 6),
            breakFlag: Utils.testBitZeroIndexed(status, 4),
            decimalModeFlag: Utils.testBitZeroIndexed(status, 3),
            irqDisableFlag: Utils.testBitZeroIndexed(status, 2),
            zeroFlag: Utils.testBitZeroIndexed(status, 1),
            carryFlag: Utils.testBitZeroIndexed(status, 0),
            aRegister,
            xRegister,
            yRegister
        }

        return cpu;
    }

    ReadC64SCMainCPUModule_1_1() {
        // vice/src/mainc64cpu.c -> maincpu_snapshot_write_module()
        this.ReadDWord(); // CLK
        const aRegister = this.ReadByte(); // AC
        const xRegister = this.ReadByte(); // XR
        const yRegister = this.ReadByte(); // YR
        const stackPointer = this.ReadByte(); // SP
        const programCounter = this.ReadWord(); // PC
        const status = this.ReadByte(); // ST
        this.ReadDWord(); // LASTOPCODE
        this.ReadDWord(); // BALOWFLAGS
        this.ReadDWord(); // IRQCLK
        this.ReadDWord(); // NMICLK
        this.ReadDWord(); // IRQPENDINGCLK
        this.ReadDWord(); // NUMLASTSTOLENCYCLES
        this.ReadDWord(); // LASTSTOLENCYCLESCLK
        this.ReadDWord(); // NIRQ
        this.ReadDWord(); // NNMI
        this.ReadDWord(); // GLOBALPENDINGINT
        this.ReadDWord(); // IRQDELAYCYCLES
        this.ReadDWord(); // NMIDELAYCYCLES

        const cpu: CPUSnapshot = {
            programCounter,
            stackPointer,
            negativeFlag: Utils.testBitZeroIndexed(status, 7),
            overflowFlag: Utils.testBitZeroIndexed(status, 6),
            breakFlag: Utils.testBitZeroIndexed(status, 4),
            decimalModeFlag: Utils.testBitZeroIndexed(status, 3),
            irqDisableFlag: Utils.testBitZeroIndexed(status, 2),
            zeroFlag: Utils.testBitZeroIndexed(status, 1),
            carryFlag: Utils.testBitZeroIndexed(status, 0),
            aRegister,
            xRegister,
            yRegister
        }

        return cpu;
    }

    ReadC64SCMainCPUModule_1_2() {
        // vice/src/mainc64cpu.c -> maincpu_snapshot_write_module()
        this.ReadClock(); // CLK
        const aRegister = this.ReadByte(); // AC
        const xRegister = this.ReadByte(); // XR
        const yRegister = this.ReadByte(); // YR
        const stackPointer = this.ReadByte(); // SP
        const programCounter = this.ReadWord(); // PC
        const status = this.ReadByte(); // ST
        this.ReadDWord(); // LASTOPCODE
        this.ReadDWord(); // maincpu_ba_low_flags

        this.ReadClock(); // IRQCLK
        this.ReadClock(); // NMICLK
        this.ReadClock(); // IRQPENDINGCLK
        this.ReadClock(); // NUMLASTSTOLENCYCLES
        this.ReadClock(); // LASTSTOLENCYCLESCLK

        this.ReadDWord(); // NIRQ
        this.ReadDWord(); // NNMI
        this.ReadDWord(); // GLOBALPENDINGINT

        this.ReadClock(); // IRQDELAYCYCLES
        this.ReadClock(); // NMIDELAYCYCLES

        const cpu: CPUSnapshot = {
            programCounter,
            stackPointer,
            negativeFlag: Utils.testBitZeroIndexed(status, 7),
            overflowFlag: Utils.testBitZeroIndexed(status, 6),
            breakFlag: Utils.testBitZeroIndexed(status, 4),
            decimalModeFlag: Utils.testBitZeroIndexed(status, 3),
            irqDisableFlag: Utils.testBitZeroIndexed(status, 2),
            zeroFlag: Utils.testBitZeroIndexed(status, 1),
            carryFlag: Utils.testBitZeroIndexed(status, 0),
            aRegister,
            xRegister,
            yRegister
        }

        return cpu;
    }


    ReadC64MemModule_0_0() {

        this.ReadByte(); // PPORTDATA
        this.ReadByte(); // PPORTDIR
        this.ReadByte(); // EXPORTEXROM
        this.ReadByte(); // EXPORTGAME
        const RAM = this.ReadBytes(0x10000);
        this.ReadByte(); // PPORTDATAOUT
        this.ReadByte(); // PPORTDATAREAD
        this.ReadByte(); // PPORTDIRREAD

        const memory: Memory = {
            RAM
        }

        return memory;
    }


    ReadC64MemModule_0_1() {
        this.ReadByte(); // PPORTDATA
        this.ReadByte(); // PPORTDIR
        this.ReadByte(); // EXPORTEXROM
        this.ReadByte(); // EXPORTGAME
        const RAM = this.ReadBytes(0x10000);
        this.ReadByte(); // PPORTDATAOUT
        this.ReadByte(); // PPORTDATAREAD
        this.ReadByte(); // PPORTDIRREAD
        this.ReadDWord(); // PPORTDATASETCLKBIT6
        this.ReadDWord(); // PPORTDATASETCLKBIT7
        this.ReadByte(); // PPORTDATASETBIT6
        this.ReadByte(); // PPORTDATASETBIT7
        this.ReadByte(); // PPORTDATAFALLOFFBIT6
        this.ReadByte(); // PPORTDATAFALLOFFBIT7

        const memory: Memory = {
            RAM
        }

        return memory;
    }


    ReadC64VicIIModule_1_1() {
        // vice/src/vicii/vicii-snapshot.c -> vicii_snapshot_write_module()
        this.ReadByte(); // ALLOWBADLINES
        this.ReadByte(); // BADLINE
        this.ReadByte(); // BLANK
        this.ReadBytes(0x0028); // COLORBUF
        const colourRAM = this.ReadBytes(0x0400); // COLORRAM
        this.ReadByte(); // IDLESTATE
        this.ReadByte(); // LPTRIGGER
        this.ReadByte(); // LPX
        this.ReadByte(); // LPY
        this.ReadBytes(0x0028); // MATRIXBUF
        this.ReadByte(); // NEWSPRITEDMAMASK
        this.ReadDWord(); // RAMBASE
        this.ReadByte(); // RASTERCYCLE
        this.ReadWord(); // RASTERLINE
        const registers = this.ReadBytes(0x0040); // REGISTERS
        this.ReadByte(); // SBCOLLMASK
        this.ReadByte(); // SPRITEDMAMASK
        this.ReadByte(); // SSCOLLMASK
        this.ReadWord(); // VBANK
        this.ReadWord(); // VC
        this.ReadByte(); // VCADD
        this.ReadWord(); // VCBASE
        this.ReadByte(); // VIDEOINT
        this.ReadByte(); // SPRITE0MEMPTR
        this.ReadByte(); // SPRITE0MEMPTRINC
        this.ReadByte(); // SPRITE0EXPFLIPFLOP
        this.ReadByte(); // SPRITE1MEMPTR
        this.ReadByte(); // SPRITE1MEMPTRINC
        this.ReadByte(); // SPRITE1EXPFLIPFLOP
        this.ReadByte(); // SPRITE2MEMPTR
        this.ReadByte(); // SPRITE2MEMPTRINC
        this.ReadByte(); // SPRITE2EXPFLIPFLOP
        this.ReadByte(); // SPRITE3MEMPTR
        this.ReadByte(); // SPRITE3MEMPTRINC
        this.ReadByte(); // SPRITE3EXPFLIPFLOP
        this.ReadByte(); // SPRITE4MEMPTR
        this.ReadByte(); // SPRITE4MEMPTRINC
        this.ReadByte(); // SPRITE4EXPFLIPFLOP
        this.ReadByte(); // SPRITE5MEMPTR
        this.ReadByte(); // SPRITE5MEMPTRINC
        this.ReadByte(); // SPRITE5EXPFLIPFLOP
        this.ReadByte(); // SPRITE6MEMPTR
        this.ReadByte(); // SPRITE6MEMPTRINC
        this.ReadByte(); // SPRITE6EXPFLIPFLOP
        this.ReadByte(); // SPRITE7MEMPTR
        this.ReadByte(); // SPRITE7MEMPTRINC
        this.ReadByte(); // SPRITE7EXPFLIPFLOP
        this.ReadDWord(); // FETCHEVENTTICK
        this.ReadByte(); // FETCHEVENTTYPE
        this.ReadDWord(); // RAMBASEPHI2
        this.ReadWord(); // VBANKPHI2

        const vicii: VICII = {
            colourRAM,
            registers
        }

        return vicii;
    }

    ReadC64SCVicIIModule_1_1() {
        // vice/src/viciisc/vicii-snapshot.c -> vicii_snapshot_write_module()
        this.ReadByte(); // MODEL
        const registers = this.ReadBytes(0x40); // REGISTERS
        this.ReadDWord(); // RASTERCYCLE
        this.ReadDWord(); // CYCLEFLAGS
        this.ReadDWord(); // RASTERLINE
        this.ReadByte(); // STARTOFFRAME
        this.ReadByte(); // VIDEOINT
        this.ReadDWord(); // RASTERIRQLINE
        this.ReadByte(); // RASTERIRQTRIGGERED
        this.ReadBytes(0x0028); // MATRIXBUF
        this.ReadBytes(0x0028); // COLORBUF
        this.ReadByte(); // GBUF
        this.ReadDWord(); // DBUFOFFSET
        this.ReadBytes(65 * 8); // DBUF
        this.ReadDWord(); // YSMOOTH
        this.ReadByte(); // ALLOWBADLINES
        this.ReadByte(); // SSCOLLMASK
        this.ReadByte(); // SBCOLLMASK
        this.ReadByte(); // CLEARCOLL
        this.ReadDWord(); // IDLESTATE
        this.ReadDWord(); // VCBASE
        this.ReadDWord(); // VC
        this.ReadDWord(); // RC
        this.ReadDWord(); // VMLI
        this.ReadDWord(); // BADLINE
        this.ReadByte(); // LPSTATE
        this.ReadByte(); // LPTRIGGER
        this.ReadDWord(); // LPX
        this.ReadDWord(); // LPY
        this.ReadDWord(); // LPXEXTRA
        this.ReadDWord(); // LPTRIGGERCYCLE
        this.ReadByte(); // REG11DELAY
        this.ReadDWord(); // PREFETCHCYCLES
        this.ReadDWord(); // SPRITEDISPLAYBITS
        this.ReadByte(); // SPRITEDMA
        this.ReadByte(); // LASTCOLOURREG
        this.ReadByte(); // LASTCOLOURVALUE
        this.ReadByte(); // LASTREADPHI1
        this.ReadByte(); // LASTBUSPHI2
        this.ReadByte(); // VBORDER
        this.ReadByte(); // SETBORDER
        this.ReadByte(); // MAINBORDER
        this.ReadByte(); // REFRESHCOUNTER
        const colourRAM = this.ReadBytes(0x0400); // COLORRAM
        this.ReadDWord(); // SPRITE0DATA
        this.ReadByte(); // SPRITE0MC
        this.ReadByte(); // SPRITE0MCBASE
        this.ReadByte(); // SPRITE0POINTER
        this.ReadByte(); // SPRITE0EXPFLIPFLOP
        this.ReadDWord(); // SPRITE0X
        this.ReadDWord(); // SPRITE1DATA
        this.ReadByte(); // SPRITE1MC
        this.ReadByte(); // SPRITE1MCBASE
        this.ReadByte(); // SPRITE1POINTER
        this.ReadByte(); // SPRITE1EXPFLIPFLOP
        this.ReadDWord(); // SPRITE1X
        this.ReadDWord(); // SPRITE2DATA
        this.ReadByte(); // SPRITE2MC
        this.ReadByte(); // SPRITE2MCBASE
        this.ReadByte(); // SPRITE2POINTER
        this.ReadByte(); // SPRITE2EXPFLIPFLOP
        this.ReadDWord(); // SPRITE2X
        this.ReadDWord(); // SPRITE3DATA
        this.ReadByte(); // SPRITE3MC
        this.ReadByte(); // SPRITE3MCBASE
        this.ReadByte(); // SPRITE3POINTER
        this.ReadByte(); // SPRITE3EXPFLIPFLOP
        this.ReadDWord(); // SPRITE3X
        this.ReadDWord(); // SPRITE4DATA
        this.ReadByte(); // SPRITE4MC
        this.ReadByte(); // SPRITE4MCBASE
        this.ReadByte(); // SPRITE4POINTER
        this.ReadByte(); // SPRITE4EXPFLIPFLOP
        this.ReadDWord(); // SPRITE4X
        this.ReadDWord(); // SPRITE5DATA
        this.ReadByte(); // SPRITE5MC
        this.ReadByte(); // SPRITE5MCBASE
        this.ReadByte(); // SPRITE5POINTER
        this.ReadByte(); // SPRITE5EXPFLIPFLOP
        this.ReadDWord(); // SPRITE5X
        this.ReadDWord(); // SPRITE6DATA
        this.ReadByte(); // SPRITE6MC
        this.ReadByte(); // SPRITE6MCBASE
        this.ReadByte(); // SPRITE6POINTER
        this.ReadByte(); // SPRITE6EXPFLIPFLOP
        this.ReadDWord(); // SPRITE6X
        this.ReadDWord(); // SPRITE7DATA
        this.ReadByte(); // SPRITE7MC
        this.ReadByte(); // SPRITE7MCBASE
        this.ReadByte(); // SPRITE7POINTER
        this.ReadByte(); // SPRITE7EXPFLIPFLOP
        this.ReadDWord(); // SPRITE7X
        this.ReadBytes(174); // MISC

        const vicii: VICII = {
            colourRAM,
            registers
        }

        return vicii;
    }

    ReadC64SCVicIIModule_1_3() {
        // vice/src/viciisc/vicii-snapshot.c -> vicii_snapshot_write_module()
        this.ReadByte(); // MODEL
        const registers = this.ReadBytes(0x40); // REGISTERS
        this.ReadDWord(); // RASTERCYCLE
        this.ReadDWord(); // CYCLEFLAGS
        this.ReadDWord(); // RASTERLINE
        this.ReadByte(); // STARTOFFRAME
        this.ReadByte(); // RASTER_IRQ_STATUS
        this.ReadDWord(); // RASTERIRQLINE
        this.ReadByte(); // RASTERIRQTRIGGERED
        this.ReadBytes(0x0028); // MATRIXBUF
        this.ReadBytes(0x0028); // COLORBUF
        this.ReadByte(); // GBUF
        this.ReadDWord(); // DBUFOFFSET
        this.ReadBytes(65 * 8); // DBUF
        this.ReadDWord(); // YSMOOTH
        this.ReadByte(); // ALLOWBADLINES
        this.ReadByte(); // SSCOLLMASK
        this.ReadByte(); // SBCOLLMASK
        this.ReadByte(); // CLEARCOLL
        this.ReadDWord(); // IDLESTATE
        this.ReadDWord(); // VCBASE
        this.ReadDWord(); // VC
        this.ReadDWord(); // RC
        this.ReadDWord(); // VMLI
        this.ReadDWord(); // BADLINE
        this.ReadByte(); // LPSTATE
        this.ReadByte(); // LPTRIGGER
        this.ReadDWord(); // LPX
        this.ReadDWord(); // LPY
        this.ReadDWord(); // LPXEXTRA
        this.ReadClock(); // LPTRIGGERCYCLE

        this.ReadByte(); // REG11DELAY
        this.ReadDWord(); // PREFETCHCYCLES
        this.ReadDWord(); // SPRITEDISPLAYBITS
        this.ReadByte(); // SPRITEDMA

        this.ReadByte(); // LASTCOLOURREG
        this.ReadByte(); // LASTCOLOURVALUE
        this.ReadByte(); // LASTREADPHI1
        this.ReadByte(); // LASTBUSPHI2
        this.ReadByte(); // VBORDER
        this.ReadByte(); // SETBORDER
        this.ReadByte(); // MAINBORDER
        this.ReadByte(); // REFRESHCOUNTER
        const colourRAM = this.ReadBytes(0x0400); // COLORRAM

        this.ReadDWord(); // SPRITE0DATA
        this.ReadByte(); // SPRITE0MC
        this.ReadByte(); // SPRITE0MCBASE
        this.ReadByte(); // SPRITE0POINTER
        this.ReadByte(); // SPRITE0EXPFLIPFLOP
        this.ReadDWord(); // SPRITE0X
        this.ReadDWord(); // SPRITE1DATA
        this.ReadByte(); // SPRITE1MC
        this.ReadByte(); // SPRITE1MCBASE
        this.ReadByte(); // SPRITE1POINTER
        this.ReadByte(); // SPRITE1EXPFLIPFLOP
        this.ReadDWord(); // SPRITE1X
        this.ReadDWord(); // SPRITE2DATA
        this.ReadByte(); // SPRITE2MC
        this.ReadByte(); // SPRITE2MCBASE
        this.ReadByte(); // SPRITE2POINTER
        this.ReadByte(); // SPRITE2EXPFLIPFLOP
        this.ReadDWord(); // SPRITE2X
        this.ReadDWord(); // SPRITE3DATA
        this.ReadByte(); // SPRITE3MC
        this.ReadByte(); // SPRITE3MCBASE
        this.ReadByte(); // SPRITE3POINTER
        this.ReadByte(); // SPRITE3EXPFLIPFLOP
        this.ReadDWord(); // SPRITE3X
        this.ReadDWord(); // SPRITE4DATA
        this.ReadByte(); // SPRITE4MC
        this.ReadByte(); // SPRITE4MCBASE
        this.ReadByte(); // SPRITE4POINTER
        this.ReadByte(); // SPRITE4EXPFLIPFLOP
        this.ReadDWord(); // SPRITE4X
        this.ReadDWord(); // SPRITE5DATA
        this.ReadByte(); // SPRITE5MC
        this.ReadByte(); // SPRITE5MCBASE
        this.ReadByte(); // SPRITE5POINTER
        this.ReadByte(); // SPRITE5EXPFLIPFLOP
        this.ReadDWord(); // SPRITE5X
        this.ReadDWord(); // SPRITE6DATA
        this.ReadByte(); // SPRITE6MC
        this.ReadByte(); // SPRITE6MCBASE
        this.ReadByte(); // SPRITE6POINTER
        this.ReadByte(); // SPRITE6EXPFLIPFLOP
        this.ReadDWord(); // SPRITE6X
        this.ReadDWord(); // SPRITE7DATA
        this.ReadByte(); // SPRITE7MC
        this.ReadByte(); // SPRITE7MCBASE
        this.ReadByte(); // SPRITE7POINTER
        this.ReadByte(); // SPRITE7EXPFLIPFLOP
        this.ReadDWord(); // SPRITE7X

        this.ReadBytes(16 + 4 + 6 + 4 + 8 + 3 + 8 + 8 + 8 + 0x2f + 2 + 4); // cycle snapshot

        this.ReadBytes(16 + 0xed1a + 0xed1a + 4);


        const vicii: VICII = {
            colourRAM,
            registers
        }

        return vicii;
    }
    ReadCIA_2_2() {

        // https://www.c64-wiki.com/wiki/CIA
        const PRA = this.ReadByte(); // PRA
        this.ReadByte(); // PRB
        this.ReadByte(); // DDRA
        this.ReadByte(); // DDRB
        this.ReadWord(); // TA
        this.ReadWord(); // TB
        this.ReadByte(); // TODTEN
        this.ReadByte(); // TODSEC
        this.ReadByte(); // TODMIN
        this.ReadByte(); // TODHR
        this.ReadByte(); // SDR
        this.ReadByte(); // ICR
        this.ReadByte(); // CRA
        this.ReadByte(); // CRB
        this.ReadWord(); // LATCHA
        this.ReadWord(); // LATCHB
        this.ReadByte(); // ICRPEEK
        this.ReadByte(); // DATA
        this.ReadByte(); // SRBITS
        this.ReadByte(); // TODALARM0
        this.ReadByte(); // TODALARM1
        this.ReadByte(); // TODALARM2
        this.ReadByte(); // TODALARM3
        this.ReadByte(); // UNKNOWN1
        this.ReadByte(); // UNKNOWN2
        this.ReadByte(); // TODLATCH0
        this.ReadByte(); // TODLATCH1
        this.ReadByte(); // TODLATCH2
        this.ReadByte(); // TODLATCH3
        this.ReadDWord(); // CLKPTR
        this.ReadWord(); // TIMERASTATE
        this.ReadWord(); // TIMERBSTATE
        this.ReadByte(); // SHIFTER
        this.ReadByte(); // SDRVALID
        this.ReadByte(); // IRQENABLED
        this.ReadByte(); // TODTICKCOUNTER

        const cia: CIA = {
            PRA
        }

        return cia;
    }

    ReadCIA_2_5() {

        // vice/src/core/ciacore.c
        const PRA = this.ReadByte(); // PRA
        this.ReadByte(); // PRB
        this.ReadByte(); // DDRA
        this.ReadByte(); // DDRB
        this.ReadWord(); // TA
        this.ReadWord(); // TB
        this.ReadByte(); // TODTEN
        this.ReadByte(); // TODSEC
        this.ReadByte(); // TODMIN
        this.ReadByte(); // TODHR
        this.ReadByte(); // SDR
        this.ReadByte(); // ICR
        this.ReadByte(); // CRA
        this.ReadByte(); // CRB
        this.ReadWord(); // LATCHA
        this.ReadWord(); // LATCHB
        this.ReadByte(); // ICRPEEK
        this.ReadByte(); // DATA
        this.ReadByte(); // SRBITS
        this.ReadByte(); // TODALARM0
        this.ReadByte(); // TODALARM1
        this.ReadByte(); // TODALARM2
        this.ReadByte(); // TODALARM3
        this.ReadByte(); // UNKNOWN1
        this.ReadByte(); // UNKNOWN2
        this.ReadByte(); // TODLATCH0
        this.ReadByte(); // TODLATCH1
        this.ReadByte(); // TODLATCH2
        this.ReadByte(); // TODLATCH3
        this.ReadClock(); // CLKPTR
        this.ReadWord(); // TIMERASTATE
        this.ReadWord(); // TIMERBSTATE
        this.ReadByte(); // SHIFTER
        this.ReadByte(); // SDRVALID
        this.ReadByte(); // IRQENABLED
        this.ReadByte(); // TODTICKCOUNTER
        this.ReadByte(); // SHIFTER_HI
        this.ReadByte(); // SDR_ALARM
        this.ReadByte(); // SP_CNT_IN
        this.ReadDWord(); // SDR_DELAY
        this.ReadByte(); // SP_CNT_OUT
        this.ReadDWord(); // IFR_DELAY
        this.ReadDouble(); // ACK_IRQFLAGS
        this.ReadDouble(); // NEW_IRQFLAGS

        const cia: CIA = {
            PRA
        }

        return cia;
    }
}