import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cx } from "../../classes/Utils";
import { Stack } from "../Stack";
import styles from './ProjectLocationTabs.module.css';
import { faCloud, faFloppyDisk, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../store/hooks";
import { selectUser } from "../../store/uiSlice";
import { faEye } from "@fortawesome/free-regular-svg-icons";

export type TabTypes = 'Local' | 'Cloud' | 'Public';

export const ProjectLocationTabs: React.FC<{ activeTab: TabTypes, setTab: (t: TabTypes) => void }> = ({ activeTab, setTab }) => {
    const { isSignedIn } = useAppSelector(selectUser);

    return (
        <Stack layout='horizontal' alignItems='center' justifyContent='start' cssClassName={styles.tabGroup}>
            <ProjectLocationTab activeTab={activeTab} thisTab='Local' label='Mine' icon={faFloppyDisk} setTab={setTab} />
            {isSignedIn && <ProjectLocationTab activeTab={activeTab} thisTab='Cloud' label='Mine' icon={faCloud} setTab={setTab} />}
            <ProjectLocationTab activeTab={activeTab} thisTab='Public' label='Public' icon={faEye} setTab={setTab} />
        </Stack>
    )
}

const ProjectLocationTab: React.FC<{ activeTab: TabTypes, thisTab: TabTypes, label: string, icon: IconDefinition, setTab: (t: TabTypes) => void }> = ({ activeTab, thisTab, label, icon, setTab }) => {
    const selectedTab = <div className={cx(styles.tab, styles.selected, styles[thisTab])}><FontAwesomeIcon className={styles.icon} icon={icon} />{label}</div>;
    const unselectedTab = <button className={cx(styles.tab, styles.unselected, styles[thisTab])} onClick={e => { e.preventDefault(); e.stopPropagation(); setTab(thisTab) }}><FontAwesomeIcon className={styles.icon} icon={icon} />{label}</button>

    return (activeTab === thisTab) ? selectedTab : unselectedTab;
}

