
export enum TextureSlots {
    Texture0 = WebGL2RenderingContext.TEXTURE0,
    Texture1 = WebGL2RenderingContext.TEXTURE1,
    Texture2 = WebGL2RenderingContext.TEXTURE2,
    Texture3 = WebGL2RenderingContext.TEXTURE3,
    Texture4 = WebGL2RenderingContext.TEXTURE4,
    Texture5 = WebGL2RenderingContext.TEXTURE5,
    Texture6 = WebGL2RenderingContext.TEXTURE6,
    Texture7 = WebGL2RenderingContext.TEXTURE7,
    Texture8 = WebGL2RenderingContext.TEXTURE8,
    Texture9 = WebGL2RenderingContext.TEXTURE9,
    Texture10 = WebGL2RenderingContext.TEXTURE10,
    Texture11 = WebGL2RenderingContext.TEXTURE11,
    Texture12 = WebGL2RenderingContext.TEXTURE12,
    Texture13 = WebGL2RenderingContext.TEXTURE13,
    Texture14 = WebGL2RenderingContext.TEXTURE14,
    Texture15 = WebGL2RenderingContext.TEXTURE15,
    Texture16 = WebGL2RenderingContext.TEXTURE16,
    Texture17 = WebGL2RenderingContext.TEXTURE17,
    Texture18 = WebGL2RenderingContext.TEXTURE18,
    Texture19 = WebGL2RenderingContext.TEXTURE19,
    Texture20 = WebGL2RenderingContext.TEXTURE20,
    Texture21 = WebGL2RenderingContext.TEXTURE21,
    Texture22 = WebGL2RenderingContext.TEXTURE22,
    Texture23 = WebGL2RenderingContext.TEXTURE23,
    Texture24 = WebGL2RenderingContext.TEXTURE24,
    Texture25 = WebGL2RenderingContext.TEXTURE25,
    Texture26 = WebGL2RenderingContext.TEXTURE26,
    Texture27 = WebGL2RenderingContext.TEXTURE27,
    Texture28 = WebGL2RenderingContext.TEXTURE28,
    Texture29 = WebGL2RenderingContext.TEXTURE29,
    Texture30 = WebGL2RenderingContext.TEXTURE30,
    Texture31 = WebGL2RenderingContext.TEXTURE31
}


export enum MagFilterTypes {
    Nearest = WebGL2RenderingContext.NEAREST,
    Linear = WebGL2RenderingContext.LINEAR
}


export enum MinFilterTypes {
    Nearest = WebGL2RenderingContext.NEAREST,
    Linear = WebGL2RenderingContext.LINEAR,
    NearestMipmapNearest = WebGL2RenderingContext.NEAREST_MIPMAP_NEAREST,
    LinearMipmapNearest = WebGL2RenderingContext.LINEAR_MIPMAP_NEAREST,
    NearestMipmapLinear = WebGL2RenderingContext.NEAREST_MIPMAP_LINEAR,
    LinearMipmapLinear = WebGL2RenderingContext.LINEAR_MIPMAP_LINEAR
}


export enum WrapTypes {
    Repeat = WebGL2RenderingContext.REPEAT,
    ClampToEdge = WebGL2RenderingContext.CLAMP_TO_EDGE,
    MirroredRepeat = WebGL2RenderingContext.MIRRORED_REPEAT
}
