import { TextureSettings } from "./TextureSettings";
import { TextureSlots } from "./TextureTypes";

export class Texture {

    private gl: WebGL2RenderingContext;
    private texture?: WebGLTexture;


    constructor(gl: WebGL2RenderingContext, settings: TextureSettings) {

        this.gl = gl;

        this.texture = gl.createTexture() ?? undefined;
        this.ApplySettings(settings);
    }


    ApplySettings(settings: TextureSettings) {

        let gl = this.gl;

        gl.bindTexture(gl.TEXTURE_2D, this.texture ?? null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, settings.MagFilter);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, settings.MinFilter);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, settings.WrapS);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, settings.WrapT);
        gl.bindTexture(gl.TEXTURE_2D, null);
    }


    ApplyDataA(width: number, height: number, data: Uint8Array) {

        let gl = this.gl;

        gl.bindTexture(gl.TEXTURE_2D, this.texture ?? null);
        gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.ALPHA, width, height, 0, gl.ALPHA, gl.UNSIGNED_BYTE, data);
        gl.bindTexture(gl.TEXTURE_2D, null);
    }


    ApplyDataByte(width: number, height: number, data: Uint8Array) {

        let gl = this.gl;

        gl.bindTexture(gl.TEXTURE_2D, this.texture ?? null);
        gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R8UI, width, height, 0, gl.RED_INTEGER, gl.UNSIGNED_BYTE, data);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.bindTexture(gl.TEXTURE_2D, null);
    }


    ApplyDataRGB(width: number, height: number, data: Uint8Array) {

        let gl = this.gl;

        gl.bindTexture(gl.TEXTURE_2D, this.texture ?? null);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, width, height, 0, gl.RGB, gl.UNSIGNED_BYTE, data);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.bindTexture(gl.TEXTURE_2D, null);
    }


    Bind(textureSlot: TextureSlots) {

        let gl = this.gl;

        gl.activeTexture(textureSlot);
        gl.bindTexture(gl.TEXTURE_2D, this.texture ?? null);
    }
}