import { useEffect } from "react";

export function useOnKeyboardShortcut(key: string, enableShortcuts: boolean, callback: () => void) {

    useEffect(() => {

        if (!enableShortcuts) { return; }

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key !== key) { return; }

            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            callback();
        }

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [key, enableShortcuts, callback]);
}

