import React from 'react';
import { nameSet, selectProjectName, selectProjectStatus, visibilitySet } from '../store/projectSlice';
import styles from './ProjectNamePanel.module.css';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { logMajorComponentRender } from '../classes/Logger';
import { ProjectVisibility } from '../classes/ProjectDescription';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { selectIsProjectReadOnly } from '../store/extraSelectors';
import { focusCountDecremented, focusCountIncremented } from '../store/uiSlice';

export const ProjectNamePanel: React.FunctionComponent = (props) => {

    logMajorComponentRender(ProjectNamePanel.name);

    const projectName = useAppSelector(selectProjectName);
    const { visibility, userName } = useAppSelector(selectProjectStatus);
    const dispatch = useAppDispatch();
    const isReadOnly = useAppSelector(selectIsProjectReadOnly);

    const handleNameChange = (name: string) => {

        if (name === projectName) { return; }

        dispatch(nameSet(name));
    }

    const handleVisibilityChange = (newVisibility: ProjectVisibility) => {

        if (visibility === newVisibility) { return; }

        dispatch(visibilitySet(newVisibility));
    }

    const handleBlur = () => {
        dispatch(focusCountDecremented());
    }

    const handleFocus = () => {
        dispatch(focusCountIncremented());
    }

    return (
        <div className={styles.container}>
            <input
                type="text"
                value={projectName}
                onChange={e => handleNameChange(e.target.value)}
                className={styles.input}
                placeholder="untitled"
                spellCheck={false}
                disabled={isReadOnly}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
            {(!isReadOnly && visibility === 'private') && <button className={styles.private} onClick={() => handleVisibilityChange('public')}><FontAwesomeIcon icon={faEyeSlash} className={styles.icon} />Private</button>}
            {(!isReadOnly && visibility === 'public') && <button className={styles.public} onClick={() => handleVisibilityChange('private')}><FontAwesomeIcon icon={faEye} className={styles.icon} />Public</button>}
            {isReadOnly && <div className={styles.readOnly}><FontAwesomeIcon icon={faLock} className={styles.icon} />{userName}</div>}
        </div>
    );
}