import * as React from "react";
import { PersistentScroller } from "./PersistentScroller";
import { logMajorComponentRender } from "../../classes/Logger";
import { CharSets, Heading, ScreensSet } from "./Rippers";

export const Screens: React.FunctionComponent = () => {

    logMajorComponentRender(Screens.name);

    return (
        <PersistentScroller name="screen">
            <Heading bank={3} />
            <CharSets bank={3} />
            <ScreensSet bank={3} />
            <Heading bank={2} />
            <CharSets bank={2} />
            <ScreensSet bank={2} />
            <Heading bank={1} />
            <CharSets bank={1} />
            <ScreensSet bank={1} />
            <Heading bank={0} />
            <CharSets bank={0} />
            <ScreensSet bank={0} />
        </PersistentScroller>
    );
}