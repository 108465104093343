import { ColourMode } from "../../classes/graphics/GraphicsEnums";
import { logMajorComponentRender } from "../../classes/Logger";
import { characterMappedModeSet, continuousModeSet, interleavedModeSet, viewLayoutSet } from "../../store/graphicsSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Stack } from "../Stack";
import { EnumSetting } from "./EnumSetting";
import { SettingsRow } from "./SettingsRow";



export const GraphicsModeSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(GraphicsModeSettingsSection.name);

    const settings = useAppSelector(state => state.graphics);
    const dispatch = useAppDispatch();

    const order: string[] = ['hires', 'multicol', 'extended'];
    const options: { [label: string]: ColourMode } = {};
    let selectedOption: ColourMode;
    let onChange: (v: ColourMode) => void;

    options['hires'] = 'HiRes';
    options['multicol'] = 'MultiColour';

    switch (settings.ViewLayout) {
        case 'CharacterMapped':
            options['extended'] = 'Extended';
            selectedOption = settings.CharacterMappedMode;
            onChange = v => dispatch(characterMappedModeSet(v));
            break;

        case 'Interleaved':
            selectedOption = settings.InterleavedMode;
            onChange = v => dispatch(interleavedModeSet(v));
            break;

        case 'Continuous':
        default:
            selectedOption = settings.ContinuousMode;
            onChange = v => dispatch(continuousModeSet(v));
            break;
    }

    return (
        <Stack layout='vertical'>
            <SettingsRow label="Layout">
                <EnumSetting
                    label="layout"
                    selectedOption={settings.ViewLayout}
                    options={{ 'charmap': 'CharacterMapped', 'bitmap': 'Interleaved', 'sprites': 'Continuous' }}
                    order={['charmap', 'bitmap', 'sprites']}
                    onChange={v => dispatch(viewLayoutSet(v))}
                />
            </SettingsRow>
            <SettingsRow label="Mode">
                <EnumSetting
                    label="mode"
                    selectedOption={selectedOption}
                    options={options}
                    order={order}
                    onChange={onChange}
                />
            </SettingsRow>
        </Stack>
    );
}