import styles from './SequenceExplorer.module.css';
import { faBackwardFast, faBackwardStep, faForwardFast, faForwardStep } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlottedByteSequence } from "./PlottedByteSequence";
import { useState } from "react";
import { SequenceAsBitGroups, SequencesAsByteGroups, SequencesAsSummaries } from './Sequences';


const Controls: React.FC<{ first: () => void, last: () => void, prev: () => void, next: () => void, disableBackwards: boolean, disableForwards: boolean }> = ({ first, last, prev, next, disableBackwards, disableForwards }) => {

    return (
        <div>
            <button onClick={first} disabled={disableBackwards}><FontAwesomeIcon icon={faBackwardFast} /></button>
            <button onClick={prev} disabled={disableBackwards}><FontAwesomeIcon icon={faBackwardStep} /></button>
            <button onClick={next} disabled={disableForwards}><FontAwesomeIcon icon={faForwardStep} /></button>
            <button onClick={last} disabled={disableForwards}><FontAwesomeIcon icon={faForwardFast} /></button>
        </div>
    );
}


export const SequenceExplorer: React.FC<{ sequences: PlottedByteSequence[] }> = ({ sequences }) => {

    const [selectedIndex, SetSelectedIndex] = useState(sequences.length - 1);
    const handleSequenceClick = (i: number) => SetSelectedIndex(i);

    return (
        <div>
            <Controls
                first={() => SetSelectedIndex(0)}
                prev={() => SetSelectedIndex(selectedIndex - 1)}
                next={() => SetSelectedIndex(selectedIndex + 1)}
                last={() => SetSelectedIndex(sequences.length - 1)}
                disableBackwards={selectedIndex === 0}
                disableForwards={selectedIndex > sequences.length - 2}
            />
            <div className={styles.divider}>
                <SequencesAsByteGroups sequences={sequences} onClick={handleSequenceClick} selectedIndex={selectedIndex} />
                <SequencesAsSummaries sequences={sequences} onClick={handleSequenceClick} selectedIndex={selectedIndex} />
            </div>

            <SequenceAsBitGroups sequence={sequences[selectedIndex]} />

            {/* <PlottedComandsAsGraphic sequences={sequences} /> */}
        </div>
    )
}
