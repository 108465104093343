import styles from './MarkupIcons.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faMessage, faMicrochip, faPalette, faParagraph, faQuestion, faScrewdriverWrench, faSquareBinary, faTag, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { cx } from '../../classes/Utils';

const makeIcon = (icon: IconDefinition, css: string) => <FontAwesomeIcon icon={icon} className={cx(styles.icon, css)} />

const GraphicIcon = makeIcon(faPalette, styles.graphic);
const FormattingIcon = makeIcon(faParagraph, styles.formatting);
const EntryPointIcon = makeIcon(faMicrochip, styles.entrypoint);
const DataRangeIcon = makeIcon(faSquareBinary, styles.data);
const PointerIcon = makeIcon(faArrowRight, styles.pointer);
const CommentIcon = makeIcon(faMessage, styles.comment);
const LabelIcon = makeIcon(faTag, styles.label);
const OverrideIcon = makeIcon(faScrewdriverWrench, styles.override);
const UnknownIcon = makeIcon(faQuestion, styles.unknown);

export const commandIcon = (name: string) => {
    if (name === 'entry-point') { return EntryPointIcon; }
    else if (name === 'override') { return OverrideIcon; }
    else if (name === 'comment') { return CommentIcon; }
    else if (name === 'label') { return LabelIcon; }
    else if (name === 'data') { return DataRangeIcon; }
    else if (name === 'pointer') { return PointerIcon; }
    else if (name === 'formatting') { return FormattingIcon; }
    else if (name === 'graphic') { return GraphicIcon; }
    else { return UnknownIcon }
}

