import { ByteSequence } from "./ByteSequence";

export type PlottedCharacter = { x: number, y: number, char: number, colour: number };


export class PlottedByteSequence extends ByteSequence {

    plots: PlottedCharacter[] = [];

    constructor(readonly bytes: number[]) {
        super(bytes);
    }

    setPlots(plots: PlottedCharacter[]) {
        this.plots = plots;
    }
}

