import styles from './Setting.module.css';
import { MouseEvent, useCallback, useContext, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newCommentCommand } from "../../classes/commands/CommentCommandHelpers";
import { selectActiveMemoryView, updateRouteToCurrentLine } from "../../store/codeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { commandAdded, commandRemoved, commandUpdated, selectGuidAndName, selectIndexedCommentCommands } from "../../store/projectSlice";
import { selectSelection } from "../../store/toolSlice";
import { EnumSetting } from "./EnumSetting";
import { SettingsRow } from "./SettingsRow";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { Modal } from "./Modal";
import { MultiLineTextInput } from './MultiLineTextInput';
import { logCommandAnalytic } from '../../classes/code/Firebase';
import { commandIcon } from './MarkupIcons';
import { selectPageHasFocus } from '../../store/uiSlice';
import { useOnKeyboardShortcut } from '../../classes/effects/useOnKeyboardShortcut';
import { RibbonMenuContext } from '../RibbonMenu';


export const CommentMarkupSettings: React.FC = () => {

    const { isHiddenCopy } = useContext(RibbonMenuContext);

    const [modalIsOpen, SetModalIsOpen] = useState<boolean>(false);

    const { guid, name } = useAppSelector(selectGuidAndName);

    const pageHasFocus = useAppSelector(selectPageHasFocus);

    const { selectionAddress, selectionCount } = useAppSelector(selectSelection);
    const selectionEndAddress = selectionAddress + selectionCount - 1;

    const activeMemoryView = useAppSelector(selectActiveMemoryView);
    const isRAM = activeMemoryView === 'ram';

    const dispatch = useAppDispatch();

    // Comments
    const commentCommands = useAppSelector(selectIndexedCommentCommands).filter(
        c => c.command.address >= selectionAddress && c.command.address <= selectionEndAddress
    );

    const touchedCommentCommand = commentCommands.filter(c => c.command.address === selectionAddress && selectionCount === 1).shift();
    const commentText = touchedCommentCommand?.command.text ?? 'new comment';

    const commentValue = commentText;
    const commentLabel = commentText.substring(0, 11).concat(commentText.length > 11 ? '…' : '');
    const noneLabel = 'none';
    const noneValue = 'none';
    const mixedLabel = 'mixed';
    const mixedValue = 'mixed';

    let selectedValue = noneValue;
    if (!isRAM || commentCommands.length === 0) {
        selectedValue = noneValue;
    } else if (selectionCount > 1) {
        selectedValue = mixedValue;
    } else {
        selectedValue = commentValue;
    }

    const extraPropertyLabel = (selectionCount < 2) ? commentLabel : mixedLabel;

    const commentOption = isRAM && (selectedValue !== mixedValue) ? { [commentLabel]: commentValue } : undefined;
    const mixedOption = isRAM && (selectedValue === mixedValue) ? { [mixedLabel]: mixedValue } : undefined;

    const canEdit = isRAM && (selectionCount === 1) && touchedCommentCommand;

    const handleCommentingChanged = useCallback((commentValue: string) => {
        if (commentValue === noneValue) {
            commentCommands.sort((a, b) => a.index - b.index).reverse().forEach(c => {
                logCommandAnalytic('delete_command', 'comment', c.command.address, guid, name);
                dispatch(commandRemoved(c.index))
            });
        } else {
            logCommandAnalytic('add_command', 'comment', selectionAddress, guid, name);
            dispatch(commandAdded(newCommentCommand(selectionAddress, commentValue)));
            SetModalIsOpen(true);
        }

        updateRouteToCurrentLine();
    }, [commentCommands, dispatch, SetModalIsOpen, guid, name, selectionAddress]);

    const handleCommentValueChanged = (text: string) => {
        SetModalIsOpen(false);
        if (touchedCommentCommand == null) { return; }

        const { address } = touchedCommentCommand.command;
        const cmd = newCommentCommand(address, text);
        logCommandAnalytic('edit_command', 'comment', address, guid, name);
        dispatch(commandUpdated({ commandIndexToUpdate: touchedCommentCommand.index, command: cmd }));
        updateRouteToCurrentLine();
    }

    const handleCloseModal = () => {
        SetModalIsOpen(false);
    }

    const handleOpenModal = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        SetModalIsOpen(true);
    }

    useOnKeyboardShortcut('c', pageHasFocus && !isHiddenCopy, () => {
        const canAdd = isRAM && (selectionCount === 1) && (touchedCommentCommand == null);
        if (!canAdd) { return; }
        handleCommentingChanged('new comment');
    });

    useOnKeyboardShortcut('C', pageHasFocus && !isHiddenCopy, () => {
        if (commentCommands.length === 0) { return; }
        handleCommentingChanged(noneValue);
    })

    return (
        <>
            <SettingsRow label={<>{commandIcon('comment')}Comment</>}>
                <EnumSetting
                    label="comment"
                    selectedOption={selectedValue}
                    options={{ [noneLabel]: noneValue, ...mixedOption, ...commentOption }}
                    order={[noneLabel, extraPropertyLabel]}
                    onChange={handleCommentingChanged}
                />
                <div style={{ display: 'flex' }}>
                    <button onClick={handleOpenModal} disabled={!canEdit}><FontAwesomeIcon icon={faPenToSquare} className={styles.iconButton} /></button>
                    {modalIsOpen &&
                        <Modal onClose={handleCloseModal} align="bottom-right">
                            <div className={styles.modalTextEditorField}>
                                <MultiLineTextInput
                                    enabled={true}
                                    label='comment'
                                    text={commentText}
                                    onChange={handleCommentValueChanged}
                                    cols={60}
                                    rows={8}
                                />
                            </div>
                        </Modal>
                    }
                </div>
            </SettingsRow>
        </>
    );
}