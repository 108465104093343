import React from 'react';
import { SettingsRow } from './SettingsRow';
import { RootState } from '../../store';
import { ColourProperties, GraphicsState } from '../../store/graphicsSlice';
import { useAppSelector } from '../../store/hooks';
import { logMajorComponentRender } from '../../classes/Logger';
import { Stack } from '../Stack';
import { ColourSwatch } from './ColourSwatch';


const getColourSettings = (s: GraphicsState) => {

    const foregroundSwatches: JSX.Element[] = [];
    const backgroundSwatches: JSX.Element[] = [];

    if (s.ViewLayout === 'CharacterMapped' && s.CharacterMappedMode === 'HiRes') {
        backgroundSwatches.push(<ColourSwatch key='0' property={ColourProperties.CharacterMappedHiresBackground00} enabled={true} />)
        foregroundSwatches.push(<ColourSwatch key='1' property={ColourProperties.CharacterMappedHiresForeground01} enabled={s.CharacterMappedMainColourSource === 'Fixed'} />);
    }

    if (s.ViewLayout === 'CharacterMapped' && s.CharacterMappedMode === 'MultiColour') {
        backgroundSwatches.push(<ColourSwatch key='00' property={ColourProperties.CharacterMappedMultiColourBackground00} enabled={true} />)
        foregroundSwatches.push(<ColourSwatch key='01' property={ColourProperties.CharacterMappedMultiColourForeground01} enabled={true} />);
        foregroundSwatches.push(<ColourSwatch key='10' property={ColourProperties.CharacterMappedMultiColourForeground10} enabled={true} />);
        foregroundSwatches.push(<ColourSwatch key='11' property={ColourProperties.CharacterMappedMultiColourForeground11} enabled={s.CharacterMappedMainColourSource === 'Fixed'} />);
    }

    if (s.ViewLayout === 'CharacterMapped' && s.CharacterMappedMode === 'Extended') {
        backgroundSwatches.push(<ColourSwatch key='00' property={ColourProperties.CharacterMappedExtendedColourBackground00} enabled={true} />)
        backgroundSwatches.push(<ColourSwatch key='01' property={ColourProperties.CharacterMappedExtendedColourBackground01} enabled={true} />)
        backgroundSwatches.push(<ColourSwatch key='10' property={ColourProperties.CharacterMappedExtendedColourBackground10} enabled={true} />)
        backgroundSwatches.push(<ColourSwatch key='11' property={ColourProperties.CharacterMappedExtendedColourBackground11} enabled={true} />)
        foregroundSwatches.push(<ColourSwatch key='1' property={ColourProperties.CharacterMappedExtendedColourForeground01} enabled={s.CharacterMappedMainColourSource === 'Fixed'} />);
    }

    if (s.ViewLayout === 'Interleaved' && s.InterleavedMode === 'HiRes') {
        backgroundSwatches.push(<ColourSwatch key='0' property={ColourProperties.InterleavedHiresBackground00} enabled={s.InterleavedMainColourSource === 'Fixed'} />)
        foregroundSwatches.push(<ColourSwatch key='1' property={ColourProperties.InterleavedHiresForeground01} enabled={s.InterleavedMainColourSource === 'Fixed'} />);
    }

    if (s.ViewLayout === 'Interleaved' && s.InterleavedMode === 'MultiColour') {
        backgroundSwatches.push(<ColourSwatch key='00' property={ColourProperties.InterleavedMultiColourBackground00} enabled={true} />)
        foregroundSwatches.push(<ColourSwatch key='01' property={ColourProperties.InterleavedMultiColourForeground01} enabled={s.InterleavedMainColourSource === 'Fixed'} />);
        foregroundSwatches.push(<ColourSwatch key='10' property={ColourProperties.InterleavedMultiColourForeground10} enabled={s.InterleavedMainColourSource === 'Fixed'} />);
        foregroundSwatches.push(<ColourSwatch key='11' property={ColourProperties.InterleavedMultiColourForeground11} enabled={s.InterleavedOtherColourSource === 'Fixed'} />);
    }

    if (s.ViewLayout === 'Continuous' && s.ContinuousMode === 'HiRes') {
        backgroundSwatches.push(<ColourSwatch key='0' property={ColourProperties.ContinuousHiresBackground00} enabled={true} />)
        foregroundSwatches.push(<ColourSwatch key='1' property={ColourProperties.ContinuousHiresForeground01} enabled={true} />);
    }

    if (s.ViewLayout === 'Continuous' && s.ContinuousMode === 'MultiColour') {
        backgroundSwatches.push(<ColourSwatch key='00' property={ColourProperties.ContinuousMultiColourBackground00} enabled={true} />)
        foregroundSwatches.push(<ColourSwatch key='01' property={ColourProperties.ContinuousMultiColourForeground01} enabled={true} />);
        foregroundSwatches.push(<ColourSwatch key='10' property={ColourProperties.ContinuousMultiColourForeground10} enabled={true} />);
        foregroundSwatches.push(<ColourSwatch key='11' property={ColourProperties.ContinuousMultiColourForeground11} enabled={true} />);
    }

    return { foregroundSwatches, backgroundSwatches };
}

export const ColoursSettingsSection: React.FunctionComponent = () => {

    logMajorComponentRender(ColoursSettingsSection.name);

    const settings = useAppSelector((state: RootState) => state.graphics);
    const { foregroundSwatches, backgroundSwatches } = getColourSettings(settings);

    return (
        <Stack layout='vertical'>
            <SettingsRow label='Fore'>
                {foregroundSwatches}
            </SettingsRow>
            <SettingsRow label='Back'>
                {backgroundSwatches}
            </SettingsRow>
        </Stack>
    );
}