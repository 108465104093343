import { OnTick } from "../../components/graphics/GraphicsCanvas";
import { logInfo } from "../Logger";
import { OnDraw } from "./GraphicsRendererModel";

type Handler<T> = (onAction: T) => void;

function removeFromArray<T>(array: Array<T>, item: T) {
    const index = array.indexOf(item);
    if (index < 0) { return; }
    array.splice(index, 1);
}

const onDrawsTemp: OnDraw[] = [];
const onTicksTemp: OnTick[] = [];

// Default handler functions store onDraws/onTicks in temporary lists, so that
// they can be later applied when real handler functions have been set
const registerOnTickHandlerDefault: Handler<OnTick> = (onTick) => onTicksTemp.push(onTick);
const deregisterOnTickHandlerDefault: Handler<OnTick> = (onTick) => removeFromArray(onTicksTemp, onTick);

let registerOnDrawHandler: Handler<OnDraw> = (onDraw) => onDrawsTemp.push(onDraw);
let deregisterOnDrawHandler: Handler<OnDraw> = (onDraw) => removeFromArray(onDrawsTemp, onDraw);
let registerOnTickHandler: Handler<OnTick> = registerOnTickHandlerDefault;
let deregisterOnTickHandler: Handler<OnTick> = deregisterOnTickHandlerDefault;

// Set handler functions for (de)registering onDraws, and apply these to any outstanding onDraws
export const setOnDrawHandlers = (newRegisterOnDrawHandler: Handler<OnDraw>, newDeregisterOnDrawHandler: Handler<OnDraw>) => {
    logInfo(`Setting onDraw handlers and batching ${onDrawsTemp.length} cached onDraws`);

    registerOnDrawHandler = newRegisterOnDrawHandler;
    deregisterOnDrawHandler = newDeregisterOnDrawHandler;

    onDrawsTemp.forEach(onDraw => registerOnDrawHandler(onDraw));
    onDrawsTemp.length = 0;
}

// Set handler functions for (de)registering onDraws, and apply these to any outstanding onTicks
export const setOnTickHandlers = (newRegisterOnTickHandler: Handler<OnTick>, newDeregisterOnTickHandler: Handler<OnTick>) => {
    logInfo(`Setting onTick handlers and batching ${onTicksTemp.length} cached onTicks`);

    registerOnTickHandler = newRegisterOnTickHandler;
    deregisterOnTickHandler = newDeregisterOnTickHandler;

    onTicksTemp.forEach(onTick => registerOnTickHandler(onTick));
    onTicksTemp.length = 0;
}

export const clearOnTickHandlers = () => {
    logInfo(`Clearing onTick handlers`);
    registerOnTickHandler = registerOnTickHandlerDefault;
    deregisterOnTickHandler = deregisterOnTickHandlerDefault;
}

// (De)register individual onDraws
export const registerOnDraw = (onDraw: OnDraw) => registerOnDrawHandler(onDraw);
export const deregisterOnDraw = (onDraw: OnDraw) => deregisterOnDrawHandler(onDraw);

// (De)register individual onTicks
export const registerOnTick = (onTick: OnTick) => registerOnTickHandler(onTick);
export const deregisterOnTick = (onTick: OnTick) => deregisterOnTickHandler(onTick);

