import { GLUtils } from './GLUtils';
import { MeshUtils } from './MeshUtils';
import { Palette } from '../Palette';
import vertShaderSource from './shaders/Uber.vert?raw';
import fragShaderSource from './shaders/Uber.frag?raw';
import { ShaderProgram } from './primitives/ShaderProgram';
import { Texture } from './primitives/Texture';
import { MagFilterTypes, MinFilterTypes, TextureSlots, WrapTypes } from './primitives/TextureTypes';
import { TextureSettings } from './primitives/TextureSettings';
import { GraphicData } from './GraphicData';
import { OnDraw } from './GraphicsRendererModel';
import { Viewport } from '../../store/rendererSlice';
import { GraphicCommand } from '../commands/GraphicCommand';
import { logWarning } from '../Logger';
import { colourCode, colourData, colourGfx, colourUnknown } from '../../components/ProjectPage';

const getFlags = (flags: string[]) => {
    let res = 0b0000000000000000;
    if (flags.includes('CHARACTERMAPPED')) res |= 1 << 0;
    if (flags.includes('INTERLEAVED')) res |= 1 << 1;
    if (flags.includes('CONTINUOUS')) res |= 1 << 2;
    if (flags.includes('COLOURS')) res |= 1 << 3;
    if (flags.includes('FIXEDCOLOUR')) res |= 1 << 4;
    if (flags.includes('RAM')) res |= 1 << 5;
    if (flags.includes('COLRAM')) res |= 1 << 6;
    if (flags.includes('MULTICOLOUR')) res |= 1 << 7;
    if (flags.includes('ECM')) res |= 1 << 8;
    if (flags.includes('MAINFIXED')) res |= 1 << 9;
    if (flags.includes('MAINRAM')) res |= 1 << 10;
    if (flags.includes('OTHERFIXED')) res |= 1 << 11;
    if (flags.includes('OTHERRAM')) res |= 1 << 12;
    if (flags.includes('OTHERCOLRAM')) res |= 1 << 13;
    if (flags.includes('MEMORY')) res |= 1 << 14;
    if (flags.includes('DOUBLE')) res |= 1 << 15;
    if (flags.includes('MAP')) res |= 1 << 16;
    return res;
}

const shaderFlags = {
    CharacterMappedHiresFixed: getFlags(['CHARACTERMAPPED', 'FIXEDCOLOUR']),
    CharacterMappedHiresRAM: getFlags(['CHARACTERMAPPED', 'RAM']),
    CharacterMappedHiresCOLRAM: getFlags(['CHARACTERMAPPED', 'COLRAM']),

    CharacterMappedMultiColourFixed: getFlags(['CHARACTERMAPPED', 'MULTICOLOUR', 'FIXEDCOLOUR']),
    CharacterMappedMultiColourRAM: getFlags(['CHARACTERMAPPED', 'MULTICOLOUR', 'RAM']),
    CharacterMappedMultiColourCOLRAM: getFlags(['CHARACTERMAPPED', 'MULTICOLOUR', 'COLRAM']),

    CharacterMappedExtendedFixed: getFlags(['CHARACTERMAPPED', 'ECM', 'FIXEDCOLOUR']),
    CharacterMappedExtendedRAM: getFlags(['CHARACTERMAPPED', 'ECM', 'RAM']),
    CharacterMappedExtendedCOLRAM: getFlags(['CHARACTERMAPPED', 'ECM', 'COLRAM']),

    InterleavedHiresFixed: getFlags(['INTERLEAVED', 'FIXEDCOLOUR']),
    InterleavedHiresRAM: getFlags(['INTERLEAVED', 'RAM']),

    InterleavedMultiColourFixedFixed: getFlags(['INTERLEAVED', 'MULTICOLOUR', 'MAINFIXED', 'OTHERFIXED']),
    InterleavedMultiColourRAMFixed: getFlags(['INTERLEAVED', 'MULTICOLOUR', 'MAINRAM', 'OTHERFIXED']),
    InterleavedMultiColourFixedRAM: getFlags(['INTERLEAVED', 'MULTICOLOUR', 'MAINFIXED', 'OTHERRAM']),
    InterleavedMultiColourRAMRAM: getFlags(['INTERLEAVED', 'MULTICOLOUR', 'MAINRAM', 'OTHERRAM']),
    InterleavedMultiColourFixedCOLRAM: getFlags(['INTERLEAVED', 'MULTICOLOUR', 'MAINFIXED', 'OTHERCOLRAM']),
    InterleavedMultiColourRAMCOLRAM: getFlags(['INTERLEAVED', 'MULTICOLOUR', 'MAINRAM', 'OTHERCOLRAM']),

    ContinuousHires: getFlags(['CONTINUOUS']),
    ContinuousMultiColour: getFlags(['CONTINUOUS', 'MULTICOLOUR']),

    ColoursSingle: getFlags(['COLOURS']),
    ColoursSingleMemory: getFlags(['COLOURS', 'MEMORY']),
    ColoursDoubleMemory: getFlags(['COLOURS', 'DOUBLE', 'MEMORY']),

    MemoryMap: getFlags(['MAP']),
    Unknown: getFlags(['UNKNOWN']),
}

export class C64Shader {

    private gl: WebGL2RenderingContext;

    private shader?: ShaderProgram;
    private devicePixelRatio: number;
    private selectionStart: number;
    private selectionCountBytes: number;
    private memoryTexture: Texture;
    private memoryUsageTexture: Texture;
    private memoryMapTexture: Texture;
    private colourTexture: Texture;
    private paletteTexture: Texture;

    private initialInstanceCount = 10;
    private packedInstancesStride = 16;
    private instancesData = new Float32Array(this.initialInstanceCount * this.packedInstancesStride);
    private packedInstances: WebGLBuffer | undefined;


    constructor(gl: WebGL2RenderingContext) {

        this.gl = gl;

        // Create texture
        const texSettings = new TextureSettings(MagFilterTypes.Nearest, MinFilterTypes.Nearest, WrapTypes.ClampToEdge, WrapTypes.ClampToEdge);
        this.memoryTexture = new Texture(gl, texSettings);
        this.memoryUsageTexture = new Texture(gl, texSettings);
        this.memoryMapTexture = new Texture(gl, texSettings);
        this.colourTexture = new Texture(gl, texSettings);
        this.paletteTexture = new Texture(gl, texSettings);

        // Build palette texture
        this.CreatePaletteTexture();

        this.devicePixelRatio = 1.0;

        this.selectionStart = 0;
        this.selectionCountBytes = 0;

        const meshData = MeshUtils.CreateQuadMeshData();
        const positions = GLUtils.buildArrayBuffer(gl, meshData.positions, gl.STATIC_DRAW);
        const indices = GLUtils.buildElementArrayBuffer(gl, meshData.indices, gl.STATIC_DRAW);
        const packedInstances = this.gl.createBuffer();

        if (
            positions === undefined
            || indices === undefined
            || packedInstances === null
        ) {
            logWarning(`Couldn't create buffers for C64Shader`);
            return;
        }

        this.packedInstances = packedInstances;

        const buffers = { positions, indices, packedInstances, packedInstancesStride: this.packedInstancesStride };
        this.shader = new ShaderProgram(gl, buffers, vertShaderSource, fragShaderSource);
    }


    CreatePaletteTexture() {

        let colours: number[] = [];
        for (let i = 0; i < 16; i++) {
            let c = Palette.index[i];
            colours.push(c.r * 255, c.g * 255, c.b * 255);
        }

        let coloursData = new Uint8Array(colours);
        this.paletteTexture.ApplyDataRGB(16, 1, coloursData);
    }


    SetDevicePixelRatio(ratio: number) {
        this.devicePixelRatio = ratio;
    }


    SetSelectionRange(startAddress: number, countBytes: number) {
        this.selectionStart = startAddress;
        this.selectionCountBytes = countBytes;
    }

    SetMemoryUsageTextureData(data: boolean[]) {

        if (data.length !== 65536) {
            logWarning('Memory texture data needs to be 65536 bytes (got ' + data.length + ')');
            return;
        }

        const textureData = new Uint8Array(data.map(d => d ? 255 : 0));
        this.memoryUsageTexture.ApplyDataByte(256, 256, textureData);
    }

    SetMemoryMapTextureData(height: number, data: Uint8Array) {

        this.memoryMapTexture.ApplyDataByte(1, height, data);
    }


    SetMemoryTextureData(data: Uint8Array) {

        if (data.length !== 65536) {
            logWarning('Memory texture data needs to be 65536 bytes (got ' + data.length + ')');
            return;
        }

        this.memoryTexture.ApplyDataByte(256, 256, data);
    }


    SetColourTextureData(data: Uint8Array) {

        if (data.length !== 1000) {
            logWarning('Colour texture data needs to be 1000 bytes (got ' + data.length + ')');
            return;
        }

        let cols: number[] = [];
        for (let y = 0; y < 25; y++) {
            for (let x = 0; x < 40; x++) {
                let i = (y * 40) + x;
                cols.push(data[i]);
            }
        }
        for (let z = 0; z < 24; z++) {
            cols.push(0);
        }
        let t = new Uint8Array(cols);

        this.colourTexture.ApplyDataByte(32, 32, t);
    }


    Clear(r: number, g: number, b: number, a: number) {
        GLUtils.clearToColour(this.gl, r, g, b, a);
    }

    StartFrame(timestampMS: number) {

        if (this.shader == null) { return; }

        // Bind textures
        this.memoryTexture.Bind(TextureSlots.Texture0);
        this.colourTexture.Bind(TextureSlots.Texture1);
        this.paletteTexture.Bind(TextureSlots.Texture2);
        this.memoryUsageTexture.Bind(TextureSlots.Texture3);
        this.memoryMapTexture.Bind(TextureSlots.Texture4);

        // Set uniforms
        this.shader.SetUniform('u_devicePixelRatio', [this.devicePixelRatio]);
        this.shader.SetUniform('u_resolutionf', [this.gl.drawingBufferWidth, this.gl.drawingBufferHeight]);
        this.shader.SetUniform('u_memoryTexture', [0]);
        this.shader.SetUniform('u_colourTexture', [1]);
        this.shader.SetUniform('u_paletteTexture', [2]);
        this.shader.SetUniform('u_markedMemoryTexture', [3]);
        this.shader.SetUniform('u_memoryMapTexture', [4]);
        this.shader.SetUniform('u_selection', [this.selectionStart, this.selectionCountBytes]);
        this.shader.SetUniform('u_timestampMs', [timestampMS]);
        this.shader.SetUniform('u_memoryMapColUnknown', [colourUnknown[0] / 255, colourUnknown[1] / 255, colourUnknown[2] / 255]);
        this.shader.SetUniform('u_memoryMapColData', [colourData[0] / 255, colourData[1] / 255, colourData[2] / 255]);
        this.shader.SetUniform('u_memoryMapColCode', [colourCode[0] / 255, colourCode[1] / 255, colourCode[2] / 255]);
        this.shader.SetUniform('u_memoryMapColGfx', [colourGfx[0] / 255, colourGfx[1] / 255, colourGfx[2] / 255]);
    }


    Draw(onDraws: OnDraw[], viewport: Viewport, showSelection: boolean, showMarked: boolean, showGrid: boolean, previewSettings?: GraphicCommand) {

        if (this.shader == null) { return; }
        if (this.packedInstances == null) { return; }
        if (previewSettings == null) { return; }

        this.shader.SetUniform('u_showSelection', [showSelection]);
        this.shader.SetUniform('u_showMarked', [showMarked]);
        this.shader.SetUniform('u_showGrid', [showGrid]);

        const packedInstancesStride = this.packedInstancesStride;
        if (this.instancesData.length < (onDraws.length * packedInstancesStride)) {
            this.instancesData = new Float32Array(onDraws.length * packedInstancesStride);
        }

        const clip = (g: GraphicData, v: Viewport) => {
            const scale = g.settings?.scale ?? 1;
            return (g.left + g.width * scale > v.left) && (g.left < v.left + v.width) && (g.top + g.height * scale > v.top) && (g.top < v.top + v.height);
        }

        let instancesCounter = 0;
        for (let i = 0; i < onDraws.length; i++) {

            // Got a function to call?
            const onDraw = onDraws[i];
            if (onDraw == null) { continue; }

            // Got a graphic back from the function?
            const graphicData = onDraw();
            if (graphicData == null) { continue; }

            // Graphic is for this viewport?
            if (graphicData.location !== viewport.location) { continue; }

            // Graphic is visible in this viewport?
            if (!clip(graphicData, viewport)) { continue; }

            // Add to the instances
            const settings = graphicData.settings!;

            // a_packedPositionAndSize
            this.instancesData[instancesCounter * packedInstancesStride + 0] = graphicData.left;
            this.instancesData[instancesCounter * packedInstancesStride + 1] = graphicData.top;
            this.instancesData[instancesCounter * packedInstancesStride + 2] = graphicData.width;
            this.instancesData[instancesCounter * packedInstancesStride + 3] = graphicData.height;

            // a_packedAddresses
            this.instancesData[instancesCounter * packedInstancesStride + 4] = settings.referenceAddress1 ?? previewSettings.referenceAddress1!;
            this.instancesData[instancesCounter * packedInstancesStride + 5] = settings.referenceAddress2 ?? previewSettings.referenceAddress2!;
            this.instancesData[instancesCounter * packedInstancesStride + 6] = settings.address ?? previewSettings.address!;
            this.instancesData[instancesCounter * packedInstancesStride + 7] = (settings.countBytes ?? previewSettings.countBytes!) + (settings.address ?? previewSettings.address!) - 1;

            // a_packedColours
            this.instancesData[instancesCounter * packedInstancesStride + 8] = settings.colour00 ?? previewSettings.colour00!;
            this.instancesData[instancesCounter * packedInstancesStride + 9] = settings.colour01 ?? previewSettings.colour01!;
            this.instancesData[instancesCounter * packedInstancesStride + 10] = settings.colour10 ?? previewSettings.colour10!;
            this.instancesData[instancesCounter * packedInstancesStride + 11] = settings.colour11 ?? previewSettings.colour11!;

            // a_packedExtras
            this.instancesData[instancesCounter * packedInstancesStride + 12] = settings.extraColour ?? previewSettings.extraColour!;
            this.instancesData[instancesCounter * packedInstancesStride + 13] = settings.widthPx;
            this.instancesData[instancesCounter * packedInstancesStride + 14] = settings.heightPx;
            this.instancesData[instancesCounter * packedInstancesStride + 15] = shaderFlags[settings.graphicsType ?? previewSettings.graphicsType!];

            instancesCounter++;
        }

        GLUtils.UpdateBuffer(this.gl, this.instancesData, this.packedInstances);

        this.shader.Draw(instancesCounter);
    }
}