import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';
import { Banner } from './HomePage';
import styles from './HomePage.module.css';

export const ErrorPage: React.FC = () => {

    const error = useRouteError();

    let errorMessage: string;
    if (isRouteErrorResponse(error)) {
        // error is type `ErrorResponse`
        errorMessage = error.statusText;
    } else if (error instanceof Error) {
        errorMessage = error.message;
    } else if (typeof error === 'string') {
        errorMessage = error;
    } else {
        console.error(error);
        errorMessage = 'Unknown error';
    }

    return (
        <div className={styles.container}>
            <div className={styles.column}>

                <h2><Banner message={'** ERROR :( '} colours={['04', '0D', '0F', '07']} background={'0A'} updateMS={250} /></h2>

                <p>
                    {errorMessage}. <Link to={'/'}>Go home</Link>
                </p>
            </div>
        </div>
    );
}