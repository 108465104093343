import styles from './SavePanel.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAsync } from '../classes/LocalStorageHelper';
import { saveAsync as saveCloudAsync } from '../classes/CloudStorageHelper';
import { faCloud, faFloppyDisk, faCopy } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import { newProjectDescription, serialise } from "../classes/ProjectDescriptionHelpers";
import { logError, logMajorComponentRender } from "../classes/Logger";
import { dirtySet } from "../store/projectSlice";
import { Stack } from './Stack';
import { cx, Utils } from '../classes/Utils';
import { logProjectActionAnalytic } from '../classes/code/Firebase';
import { selectIsProjectReadOnly } from '../store/extraSelectors';
import { makeCopy } from '../classes/ProjectDescription';
import { useLocation, useNavigate } from 'react-router-dom';
import { setRoute } from '../App';
import { selectUser } from '../store/uiSlice';

export const SavePanel: React.FC = () => {

    logMajorComponentRender(SavePanel.name);

    const dispatch = useAppDispatch();
    const project = useAppSelector((state: RootState) => state.project);
    const isReadOnly = useAppSelector(selectIsProjectReadOnly);
    const navigate = useNavigate();
    const location = useLocation();

    const { uid, userName, isSignedIn } = useAppSelector(selectUser);

    const handleOnExport = () => {
        const desc = serialise(newProjectDescription(project.name, project.guid, project.visibility, project.RAM, project.COLRAM, project.commands), Date.now());
        logProjectActionAnalytic('export_project', project.guid, project.name);
        Utils.downloadText(desc, encodeURIComponent(project.name))
    }

    const handleOnSave = async () => {

        logProjectActionAnalytic('save_project', project.guid, project.name);
        try {
            const t = Date.now();
            const saveMethod = project.isCloud ? saveCloudAsync : saveAsync;
            const desc = newProjectDescription(project.name, project.guid, project.visibility, project.RAM, project.COLRAM, project.commands, project.uid, project.userName);
            await saveMethod(desc, t);
            dispatch(dirtySet(false));
        } catch (error) {
            logError(String(error));
        }
    }

    const handleOnClone = async () => {
        logProjectActionAnalytic('clone_public_project', project.guid, project.name);
        try {
            const saveMethod = isSignedIn ? saveCloudAsync : saveAsync;

            const t = Date.now();
            const desc = makeCopy(newProjectDescription(project.name, project.guid, 'private', project.RAM, project.COLRAM, project.commands));

            desc.uid = isSignedIn ? uid : undefined;
            desc.userName = isSignedIn ? userName : undefined;

            await saveMethod(desc, t);
            dispatch(dirtySet(false));
            setRoute(navigate, location, { guid: desc.guid, tool: 'library' })
        } catch (error) {
            logError(String(error));
        }
    }

    const saveButtonDisabled = (!project.dirty) || (project.name === '');
    const symbol = project.isCloud ? faCloud : faFloppyDisk;

    return (
        <Stack layout='horizontal'>
            {!isReadOnly && <button onClick={handleOnExport} className={cx(styles.button, styles.smallButton)}>Export</button>}
            {!isReadOnly && <button onClick={handleOnSave} disabled={saveButtonDisabled} className={styles.button}><FontAwesomeIcon icon={symbol} className={styles.icon} /> Save</button>}
            {isReadOnly && <button onClick={handleOnClone} className={styles.button}><FontAwesomeIcon icon={faCopy} className={styles.icon} />Clone to edit</button>}
        </Stack>
    )
}