import styles from './Rippers.module.css';
import { CharacterSet } from "../graphics-templates/CharacterSet";
import { Screen } from "../graphics-templates/Screen";
import { Utils } from "../../classes/Utils";
import { useAppSelector } from '../../store/hooks';
import { selectSelectionWidth } from '../../store/graphicsSlice';
import { Sprite } from '../graphics-templates/Sprite';
import { BitmapScreen } from '../graphics-templates/BitmapScreen';
import { Bitmap } from '../graphics-templates/Bitmap';

export const Heading: React.FC<{ bank: number }> = (props) =>
    <div className={styles.heading}>
        <span className={styles.name}>Bank {props.bank}</span>
        <span className={styles.divider}>|</span>
        <span className={styles.address}>{Utils.to4DigitHexString(0x4000 * (3 - props.bank))}</span>
        <span className={styles.length}>+{Utils.toHexAuto(0x4000)}</span>
    </div>

export const CharSets: React.FC<{ bank: number }> = (props) => {

    const baseIndex = (3 - props.bank) * 0x08;

    return (
        <div className={styles.subSet}>
            <div className={styles.subHeading}>Character set source</div>
            <CharacterSet startAddress={(baseIndex + 0x00) * 0x0800} />
            <CharacterSet startAddress={(baseIndex + 0x01) * 0x0800} />
            <CharacterSet startAddress={(baseIndex + 0x02) * 0x0800} />
            <CharacterSet startAddress={(baseIndex + 0x03) * 0x0800} />
            <CharacterSet startAddress={(baseIndex + 0x04) * 0x0800} />
            <CharacterSet startAddress={(baseIndex + 0x05) * 0x0800} />
            <CharacterSet startAddress={(baseIndex + 0x06) * 0x0800} />
            <CharacterSet startAddress={(baseIndex + 0x07) * 0x0800} />
        </div>
    );
}

export const ScreensSet: React.FC<{ bank: number }> = (props) => {

    const baseIndex = (3 - props.bank) * 0x10;

    return (
        <div className={styles.set}>
            <Screen startAddress={(baseIndex + 0x00) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x01) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x02) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x03) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x04) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x05) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x06) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x07) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x08) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x09) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x0a) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x0b) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x0c) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x0d) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x0e) * 0x0400} />
            <Screen startAddress={(baseIndex + 0x0f) * 0x0400} />
        </div>
    );
}

const heightsAndSkip = [[32, 0], [32, 0], [21, 1], [32, 0], [25, 3], [21, 2], [18, 2], [32, 0]];

export const SpritesSet: React.FC<{ bank: number }> = (props) => {

    const { selectionWidth } = useAppSelector(selectSelectionWidth);
    const widthChars = Math.min(selectionWidth, 8);

    const [heightPx, skipBytes] = heightsAndSkip[widthChars - 1];

    const countBytes = widthChars * heightPx;
    const spritesPerBank = Math.floor(0x4000 / (countBytes + skipBytes));

    const baseIndex = (3 - props.bank) * spritesPerBank;

    let s: JSX.Element[] = [];
    for (let index = 0; index < spritesPerBank; index++) {
        const totalIndex = (baseIndex + index);
        const startAddress = (countBytes + skipBytes) * totalIndex;
        s.push(<Sprite key={totalIndex} startAddress={startAddress} widthChars={widthChars} countBytes={countBytes} />)
    }

    return <div className={styles.set}>{s}</div>;
}

export const BitmapScreensSet: React.FC<{ bank: number }> = (props) => {

    const baseIndex = (3 - props.bank) * 0x10;

    return (
        <div className={styles.subSet}>
            <div className={styles.subHeading}>Bitmap colours source</div>
            <BitmapScreen startAddress={(baseIndex + 0x00) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x01) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x02) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x03) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x04) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x05) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x06) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x07) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x08) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x09) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x0a) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x0b) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x0c) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x0d) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x0e) * 0x0400} />
            <BitmapScreen startAddress={(baseIndex + 0x0f) * 0x0400} />
        </div>
    );
}

export const BitmapsSet: React.FC<{ bank: number }> = (props) => {

    const baseIndex = (3 - props.bank) * 0x02;
    const screen0Address = (baseIndex + 0) * 0x2000;
    const screen1Address = (baseIndex + 1) * 0x2000;

    return (
        <div className={styles.set}>
            <Bitmap startAddress={screen0Address} />
            <Bitmap startAddress={screen1Address} />
        </div>
    );
}
