import * as React from "react";
import { PersistentScroller } from "./PersistentScroller";
import { logMajorComponentRender } from "../../classes/Logger";
import { BitmapScreensSet, BitmapsSet, Heading } from "./Rippers";


export const Bitmaps: React.FunctionComponent = (props) => {

    logMajorComponentRender(Bitmaps.name);

    return (
        <PersistentScroller name="bitmaps">
            <Heading bank={3} />
            <BitmapScreensSet bank={3} />
            <BitmapsSet bank={3} />
            <Heading bank={2} />
            <BitmapScreensSet bank={2} />
            <BitmapsSet bank={2} />
            <Heading bank={1} />
            <BitmapScreensSet bank={1} />
            <BitmapsSet bank={1} />
            <Heading bank={0} />
            <BitmapScreensSet bank={0} />
            <BitmapsSet bank={0} />
        </PersistentScroller>
    );
}