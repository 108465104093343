import { PointerEvent, useCallback } from 'react';
import styles from './Resizer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLinesVertical } from '@fortawesome/free-solid-svg-icons';


const pointerDownHandler = (e: PointerEvent<HTMLDivElement>) => {
    e.currentTarget.setPointerCapture(e.pointerId);
}

const pointerUpHandler = (e: PointerEvent<HTMLDivElement>) => {
    e.currentTarget.releasePointerCapture(e.pointerId);
}

const clampNumber = (num: number, min: number, max: number) =>
    Math.max(Math.min(num, Math.max(min, max)), Math.min(min, max));

export const Resizer: React.FC<{ minSizePercent: number, maxSizePercent: number, onResizedPercent: (widthPercent: number) => void }> = (props) => {

    const { minSizePercent, maxSizePercent, onResizedPercent } = props;

    const pointerMoveHandler = useCallback((e: PointerEvent<HTMLDivElement>) => {
        if (!e.currentTarget.hasPointerCapture(e.pointerId)) { return; }

        const containerWidthPx = e.currentTarget.parentElement?.clientWidth ?? 0;
        const containerLeftPx = e.currentTarget.parentElement?.offsetLeft ?? 0;
        const halfDividerWidthPx = e.currentTarget.clientWidth * 0.5;
        const mousePositionPx = e.clientX;

        const minWidthPx = Math.floor(containerWidthPx * (minSizePercent / 100));
        const maxWidthPx = Math.floor(containerWidthPx * (maxSizePercent / 100));
        const widthPx = (mousePositionPx - halfDividerWidthPx) - containerLeftPx;
        const widthClampedPx = clampNumber(widthPx, minWidthPx, maxWidthPx);
        const widthClampedPercent = 100 * (widthClampedPx / containerWidthPx);

        onResizedPercent(widthClampedPercent);

    }, [minSizePercent, maxSizePercent, onResizedPercent]);

    return (
        <div
            className={styles.divider}
            onPointerDown={pointerDownHandler}
            onPointerUp={pointerUpHandler}
            onPointerMove={pointerMoveHandler}>
            <FontAwesomeIcon icon={faGripLinesVertical} />
        </div>
    );
}