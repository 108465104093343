import React from "react";
import { useEffect, useRef, useState } from "react";
import SizeAndPositionManager from "./SizeAndPositionManager";
import styles from './VirtualList.module.css';
import { logInfo, logMajorComponentRender } from "../../classes/Logger";
import { useAppSelector } from "../../store/hooks";
import { selectActiveMemoryView } from "../../store/codeSlice";

export interface Props {
    overscanCount?: number,
    itemSizes: number[],
    totalItemSize: number,
    renderItem: (index: number) => JSX.Element,
    onClick?: (e: React.MouseEvent<HTMLElement>) => void,
    setMiddleItem: (i: number) => void
}

const scrollTopDict: { [name: string]: number } = {};

export const VirtualList: React.FC<Props> = (props) => {

    logMajorComponentRender(VirtualList.name);

    const rootRef = useRef<HTMLDivElement>(null);
    const [sizeAndPositionManager, setSizeAndPositionManager] = useState<SizeAndPositionManager>();
    const [items, setItems] = useState<JSX.Element[]>();
    const [viewportHeight, setViewportHeight] = useState<number>(0);
    const activeMemoryView = useAppSelector(selectActiveMemoryView);
    const [offsetTarget, SetOffsetTarget] = useState<number | undefined>(undefined);

    const {
        overscanCount = 10,
        itemSizes,
        totalItemSize,
        renderItem,
        onClick,
        setMiddleItem
    } = props;


    // Respond to 'navigate' events by setting offset target
    useEffect(() => {

        const navigate = (e: CustomEvent<number>) => SetOffsetTarget(e.detail);

        document.addEventListener<any>('navigate', navigate);

        return () => document.removeEventListener<any>('navigate', navigate);
    }, [SetOffsetTarget])

    // Respond to offsetTarget changes by scrolling
    useEffect(() => {
        if (rootRef.current === null) { return; }
        if (offsetTarget == null) { return; }

        const distance = Math.abs(offsetTarget - rootRef.current.scrollTop);
        const behavior = (distance < viewportHeight) ? 'smooth' : 'auto';

        rootRef.current.scrollTo({ top: offsetTarget, behavior });
        delete scrollTopDict[activeMemoryView];

        SetOffsetTarget(undefined);

    }, [rootRef, offsetTarget, viewportHeight, SetOffsetTarget, activeMemoryView]);

    // http://localhost:3000/project/G8NTKOSW/code/ram/1000

    // Watch for viewport height changes
    useEffect(() => {
        let timeoutID: NodeJS.Timeout;
        let lastViewportHeight = -1;
        const onTimeout = () => {
            if (rootRef.current !== null) {
                const cssHeight = rootRef.current.offsetHeight;
                if (lastViewportHeight !== cssHeight) {
                    lastViewportHeight = cssHeight;
                    setViewportHeight(cssHeight);
                }
            }

            timeoutID = setTimeout(onTimeout, 2000);
        };

        onTimeout();

        return () => {
            clearTimeout(timeoutID);
        }
    }, [rootRef]);

    // Create the SizeAndPositionManager
    useEffect(() => {
        setSizeAndPositionManager(new SizeAndPositionManager(itemSizes, totalItemSize));
    }, [itemSizes, totalItemSize, setSizeAndPositionManager])

    // Rebuild visible items
    useEffect(() => {
        if (rootRef.current === null) { return; }
        if (sizeAndPositionManager === undefined) { return; }
        if (itemSizes.length === 0) { return; }

        const thisScrollRef = rootRef.current;

        const itemCache: { [index: number]: JSX.Element } = {};

        const rebuildItems = (scrollContainer: HTMLDivElement) => {
            const virtualListOffset = scrollContainer.scrollTop;

            const halfFirstItemHeight = itemSizes[0] / 2;

            const halfViewportHeight = viewportHeight / 2;
            const { items: visibleItems, closestToMiddleIndex } = sizeAndPositionManager.getVisibleItemPositions(viewportHeight, virtualListOffset - halfViewportHeight + halfFirstItemHeight, overscanCount);

            if (closestToMiddleIndex !== -1) {
                setMiddleItem(closestToMiddleIndex);
            }

            const renderedVisibleItems = visibleItems.map(i => {
                if (itemCache[i.index] == null) {
                    itemCache[i.index] = (
                        <div className={styles.item} style={{ height: i.height, top: i.top + halfViewportHeight - halfFirstItemHeight }} key={i.index}>
                            {renderItem(i.index)}
                        </div>
                    );
                }

                return itemCache[i.index];
            })

            setItems(renderedVisibleItems);
        }

        let workingScrollTop = scrollTopDict[activeMemoryView];
        if (workingScrollTop != null) {
            logInfo(`Applying saved scrollTop ${scrollTopDict[activeMemoryView] ?? 0}`);
            thisScrollRef.scrollTop = scrollTopDict[activeMemoryView] ?? 0;
        } else {
            workingScrollTop = thisScrollRef.scrollTop;
        }

        const handleScroll = (e: Event) => {
            const element = e.currentTarget as HTMLDivElement;
            workingScrollTop = element.scrollTop;
            rebuildItems(element);
        }

        thisScrollRef.addEventListener('scroll', handleScroll);
        rebuildItems(thisScrollRef);

        return () => {
            logInfo(`Saved scrollTop ${workingScrollTop}`);
            scrollTopDict[activeMemoryView] = workingScrollTop;
            thisScrollRef.removeEventListener('scroll', handleScroll);
        }

    }, [rootRef, overscanCount, sizeAndPositionManager, viewportHeight, renderItem, setMiddleItem, itemSizes, activeMemoryView])

    const itemCount = itemSizes.length;
    const halfFirstItemHeight = itemCount > 0 ? itemSizes[0] / 2 : 0;
    const halfLastItemHeight = itemCount > 0 ? itemSizes[itemSizes.length - 1] / 2 : 0;
    const totalHeight = (viewportHeight / 2) + totalItemSize + (viewportHeight / 2) - halfFirstItemHeight - halfLastItemHeight;

    return (
        <div
            ref={rootRef}
            className={styles.wrapper}
        >
            <div
                style={{ 'height': totalHeight }}
                className={styles.inner}
                onClick={onClick}
            >
                {items}
            </div>
        </div>
    );

}