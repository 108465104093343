import { Utils } from '../Utils';
import { MemoryAddress, MemoryLocation, ReferencePart } from '../code/MemoryLocation';
import { PointerCommand } from './PointerCommand';

const hex = Utils.to4DigitHexString;

export const version = "2";

export const newPointerCommand = (address: number, target: MemoryAddress, part: ReferencePart): PointerCommand => {
    return {
        type: 'pointer',
        address,
        target,
        part
    }
}

export const markUpMemory = (command: PointerCommand, ram: MemoryLocation[], rom: MemoryLocation[]) => {

    const labelIsValid = () => {
        const labelRefTarget = command.target.address;
        const labelRefByteLo = labelRefTarget & 0xff;
        const labelRefByteHi = labelRefTarget >> 8;

        const thisByte = ram[command.address].Value;
        const nextByte = ram[command.address + 1].Value;
        const labelRefPart = command.part;
        const isLoAndLoByteMatches = (labelRefPart === 'lo') && (labelRefByteLo === thisByte);
        const isHiAndHiByteMatches = (labelRefPart === 'hi') && (labelRefByteHi === thisByte);
        const is16BitAndBothBytesMatch = (labelRefPart === '16bit') && (labelRefByteLo === thisByte) && (labelRefByteHi === nextByte);
        return isLoAndLoByteMatches || isHiAndHiByteMatches || is16BitAndBothBytesMatch;
    }

    ram[command.address].OutgoingPointer = { target: command.target, part: command.part }

    const isValid = labelIsValid();
    ram[command.address].OutgoingPointerIsValid = isValid;

    if (isValid) {
        const targetLocation = (command.target.type === 'ram') ? ram[command.target.address] : rom[command.target.address]; // TODO RAM/ROM switch here
        targetLocation.IncomingPointers.push({ type: 'ram', address: command.address });
    }
}

export const equals = (command: PointerCommand, otherCommand: PointerCommand): boolean => {

    let res = true;
    res &&= (command.address === otherCommand.address);
    res &&= (command.target === otherCommand.target);
    res &&= (command.part === otherCommand.part);

    return res;
}

export const serialise = (command: PointerCommand): string => {
    const partShort = (command.part === 'hi') ? 'h' : (
        command.part === 'lo' ? 'l' : 'a'
    );
    const targetMemoryTypeShort = (command.target.type === 'ram') ? 'a' : 'o';
    return `pt|${hex(command.address)}|${hex(command.target.address)}|${targetMemoryTypeShort}|${partShort}`;
}

const deserialise_v01 = (components: string[]): PointerCommand | undefined => {
    if (components.length !== 3) { return; }
    const address = Number(`0x${components[0]}`);
    const target = Number(`0x${components[1]}`);
    const part: ReferencePart | undefined =
        components[2] === 'h' ? 'hi' : (
            components[2] === 'l' ? 'lo' : (
                components[2] === 'a' ? '16bit' : undefined
            )
        );

    if (isNaN(address) || isNaN(target) || part == null) { return; }

    return newPointerCommand(address, { address: target, type: 'ram' }, part);
}

const deserialise_v02 = (components: string[]): PointerCommand | undefined => {
    if (components.length !== 4) { return; }
    const address = Number(`0x${components[0]}`);
    const targetAddress = Number(`0x${components[1]}`);
    const targetMemoryType =
        components[2] === 'o' ? 'rom' : (
            components[2] === 'a' ? 'ram' : undefined
        );
    const part: ReferencePart | undefined =
        components[3] === 'h' ? 'hi' : (
            components[3] === 'l' ? 'lo' : (
                components[3] === 'a' ? '16bit' : undefined
            )
        );

    if (isNaN(address) || isNaN(targetAddress) || targetMemoryType == null || part == null) { return; }

    return newPointerCommand(address, { address: targetAddress, type: targetMemoryType }, part);
}

export const deserialisers: { [version: string]: (c: string[]) => PointerCommand | undefined } = {
    '1': deserialise_v01,
    '2': deserialise_v02
};

