import { useContext } from "react";
import { logCommandAnalytic } from "../../classes/code/Firebase";
import { FormattingType } from "../../classes/commands/FormattingCommand";
import { useOnKeyboardShortcut } from "../../classes/effects/useOnKeyboardShortcut";
import { selectActiveMemoryView, updateRouteToCurrentLine } from "../../store/codeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { formattingCleared, formattingToggled, selectGuidAndName, selectIndexedFormattingCommands } from "../../store/projectSlice";
import { selectSelection } from "../../store/toolSlice";
import { selectPageHasFocus } from "../../store/uiSlice";
import { EnumSetting } from "./EnumSetting";
import { commandIcon } from "./MarkupIcons";
import { SettingsRow } from "./SettingsRow";
import { RibbonMenuContext } from "../RibbonMenu";

export const FormattingMarkupSettings: React.FC = () => {

    const { guid, name } = useAppSelector(selectGuidAndName);

    const pageHasFocus = useAppSelector(selectPageHasFocus);
    const { isHiddenCopy } = useContext(RibbonMenuContext);

    const { selectionAddress, selectionCount } = useAppSelector(selectSelection);
    const selectionEndAddress = selectionAddress + selectionCount - 1;
    const activeMemoryView = useAppSelector(selectActiveMemoryView);
    const isRAM = activeMemoryView === 'ram';

    const dispatch = useAppDispatch();

    const formattingCommands = useAppSelector(selectIndexedFormattingCommands)
        .filter(c => c.command.address >= selectionAddress && c.command.address <= selectionEndAddress);

    const handleFormattingChange = (v: FormattingOptions) => {
        if (v === 'none') {
            formattingCommands.sort((a, b) => a.index - b.index).reverse().forEach(c => {
                logCommandAnalytic('delete_command', 'formatting', c.command.address, guid, name);
            });
            dispatch(formattingCleared({ startAddress: selectionAddress, endAddress: selectionEndAddress }));
        } else if (selectionCount === 1) {
            const makeNew = formattingCommands.length === 0;
            logCommandAnalytic(makeNew ? 'add_command' : 'edit_command', 'formatting', selectionAddress, guid, name);
            dispatch(formattingToggled({ address: selectionAddress, toggleType: makeNew ? (v === 'gap') : true }));
        }

        updateRouteToCurrentLine();
    }

    type FormattingOptions = FormattingType | 'none' | 'mixed';
    let current: FormattingOptions = 'none';
    const uniqueTypes = [...new Set(formattingCommands.map(c => c.command.format))];
    if (!isRAM || uniqueTypes.length === 0) {
        current = 'none';
    } else if (uniqueTypes.length === 1 && selectionCount === formattingCommands.length) {
        current = uniqueTypes[0];
    } else {
        current = 'mixed';
    }

    const showMixedOnly = current === 'mixed';

    const simple = selectionCount === 1;
    const lineProperty = isRAM && (simple || (current === 'line-break')) ? 'line' : '';
    const gapProperty = isRAM && (simple || (current === 'gap')) ? 'gap' : '';
    const mixedProperty = isRAM && (showMixedOnly) ? 'mixed' : '';

    const options = showMixedOnly ? ['mixed'] : ['line', 'gap'];

    useOnKeyboardShortcut('f', pageHasFocus && !isHiddenCopy, () => {
        const canAddOrEdit = isRAM && (selectionCount === 1) && (formattingCommands.length < 2);
        if (!canAddOrEdit) { return; }
        if (formattingCommands.length === 0) {
            handleFormattingChange('line-break');
        } else {
            const newType: FormattingType = formattingCommands[0].command.format === 'gap' ? 'line-break' : 'gap';
            handleFormattingChange(newType);
        }
    });

    useOnKeyboardShortcut('F', pageHasFocus && !isHiddenCopy, () => {
        if (formattingCommands.length === 0) { return; }
        handleFormattingChange('none');
    });

    return (
        <SettingsRow label={<>{commandIcon('formatting')}Formatting</>}>
            <EnumSetting
                label="formatting"
                selectedOption={current}
                options={{ 'none': 'none', [lineProperty]: 'line-break', [gapProperty]: 'gap', [mixedProperty]: 'mixed' }}
                order={['none', ...options]}
                onChange={handleFormattingChange}
            />
        </SettingsRow>
    );
}