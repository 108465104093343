import styles from './Setting.module.css';
import { MouseEvent, useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newLabelCommand } from "../../classes/commands/LabelCommandHelpers";
import { selectActiveMemoryView, updateRouteToCurrentLine } from "../../store/codeSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { commandAdded, commandRemoved, commandUpdated, selectGuidAndName, selectIndexedLabelCommands } from "../../store/projectSlice";
import { selectSelection } from "../../store/toolSlice";
import { EnumSetting } from "./EnumSetting";
import { SettingsRow } from "./SettingsRow";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { Modal } from "./Modal";
import { useState } from "react";
import { TextInput } from './TextInput';
import { logCommandAnalytic } from '../../classes/code/Firebase';
import { commandIcon } from './MarkupIcons';
import { useOnKeyboardShortcut } from '../../classes/effects/useOnKeyboardShortcut';
import { selectPageHasFocus } from '../../store/uiSlice';
import { RibbonMenuContext } from '../RibbonMenu';

export const LabelMarkupSettings: React.FC = () => {

    const { guid, name } = useAppSelector(selectGuidAndName);

    const pageHasFocus = useAppSelector(selectPageHasFocus);
    const { isHiddenCopy } = useContext(RibbonMenuContext);

    const [modalIsOpen, SetModalIsOpen] = useState<boolean>(false);

    const { selectionAddress, selectionCount } = useAppSelector(selectSelection);
    const selectionEndAddress = selectionAddress + selectionCount - 1;

    const activeMemoryView = useAppSelector(selectActiveMemoryView);
    const isRAM = activeMemoryView === 'ram';

    const dispatch = useAppDispatch();

    // Labels
    const labelCommands = useAppSelector(selectIndexedLabelCommands).filter(
        c => c.command.address >= selectionAddress && c.command.address <= selectionEndAddress
    );

    const touchedLabelCommand = labelCommands.filter(c => c.command.address === selectionAddress && selectionCount === 1).shift();
    const labelText = touchedLabelCommand?.command.text ?? 'new label';

    const labelValue = labelText;
    const labelLabel = labelText.substring(0, 10).concat(labelText.length > 10 ? '…' : '');
    const noneLabel = 'none';
    const noneValue = 'none';
    const mixedLabel = 'mixed';
    const mixedValue = 'mixed';

    let selectedValue = noneValue;
    if (!isRAM || labelCommands.length === 0) {
        selectedValue = noneValue;
    } else if (selectionCount > 1) {
        selectedValue = mixedValue;
    } else {
        selectedValue = labelValue;
    }

    const extraPropertyLabel = (selectionCount < 2) ? labelLabel : mixedLabel;

    const labelOption = isRAM && (selectedValue !== mixedValue) ? { [labelLabel]: labelValue } : undefined;
    const mixedOption = isRAM && (selectedValue === mixedValue) ? { [mixedLabel]: mixedValue } : undefined;

    const canEdit = isRAM && (selectionCount === 1) && touchedLabelCommand;

    const handleLabellingChanged = (labelValue: string) => {
        if (labelValue === noneValue) {
            labelCommands.sort((a, b) => a.index - b.index).reverse().forEach(c => {
                logCommandAnalytic('delete_command', 'label', c.command.address, guid, name);
                dispatch(commandRemoved(c.index))
            });
        } else {
            logCommandAnalytic('add_command', 'label', selectionAddress, guid, name);
            dispatch(commandAdded(newLabelCommand(selectionAddress, labelValue)));
            SetModalIsOpen(true);
        }

        updateRouteToCurrentLine();
    }

    const handleLabelValueChanged = (text: string) => {
        SetModalIsOpen(false);
        if (touchedLabelCommand == null) { return; }

        const { address } = touchedLabelCommand.command;
        const cmd = newLabelCommand(address, text);
        logCommandAnalytic('edit_command', 'label', address, guid, name);
        dispatch(commandUpdated({ commandIndexToUpdate: touchedLabelCommand.index, command: cmd }));
        updateRouteToCurrentLine();
    }

    const handleCloseModal = () => {
        SetModalIsOpen(false);
    }

    const handleOpenModal = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        SetModalIsOpen(true);
    }

    useOnKeyboardShortcut('l', pageHasFocus && !isHiddenCopy, () => {
        const canAdd = isRAM && (selectionCount === 1) && (touchedLabelCommand == null);
        if (!canAdd) { return; }
        handleLabellingChanged('new label');
    });

    useOnKeyboardShortcut('L', pageHasFocus && !isHiddenCopy, () => {
        if (labelCommands.length === 0) { return; }
        handleLabellingChanged(noneValue);
    })

    return (
        <SettingsRow label={<>{commandIcon('label')}Label</>}>
            <EnumSetting
                label="label"
                selectedOption={selectedValue}
                options={{ [noneLabel]: noneValue, ...mixedOption, ...labelOption }}
                order={[noneLabel, extraPropertyLabel]}
                onChange={handleLabellingChanged}
            />
            <div style={{ display: 'flex' }}>
                <button onClick={handleOpenModal} disabled={!canEdit}><FontAwesomeIcon icon={faPenToSquare} className={styles.iconButton} /></button>
                {modalIsOpen &&
                    <Modal onClose={handleCloseModal} align="bottom-right">
                        <div className={styles.modalTextEditorField}>
                            <TextInput
                                enabled={true}
                                label='label'
                                text={labelText}
                                onChange={handleLabelValueChanged}
                                size={30}
                            />
                        </div>
                    </Modal>
                }
            </div>
        </SettingsRow>
    );
}