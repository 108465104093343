import styles from './ProjectList.module.css';
import { useCallback, useEffect, useState } from "react";
import { ProjectInfo } from "../../classes/ProjectInfo";
import { logError } from "../../classes/Logger";
import { ProjectCard } from './ProjectCard';
import { Cursor, PageAction } from '../../classes/CloudStorageHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export const ProjectList: React.FC<{ label?: string, listFunction: () => Promise<ProjectInfo[] | undefined> }> = ({ label, listFunction }) => {

    const [isReady, SetIsReady] = useState<boolean>(false);
    const [projectInfos, SetProjectInfos] = useState<ProjectInfo[]>([]);

    const hasInfos = (projectInfos.length > 0);

    // Call the list function on mount and when told to via an event
    useEffect(() => {

        const doRefresh = async () => {
            try {
                SetIsReady(false);

                const infos = await listFunction();
                if (infos != null) {
                    SetProjectInfos(infos);
                }
            } catch (error) {
                logError(String(error));
            } finally {
                SetIsReady(true);
            }
        }

        doRefresh();

        document.addEventListener<any>('refresh-project-lists', doRefresh);
        return () => document.removeEventListener<any>('refresh-project-lists', doRefresh);
    }, [listFunction]);

    return (
        <div className={styles.listContainer}>
            {(label != null) && <h4>{label}</h4>}

            {!isReady &&
                <div className={styles.detail}>...loading...</div>
            }

            {isReady &&
                <div className={styles.listItemsContainer}>
                    {hasInfos && projectInfos.map(p => <ProjectCard key={p.guid} projectInfo={p} />)}
                    {!hasInfos && <div className={styles.detail}>No projects</div>}
                </div>
            }
        </div>
    )
}


export const PaginatedProjectList: React.FC<{
    label?: string,
    maxPerPage: number,
    listFunction: (countPerPage: number, cursor?: Cursor, pageAction?: PageAction) => Promise<{
        projects: ProjectInfo[];
        updatedCursor: Cursor;
        moreBefore: boolean;
        moreAfter: boolean;
    }>
}
> = ({ label, maxPerPage, listFunction }) => {

    const [isReady, SetIsReady] = useState<boolean>(false);
    const [projectInfos, SetProjectInfos] = useState<ProjectInfo[]>([]);

    const [cursor, SetCursor] = useState<Cursor | undefined>();
    const [prevButtonEnabled, SetPrevButtonEnabled] = useState(false);
    const [nextButtonEnabled, SetNextButtonEnabled] = useState(false);

    const hasInfos = (projectInfos.length > 0);

    const refresh = useCallback(async (cursor?: Cursor, pageAction?: PageAction) => {

        try {
            SetIsReady(false);

            const { projects, updatedCursor, moreBefore, moreAfter } = await listFunction(maxPerPage, cursor, pageAction);
            if (projects == null || projects.length === 0) { throw new Error('No projects found'); }

            SetProjectInfos(projects);
            SetCursor(updatedCursor);
            SetPrevButtonEnabled(moreBefore);
            SetNextButtonEnabled(moreAfter);

        } catch (error) {
            logError(String(error));
        } finally {
            SetIsReady(true);
        }
    }, [listFunction, maxPerPage]);

    // Call the list function on mount and when told to via an event
    useEffect(() => {
        const doRefresh = async () => await refresh();
        doRefresh();

        document.addEventListener<any>('refresh-project-lists', doRefresh);
        return () => document.removeEventListener<any>('refresh-project-lists', doRefresh);
    }, [refresh]);

    const prevPage = async () => {
        await refresh(cursor, 'prev');
    }

    const nextPage = async () => {
        await refresh(cursor, 'next');
    }

    return (
        <div className={styles.listContainer}>
            {(label != null) && <h4>{label}</h4>}

            {!isReady &&
                <div className={styles.overlay} />
            }

            <div className={styles.pageControls}>
                <button onClick={e => { e.stopPropagation(); prevPage(); }} disabled={!prevButtonEnabled}><FontAwesomeIcon icon={faAngleLeft} className={styles.icon} />Prev</button>
                <button onClick={e => { e.stopPropagation(); nextPage(); }} disabled={!nextButtonEnabled}>Next<FontAwesomeIcon icon={faAngleRight} className={styles.icon} /></button>
            </div>


            <div className={styles.listItemsContainer}>
                {(!hasInfos && isReady) && <div className={styles.detail}>No projects</div>}
                {(!hasInfos && !isReady) && <div className={styles.detail}>...loading...</div>}

                {hasInfos && projectInfos.map(p => <ProjectCard key={p.guid} projectInfo={p} />)}
            </div>
        </div>
    )
}