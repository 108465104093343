import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { logReducer, logSelector } from "../classes/Logger";

export type ToolTypes = 'graphics' | 'code' | 'library';

type ToolState = {
    activeTool: ToolTypes;
    selectionAddress: number;
    selectionCount: number;
    selectionAddressROM: number;
    selectionCountROM: number;
}


const initialState: ToolState = {
    activeTool: 'graphics',
    selectionAddress: 0x0400,
    selectionCount: 0x03e8,
    selectionAddressROM: 0xd020,
    selectionCountROM: 0x0001,
}

const toolSlice = createSlice({
    name: 'tool',
    initialState,
    reducers: {
        activeToolSet(state, action: PayloadAction<ToolTypes>) {
            logReducer('toolSlice', 'activeToolSet');
            state.activeTool = action.payload;
        },

        selectionAddressSet(state, action: PayloadAction<{ address: number, type: 'ram' | 'rom' }>) {
            logReducer('toolSlice', 'selectionAddressSet');
            const { address, type } = action.payload;
            if (type === 'ram') {
                state.selectionAddress = address;
            } else {
                state.selectionAddressROM = address;
            }
        },

        selectionExtended(state, action: PayloadAction<{ address: number, type: 'ram' | 'rom' }>) {
            logReducer('toolSlice', 'selectionEndAddressSet');
            const { address, type } = action.payload;

            const isRAM = (type === 'ram');
            const selectionAddress = isRAM ? state.selectionAddress : state.selectionAddressROM;
            const selectionCount = isRAM ? state.selectionCount : state.selectionCountROM;

            let newStartAddress: number;
            let newEndAddress: number;
            if (address < selectionAddress)
                newStartAddress = address;
            else
                newStartAddress = selectionAddress;

            if (address > selectionAddress + selectionCount - 1)
                newEndAddress = address;
            else
                newEndAddress = selectionAddress + selectionCount - 1;

            const newCount = newEndAddress - newStartAddress + 1;

            if (isRAM) {
                state.selectionAddress = newStartAddress;
                state.selectionCount = newCount;
            } else {
                state.selectionAddressROM = newStartAddress;
                state.selectionCountROM = newCount;
            }
        },

        selectionCountSet(state, action: PayloadAction<{ count: number, type: 'ram' | 'rom' }>) {
            logReducer('toolSlice', 'selectionCountSet');
            const { count, type } = action.payload;
            if (type === 'ram') {
                state.selectionCount = count;
            } else {
                state.selectionCountROM = count;
            }
        },

        selectionSet(state, action: PayloadAction<{ startAddress: number, count: number, type: 'ram' | 'rom' }>) {
            logReducer('toolSlice', 'selectionSet');
            const { startAddress, count, type } = action.payload;
            if (type === 'ram') {
                state.selectionAddress = startAddress;
                state.selectionCount = count;
            } else {
                state.selectionAddressROM = startAddress;
                state.selectionCountROM = count;
            }
        },

        countAddedToAddress(state) {
            logReducer('toolSlice', 'countAddedToAddress');
            state.selectionAddress = Math.min(state.selectionAddress + state.selectionCount, 0xffff);
        },

        countTakenFromAddress(state) {
            logReducer('toolSlice', 'countTakenFromAddress');
            state.selectionAddress = Math.max(state.selectionAddress - state.selectionCount, 0x0000)
        }
    }
})

export const { activeToolSet, selectionAddressSet, selectionCountSet, selectionSet, selectionExtended, countAddedToAddress, countTakenFromAddress } = toolSlice.actions;
export default toolSlice.reducer;

export const selectActiveTool = createSelector(
    (state: RootState) => state.tool,
    (tool) => {
        logSelector('toolSlice', 'selectActiveTool');
        return tool.activeTool;
    }
)

export const selectSelection = createSelector(
    (state: RootState) => state.tool.selectionAddress,
    (state: RootState) => state.tool.selectionCount,
    (state: RootState) => state.tool.selectionAddressROM,
    (state: RootState) => state.tool.selectionCountROM,
    (selectionAddress, selectionCount, selectionAddressROM, selectionCountROM) => {
        logSelector('toolSlice', 'selectSelection');
        return { selectionAddress, selectionCount, selectionAddressROM, selectionCountROM }
    }
)