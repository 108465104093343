import React, { useMemo, useRef } from 'react';
import { GraphicCommand } from '../classes/commands/GraphicCommand';
import { useOnDraw } from '../classes/effects/useOnDraw';
import { GraphicData } from '../classes/graphics/GraphicData';
import { Utils } from '../classes/Utils';
import { ViewportLocation } from '../store/rendererSlice';
import { memoryMapHeightSet } from '../store/uiSlice';
import styles from './MemoryMap.module.css';
import { newGraphicCommand } from '../classes/commands/GraphicCommandHelpers';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { logMajorComponentRender } from '../classes/Logger';
import { Stack } from './Stack';
import { navigate, selectActiveMemoryView } from '../store/codeSlice';

const Label: React.FunctionComponent<{ colour: string, label: string }> = (props) => {
    return (
        <div className={styles.label}>
            {props.label}
            <span style={{ backgroundColor: `rgb(var(${props.colour}))` }} className={styles.swatch} />
        </div>
    )
}

export const MemoryMap: React.FC = () => {

    logMajorComponentRender(MemoryMap.name);

    const activeMemoryView = useAppSelector(selectActiveMemoryView);

    const divRefSource = useRef<HTMLDivElement>(null);
    const prevHeight = useRef(-1);
    const settingsRef = useRef<GraphicCommand>();
    let mouseDown = false;

    const dispatch = useAppDispatch();

    const dataRef = useRef<GraphicData>({
        location: ViewportLocation.MemoryMap,
        left: 0,
        top: 0,
        width: 0,
        height: 0
    });

    const onDraw = useMemo(() => () => {

        if (divRefSource.current == null || dataRef.current == null) {
            return undefined;
        }

        const data = dataRef.current;
        const rect = divRefSource.current.getBoundingClientRect();

        if (prevHeight.current !== rect.height) {
            dispatch(memoryMapHeightSet(rect.height));
            prevHeight.current = rect.height;

            settingsRef.current = newGraphicCommand({
                address: 0x0000,
                countBytes: 0x10000,
                widthPx: 0,
                heightPx: rect.height,
                scale: 1,
                graphicsType: 'MemoryMap'
            });
        }

        data.left = rect.left;
        data.top = rect.top;
        data.width = rect.width;
        data.height = rect.height;
        data.settings = settingsRef.current;

        return data;

    }, [divRefSource, dataRef, settingsRef, prevHeight, dispatch]);
    useOnDraw(onDraw);

    // const handleStart = (e: React.MouseEvent<HTMLDivElement>) => {
    //     mouseDown = true;
    // }

    // const handleStop = (e: React.MouseEvent<HTMLDivElement>) => {
    //     mouseDown = false;
    // }

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        handleMove(e, true);
    }

    const handleMove = (e: React.MouseEvent<HTMLDivElement>, fromClick: boolean = false) => {
        if (prevHeight.current === -1) { return; }
        if (!fromClick && !mouseDown) { return; }

        const row = e.clientY - e.currentTarget.offsetTop;
        const thisRowStartAddress = Utils.clamp(Math.floor((0x10000 * (row / prevHeight.current)) + 0.5), 0x0000, 0xffff);

        navigate({ address: thisRowStartAddress, type: activeMemoryView });
    }

    return (
        <Stack layout='vertical' sizes='1fr auto' cssClassName={styles.outer}>
            <div
                ref={divRefSource}
                className={styles.map}
                onClick={handleClick}
                // onMouseDown={handleStart}
                // onMouseMove={handleMove}
                // onMouseUp={handleStop}
                // onMouseLeave={handleStop}
            />

            <div className={styles.legend}>
                <Label colour={'--colour-graphics-source'} label='Gfx' />
                <Label colour={'--colour-code-source'} label='Code' />
                <Label colour={'--colour-data-source'} label='Data' />
            </div>
        </Stack>
    )
}