import * as React from "react";
import { useEffect, useRef } from "react";
import { recentlyClickedAddressAdded, selectRecentlyClickedAddresses } from "../../store/uiSlice";
import styles from '../MainPanel.module.css';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logMajorComponentRender } from "../../classes/Logger";

const scrollTopDict: { [name: string]: number } = {};


export const PersistentScroller: React.FunctionComponent<{ name: string, children: React.ReactNode }> = (props) => {

    logMajorComponentRender(PersistentScroller.name);

    const dispatch = useAppDispatch();
    const scrollTop = scrollTopDict[props.name] ?? 0;
    const scrollRef = useRef<HTMLDivElement>(null);

    const recentlyClickedAddress = useAppSelector(selectRecentlyClickedAddresses);

    useEffect(() => {
        if (scrollRef.current == null) { return; }

        if (recentlyClickedAddress !== -1) {
            const offsetToFocus = scrollRef.current.scrollHeight * (recentlyClickedAddress / 0x10000);
            const halfViewportHeight = scrollRef.current.clientHeight / 2;
            const offset = Math.max(offsetToFocus - halfViewportHeight, 0);
            scrollTopDict[props.name] = offset;
            dispatch(recentlyClickedAddressAdded(-1));
            return;
        }

        const thisScrollRef = scrollRef.current;
        thisScrollRef.scrollTop = scrollTop;

        let workingScrollTop = scrollTop;
        const handleOnScroll = (e: Event) => {
            const element = e.currentTarget as HTMLDivElement;
            workingScrollTop = element.scrollTop;
        };

        thisScrollRef.addEventListener('scroll', handleOnScroll);

        return () => {
            scrollTopDict[props.name] = workingScrollTop;
            thisScrollRef.removeEventListener('scroll', handleOnScroll);
        }
    }, [scrollRef, scrollTop, recentlyClickedAddress, props.name, dispatch]);


    return <div className={styles.frame} ref={scrollRef}>{props.children}</div>
}