import { Link } from "react-router-dom";
import { setWindowTitle, toHex, toHexLong } from "../classes/Utils";
import { PlottedByteSequence } from "./lib/PlottedByteSequence";
import { SequenceExplorer } from "./lib/SequenceExplorer";
import { roomBytes, roomPointersBytes } from "./MontezumasRevengeData";
// import { useAppDispatch } from "../store/hooks";
// import { useEffect } from "react";
// import { memorySet } from "../store/projectSlice";

type RoomData = { baseAddress: number, offset: number, count: number, bytes: number[], sequences: PlottedByteSequence[], error: boolean, mysteryBytes: number[] }

const prepareStandardAddress = (sequence: PlottedByteSequence) => {
    const addressHi = sequence.describeBits(0, 'address-hi', 0b11000000, v => v * 0x100, t => toHexLong(t, true));
    const addressLo = sequence.describeBits(1, 'address-lo', 0b11111111, undefined, t => toHex(t, true));
    const address = addressHi.transformed + addressLo.transformed;
    const y = Math.floor(address / 0x28);
    const x = address - (y * 0x28);
    const coordsStr = ` @ (${x}, ${y})`;

    return { addressHi, addressLo, coordsStr };
}

const decodeRoom = (bytesOrig: number[]) => {

    const bytes = [...bytesOrig, 0, 0, 0];

    let counter = 0;
    let sequences: PlottedByteSequence[] = [];
    let loopCounter = 0;
    while (counter < bytesOrig.length && loopCounter < 500) {

        const b0 = bytes[counter];
        const b1 = bytes[counter + 1]
        const b2 = bytes[counter + 2]
        const b3 = bytes[counter + 3];
        const sequence = new PlottedByteSequence([b0, b1, b2, b3]);

        if ((b0 & 0x3e) === 0x3e) {
            // Reference to another room
            const unused = sequence.describeBits(0, 'unused', 0b11000000);
            const refType = sequence.describeBits(0, 'type', 0b00111110, undefined, _ => 'reference');
            const mirrored = sequence.describeBits(0, 'mirrored', 0b00000001, undefined, t => ['normal', 'mirrored'][t]);

            const roomRef = sequence.describeBits(1, 'roomRef', 0b11111111, undefined, t => toHex(t, true));

            sequence.setElements([unused, refType, mirrored, roomRef]);

            sequence.setBytesCount(2);
            sequence.setSummary(`${refType.displayed}-${mirrored.displayed}:${roomRef.displayed}`);

        } else if ((b0 & 0x3f) === 0x3d) {
            // Level gate - anything after this is ignored if level is too low
            const unused = sequence.describeBits(0, 'unused', 0b11000000);
            const gateType = sequence.describeBits(0, 'type', 0b00111111, undefined, _ => 'level-gate');
            const level = sequence.describeBits(1, 'level', 0b11111111, undefined, t => toHex(t));

            sequence.setElements([unused, gateType, level]);

            sequence.setBytesCount(2);
            sequence.setSummary(`${gateType.displayed}-${level.displayed}`);

        } else if ((b0 & 0b00111100) === 0b00000000) {
            // Rectangle
            const { addressHi, addressLo, coordsStr } = prepareStandardAddress(sequence);
            const rectType = sequence.describeBits(0, 'type', 0b00111100, undefined, _ => 'rect');
            const option = sequence.describeBits(0, 'option', 0b00000010, undefined, t => ['blanks', 'bricks'][t]);
            const extended = sequence.describeBits(0, 'extended', 0b00000001, undefined, t => ['normal', 'extended'][t]);

            const isExtended = (extended.value === 1);

            const width = isExtended ? sequence.describeBits(2, 'width', 0b11111111, v => v + 1) : sequence.describeBits(2, 'packed-width', 0b00001111, v => v + 1);
            const height = isExtended ? sequence.describeBits(3, 'height', 0b11111111, v => v + 1) : sequence.describeBits(2, 'packed-height', 0b11110000, v => v + 1);

            sequence.setElements([addressHi, addressLo, rectType, option, extended, width, height]);

            sequence.setBytesCount(isExtended ? 4 : 3);
            sequence.setSummary(`${rectType.displayed}-${option.displayed}-${extended.displayed}-${width.displayed}x${height.displayed}${coordsStr}`);

        } else if ((b0 & 0b00111100) === 0b00000100) {
            // Slope
            const { addressHi, addressLo, coordsStr } = prepareStandardAddress(sequence);
            const slopeType = sequence.describeBits(0, 'type', 0b00111100, undefined, _ => 'slope');
            const option = sequence.describeBits(0, 'option', 0b00000011, undefined, t => ['down-left-above', 'down-right-below', 'down-left-below', 'down-right-above'][t]);
            const size = sequence.describeBits(2, 'size', 0b11111111);

            sequence.setElements([addressHi, addressLo, slopeType, option, size]);

            sequence.setBytesCount(3);
            sequence.setSummary(`${slopeType.displayed}-${option.displayed}:${size.displayed}x${size.displayed}${coordsStr}`);

        } else if ((b0 & 0b00111100) === 0b00001000) {
            // Vertically-scaled objects
            const { addressHi, addressLo, coordsStr } = prepareStandardAddress(sequence);
            const verticalType = sequence.describeBits(0, 'type', 0b00111100, undefined, _ => 'vertical');
            const option = sequence.describeBits(0, 'option', 0b00000011, undefined, t => ['ladder', 'rope', 'pole', 'fence'][t])
            const height = sequence.describeBits(2, 'height', 0b11111111);

            sequence.setElements([addressHi, addressLo, verticalType, option, height]);

            sequence.setBytesCount(3);
            sequence.setSummary(`${verticalType.displayed}-${option.displayed}:${height.displayed}h${coordsStr}`);

        } else if ((b0 & 0b00111100) === 0b00001100) {
            // Horizontally-scaled objects
            const { addressHi, addressLo, coordsStr } = prepareStandardAddress(sequence);
            const horizontalType = sequence.describeBits(0, 'type', 0b00111100, undefined, _ => 'horizontal');
            const option = sequence.describeBits(0, 'option', 0b00000011, undefined, t => ['conveyor-right', 'conveyor-left', 'bridge', 'invisible-bridge'][t]);
            const width = sequence.describeBits(2, 'width', 0b11111111);

            sequence.setElements([addressHi, addressLo, horizontalType, option, width]);

            sequence.setBytesCount(3);
            sequence.setSummary(`${horizontalType.displayed}-${option.displayed}:${width.displayed}w${coordsStr}`);

        } else if ((b0 & 0b00111100) === 0b00010000) {
            // Rectangle alternative - unused?ß
            const { addressHi, addressLo, coordsStr } = prepareStandardAddress(sequence);
            const rectType = sequence.describeBits(0, 'type', 0b00111100, undefined, _ => 'rect-alt');
            const option = sequence.describeBits(0, 'option', 0b00000010, undefined, t => ['unknown-a', 'unknown-b'][t]);
            const extended = sequence.describeBits(0, 'extended', 0b00000001, undefined, t => ['normal', 'extended'][t]);

            const isExtended = (extended.value === 1);

            const width = isExtended ? sequence.describeBits(2, 'width', 0b11111111, v => v + 1) : sequence.describeBits(2, 'packed-width', 0b00001111, v => v + 1);
            const height = isExtended ? sequence.describeBits(3, 'height', 0b11111111, v => v + 1) : sequence.describeBits(2, 'packed-height', 0b11110000, v => v + 1);

            sequence.setElements([addressHi, addressLo, rectType, option, extended, width, height]);

            sequence.setBytesCount(isExtended ? 4 : 3);
            sequence.setSummary(`${rectType.displayed}-${option.displayed}-${extended.displayed}-${width.displayed}x${height.displayed}${coordsStr}`);

        } else if ((b0 & 0b00111100) === 0b00010100) {
            // Steep slope(double height) - unused?
            const { addressHi, addressLo, coordsStr } = prepareStandardAddress(sequence);
            const slopeType = sequence.describeBits(0, 'type', 0b00111100, undefined, _ => 'steep-slope');
            const option = sequence.describeBits(0, 'option', 0b00000011, undefined, t => ['down-left-above', 'down-right-below', 'down-left-below', 'down-right-above'][t]);
            const size = sequence.describeBits(2, 'size', 0b11111111);

            sequence.setElements([addressHi, addressLo, slopeType, option, size]);

            sequence.setBytesCount(3);
            sequence.setSummary(`${slopeType.displayed}-${option.displayed}:${size.displayed}x${size.displayed}${coordsStr}`);

        } else if ((b0 & 0b00111100) === 0b00011000) {
            // Vertically-scaled objects with top piece
            const { addressHi, addressLo, coordsStr } = prepareStandardAddress(sequence);
            const verticalType = sequence.describeBits(0, 'type', 0b00111100, undefined, _ => 'vertical-with-top');
            const unused = sequence.describeBits(0, 'unknown', 0b00000010, undefined)
            const option = sequence.describeBits(0, 'option', 0b00000001, undefined, t => ['ladder', 'rope'][t])
            const height = sequence.describeBits(2, 'height', 0b11111111);

            sequence.setElements([addressHi, addressLo, verticalType, unused, option, height]);

            sequence.setBytesCount(3);
            sequence.setSummary(`${verticalType.displayed}-${option.displayed}:${height.displayed}h${coordsStr}`);

        } else if ((b0 & 0b00111100) === 0b00011100) {
            // Horizontally-scaled objects with repeating chars
            const { addressHi, addressLo, coordsStr } = prepareStandardAddress(sequence);
            const horizontalType = sequence.describeBits(0, 'type', 0b00111100, undefined, _ => 'horizontal-pattern');
            const option = sequence.describeBits(0, 'option', 0b00000011, undefined, t => ['small-bricks', 'small-bricks-alternating', 'fire-top', 'fire-body'][t]);
            const width = sequence.describeBits(2, 'width', 0b11111111);

            sequence.setElements([addressHi, addressLo, horizontalType, option, width]);

            sequence.setBytesCount(3);
            sequence.setSummary(`${horizontalType.displayed}-${option.displayed}:${width.displayed}w${coordsStr}`);

        } else if ((b0 & 0b00111000) === 0b00100000) {
            // Interactibles 1 - misc
            const { addressHi, addressLo, coordsStr } = prepareStandardAddress(sequence);
            const interactiblesAType = sequence.describeBits(0, 'type', 0b00111000, undefined, _ => 'interactibles-a');
            const option = sequence.describeBits(0, 'option', 0b00000111, undefined, t => ['gem', 'torch', 'sword', 'fire', 'blueKey', 'redKey', 'cyanKey', 'barrel'][t]);

            sequence.setElements([addressHi, addressLo, interactiblesAType, option]);

            sequence.setBytesCount(2);
            sequence.setSummary(`${interactiblesAType.displayed}-${option.displayed}${coordsStr}`);

        } else if ((b0 & 0b00111000) === 0b00101000) {
            // Interactibles 2 - Doors and misc
            const { addressHi, addressLo, coordsStr } = prepareStandardAddress(sequence);
            const interactiblesBType = sequence.describeBits(0, 'type', 0b00111000, undefined, _ => 'interactibles-b');
            const option = sequence.describeBits(0, 'option', 0b00000111, undefined, t => ['blueDoor', 'redDoor', 'cyanDoor', 'cobwebRight', 'cobwebLeft', 'unknown-a', 'unknown-b', 'unknown-c'][t]);

            sequence.setElements([addressHi, addressLo, interactiblesBType, option]);

            sequence.setBytesCount(2);
            sequence.setSummary(`${interactiblesBType.displayed}-${option.displayed}${coordsStr}`);

        } else if ((b0 & 0b00110000) === 0b00110000) {
            const somethingLo = sequence.describeBits(0, 'something-lo', 0b11000000, undefined);
            const enemyType = sequence.describeBits(0, 'type', 0b00110000, undefined, _ => 'enemy');
            const unknownB = sequence.describeBits(0, 'unknown', 0b00001000);
            //const option = sequence.describeBits(0, 'option', 0b00000111, undefined, t => ['a', 'spider', 'rolling-skull', 'd', 'unknown-a', 'unknown-b', 'unknown-c', 'unknown-d'][t]);
            const somethingHi = sequence.describeBits(0, 'something-hi', 0b00000111, v => v * 4);
            const xCoord = sequence.describeBits(1, 'x-coord', 0b11110000, v => [0x17, 0x26, 0x2E, 0x32, 0x34, 0x36, 0x3E, 0x46, 0x4C, 0x53, 0x63, 0x68, 0x6E, 0x7B, 0x86, 0x96][v], t => toHex(t)); // see 9563
            const yCoord = sequence.describeBits(1, 'y-coord', 0b00001111, v => [0x6A, 0x8A, 0x92, 0x9A, 0xA2, 0xB2, 0xBA, 0xC2, 0xD2, 0xDA, 0xE2, 0x00, 0x00, 0x00, 0x00, 0x00][v], t => toHex(t)); // see 956e

            sequence.setElements([somethingLo, somethingHi, enemyType, unknownB, xCoord, yCoord]);

            sequence.setBytesCount(2);
            sequence.setSummary(`enemy:$${toHex(b0)}/$${toHex(b1)}-${toHex(somethingHi.transformed + somethingLo.transformed)}`);

        } else {
            sequence.setElements([]);
            sequence.setBytesCount(0);
            sequence.setSummary('UNKNOWN_TYPE :(');
        }

        sequences.push(sequence);
        counter += sequence.bytesCount;

        loopCounter++;
    }

    const mysteryBytes = bytesOrig.slice(counter, bytesOrig.length);
    const error = mysteryBytes.length > 0;

    return { sequences, error, mysteryBytes };
}

const baseAddress = roomBytes.address;
const roomCount = roomPointersBytes.bytes.length / 2;
const rooms: RoomData[] = new Array(roomCount)
    .fill(0)
    .map((_, i) => {
        const getOffset = (index: number) => {
            const lo = roomPointersBytes.bytes[2 * index]
            const hi = roomPointersBytes.bytes[2 * index + 1]
            const address = lo + (hi * 0x0100);
            return address - baseAddress;
        }

        const offset = getOffset(i);
        const count = i < (roomCount - 1) ? getOffset(i + 1) - offset : roomBytes.bytes.length - offset;
        const bytes = roomBytes.bytes.slice(offset, offset + count);
        const { sequences, error, mysteryBytes } = decodeRoom(bytes)
        return { baseAddress, offset, count, bytes, sequences, error, mysteryBytes };
    });


export const MontezumasRevenge: React.FC = () => {

    setWindowTitle(`46c - Montezuma's Revenge rooms`);

    // const dispatch = useAppDispatch();

    // useEffect(() => {
    //     const RAM = new Array(0x10000).fill(0).map(() => Math.random() * 255);
    //     const COLRAM = new Array(0x03e8).fill(0).map(() => Math.random() * 255);
    //     dispatch(memorySet({ RAM, COLRAM }))
    // }, [dispatch]);

    return (
        <>
            <div>
                See 'SetUpAndDrawRoom' <Link to='/project/4DQVK9QP/code/ram/8f7a'>8f7a</Link>
            </div>
            {rooms.map((p, i) =>
                <div key={i}>

                    <hr />

                    <h3>Room {toHex(i, true)} @ ${toHexLong(baseAddress + p.offset)}+${toHex(p.count)}</h3>
                    {(p.mysteryBytes.length > 0) && <h4><b>Error</b> - ${toHex(p.mysteryBytes.length)} mystery bytes!</h4>}

                    <SequenceExplorer sequences={p.sequences} />
                </div>
            )}
        </>
    );
}